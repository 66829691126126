import { useChainId } from 'src/hooks/chain';
import { useApiQuery } from 'src/infrastructure/rest-api/useApi';

export const useChainSettingsApiQuery = () => {
    const chainId = useChainId();

    const { data, loading, error, refetch } = useApiQuery('/api/chainsettings/chains/{chainId}', 'get', {
        path: {
            chainId,
        },
        enabled: !!chainId,
    });

    return {
        data,
        loading,
        error,
        refetch,
    };
};
