import { useCallback, useState } from 'react';
import { Module, ScopeEnum, StockManagementRow } from 'src/domain';
import { StockMgmtTable } from 'src/domain/enums/stock-management-table';
import { useChangesSnackbar, useCurrentId, useScope } from 'src/hooks';
import { useDiscardChangesModule } from 'src/hooks/discard-changes/useDiscardChangesModule';
import { useCompositePartner } from 'src/hooks/partner/useCompositePartner';
import { useEntityType } from 'src/hooks/useEntityType';
import { UpdateStockManagementInput } from 'src/infrastructure/rest-api/api-types';
import { useApiMutation } from 'src/infrastructure/rest-api/useApi';
import { useStockManagementOverviewSimulationRows } from '../simulation/useStockManagementOverviewSimulationRows';
import { useTable } from '../table/useTable';

function mapRowsToInput(rows: StockManagementRow[]): UpdateStockManagementInput[] {
    const updateRows = rows.reduce<Record<string, UpdateStockManagementInput>>((acc, row) => {
        Object.keys(row.columns).forEach((month) => {
            if (month.endsWith('Id')) return;
            const id = row.columns[`${month}Id`] as number;
            acc[id] = acc[id] ?? {
                id,
            };

            if (row.subCategory === 'Minimum') {
                acc[id].minimum = row.columns[month] as number;
            }

            if (row.subCategory === 'Maximum') {
                acc[id].maximum = row.columns[month] as number;
            }
        });

        return acc;
    }, {});

    return Object.values(updateRows);
}

export const useStockManagementSaveChanges = () => {
    const selectedTable = useTable();
    const useStockManagementTotals = selectedTable === StockMgmtTable.Total;

    const { data: simulationRows } = useStockManagementOverviewSimulationRows();
    const [isUpdating, setIsUpdating] = useState(false);
    const showSnackbar = useChangesSnackbar();

    const discardChanges = useDiscardChangesModule(Module.StockManagement);

    const scope = useScope();
    const currentId = useCurrentId();
    const entityType = useEntityType();
    const compositePartner = useCompositePartner();

    const [mutateStockmanagement] = useApiMutation('/api/stockmanagement', 'put', {
        update(data, _variables, queryClient) {
            const query = { entityId: Number(currentId), entityType };
            if (scope === ScopeEnum.PARTNER) {
                query['entityId'] = Number(compositePartner?.id);
            }
            queryClient.setQueryData(['/api/stockmanagement', query], data);
        },
    });

    const sendUpdateQuery = useCallback(async () => {
        const filteredSimulationRows = simulationRows?.filter(
            (row) =>
                !row.category.includes('Stock Optimal') &&
                !row.category.includes('COGS') &&
                !row.category.includes('Total')
        );
        const valuesToUpdate = mapRowsToInput(filteredSimulationRows ?? []);
        return mutateStockmanagement({
            body: valuesToUpdate,
            query: {
                stockManagementSplit: useStockManagementTotals ? 'Totals' : 'Categories',
            },
        });
    }, [mutateStockmanagement, simulationRows, useStockManagementTotals]);

    const update = useCallback(async () => {
        setIsUpdating(true);

        try {
            const response = await sendUpdateQuery();
            discardChanges();
            showSnackbar();
            return response;
        } catch {
            showSnackbar(true);
        } finally {
            setIsUpdating(false);
        }
    }, [discardChanges, sendUpdateQuery, showSnackbar]);

    return [update, { loading: isUpdating }] as const;
};
