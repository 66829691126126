import { useMemo } from 'react';
import { EditableValueCell } from 'src/components/molecules/EditableCell/EditableCell';
import { createDynamicMonthColumns } from 'src/components/organisms/DataTable/columns/utils';
import { Inheritance, Module } from 'src/domain';
import { useInheritance } from 'src/hooks/inheritance';
import { mapDeliveryProfileToOverviewRows } from 'src/mapping/delivery-profile.mapping';
import { useDeliveryProfileQuery } from '../queries/useDeliveryProfileQuery';

export const useDeliveryProfileOverviewRows = (inheritance?: Inheritance) => {
    const { expected, styleCategories, error, loading } = useDeliveryProfileQuery(inheritance) ?? {};

    const moduleInheritance = useInheritance(Module.DeliveryProfile);
    const inheritanceToUse = inheritance ?? moduleInheritance;

    const tableData = useMemo(() => {
        if (!inheritanceToUse) {
            return;
        }
        return mapDeliveryProfileToOverviewRows(expected, styleCategories);
    }, [expected, styleCategories, inheritanceToUse]);

    const columns = useMemo(
        () =>
            expected
                ? createDynamicMonthColumns(
                      expected.filter((item) => !!item && !!item.monthKey) as { monthKey: number }[],
                      {
                          cellRenderer: EditableValueCell,
                      }
                  )
                : [],
        [expected]
    );

    return { data: tableData, columns, error, loading };
};
