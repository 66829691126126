import { ColDef } from 'ag-grid-community';
import { RowGroupCell } from 'src/components/molecules/RowGroupCell';

export const AutoGroupColumn: ColDef = {
    headerName: 'Limiter',
    field: 'subCategory',
    suppressSizeToFit: true,
    cellRenderer: RowGroupCell,
    cellRendererParams: {
        rowGroupField: 'category',
    },
    pinned: 'left',
    lockPinned: true,
};
