import { useReactiveVar } from '@bestseller-bit/retail-planning.utils.reactive-vars';
import { useMemo } from 'react';
import { countryIdToNameListVar } from 'src/infrastructure/local_state';
import { useCurrentStore } from '../store';
import { useQueryParams } from '../useQueryParams';

export const useCountry = () => {
    const [countryIdToNameList] = useReactiveVar(countryIdToNameListVar);
    const { countryId } = useQueryParams();
    const store = useCurrentStore();

    const country = useMemo(() => {
        const countryName = countryIdToNameList.get(Number(countryId));
        return countryName ?? store?.country?.name?.toUpperCaseLetters();
    }, [countryId, countryIdToNameList, store?.country?.name]);

    return country;
};
