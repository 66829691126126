import { useMemo } from 'react';
import { Module } from 'src/domain';
import { EnumUtil } from 'src/utils/EnumUtil';
import { useCurrentModuleName } from './useCurrentModuleName';

export const useCurrentModule = () => {
    const moduleName = useCurrentModuleName();
    const moduleArray = useMemo(() => EnumUtil.toArray(Module), []);

    const currentModule = useMemo(
        () => moduleArray.find((x) => moduleName?.equalsInsensitive(x)),
        [moduleArray, moduleName]
    );

    return currentModule;
};
