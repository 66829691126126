import { FC, memo, useMemo } from 'react';
import TitleCard from 'src/components/molecules/TitleCard';
import { ScopeEnum } from 'src/domain';
import { useCluster, useCurrentStore, useFilteredStores, useScope } from 'src/hooks';
import { useCountry } from 'src/hooks/country/useCountry';
import { useSelectedPartner } from 'src/hooks/useSelectedPartner';
import { StoreModel } from 'src/infrastructure/rest-api/api-types';

export const SmartTitleCard: FC = memo(() => {
    const scope = useScope();
    const countryName = useCountry();
    const clusterName = useCluster();
    const store = useCurrentStore();
    const storeName = store?.storeName;
    const storeNumber = store?.storeNumber;
    const storeTitle = `${storeName} (${storeNumber})`;
    const [partner] = useSelectedPartner();

    const [filteredStores] = useFilteredStores();
    const brandNumber = filteredStores?.[0]?.chain.brandNumber;

    const isLoading = useMemo(() => {
        switch (scope) {
            case ScopeEnum.STORE:
                return !storeName || !clusterName || !countryName;
            case ScopeEnum.CLUSTER:
                return !clusterName || !partner;
            case ScopeEnum.PARTNER:
                return !countryName || !partner;
        }
    }, [clusterName, countryName, partner, scope, storeName]);

    return (
        <TitleCard
            productLineNumber={brandNumber}
            level={scope}
            store={store as StoreModel}
            storeName={storeTitle}
            country={countryName}
            cluster={clusterName}
            partner={partner?.name}
            loading={isLoading}
        />
    );
});
