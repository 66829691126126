import { TableOverviewRow } from 'src/domain';

export function mapCategorySplitsToRows(
    rows: TableOverviewRow[],
    categorySplitRows: TableOverviewRow[]
): TableOverviewRow[] {
    return rows?.reduce<TableOverviewRow[]>((acc, item) => {
        Object.keys(item?.columns).forEach((fieldName) => {
            if (fieldName.endsWith('Id') || fieldName.endsWith('PlannedSplit')) return;

            const categorySplit = categorySplitRows?.find((x) => {
                return x?.category.toLowerCase() === item?.category.toLowerCase();
            });

            const index = acc.findIndex((x) => x?.id === item?.id);
            if (index === -1) {
                const result: TableOverviewRow = {
                    ...item,
                    columns: {
                        ...item?.columns,
                        [`${fieldName}PlannedSplit`]: categorySplit?.columns[fieldName] ?? 0,
                    },
                };
                acc.push(result);
            } else {
                const result = acc[index];
                result.columns[`${fieldName}PlannedSplit`] = categorySplit?.columns[fieldName] ?? 0;
                acc[index] = result;
            }
        });
        return acc;
    }, []);
}
