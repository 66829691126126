import { useReactiveVar } from '@bestseller-bit/retail-planning.utils.reactive-vars';
import { Module, ProductLine, ProductLineError } from 'src/domain';
import { selectedProductLineMapVar } from 'src/infrastructure/local_state';
import { SerializeMap } from 'src/utils/SerializeMap';
import { useCurrentId } from '../useCurrentId';
import { useCurrentModule } from '../useCurrentModule';
import { useScope } from '../useScope';

export const useSetSelectedProductLine = (module?: Module) => {
    const [selectedProductLineMap, setSelectedProductLineMap] = useReactiveVar(selectedProductLineMapVar);
    const scope = useScope();
    const id = useCurrentId();
    const currentModule = useCurrentModule();
    const selectedModule = module ?? currentModule;

    const useSetSelectedProductLine = (productline: ProductLine) => {
        if (!productline) throw new ProductLineError('Could not find selected productline');
        if (!scope) throw new ProductLineError('Could not find current scope');
        if (!selectedModule) throw new ProductLineError('Could not find current module');
        if (!id) throw new ProductLineError('Could not get current ID ');

        // we need to ensure new reference for reactive var to trigger
        selectedProductLineMap.set({ id, scope, module: selectedModule }, productline);
        setSelectedProductLineMap(new SerializeMap(selectedProductLineMap));
    };

    return useSetSelectedProductLine;
};
