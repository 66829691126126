import { ScopeEnum } from 'src/domain';
import { useCompositePartner } from 'src/hooks/partner/useCompositePartner';
import { useScope } from 'src/hooks/useScope';
import { useSelectedPartner } from 'src/hooks/useSelectedPartner';
import { useApiQuery } from 'src/infrastructure/rest-api/useApi';

export const usePartnerOverviewTurnover = () => {
    const scope = useScope();
    const [partner] = useSelectedPartner();
    const partnerComposite = useCompositePartner(partner);

    const { data, loading, error, refetch } = useApiQuery('/api/turnover/partners/{partnerId}', 'get', {
        path: {
            partnerId: partnerComposite?.id,
        },
        enabled: !!partnerComposite?.id && scope === ScopeEnum.PARTNER,
    });

    return { data, loading, error, refetch };
};
