import { isNumber } from 'lodash';
import { useCompositePartner } from 'src/hooks/partner/useCompositePartner';
import { useSelectedPartner } from 'src/hooks/useSelectedPartner';
import { useApiQuery } from 'src/infrastructure/rest-api/useApi';

export const useInheritanceSettingsQuery = () => {
    const [partner] = useSelectedPartner();
    const partnerComposite = useCompositePartner(partner);

    const { data, loading, error } = useApiQuery('/api/inheritances', 'get', {
        query: {
            partnerId: partnerComposite?.id,
        },
        enabled: isNumber(partnerComposite?.id),
    });

    return {
        data: data,
        loading,
        error,
    };
};
