import { Inheritance, Module } from 'src/domain';
import { useInheritance } from '../inheritance';

export interface NoosShareDetailsColumns {
    sharePercentLLY: string;
    sharePercentLY: string;
    plannedPercent: string;
    plannedValue: string;
    shareValueLY: string;
    shareValueLLY: string;
}
export const useNoosShareDetailsTableColumnNames = (): NoosShareDetailsColumns => {
    const inheritance = useInheritance(Module.NoosShare);

    const historicalSplitNames = (() => {
        switch (inheritance) {
            case Inheritance.Historical:
                return {
                    sharePercentLLY: 'NOOS % LLY',
                    sharePercentLY: 'NOOS % LY',
                };

            case Inheritance.NoosDDR:
                return {
                    sharePercentLLY: 'DDR % LLY',
                    sharePercentLY: 'DDR % LY',
                };

            case Inheritance.NoosHistoricalOrders:
                return {
                    sharePercentLLY: 'Order Share % LLY',
                    sharePercentLY: 'Order Share % LY',
                    plannedPercent: 'Planned Order Share',
                };

            default:
                return {
                    sharePercentLLY: 'Replenishment LLY',
                    sharePercentLY: 'Replenishment LY',
                    plannedPercent: 'Planned Replenishment',
                };
        }
    })();

    switch (inheritance) {
        case Inheritance.Historical:
            return {
                ...historicalSplitNames,
                plannedPercent: 'Planned NOOS',
                plannedValue: 'Planned Sales iV',
                shareValueLY: 'Sales iV LY',
                shareValueLLY: 'Sales iV LLY',
            };

        case Inheritance.NoosDDR:
            return {
                ...historicalSplitNames,
                plannedPercent: 'Planned DDR',
                plannedValue: 'Planned Sales iV',
                shareValueLY: 'Sales iV LY',
                shareValueLLY: 'Sales iV LLY',
            };

        case Inheritance.NoosHistoricalOrders:
            return {
                ...historicalSplitNames,
                plannedPercent: 'Planned Order Share',
                plannedValue: 'Planned Sales iV',
                shareValueLY: 'Sales iV LY',
                shareValueLLY: 'Sales iV LLY',
            };

        default:
            return {
                ...historicalSplitNames,
                plannedPercent: 'Planned Replenishment',
                plannedValue: 'Planned Sales iV',
                shareValueLY: 'Sales iV LY',
                shareValueLLY: 'Sales iV LLY',
            };
    }
};
