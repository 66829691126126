import { useMemo } from 'react';
import { Inheritance, Module } from 'src/domain';
import { useGenderId } from 'src/hooks/gender/useGenderId';
import { useInheritance } from 'src/hooks/inheritance/useInheritance';
import { useProductLineId } from 'src/hooks/productline/useProductLineId';
import { useStyleCategoriesQuery } from 'src/hooks/style-categories/queries/useStyleCategoriesQuery';
import { useWeekCoverSplitLevelQuery } from './useWeekCoverSplitLevelQuery';

export const useWeekCoverInheritanceQuery = (inheritance?: Inheritance, genderId?: number, productLineId?: number) => {
    const moduleInheritance = useInheritance(Module.WeekCover);
    const selectedInheritance = inheritance ?? moduleInheritance;

    const moduleGenderId = useGenderId(Module.WeekCover);
    const selectedGenderId = genderId ?? moduleGenderId;

    const moduleProductLineId = useProductLineId(Module.WeekCover);
    const selectedProductLineId = productLineId ?? moduleProductLineId;

    const {
        data: styleCategories,
        loading: styleCategoriesLoading,
        error: styleCategoriesError,
    } = useStyleCategoriesQuery();
    const { data, error, loading } = useWeekCoverSplitLevelQuery() ?? {};

    const sanitizedData = useMemo(() => {
        if (!data) return;

        switch (selectedInheritance) {
            case Inheritance.Typed:
            case Inheritance.Historical:
                return data.storeWeekCover ?? data.clusterWeekCover ?? data.partnerWeekCover;
            case Inheritance.Cluster:
                return data.clusterWeekCover;
            case Inheritance.Partner:
                return data.partnerWeekCover;
        }
    }, [data, selectedInheritance]);

    const filteredData = useMemo(() => {
        if (!sanitizedData) return;
        return sanitizedData.filter((item) => {
            const isGenderMatch = typeof selectedGenderId === 'number' ? item.genderId === selectedGenderId : true;
            const isProductLineMatch =
                typeof selectedProductLineId === 'number' ? item.productlineGroupId === selectedProductLineId : true;
            return isGenderMatch && isProductLineMatch;
        });
    }, [sanitizedData, selectedGenderId, selectedProductLineId]);

    return {
        data: filteredData,
        sanitizedData,
        styleCategories: styleCategories,
        error: error ?? styleCategoriesError,
        loading: loading || styleCategoriesLoading,
    };
};
