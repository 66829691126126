import { HelpOutline } from '@mui/icons-material';
import { Button, styled } from '@mui/material';
import { motion } from 'framer-motion';
import { FC, useState } from 'react';

export const StickyHelpButton: FC = () => {
    const [isHovering, setIsHovering] = useState<boolean>(false);

    return (
        <HelpButtonLink
            href="https://bestseller.service-now.com/bestsellersp?id=sc_cat_item&sys_id=8c2094411b55ac1046aff646464bcbd3&sysparm_category=0cba6c05dbf91410693e231cd39619a2&assignment_group=52724f32879fdd50fcd5cb790cbb3513"
            target={'_blank'}
            rel="noreferrer"
        >
            <HelpButton
                variant="contained"
                startIcon={<HelpOutline />}
                onMouseEnter={() => setIsHovering(true)}
                onMouseLeave={() => setIsHovering(false)}
                initial={{ width: '30px' }}
                whileHover={{ width: '150px' }}
                transition={{ ease: 'easeOut', duration: 0.2 }}
            >
                {isHovering ? 'Request help' : ''}
            </HelpButton>
        </HelpButtonLink>
    );
};

const HelpButtonLink = styled('a')`
  z-index: 999;
`;

// biome-ignore lint/suspicious/noExplicitAny: we need to use any
const HelpButton = styled(motion.create(Button as any))`
  position: fixed;
  right: 0px;
  bottom: 50px;
  text-transform: none;
  border-radius: 15px 0px 0px 15px;
  height: 40px;
  max-height: 40px;
  min-width: 30px;
  padding: 6px;
  text-align: center;
  white-space: nowrap;
  span {
    margin: 0;
  }
  &:hover {
    width: 150px;
    padding: 6px 16px;
    span {
      margin-right: 8px;
    }
  }
`;
