import { Delete } from '@mui/icons-material';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { FC } from 'react';

type DeleteDialogProps = {
    open: boolean;
    title: string;
    content: string;
    onDelete?: () => void | Promise<void>;
    onCancel?: () => void;
};

export const DeleteDialog: FC<DeleteDialogProps> = ({ onDelete, onCancel, open, title, content }) => {
    return (
        <Dialog
            data-testid="deleteDialog"
            open={open}
            onClose={onCancel}
            aria-labelledby="delete-dialog-title"
            aria-describedby="delete-dialog-description"
        >
            <DialogTitle id="delete-dialog-title">{title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="delete-dialog-description" noWrap={false}>
                    {content}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button data-testid="cancelButton" variant={'outlined'} onClick={onCancel} autoFocus>
                    Cancel
                </Button>
                <Button
                    data-testid="deleteButton"
                    color="error"
                    startIcon={<Delete />}
                    variant="contained"
                    onClick={onDelete}
                >
                    Delete
                </Button>
            </DialogActions>
        </Dialog>
    );
};
