import { useReactiveVar } from '@bestseller-bit/retail-planning.utils.reactive-vars';
import { useMemo } from 'react';
import { Module } from 'src/domain';
import { selectedProductLineMapVar } from 'src/infrastructure/local_state';
import { useCurrentId } from '../useCurrentId';
import { useCurrentModule } from '../useCurrentModule';
import { useScope } from '../useScope';

export const useSelectedProductLine = (module?: Module) => {
    const [selectedProductLineMap] = useReactiveVar(selectedProductLineMapVar);
    const scope = useScope();
    const id = useCurrentId();
    const currentModule = useCurrentModule();
    const selectedModule = module ?? currentModule;

    const selectedProductLine = useMemo(() => {
        if (!scope || !id || !selectedModule) return;

        return selectedProductLineMap.get({ id, scope, module: selectedModule });
    }, [id, selectedProductLineMap, selectedModule, scope]);

    return selectedProductLine;
};
