import { useCallback, useRef, useState } from 'react';

export const useBufferTime = <T>(delay?: number) => {
    const [buffer, setBuffer] = useState<T[]>([]);
    const internalBuffer = useRef<T[]>([]);
    const timerRef = useRef<NodeJS.Timeout>();

    const pushToBuffer = useCallback(
        (val: T) => {
            clearTimeout(timerRef.current);

            internalBuffer.current.push(val);

            timerRef.current = setTimeout(() => {
                setBuffer([...internalBuffer.current]);
                internalBuffer.current = [];
            }, delay ?? 10);
        },
        [delay]
    );

    return [buffer, pushToBuffer] as const;
};
