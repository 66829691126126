import { Partner } from 'src/domain';
import { useApiQuery } from 'src/infrastructure/rest-api/useApi';
import { useCurrentStore } from '../store';
import { useSelectedPartner } from '../useSelectedPartner';

export const useCompositePartner = (partner?: Partner) => {
    const [selectedPartner] = useSelectedPartner();
    const store = useCurrentStore();

    const partnerToUse = partner ?? selectedPartner ?? { compositeId: store?.operationalResponsibleComposite?.id };

    const { data } = useApiQuery('/api/partners/{partnerId}', 'get', {
        path: {
            partnerId: partnerToUse?.compositeId,
        },
        enabled: !!partnerToUse?.compositeId,
    });

    return data;
};
