import { Box, styled } from '@mui/material';
import { CustomCellEditorProps } from 'ag-grid-react';
import { FC } from 'react';
import Select from 'src/components/atoms/Select';
import { GroupedOption } from 'src/components/atoms/Select/Select';

interface SelectCellEditorProps extends CustomCellEditorProps {
    selectOptions: GroupedOption[];
    isHeaderSelectable?: boolean;
}

export const SelectCellEditor: FC<SelectCellEditorProps> = ({
    selectOptions,
    isHeaderSelectable = false,
    column,
    onValueChange,
    value,
    api,
}) => {
    return (
        <SelectWrapper>
            <Select
                open={true}
                isHeaderSelectable={isHeaderSelectable}
                useMargin={false}
                width={column.getActualWidth()}
                options={selectOptions}
                onSelect={onValueChange}
                activeItem={value ?? ''}
                showLabel={false}
                onClose={() => {
                    setTimeout(() => api.stopEditing());
                }}
            ></Select>
        </SelectWrapper>
    );
};

const SelectWrapper = styled(Box)`
  .MuiFormControl-root,
  .MuiSelect-select {
    height: 100%;
  }

  .MuiSelect-select {
    padding: 8px 32px 8px 12px;
  }

  .MuiInputBase-root {
    font-size: inherit;
  }
`;
