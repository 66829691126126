import { Stack } from '@mui/material';
import { CellClassParams, ColDef, IsGroupOpenByDefaultParams } from 'ag-grid-community';
import { FC } from 'react';
import Container from 'src/components/atoms/Container';
import Header from 'src/components/atoms/Header';
import { RowGroupCell } from 'src/components/molecules/RowGroupCell';
import { GroupedExpandableCell } from 'src/components/molecules/RowGroupCell/RowGroupCell';
import { DataTable } from 'src/components/organisms/DataTable/DataTable';
import { defaultColDefNumberWithSpaces } from 'src/components/organisms/DataTable/columns/default-options';
import { defaultRowGroupOptions } from 'src/components/organisms/DataTable/options/row-group-options';
import { customRowGroupHeight } from 'src/components/organisms/DataTable/options/row-height-options';
import { SumBold } from 'src/components/organisms/DataTable/options/sum-bold';
import { validateSumEqualsTotal } from 'src/components/organisms/DataTable/validators/validateSumEqualsTotal';
import Topbar from 'src/components/organisms/Topbar';
import { StyledHeaderWrapper } from 'src/components/styled/StyledHeaderWrapper';
import { TableWrapper } from 'src/components/styled/TableWrapper';
import { Headings, Module, ScopeEnum } from 'src/domain';
import { useHasUnsavedChanges, useScope } from 'src/hooks';
import { useDiscardChangesModule } from 'src/hooks/discard-changes/useDiscardChangesModule';
import { useCanSave } from 'src/hooks/optimal-option-count/save-changes/useCanSave';
import { useOptimalOptionCountSaveChanges } from 'src/hooks/optimal-option-count/save-changes/useOptimalOptionCountSaveChanges';
import { useOptimalOptionCountPlacementSimulationRows } from 'src/hooks/optimal-option-count/simulation/useOptimalOptionCountPlacementSimulationRows';
import { useOptimalOptionCountTotalsSimulationRows } from 'src/hooks/optimal-option-count/simulation/useOptimalOptionCountTotalsSimulationRows';
import { useOptimalOptionCountStoreRows } from 'src/hooks/optimal-option-count/table/useOptimalOptionCountStoreRows';
import { useMonthsWithinTreshhold } from 'src/hooks/optimal-option-count/useIsWithinTreshhold';
import { useOptimalOptionCountHandleCellChanges } from 'src/hooks/optimal-option-count/useOptimalOptionCountHandleCellChanges';
import { defaultColDef, placementsColumns, storesColumns, totalsColumns } from './data/columns';

export const OptimalOptionCount: FC = () => {
    const scope = useScope();
    const { data: totalsRows, loading: totalsLoading } = useOptimalOptionCountTotalsSimulationRows();
    const { data: placementRows, loading: placementLoading } = useOptimalOptionCountPlacementSimulationRows();
    const { data: storesRows, loading: storesLoading } = useOptimalOptionCountStoreRows();

    const [saveChanges, { loading: savingChanges }] = useOptimalOptionCountSaveChanges();
    const onDiscardChanges = useDiscardChangesModule(Module.OptimalOptionCount);
    const handleCellValueChanges = useOptimalOptionCountHandleCellChanges();
    const monthsWithinTreshhold = useMonthsWithinTreshhold();

    const hasUnsavedChanges = useHasUnsavedChanges(Module.OptimalOptionCount);

    const totalsIsLoading = totalsLoading || savingChanges;
    const placementIsLoading = placementLoading || savingChanges;
    const isLoading = totalsIsLoading || placementIsLoading;
    const canSave = useCanSave();

    const updateColumnsWithScope = (cols: ColDef[]) => {
        return cols.map((column) => {
            if (scope !== ScopeEnum.STORE) {
                return {
                    ...column,
                    width: 210,
                    editable: false,
                    cellRenderer: null,
                };
            }
            return { ...column };
        });
    };

    return (
        <Stack spacing={2}>
            <Topbar
                hideToggleButtons={true}
                discardDisabled={!hasUnsavedChanges}
                saveDisabled={!canSave}
                loading={isLoading}
                saveLoading={savingChanges}
                onSave={saveChanges}
                onDiscard={onDiscardChanges}
            />
            <Container>
                <StyledHeaderWrapper>
                    <Header heading={Headings.h2}>Optimal Option Count</Header>
                </StyledHeaderWrapper>
                <TableWrapper
                    className="ag-theme-alpine-custom-compact ag-minimal-height"
                    data-testid="optimal-option-count-totals-table"
                >
                    {scope === ScopeEnum.STORE ? (
                        <DataTable
                            key={'totals-table-store'}
                            autoSizeToFit
                            columns={totalsColumns}
                            rows={totalsRows}
                            defaultColDef={defaultColDefNumberWithSpaces}
                            onCellValuesChanged={handleCellValueChanges}
                            loading={totalsLoading}
                        />
                    ) : (
                        <DataTable
                            key={'totals-table-partner-cluster'}
                            autoSizeToFit
                            columns={updateColumnsWithScope(totalsColumns)}
                            rows={totalsRows}
                            defaultColDef={defaultColDefNumberWithSpaces}
                            loading={totalsLoading}
                        />
                    )}
                </TableWrapper>
                {scope === ScopeEnum.STORE && (
                    <TableWrapper
                        className="ag-theme-alpine-custom-compact"
                        data-testid="optimal-option-count-placement-table"
                    >
                        <DataTable
                            {...defaultRowGroupOptions}
                            {...SumBold}
                            autoGroupColumnDef={{
                                headerName: 'Placement',
                                field: 'subCategory',
                                cellRenderer: RowGroupCell,
                                cellRendererParams: {
                                    rowGroupField: 'category',
                                    aggregationLabel: 'Total',
                                },
                                suppressSizeToFit: true,
                                initialWidth: 160,
                                pinned: 'left',
                                lockPinned: true,
                            }}
                            autoSizeToFit
                            columns={updateColumnsWithScope(placementsColumns)}
                            suppressAggFuncInHeader={true}
                            rows={placementRows}
                            defaultColDef={defaultColDef}
                            onCellValuesChanged={handleCellValueChanges}
                            loading={placementLoading}
                            cellValidationOptions={{
                                columns: true,
                                validationFunc: (params: CellClassParams) =>
                                    validateSumEqualsTotal(params, monthsWithinTreshhold),
                            }}
                        />
                    </TableWrapper>
                )}
            </Container>
            {scope !== ScopeEnum.STORE && (
                <Container>
                    <StyledHeaderWrapper>
                        <Header heading={Headings.h2}>Stores Overview</Header>
                    </StyledHeaderWrapper>
                    <TableWrapper
                        className="ag-theme-alpine-custom-compact ag-sum"
                        data-testid="optimal-option-count-stores-overview-table"
                    >
                        <DataTable
                            {...SumBold}
                            getRowHeight={customRowGroupHeight}
                            suppressAggFuncInHeader={true}
                            groupDisplayType={'singleColumn'}
                            autoGroupColumnDef={{
                                headerName: 'Store',
                                field: 'subCategory',
                                suppressSizeToFit: true,
                                initialWidth: 210,
                                cellRendererParams: {
                                    suppressCount: true,
                                },
                                cellRenderer: GroupedExpandableCell,
                                pinned: 'left',
                                lockPinned: true,
                            }}
                            isGroupOpenByDefault={(params: IsGroupOpenByDefaultParams) => params.field === 'category'}
                            showOpenedGroup={true}
                            groupRemoveSingleChildren={true}
                            autoSizeToFit
                            columns={storesColumns}
                            rows={storesRows}
                            defaultColDef={defaultColDef}
                            loading={storesLoading}
                        />
                    </TableWrapper>
                </Container>
            )}
        </Stack>
    );
};
