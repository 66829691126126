import { IAggFuncParams } from 'ag-grid-community';
import { parseNumber } from '../formatNumber';

export function sumAggFunc({ values }: IAggFuncParams) {
    return values.reduce((acc, value) => {
        const valueAsNumber = parseNumber(value);
        if (isNaN(valueAsNumber)) return acc;
        return acc + valueAsNumber;
    }, 0);
}

export function avgAggFunc(params: IAggFuncParams) {
    const sum = sumAggFunc(params);
    return sum / params.values.length;
}
