import { useReactiveVar } from '@bestseller-bit/retail-planning.utils.reactive-vars';
import { useMemo } from 'react';
import { useClusterId } from 'src/hooks/cluster';
import { useIsUsingGender } from 'src/hooks/gender/useIsUsingGender';
import { useInitializeIsGenderSplitHistorical } from 'src/hooks/historical';
import { useCompositePartner } from 'src/hooks/partner/useCompositePartner';
import { useStoreId } from 'src/hooks/store';
import { selectedPartnerVar } from 'src/infrastructure/local_state';
import { components } from 'src/infrastructure/rest-api/generated';
import { useGenderSplitApiQuery } from './useGenderSplitApiQuery';

export type GenderSplit = components['schemas']['GenderSplitModel'];

export type GenderSplitLevelQuery = {
    data:
        | {
              storeGenderSplit?: GenderSplit[];
              clusterGenderSplit?: GenderSplit[];
              partnerGenderSplit?: GenderSplit[];
          }
        | undefined;
    error: Error | null;
    loading: boolean;
};

export const useGenderSplitSplitLevelQuery = (): GenderSplitLevelQuery | undefined => {
    const storeId = useStoreId();
    const [selectedPartner] = useReactiveVar(selectedPartnerVar);
    const clusterId = useClusterId(selectedPartner);
    const compositePartner = useCompositePartner();

    const isUsingGenderSplit = useIsUsingGender();

    const {
        data: storeGenderSplit,
        error: storeError,
        loading: storeLoading,
    } = useGenderSplitApiQuery(storeId, 'Store');
    const {
        data: clusterGenderSplit,
        error: clusterError,
        loading: clusterLoading,
    } = useGenderSplitApiQuery(clusterId, 'Cluster');
    const {
        data: partnerGenderSplit,
        error: partnerError,
        loading: partnerLoading,
    } = useGenderSplitApiQuery(compositePartner?.id, 'Partner');

    const data = useMemo(() => {
        if (!isUsingGenderSplit) return;

        return {
            storeGenderSplit,
            clusterGenderSplit,
            partnerGenderSplit,
        };
    }, [isUsingGenderSplit, storeGenderSplit, clusterGenderSplit, partnerGenderSplit]);

    useInitializeIsGenderSplitHistorical(data);

    return {
        data,
        error: storeError ?? clusterError ?? partnerError,
        loading: storeLoading ?? clusterLoading ?? partnerLoading,
    };
};
