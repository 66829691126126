import { Inheritance } from 'src/domain';
import { useStyleCategoriesQuery } from 'src/hooks/style-categories/queries/useStyleCategoriesQuery';
import { useNoosShareInheritanceQuery } from './useNoosShareInheritanceQuery';

export const useNoosShareQuery = (inheritance?: Inheritance) => {
    const {
        data: styleCategories,
        error: styleCategoriesError,
        loading: styleCategoriesLoading,
    } = useStyleCategoriesQuery();

    const { data, expected, error, loading } = useNoosShareInheritanceQuery(inheritance);
    return {
        expected,
        data,
        styleCategories: styleCategories,
        error: error ?? styleCategoriesError,
        loading: loading || styleCategoriesLoading,
    };
};
