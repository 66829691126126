import styled from '@emotion/styled';
import { Tooltip as MuiTooltip } from '@mui/material';
import { ITooltipParams } from 'ag-grid-community';

export function CustomAgGridTooltip(params: ITooltipParams) {
    return (
        <StyledMuiTooltip title={params?.data?.buyingEvent}>
            <span>{params?.data?.buyingEvent}</span>
        </StyledMuiTooltip>
    );
}

const StyledMuiTooltip = styled(MuiTooltip)`
  background: #fff;
  padding: 3px 10px;
  border: 0.5px solid #333;
  border-radius: 3px;
`;
