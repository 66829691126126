import { useReactiveVar } from '@bestseller-bit/retail-planning.utils.reactive-vars';
import { useMemo } from 'react';
import { useClusterId } from 'src/hooks/cluster';
import { useCompositePartner } from 'src/hooks/partner/useCompositePartner';
import { useStoreId } from 'src/hooks/store';
import { selectedPartnerVar } from 'src/infrastructure/local_state';
import { components } from 'src/infrastructure/rest-api/generated';
import { useWeekCoverApiQuery } from './useWeekCoverApiQuery';

type WeekCover = components['schemas']['WeekCoverExpectedModel'];

export type WeekCoverSplitLevelQuery = {
    data: {
        storeWeekCover?: WeekCover[];
        clusterWeekCover?: WeekCover[];
        partnerWeekCover?: WeekCover[];
    };
    error: Error | null;
    loading: boolean;
};

export const useWeekCoverSplitLevelQuery = (): WeekCoverSplitLevelQuery | undefined => {
    const storeId = useStoreId();
    const [selectedPartner] = useReactiveVar(selectedPartnerVar);
    const clusterId = useClusterId(selectedPartner);
    const compositePartner = useCompositePartner();

    const storeResponse = useWeekCoverApiQuery(storeId, 'Store');
    const clusterResponse = useWeekCoverApiQuery(clusterId, 'Cluster');
    const partnerResponse = useWeekCoverApiQuery(compositePartner?.id, 'Partner');

    const response = useMemo<WeekCoverSplitLevelQuery | undefined>(() => {
        return {
            data: {
                storeWeekCover: storeResponse.data,
                clusterWeekCover: clusterResponse.data,
                partnerWeekCover: partnerResponse.data,
            },
            error: storeResponse.error ?? clusterResponse.error ?? partnerResponse.error,
            loading: storeResponse.loading || clusterResponse.loading || partnerResponse.loading,
        };
    }, [
        clusterResponse.data,
        clusterResponse.error,
        clusterResponse.loading,
        partnerResponse.data,
        partnerResponse.error,
        partnerResponse.loading,
        storeResponse.data,
        storeResponse.error,
        storeResponse.loading,
    ]);

    return response;
};
