import { useMemo } from 'react';
import { Module, TableOverviewRow } from 'src/domain';
import { NoosShareDetailsRow } from 'src/domain/table/noos-share.row';
import { useCategorySplitOverviewSimulationRows } from 'src/hooks/category-split/simulation/useCategorySplitOverviewSimulationRows';
import { useGender, useGenderId, useIsUsingGender } from 'src/hooks/gender';
import { useGenderSplitOverviewSimulationRows } from 'src/hooks/gender-split/simulation/useGenderSplitOverviewSimulationRows';
import { useIsUsingProductline, useProductLine } from 'src/hooks/productline';
import { useProductLineSplitOverviewSimulationRows } from 'src/hooks/productline-split/simulation/useProductLineSplitOverviewSimulationRows';
import { useTurnoverSimulationRows } from 'src/hooks/turnover/simulation/useTurnoverSimulationRows';
import { getGenderProductLineSplit } from 'src/mapping/getGenderProductLineSplit';
import { monthKeyToField } from 'src/utils/monthKeys';
import { useNoosShareDetailsRows } from '../table/useNoosShareDetailsRows';
import { useNoosShareOverviewSimulationRows } from './useNoosShareOverviewSimulationRows';

export interface DetailsRowMapperProps {
    month: string;
    turnoverPlannedSalesIv: number;
    genderProductLineSplit: number;
    categorySplitSimulationData: TableOverviewRow[] | undefined;
    noosShareSimulationData: TableOverviewRow[] | undefined;
}

export const detailsRowMapper = ({
    month,
    genderProductLineSplit,
    turnoverPlannedSalesIv,
    categorySplitSimulationData,
    noosShareSimulationData,
}: DetailsRowMapperProps) => {
    return (row: NoosShareDetailsRow): NoosShareDetailsRow => {
        const noosShare = noosShareSimulationData?.find((change) => change.category.equalsInsensitive(row.category))
            ?.columns[month];

        const categorySplit =
            categorySplitSimulationData?.find((change) => change.category.equalsInsensitive(row.category))?.columns[
                month
            ] ?? 0;

        const plannedPercent = noosShare ?? 0;
        const plannedValue: number =
            turnoverPlannedSalesIv * genderProductLineSplit * (categorySplit / 100) * (plannedPercent / 100);

        return {
            ...row,
            plannedPercent,
            plannedValue: Math.round(plannedValue * 100) / 100,
        };
    };
};

export const useNoosShareDetailsSimulationRows = () => {
    const isUsingGender = useIsUsingGender();
    const isUsingProductLine = useIsUsingProductline();
    const gender = useGender(Module.NoosShare);
    const genderId = useGenderId(Module.NoosShare);
    const productLine = useProductLine(Module.NoosShare);

    const { data, loading, error } = useNoosShareDetailsRows() ?? {};

    const { data: turnoverSimulationData } = useTurnoverSimulationRows();
    const { data: genderSplitSimulationData } = useGenderSplitOverviewSimulationRows();
    const { data: productLineSplitSimulationData } = useProductLineSplitOverviewSimulationRows();
    const { data: categorySplitSimulationData } = useCategorySplitOverviewSimulationRows(
        undefined,
        genderId,
        productLine?.id
    );
    const { data: noosShareSimulationData } = useNoosShareOverviewSimulationRows();

    const simulatedData = useMemo(() => {
        if (!data) return;
        if (
            !genderSplitSimulationData &&
            !noosShareSimulationData &&
            !productLineSplitSimulationData &&
            !categorySplitSimulationData &&
            !turnoverSimulationData
        ) {
            return data;
        }

        const map = new Map<string, NoosShareDetailsRow[]>();

        data.forEach((detailsRows, month) => {
            const turnoverPlannedSalesIv = turnoverSimulationData?.find((change) =>
                monthKeyToField(change.monthKey).equalsInsensitive(month)
            )?.plannedSalesIv;

            const genderSplit = genderSplitSimulationData?.find((change) => change.category.equalsInsensitive(gender))
                ?.columns[month];

            const productLineSplit = productLineSplitSimulationData?.find(
                (change) =>
                    change.category.equalsInsensitive(productLine?.name) &&
                    change.subCategory?.equalsInsensitive(gender)
            )?.columns[month];

            map.set(
                month,
                detailsRows.map(
                    detailsRowMapper({
                        month,
                        turnoverPlannedSalesIv: turnoverPlannedSalesIv ?? 0,
                        genderProductLineSplit: getGenderProductLineSplit({
                            isUsingGender,
                            isUsingProductLine,
                            genderSplit,
                            productLineSplit,
                        }),
                        categorySplitSimulationData,
                        noosShareSimulationData: noosShareSimulationData,
                    })
                )
            );
        });

        return map;
    }, [
        data,
        genderSplitSimulationData,
        noosShareSimulationData,
        productLineSplitSimulationData,
        categorySplitSimulationData,
        turnoverSimulationData,
        isUsingGender,
        isUsingProductLine,
        gender,
        productLine?.name,
    ]);

    return { data: simulatedData, loading, error };
};
