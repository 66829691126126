import { Dispatch, FC, PropsWithChildren, SetStateAction, createContext, useMemo, useState } from 'react';
import { IsHistoricalMap } from 'src/domain';

export const IsHistoricalContext = createContext<readonly [IsHistoricalMap, Dispatch<SetStateAction<IsHistoricalMap>>]>(
    // biome-ignore lint/suspicious/noEmptyBlockStatements: <explanation>
    [new Map(), () => {}]
);

export const IsHistoricalProvider: FC<PropsWithChildren<unknown>> = ({ children }) => {
    const [isHistoricalMap, setIsHistoricalMap] = useState<IsHistoricalMap>(new Map());

    const contextValue = useMemo(() => [isHistoricalMap, setIsHistoricalMap] as const, [isHistoricalMap]);

    return <IsHistoricalContext.Provider value={contextValue}>{children}</IsHistoricalContext.Provider>;
};
