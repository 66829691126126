import styled from '@emotion/styled';
import { Theme, useTheme } from '@mui/material';
import { FC } from 'react';
import { Route, Routes } from 'react-router-dom';
import { useDrawerState } from 'src/hooks';
import { DefaultSidebar } from './routes/Default';
import { SettingsSidebar } from './routes/Settings';

interface SidebarProps {
    drawerWidth?: number;
}

export const Sidebar: FC<SidebarProps> = ({ drawerWidth = 300 }) => {
    const { hasSidebar } = useDrawerState();
    const theme = useTheme();

    return (
        <StyledBox hasSidebar={hasSidebar} theme={theme} drawerWidth={drawerWidth}>
            <Routes>
                <Route path="/settings/*" element={<SettingsSidebar />} />
                <Route path="/*" element={hasSidebar && <DefaultSidebar />} />
            </Routes>
        </StyledBox>
    );
};

const StyledBox = styled.div<{ hasSidebar: boolean; theme: Theme; drawerWidth: number }>`
  --drawer-closed-width: calc(${({ theme }) => theme.spacing(7)} + 1px);
  background-color: ${({ hasSidebar }) => (hasSidebar ? '#b4b4b4' : '')};
  z-index: 1199;
  position: absolute;
  left: var(--drawer-closed-width);
  box-sizing: border-box;
  width: calc(${({ drawerWidth }) => drawerWidth}px - var(--drawer-closed-width));
  height: 100vh;
  overflow-y: auto;
`;
