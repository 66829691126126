import { sentenceCase } from 'change-case';
import { format } from 'date-fns';
import { NotificationData } from 'src/hooks/notifications/queries/useNotificationsQuery';
import { NotificationSourceTypeEnum } from 'src/infrastructure/rest-api/api-types';

export type PR = {
    type: NotificationSourceTypeEnum;
    text: string;
    releaseNumber: string | undefined;
};

export type Release = {
    title: string;
    body: PR[];
    dateTime: string;
    key: string;
};

export const mapReleases = (releaseNotifications: NotificationData | null) => {
    if (!releaseNotifications) return;

    return releaseNotifications.reduce<Release[]>((acc, releaseNotification) => {
        const title = releaseNotification?.title;
        const body = releaseNotification.body;
        const dateTime = releaseNotification.dateTime;
        const type = releaseNotification.sourceType;

        if (!title) return acc;

        const date = new Date(dateTime);
        const monthShort = format(date, 'MMM');
        const day = date.getDate();
        const formattedDateTime = `${monthShort} ${day}`;
        const key = format(date, 'yyyy-MM-dd');

        const releaseNumber = releaseNotification?.title?.match(/(\d+.{1}\d{1,2})/)?.[0];

        const bodyArray = body?.split('\n').reduce<PR[]>((acc, pr) => {
            if (!type || !pr) return acc;
            acc.push({
                type,
                text: sentenceCase(pr.replace('* ', '')),
                releaseNumber,
            });
            return acc;
        }, []);

        const index = acc.findIndex((release) => release.key === key);

        if (index !== -1 && bodyArray) {
            acc[index].body.push(...bodyArray);
        } else {
            const result: Release = {
                title: formattedDateTime,
                body: bodyArray ?? [],
                dateTime: formattedDateTime,
                key,
            };
            acc.push(result);
        }

        return acc;
    }, []);
};
