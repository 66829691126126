import { isNumber } from 'lodash';
import { DeliveryProfileRow } from 'src/domain/table/delivery-profile.row';

import { DeliveryProfileExpectedModel, StyleCategoryModel } from 'src/infrastructure/rest-api/api-types';
import { monthKeyToField } from 'src/utils/monthKeys';

export function groupByStyle(data: DeliveryProfileExpectedModel[]): Map<number, DeliveryProfileExpectedModel[]> {
    return data.reduce((acc, item) => {
        const styleCategoryId = item?.styleCategoryId;
        if (!styleCategoryId) {
            return acc;
        }

        const items = acc.get(styleCategoryId) ?? [];
        items.push(item);
        acc.set(styleCategoryId, items);

        return acc;
    }, new Map<number, DeliveryProfileExpectedModel[]>());
}

export function valuesToDeliveryProfileRow(values: DeliveryProfileExpectedModel[]): Record<string, number> {
    return values.reduce(
        (acc, value) => {
            if (!value?.monthKey || !value?.id) {
                return acc;
            }

            const monthKeyStr = monthKeyToField(value.monthKey);
            acc[monthKeyStr] = value.value;
            acc[`${monthKeyStr}Id`] = value.id;
            return acc;
        },
        {} as Record<string, number>
    );
}

export function mapDeliveryProfileToOverviewRows(
    data: DeliveryProfileExpectedModel[] | null | undefined,
    styleCategories: StyleCategoryModel[] | undefined
): DeliveryProfileRow[] {
    if (!data || !styleCategories) {
        return [];
    }

    const groupedByStyle = groupByStyle(data);

    const rows = Array.from(groupedByStyle.entries()).reduce<DeliveryProfileRow[]>((acc, styleGroup) => {
        const [styleCategoryId, values] = styleGroup;

        const styleCategory = styleCategories?.find((category) => category.id === styleCategoryId);

        if (!styleCategory?.name) {
            return acc;
        }

        acc.push({
            category: styleCategory.name,
            id: styleCategory.id,
            columns: valuesToDeliveryProfileRow(values),
        });

        return acc;
    }, []);

    // Ensure sorting order follows style categories
    rows.sort((a, b) => {
        const aStyleCategory = styleCategories?.find((category) => category.id === a.id);
        const bStyleCategory = styleCategories?.find((category) => category.id === b.id);

        if (!aStyleCategory || !bStyleCategory) {
            return 1;
        }

        if (!isNumber(aStyleCategory.order) || !isNumber(bStyleCategory.order)) {
            return 1;
        }

        return aStyleCategory.order - bStyleCategory.order;
    });

    return rows;
}
