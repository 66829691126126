import { useMemo } from 'react';
import { BuyingEvent, mapOpenToBuyLimitToTableData } from 'src/mapping/open-to-buy-limit.mapping';
import { useOpenToBuyLimitQuery } from '../queries/useOpenToBuyLimitQuery';

export const useOpenToBuyLimitOverviewRows = () => {
    const { data, plannedOn, buyingEvents, loading, error } = useOpenToBuyLimitQuery();
    const { rows: tableData, columns } = useMemo(
        () => mapOpenToBuyLimitToTableData(data, buyingEvents, plannedOn),
        [buyingEvents, data, plannedOn]
    );

    const mappedBuyingEvents = buyingEvents?.reduce<BuyingEvent[]>((acc, event) => {
        if (event?.id) {
            acc.push({
                id: event.id,
                name: event.name,
                orderMonth: event.orderMonth,
                buyingStart: event.buyingStart,
                buyingEnd: event.buyingEnd,
                isDefault: event.isDefault,
            });
        }
        return acc;
    }, []);

    return { data: tableData, buyingEvents: mappedBuyingEvents, columns, plannedOn, loading, error };
};
