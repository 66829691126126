import { useEffect } from 'react';

const useScrollRestoration = (scrollableRef: React.RefObject<HTMLElement>) => {
    useEffect(() => {
        if (scrollableRef.current && typeof scrollableRef.current.scrollTo === 'function') {
            scrollableRef.current.scrollTo(0, 0);
        }
    }, [scrollableRef]);
};

export default useScrollRestoration;
