import { ColDef } from 'ag-grid-community';

export const withDefaultHeaderToolTip = <T extends ColDef>(columns: T[]): T[] => {
    return columns.map((column) => {
        return {
            headerTooltip: column.headerName,
            ...column,
        };
    });
};
