import { ColDef, EditableCallback } from 'ag-grid-community';
import { groupBy, sortBy } from 'lodash';
import { EditablePercentageCell } from 'src/components/molecules/EditableCell/EditableCell';
import { DefaultColumnsOptionsOverviewPercentage } from 'src/components/organisms/DataTable/columns/default-options';
import { IsEditable } from 'src/components/organisms/DataTable/columns/is-editable';
import { createDynamicMonthColumns } from 'src/components/organisms/DataTable/columns/utils';
import { Nullable } from 'src/domain';
import { OpenToBuyLimitOverviewRow } from 'src/domain/table/open-to-buy-limit.row';
import { BuyingEventModel, OpenToBuyLimitModel } from 'src/infrastructure/rest-api/api-types';
import { sumAggFunc } from 'src/utils/ag-grid/agg-functions';
import { monthKeyToField } from 'src/utils/monthKeys';

export interface PlannedOnEntry {
    id: number;
    name: Nullable<string>;
    sort?: Nullable<number>;
}

export interface BuyingEvent {
    id: number;
    name: Nullable<string>;
    orderMonth?: Nullable<number>;
    buyingStart?: Nullable<number>;
    buyingEnd?: Nullable<number>;
    isDefault?: Nullable<boolean>;
}

export function mapOpenToBuyLimitToTableData(
    data: Nullable<OpenToBuyLimitModel[]>,
    buyingEvents: Nullable<BuyingEventModel[]>,
    plannedOn: PlannedOnEntry[] | undefined
): { rows: OpenToBuyLimitOverviewRow[] | undefined; columns: ColDef<OpenToBuyLimitOverviewRow>[] | undefined } {
    if (!data || !buyingEvents || !plannedOn) {
        return {
            rows: undefined,
            columns: undefined,
        };
    }

    const groupedByPlannedOnId = groupBy(data, (item) => item.plannedOnId);

    const rows = Object.keys(groupedByPlannedOnId).reduce<OpenToBuyLimitOverviewRow[]>((acc, groupId) => {
        const group = groupedByPlannedOnId[groupId];
        const groupedByBuyingEvent = groupBy(group, (item) => item.buyingEventId);
        const category = plannedOn.find((planOn) => planOn.id.toString() === groupId);

        Object.keys(groupedByBuyingEvent).forEach((buyingEventGroupId) => {
            const buyingEvent = buyingEvents?.find((event) => event?.id.toString() === buyingEventGroupId);
            const buyingEventGroup = groupedByBuyingEvent[buyingEventGroupId];

            acc.push({
                id: Number(`${groupId}${buyingEventGroupId}`),
                category: category?.name ?? '',
                categoryId: Number(groupId),
                buyingEvent: buyingEvent?.name ?? '',
                buyingEventId: buyingEvent?.id ?? -1,
                orderMonth: buyingEvent?.orderMonth,
                isDefault: buyingEvent?.isDefault,
                columns: buyingEventGroup.reduce(
                    (acc, buyingEvent) => {
                        const fieldName = monthKeyToField(buyingEvent.monthKey);
                        acc[fieldName] = buyingEvent.splitPercent;
                        acc[`${fieldName}Id`] = buyingEvent.id;
                        return acc;
                    },
                    {} as Record<string, number>
                ),
            });
        });

        return acc;
    }, []);

    const sortedRows = sortRows(rows, plannedOn);

    const columns = createDynamicMonthColumns<OpenToBuyLimitOverviewRow>(data, {
        editable: isOpenToBuyLimitCellEditable,
        aggFunc: sumAggFunc,
        cellRenderer: EditablePercentageCell,
        valueFormatter: DefaultColumnsOptionsOverviewPercentage.valueFormatter,
    });

    return { rows: sortedRows, columns };
}

export function sortRows(rows: OpenToBuyLimitOverviewRow[], plannedOn: PlannedOnEntry[]) {
    return sortBy(rows, [
        (value, _) => {
            const categorySort = plannedOn?.find((x) => x?.name?.includes(value?.category))?.sort;
            if (categorySort) return categorySort;
            return value.categoryId;
        },
        (value, _) => {
            return value.orderMonth;
        },
    ]);
}

export const isOpenToBuyLimitCellEditable: EditableCallback<OpenToBuyLimitOverviewRow> = (params) => {
    // TODO: Can we solve this in a better way? This seems a bit flaky, since it is implicitly relying on the key to not be present. If set to zero or undefined instead, this check will fail. See also useIsWithinTreshhold hook.
    const columnId = params.column.getColId();
    if (params.data) {
        if (params.data.isDefault) {
            return false;
        }

        const month = columnId.split('.')[1];
        if (Object.prototype.hasOwnProperty.call(params.data.columns, month)) {
            return true;
        } else {
            return false;
        }
    }
    return IsEditable(params);
};
