import { useMsal } from '@azure/msal-react';
import { RumInitConfiguration, datadogRum as datadogRumImport } from '@datadog/browser-rum';
import { useEffect } from 'react';

type UseDataDogParams = Required<
    Pick<RumInitConfiguration, 'applicationId' | 'clientToken' | 'env' | 'service' | 'version'>
>;

/**
 * Shamelessly stolen from https://github.com/BESTSELLER/bestone-buying-ui-library/tree/master/src/hooks/useDataDog
 *
 * @description This hook can be used to initialise communication with DataDog.
 * @param {string} [applicationId] - The id of the target application
 * @param {string} [clientToken] - The client token issued by DataDog
 * @param {string} [env] - The environment of the target application
 * @param {string} [service] - The name of the target application (usually declared in package.json)
 * @param {string} [version] - The version of the target application (usually declared in package.json)
 * @param {boolean=} [includeReplay] - Whether or not to record sessions for session replay
 * @example
 * ```ts
 * import packageJson from 'path/to/package.json';
 *
 * // Basic initialisation of DataDog
 * useDataDog({
 *   applicationId: 'xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx',
 *   clientToken: 'xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx',
 *   env: process.env.NODE_ENV,
 *   service: packageJson.name,
 *   version: packageJson.version,
 * });
 *
 * // Initialisation and setting user afterwards
 * const { setDataDogUser } = useDataDog({
 *   applicationId: 'xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx',
 *   clientToken: 'xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx',
 *   env: process.env.NODE_ENV,
 *   service: packageJson.name,
 *   version: packageJson.version,
 * });
 *
 * setDataDogUser(token, user);
 *
 * // Initialisation with access to the datadogRum instance
 * const { datadogRum } = useDataDog({
 *   applicationId: 'xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx',
 *   clientToken: 'xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx',
 *   env: process.env.NODE_ENV,
 *   service: packageJson.name,
 *   version: packageJson.version,
 * });
 *
 * datadogRum.addAction('Test log', { testContext: 'someValue' });
 * // Documentation (datadogRum):
 * // Custom actions: https://docs.datadoghq.com/real_user_monitoring/browser/tracking_user_actions/?tab=npm#custom-actions
 * // Custom errors:  https://docs.datadoghq.com/real_user_monitoring/browser/collecting_browser_errors/?tab=npm#collect-errors-manually
 * ```
 */
export const useDataDog = ({
    applicationId,
    clientToken,
    env,
    service,
    version,
}: UseDataDogParams): {
    datadogRum: typeof datadogRumImport;
} => {
    const { accounts } = useMsal();

    useEffect(() => {
        // Only disable datadog on localhost
        if (window.location.host.includes('localhost')) {
            return;
        }

        if (!datadogRumImport.getInitConfiguration()) {
            datadogRumImport.init({
                site: 'datadoghq.eu',
                trackUserInteractions: true,
                trackResources: true,
                trackLongTasks: true,
                allowedTracingUrls: [window.origin],
                silentMultipleInit: true,
                applicationId,
                clientToken,
                env,
                service,
                version,
                sessionSampleRate: 100,
                sessionReplaySampleRate: 100,
                defaultPrivacyLevel: 'allow',
                beforeSend: (event) => {
                    event.context = {
                        ...event.context,
                        screenSize: `${window.screen.width}x${window.screen.height}`,
                        viewportSize: `${document.documentElement.clientWidth}x${document.documentElement.clientHeight}`,
                    };
                    return true;
                },
            });

            datadogRumImport.startSessionReplayRecording();
        }

        if (accounts?.length > 0) {
            datadogRumImport.setUser({
                name: accounts[0].name,
                email: accounts[0].username,
                department: accounts[0].idTokenClaims?.department,
                departmentnumber: accounts[0].idTokenClaims?.departmentnumber,
                jobtitle: accounts[0].idTokenClaims?.jobtitle,
            });
        }
    }, [accounts, applicationId, clientToken, env, service, version]);

    return { datadogRum: datadogRumImport };
};
