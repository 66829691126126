import { Inheritance, Module } from 'src/domain';
import { TableOverviewRow } from 'src/domain/table/table-overview-row';
import { useInheritance } from 'src/hooks/inheritance';
import { useUnsavedChangesModule } from '../../unsaved-changes';
import { useGenderSplitOverviewRows } from '../table/useGenderSplitOverviewRows';

export const useGenderSplitOverviewSimulationRows = (inheritance?: Inheritance) => {
    const [unsavedChanges] = useUnsavedChangesModule<TableOverviewRow[]>(Module.GenderSplit);
    const { data, columns, error, loading } = useGenderSplitOverviewRows(inheritance);
    const hasUnsavedChanges = !!unsavedChanges;

    const moduleInheritance = useInheritance(Module.GenderSplit);
    const selectedInheritance = inheritance ?? moduleInheritance;

    const isTyped = selectedInheritance === Inheritance.Typed;

    const overviewRows = isTyped && hasUnsavedChanges ? unsavedChanges : data;

    return { data: overviewRows, columns, error, loading };
};
