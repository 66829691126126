import { useReactiveVar } from '@bestseller-bit/retail-planning.utils.reactive-vars';
import { useMemo } from 'react';
import { selectedPartnerVar } from 'src/infrastructure/local_state';
import { usePartners } from './partner';
import { useType } from './useType';

export const useSelectedPartner = () => {
    const type = useType();
    const partners = usePartners(type);
    const [selectedPartner, setSelectedPartner] = useReactiveVar(selectedPartnerVar);

    return useMemo(() => {
        if (partners?.length === 1 && !selectedPartner) {
            return [partners?.[0], setSelectedPartner] as const;
        }
        return [selectedPartner, setSelectedPartner] as const;
    }, [partners, selectedPartner, setSelectedPartner]);
};
