import { months } from 'src/domain';

export const monthNumberToName = (nbr: number) => {
    return months[nbr - 1];
};

export const buildMonthTitle = (index: number, month: string, year: string) => {
    if (index === 0) {
        return monthNumberToName(parseInt(month)) + ' (current)';
    } else if (index > 11) {
        return monthNumberToName(parseInt(month)) + ' (' + year + ')';
    }
    return monthNumberToName(parseInt(month));
};
