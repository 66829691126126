import { EditNote } from '@mui/icons-material';
import { Button } from '@mui/material';
import { FC, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { StyledSkeleton } from 'src/components/styled/StyledSkeleton';

interface Props {
    loading?: boolean;
    disabled?: boolean;
    onSave?: () => unknown;
}

const EditAllPartnersButton: FC<Props> = ({ loading }) => {
    const location = useLocation();
    const navigate = useNavigate();

    const isGlobal = location.search.includes('storeId=global');

    const globalSearch = useMemo(() => {
        if (isGlobal) return location.search.replace(/storeId=global/, 'storeId=overview');
        return location.search.replace(/storeId=[0-9]+|storeId=overview/, 'storeId=global');
    }, [isGlobal, location.search]);

    return (
        <>
            {!loading && (
                <Button
                    variant="contained"
                    color="secondary"
                    startIcon={<EditNote />}
                    onClick={() => {
                        navigate({
                            ...location,
                            search: globalSearch,
                        });
                    }}
                >
                    {isGlobal ? 'Edit Single Partner' : 'Edit All Partners'}
                </Button>
            )}
            {loading && <StyledSkeleton variant="rectangular" width={100} height={30} />}
        </>
    );
};

export default EditAllPartnersButton;
