export enum Module {
    StoreSelection = 'overview',
    Turnover = 'turnover',
    CategorySplit = 'category-split',
    Markup = 'markup',
    Markdown = 'markdown',
    ProductLineSplit = 'productline-split',
    GenderSplit = 'gender-split',
    NoosShare = 'noos-share',
    WeekCover = 'week-cover',
    DeliveryProfile = 'delivery-profile',
    StockManagement = 'stock-management',
    ClusterManagement = 'cluster-management',
    OpenToBuyLimit = 'open-to-buy-limit',
    BuyingEventManagement = 'buying-event-management',
    StyleTimeline = 'style-timeline',
    StyleTimelineSettings = 'style-timeline-settings',
    Settings = 'settings',
    InheritanceSettings = 'inheritance-settings',
    OptimalOptionCount = 'optimal-option-count',
    ProjectedOptionCount = 'projected-option-count',
    StyleWarnings = 'style-warnings',
    SalesCampaigns = 'sales-campaigns',
    SalesCampaignsPlanning = 'sales-campaigns-planning',
    SalesCampaignsReport = 'sales-campaigns-report',
    StyleFamilyNameEditor = 'style-family-name-editor',
}

export enum InheritanceModule {
    SalesBudget = 'salesBudget',
    CategorySplit = 'categorySplit',
    Markup = 'markup',
    Markdown = 'markdown',
    ProductLineSplit = 'productLineSplit',
    GenderSplit = 'genderSplit',
    NoosShare = 'noosShare',
    WeekCover = 'weekCover',
    DeliveryProfile = 'deliveryProfile',
}
