import { useStyleCategoriesQuery } from 'src/hooks/style-categories/queries/useStyleCategoriesQuery';
import { useStyleTimelineCollectionsQuery } from './useStyleTimelineCollectionsQuery';
import { useStyleTimelineLevelQuery } from './useStyleTimelineLevelQuery';

export const useStyleTimelineQuery = (selectedStorefrontType?: string) => {
    const { data, error, loading } = useStyleTimelineLevelQuery(selectedStorefrontType) ?? {};
    const {
        data: styleCategories,
        loading: styleCategoriesLoading,
        error: styleCategoriesError,
    } = useStyleCategoriesQuery();
    const {
        data: collections,
        loading: collectionsLoading,
        error: collectionsError,
    } = useStyleTimelineCollectionsQuery(selectedStorefrontType);

    return {
        styleTimelines: data,
        collections: collections,
        styleCategories: styleCategories,
        loading: loading || collectionsLoading || styleCategoriesLoading,
        error: error ?? collectionsError ?? styleCategoriesError,
    };
};
