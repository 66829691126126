import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import { LicenseManager } from 'ag-grid-enterprise';
import MathJaxContext from 'better-react-mathjax/MathJaxContext';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import mathJaxURL from 'mathjax-full/es5/tex-chtml.js?url';
import { SnackbarProvider } from 'notistack';
import { useEffect, useMemo } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { AppContent } from './AppContent';
import Initializer from './Initializer';
import { CustomThemeProvider } from './Theme';
import NotAuthorized from './components/atoms/NotAuthorized';
import { DataDogRumProvider } from './contexts/DataDogRumContext';
import { DrawerContextProvider } from './contexts/DrawerContextProvider';
import { FilterContextProvider } from './contexts/FilterContext';
import { MockProvider } from './contexts/MockContext';
import { StoreCacheContextProvider } from './contexts/StoreCacheContext';
import { EnvironmentVariableError } from './domain/errors/EnvironmentVariable.error';
import { DevToolsProvider } from './hooks/devtools/DevToolsContext';
import { IsHistoricalProvider } from './hooks/historical/IsHistoricalContext';
import { useIsDevEnv } from './hooks/useIsDevEnv';

const agGridLicense = import.meta.env.VITE_APP_AG_GRID_LICENSE_KEY;

if (!agGridLicense) throw new EnvironmentVariableError(`AG Grid license key missing`);

LicenseManager.setLicenseKey(agGridLicense);

const mathJaxConfig = {
    loader: { load: ['[tex]/html'] },
    tex: {
        packages: { '[+]': ['html'] },
        inlineMath: [
            ['$', '$'],
            ['\\(', '\\)'],
        ],
        displayMath: [
            ['$$', '$$'],
            ['\\[', '\\]'],
        ],
    },
};

const App = () => {
    const isDevEnv = useIsDevEnv();

    // Create a LaunchDarkly provider depending on whether we're in a dev environment or not
    const LDProvider = useMemo(() => {
        return withLDProvider({
            clientSideID: isDevEnv ? '639b0ca462455c121534c7fe' : '639b0ca462455c121534c7ff',
        })(AppContent);
    }, [isDevEnv]);

    useEffect(() => {
        if (isDevEnv) {
            let link = document.querySelector("link[rel~='icon']") as HTMLLinkElement;
            if (!link) {
                link = document.createElement('link');
                link.rel = 'icon';
                document.getElementsByTagName('head')[0].appendChild(link);
            }
            link.href = '/storeforecast-icon-dev.svg';
        }
    }, [isDevEnv]);

    return (
        <MockProvider>
            <DevToolsProvider>
                <DataDogRumProvider>
                    <BrowserRouter>
                        <CustomThemeProvider>
                            <FilterContextProvider>
                                <SnackbarProvider maxSnack={3}>
                                    <StoreCacheContextProvider>
                                        <IsHistoricalProvider>
                                            <Initializer>
                                                <MathJaxContext src={mathJaxURL} config={mathJaxConfig}>
                                                    <AuthenticatedTemplate>
                                                        <DrawerContextProvider>
                                                            <LDProvider />
                                                        </DrawerContextProvider>
                                                    </AuthenticatedTemplate>
                                                    <UnauthenticatedTemplate>
                                                        <NotAuthorized />
                                                    </UnauthenticatedTemplate>
                                                </MathJaxContext>
                                            </Initializer>
                                        </IsHistoricalProvider>
                                    </StoreCacheContextProvider>
                                </SnackbarProvider>
                            </FilterContextProvider>
                        </CustomThemeProvider>
                    </BrowserRouter>
                </DataDogRumProvider>
            </DevToolsProvider>
        </MockProvider>
    );
};

export default App;
