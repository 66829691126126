import { useCallback, useState } from 'react';
import { Module } from 'src/domain';
import { useSnackbar } from 'src/hooks/snackbar/useSnackbar';
import { AnnouncementTypeEnum } from 'src/infrastructure/rest-api/api-types';

import { useApiMutation } from 'src/infrastructure/rest-api/useApi';
import { moduleToAnnouncementModule } from 'src/utils/modules';

interface Props {
    id: number | undefined | null;
    title: string;
    description: string;
    type: AnnouncementTypeEnum;
    module: Module | null;
    resolved: boolean;
}

export const useUpdateAnnouncement = ({ id, title, description, type, module, resolved }: Props) => {
    const [updateAnnouncement] = useApiMutation('/api/announcements', 'put', {
        update(data, _variables, queryClient) {
            queryClient.setQueryData(['/api/announcements'], data);
        },
    });

    const [isUpdating, setIsUpdating] = useState(false);

    const showSnackbar = useSnackbar();

    const updatingAnnouncement = useCallback(async () => {
        if (id === undefined || id === null) return;
        setIsUpdating(true);

        try {
            const response = await updateAnnouncement({
                body: {
                    announcementId: id,
                    title,
                    description,
                    announcementTypeEnum: type,
                    moduleEnum: moduleToAnnouncementModule(module),
                    resolved: resolved ? new Date().toISOString() : null,
                },
            });
            showSnackbar(`Announcement '${title}' has been updated`, 'success');
            return response;
        } catch {
            showSnackbar('Failed to update announcement', 'error');
        } finally {
            setIsUpdating(false);
        }
    }, [id, updateAnnouncement, title, description, type, module, resolved, showSnackbar]);

    return [updatingAnnouncement, { loading: isUpdating }] as const;
};
