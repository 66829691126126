import { InteractionStatus } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import { useCallback } from 'react';
import { setToken } from 'src/utils/token/tokenUtils';

export const useAcquireToken = () => {
    const { accounts, inProgress, instance } = useMsal();

    const acquireToken = useCallback(async () => {
        const accessTokenRequest = {
            scopes: ['user.read'],
            account: accounts[0],
        };
        if (inProgress === InteractionStatus.None) {
            const accessTokenResponse = await instance.acquireTokenSilent(accessTokenRequest);

            const idToken = accessTokenResponse.idToken;

            setToken(idToken);
            return idToken;
        }
    }, [accounts, inProgress, instance]);

    return acquireToken;
};
