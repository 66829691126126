import { FC, PropsWithChildren, ReactNode, createContext, useContext, useState } from 'react';
import { Filter, initialFilters } from 'src/domain';

export const FilterContext = createContext<[Filter, (filter: Filter) => void]>([
    initialFilters,
    () => {
        return undefined;
    },
]);
export const useFilterContext = () => useContext(FilterContext);

export const FilterContextProvider: FC<PropsWithChildren<unknown>> = ({ children }: { children?: ReactNode }) => {
    const [filterState, setFilterState] = useState(initialFilters);
    const defaultFilterContext: [Filter, typeof setFilterState] = [filterState, setFilterState];
    return <FilterContext.Provider value={defaultFilterContext}>{children}</FilterContext.Provider>;
};
