import { EditableCallbackParams } from 'ag-grid-community';

export function IsEditable(params: EditableCallbackParams) {
    // only the last row, that is not part of a row group, should not be editable
    if (params && params.node && params.node.id && params.node.lastChild && params.node.level === 0) {
        return false;
    }

    return true;
}

export function isEditableWithFooter(params: EditableCallbackParams) {
    if (params?.node?.footer || params.data?.footer) return false;
    return true;
}
