import { useCallback, useState } from 'react';
import { useChainId } from 'src/hooks/chain';
import { useSnackbar } from 'src/hooks/snackbar/useSnackbar';
import { useApiMutation } from 'src/infrastructure/rest-api/useApi';

interface Props {
    name: string;
    initialDeliveryRangeStart: number;
    initialDeliveryRangeEnd: number;
    orderMonth: number;
    plannedOnIds: number[];
}

export const useCreateNewBuyingEvent = ({
    initialDeliveryRangeStart,
    initialDeliveryRangeEnd,
    name,
    orderMonth,
    plannedOnIds,
}: Props) => {
    const [createBuyingEvent] = useApiMutation('/api/buyingevents', 'post', {
        update(data, _variables, queryClient) {
            const path = { chaidId: chainId };
            queryClient.setQueryData(['/api/buyingevents/chains/{chainId}', path], data);
        },
    });
    const [isUpdating, setIsUpdating] = useState(false);
    const chainId = useChainId();

    const showSnackbar = useSnackbar();

    const creatingBuyingEvent = useCallback(async () => {
        if (!chainId) {
            return;
        }
        setIsUpdating(true);
        try {
            const response = await createBuyingEvent({
                body: {
                    buyingStart: initialDeliveryRangeStart,
                    buyingEnd: initialDeliveryRangeEnd,
                    chainId,
                    name,
                    orderMonth,
                    plannedOnIds,
                },
            });
            showSnackbar(`Buying event '${name}' has been created!`, 'success');
            return response;
        } catch {
            showSnackbar(`Failed to create buying event. Please try again later`, 'error');
        } finally {
            setIsUpdating(false);
        }
    }, [
        createBuyingEvent,
        initialDeliveryRangeStart,
        initialDeliveryRangeEnd,
        chainId,
        name,
        orderMonth,
        plannedOnIds,
        showSnackbar,
    ]);

    return [creatingBuyingEvent, { loading: isUpdating }] as const;
};
