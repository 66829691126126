import { Box, styled } from '@mui/material';
import { FC } from 'react';
import KPIContainer from 'src/components/molecules/KPIContainer/KPIContainer';
import { useVat } from 'src/hooks/turnover/useVat';
import { calculateGrossMarginPercent } from 'src/utils/gross-margin/calculateGrossMargin';
import { numberWithSpaces } from 'src/utils/numberWithSpaces';
import { calculateSummary } from '../calculateSummary';
import { SummaryReportProps } from '../types';

export const TotalSummaryTab: FC<SummaryReportProps> = ({ salesCampaignRows }) => {
    const vat = useVat();

    const summaryData = calculateSummary(salesCampaignRows);

    //! FIXME: Are GM% calculations correct? We cannot verify due to missing recommended retail prices in the data. The missing data causes wrong calculations, so for now we use NaN if not all data is present.
    const expectedGMPercent = calculateGrossMarginPercent(
        summaryData.totalSalesPriceOnSale,
        summaryData.totalCogsOnSale,
        vat
    );

    const expectedTotalGMPercent = calculateGrossMarginPercent(summaryData.totalSalesPrice, summaryData.totalCogs, vat);

    return (
        <SummaryContainer>
            <KPIContainer title="Total stock value" content={numberWithSpaces(summaryData.totalStockValue)} />
            <KPIContainer
                title="Total stock value on sale"
                content={numberWithSpaces(summaryData.totalStockValueOnSale)}
            />
            <KPIContainer
                title="% stock value on sale"
                content={numberWithSpaces(summaryData.percentageStockValueOnSale, { postfix: '%' })}
            />
            <KPIContainer
                title="Total expected sales iV"
                content={numberWithSpaces(summaryData.totalExpectedSalesIv)}
            />
            <KPIContainer
                title="Avg. markdown %"
                content={numberWithSpaces(summaryData.avgMarkDown, { postfix: '%', default: 'N/A' })}
            />
            <KPIContainer
                title="Expected GM%"
                content={numberWithSpaces(expectedGMPercent, { postfix: '%', default: 'N/A' })}
            />
            <KPIContainer
                title="Expected total GM%"
                content={numberWithSpaces(expectedTotalGMPercent, { postfix: '%', default: 'N/A' })}
            />
        </SummaryContainer>
    );
};

const SummaryContainer = styled(Box)`
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
`;
