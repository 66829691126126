import styled from '@emotion/styled';
import { ComponentProps, FC } from 'react';
import { Headings } from 'src/domain';

interface Props extends ComponentProps<'h1'> {
    heading: Headings;
    bold?: boolean;
}

const Header: FC<Props> = ({ children, heading, bold = false, ...props }) => {
    const headerType = () => {
        switch (heading) {
            case Headings.h1:
                return 'h1';
            case Headings.h2:
                return 'h2';
            case Headings.h3:
                return 'h3';
            case Headings.h4:
                return 'h4';
            default:
                return 'h6';
        }
    };
    return (
        <StyledHeader bold={bold} as={headerType()} {...props}>
            {children}
        </StyledHeader>
    );
};

const StyledHeader = styled.h1<{ bold: boolean }>`
  margin: 0;
  font-weight: ${(props) => (props.bold ? 700 : 400)};
`;

export default Header;
