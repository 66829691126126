import { CategoryOverviewRow, Module, SessionStorageGenderProductLineType } from 'src/domain';
import { useCurrentModule } from '../useCurrentModule';
import { useGenderProductLineKey } from '../useGenderProductLineKey';
import { useUnsavedChangesModule } from './useUnsavedChangesModule';

export const useHasUnsavedChangesGenderProductline = (module?: Module) => {
    const currentModule = useCurrentModule();
    const moduleToUse = module ?? currentModule;
    const [unsavedChanges] =
        useUnsavedChangesModule<SessionStorageGenderProductLineType<CategoryOverviewRow[]>>(moduleToUse);

    const genderProductLineKey = useGenderProductLineKey();

    return !!unsavedChanges && !!genderProductLineKey && !!unsavedChanges[genderProductLineKey];
};
