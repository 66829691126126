import { useApiQuery } from 'src/infrastructure/rest-api/useApi';
import { useCompositePartner } from '../partner/useCompositePartner';

export const useSalesCampaigns = () => {
    const compositePartner = useCompositePartner();

    const { data, loading, error, refetch } = useApiQuery('/api/salescampaigns/partners/{partnerId}', 'get', {
        path: {
            partnerId: compositePartner?.id,
        },
        enabled: !!compositePartner?.id,
    });

    return { data, loading, refetch, error };
};
