import { Add, ChevronRight, Delete, ExpandMore, Save } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Stack, Typography } from '@mui/material';
import { FC } from 'react';
import Container from 'src/components/atoms/Container';
import { StyledDiscardButton } from 'src/components/styled/DiscardButton';
import { StyledLoadingButton } from 'src/components/styled/LoadingButton';
import { useSelectedPartner } from 'src/hooks';

interface ClusterManagementTopBarProps {
    onExpandAll: () => void;
    onCollapseAll: () => void;
    onAddCluster: () => void;
    onDiscardChanges: () => void;
    onSaveChanges: () => void;
    isDiscardButtonDisabled: boolean;
    isSaveButtonDisabled: boolean;
    isSaving: boolean;
}

export const ClusterManagementTopBar: FC<ClusterManagementTopBarProps> = ({
    onExpandAll,
    onCollapseAll,
    onAddCluster,
    onDiscardChanges,
    onSaveChanges,
    isDiscardButtonDisabled,
    isSaveButtonDisabled,
    isSaving,
}) => {
    const [partner] = useSelectedPartner();

    return (
        <Stack justifyContent="space-between" direction="row" gap="1rem">
            <Container>
                <Stack>
                    <Typography variant="h4">{partner?.name}</Typography>
                    <Typography sx={{ color: '#787C8D' }} variant="h5">
                        Cluster Management
                    </Typography>
                </Stack>
            </Container>
            <Stack justifyContent="flex-end" spacing={2} direction="row" alignSelf="flex-end">
                <LoadingButton
                    color="secondary"
                    variant="contained"
                    size="small"
                    onClick={() => onExpandAll()}
                    endIcon={<ExpandMore />}
                >
                    Expand All
                </LoadingButton>
                <LoadingButton
                    color="secondary"
                    variant="contained"
                    size="small"
                    onClick={() => onCollapseAll()}
                    startIcon={<ChevronRight />}
                >
                    Collapse All
                </LoadingButton>
                <LoadingButton
                    color="secondary"
                    variant="contained"
                    size="small"
                    onClick={() => onAddCluster()}
                    startIcon={<Add />}
                    disabled={isSaving}
                >
                    New Cluster
                </LoadingButton>
                <StyledDiscardButton
                    disabled={isDiscardButtonDisabled || isSaving}
                    color={'error'}
                    size={'small'}
                    variant={'contained'}
                    startIcon={<Delete />}
                    onClick={() => onDiscardChanges()}
                >
                    Discard changes
                </StyledDiscardButton>
                <StyledLoadingButton
                    size={'small'}
                    variant={'contained'}
                    onClick={() => onSaveChanges()}
                    startIcon={<Save />}
                    disabled={isSaveButtonDisabled || isSaving}
                    loading={isSaving}
                >
                    Save Changes
                </StyledLoadingButton>
            </Stack>
        </Stack>
    );
};
