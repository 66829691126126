import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    FormHelperText,
    InputLabel,
    OutlinedInput,
    Stack,
} from '@mui/material';
import { FC, useState } from 'react';

type CreateClusterDialogProps = {
    initialClusterName?: string;
    initialClusterDescription?: string;
    isEditing?: boolean;
    open: boolean;
    onOk: (name: string, description?: string) => void;
    onCancel: () => void;
};

export const CreateClusterDialog: FC<CreateClusterDialogProps> = ({
    initialClusterName = '',
    initialClusterDescription = '',
    isEditing = false,
    onOk,
    onCancel,
    open,
}) => {
    const [clusterName, setClusterName] = useState<string>(initialClusterName);
    const [clusterDescription, setClusterDescription] = useState<string>(initialClusterDescription);
    const [okClicked, setOkClicked] = useState<boolean>(false);

    const reset = () => {
        setClusterName('');
        setClusterDescription('');
    };

    const handleOkClicked = () => {
        if (isClusterNameInvalid) {
            setOkClicked(true);
            return;
        }

        onOk(clusterName, clusterDescription ?? '');
        setOkClicked(false);
        reset();
    };

    const handleCancelClicked = () => {
        onCancel();
        reset();
    };

    const isClusterNameInvalid = !clusterName || clusterName?.isEmpty() || clusterName.match(/^ *$/) !== null;

    const isClusterNameTooLong = !!clusterName && clusterName.length > 20;

    return (
        <Dialog
            open={open}
            onClose={onCancel}
            aria-labelledby="create-cluster-dialog-title"
            aria-describedby="create-cluster-dialog-description"
        >
            <DialogTitle id="create-cluster-dialog-title">
                {isEditing ? 'Edit existing cluster' : 'Create new cluster'}
            </DialogTitle>
            <DialogContent dividers>
                <Stack spacing={2}>
                    <DialogContentText id="create-cluster-dialog-description">
                        {isEditing
                            ? 'You can edit the name and description of your cluster.'
                            : 'Please provide a cluster name and description for your new cluster.'}
                    </DialogContentText>

                    <FormControl>
                        <InputLabel htmlFor="component-outlined">Cluster Name</InputLabel>
                        <OutlinedInput
                            autoFocus
                            value={clusterName}
                            error={(isClusterNameInvalid || isClusterNameTooLong) && okClicked}
                            id="component-outlined"
                            label="Cluster Name"
                            onChange={(e) => setClusterName(e.currentTarget.value)}
                        />
                        {isClusterNameInvalid && okClicked && (
                            <FormHelperText id="component-error-text">
                                Please provide a valid cluster name.
                            </FormHelperText>
                        )}
                        {isClusterNameTooLong && okClicked && (
                            <FormHelperText id="component-error-text">
                                Cluster name cannot be over 20 characters.
                            </FormHelperText>
                        )}
                    </FormControl>
                    <FormControl>
                        <InputLabel htmlFor="component-outlined">Cluster Description</InputLabel>
                        <OutlinedInput
                            id="component-outlined"
                            label="Cluster Description"
                            value={clusterDescription}
                            onChange={(e) => setClusterDescription(e.currentTarget.value)}
                        />
                    </FormControl>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancelClicked}>Cancel</Button>
                <Button onClick={handleOkClicked}>{isEditing ? 'Save' : 'Create'}</Button>
            </DialogActions>
        </Dialog>
    );
};
