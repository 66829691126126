import { FC, PropsWithChildren } from 'react';
import { useInitializeFilteredStores, useInitializeLookupTables } from './hooks';
import { useInitializeIsHistorical } from './hooks/initializers/useInitializeIsHistorical';
import { useInitializeMsal } from './hooks/initializers/useInitializeMsal';

/**
 * Initialize global state
 */
const Initializer: FC<PropsWithChildren<unknown>> = ({ children }) => {
    useInitializeIsHistorical();
    useInitializeMsal();
    useInitializeLookupTables();
    useInitializeFilteredStores();

    return <>{children}</>;
};

export default Initializer;
