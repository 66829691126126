import { Stack } from '@mui/material';
import { FC, useMemo } from 'react';
import Container from 'src/components/atoms/Container';
import Header from 'src/components/atoms/Header';
import { DataTable } from 'src/components/organisms/DataTable/DataTable';
import { defaultCategoryColumn } from 'src/components/organisms/DataTable/columns/columns-overview-sum';
import {
    DefaultColumnOptionsDetails,
    DefaultColumnsOptionsOverviewPercentageWithSum,
} from 'src/components/organisms/DataTable/columns/default-options';
import { SumBold } from 'src/components/organisms/DataTable/options/sum-bold';
import { validateSumIs100Percent } from 'src/components/organisms/DataTable/validators/validateSumIs100Percent';
import DetailsTable from 'src/components/organisms/DetailsTable';
import InheritanceDropdown from 'src/components/organisms/InheritanceDropdown';
import { InheritanceTable } from 'src/components/organisms/InheritanceTable/InheritanceTable';
import Topbar from 'src/components/organisms/Topbar';
import { StyledHeaderWrapper } from 'src/components/styled/StyledHeaderWrapper';
import { TableWrapper } from 'src/components/styled/TableWrapper';
import { GenderDetailsRow, GenderDetailsRowTuple, Headings, Module } from 'src/domain';
import { useHasUnsavedChanges } from 'src/hooks';
import { useDiscardChangesModule } from 'src/hooks/discard-changes/useDiscardChangesModule';
import { useGenderSplitReset } from 'src/hooks/gender-split/reset/useGenderSplitReset';
import { useGenderSplitSaveChanges } from 'src/hooks/gender-split/save-changes/useGenderSplitSaveChanges';
import { useGenderSplitDetailsSimulationRows } from 'src/hooks/gender-split/simulation/useGenderSplitDetailsSimulationRows';
import { useGenderSplitOverviewSimulationRows } from 'src/hooks/gender-split/simulation/useGenderSplitOverviewSimulationRows';
import { useGenderSplitUpdateInheritance } from 'src/hooks/gender-split/split-level/useGenderSplitUpdateInheritance';
import { useGenderSplitDetailsRows } from 'src/hooks/gender-split/table/useGenderSplitDetailsRows';
import { useGenderSplitHandleCellChanges } from 'src/hooks/gender-split/useGenderSplitHandleCellChanges';
import { useIsSum100 } from 'src/hooks/useIsSum100';
import { useModulePlanningRange } from 'src/hooks/useModulePlanningRange';
import { mergeTableDetailsRowValuesToTuples } from 'src/mapping/table.mapping';
import { getButtonTooltips } from 'src/utils/getTooltipText';
import { GenderDetailsColumnDefinition } from './data/GenderDetailsColumnDefinitions';

export const GenderSplit: FC = () => {
    const {
        data: overviewData,
        columns: monthColumns,
        loading: overviewQueryLoading,
    } = useGenderSplitOverviewSimulationRows();
    const { data: detailsData, loading: detailsQueryLoading } = useGenderSplitDetailsRows();
    const { data: detailsSimulationData, loading: detailsSimluationQueryLoading } =
        useGenderSplitDetailsSimulationRows();
    const planningRange = useModulePlanningRange(Module.GenderSplit);

    const handleCellValuesChanges = useGenderSplitHandleCellChanges();
    const { updateInheritance, loading: updateInheritanceLoading } = useGenderSplitUpdateInheritance();
    const { resetGenderSplitStore, loading: resetStoreLoading } = useGenderSplitReset();

    const [saveChanges, { loading: isSaving }] = useGenderSplitSaveChanges();
    const discardChanges = useDiscardChangesModule(Module.GenderSplit);

    const hasUnsavedChanges = useHasUnsavedChanges();
    const isSum100 = useIsSum100(overviewData);
    const canSave = hasUnsavedChanges && isSum100;
    const loading =
        overviewQueryLoading ||
        detailsQueryLoading ||
        resetStoreLoading ||
        updateInheritanceLoading ||
        detailsSimluationQueryLoading ||
        isSaving;

    const overviewRows = !loading ? overviewData : undefined;

    const { saveTooltip } = useMemo(
        () => getButtonTooltips(hasUnsavedChanges, isSum100),
        [hasUnsavedChanges, isSum100]
    );

    const detailsRows = useMemo(() => {
        if (loading) {
            return;
        }

        return mergeTableDetailsRowValuesToTuples<GenderDetailsRow, GenderDetailsRowTuple>(
            detailsData,
            detailsSimulationData,
            ['plannedSalesIv', 'plannedIndex']
        );
    }, [detailsData, detailsSimulationData, loading]);

    const columns = useMemo(() => {
        return [defaultCategoryColumn, ...monthColumns];
    }, [monthColumns]);

    return (
        <Stack spacing={2}>
            <Topbar
                hideToggleButtons
                onDiscard={discardChanges}
                onSave={saveChanges}
                saveDisabled={!canSave}
                saveTooltip={saveTooltip}
                onReset={resetGenderSplitStore}
                onSetInheritance={updateInheritance}
                loading={loading}
                saveLoading={isSaving}
            />

            <Container>
                <StyledHeaderWrapper>
                    <Header heading={Headings.h2}>Gender Split</Header>
                    <InheritanceDropdown />
                </StyledHeaderWrapper>
                <TableWrapper
                    className="ag-theme-alpine-custom-compact ag-sum"
                    data-testid="gender-split-overview-table"
                >
                    <DataTable
                        {...SumBold}
                        autoSizeToFit={planningRange === 'TWELVE_MONTHS'}
                        key={'overview-table'}
                        headerHeight={60}
                        columns={columns}
                        defaultColDef={DefaultColumnsOptionsOverviewPercentageWithSum}
                        rows={overviewRows}
                        grandTotalRow="bottom"
                        onCellValuesChanged={handleCellValuesChanges}
                        loading={loading}
                        cellValidationOptions={{
                            columns: true,
                            validationFunc: validateSumIs100Percent,
                        }}
                    />
                </TableWrapper>
            </Container>
            <DetailsTable
                key={'details-table'}
                title={'Gender Split Details'}
                {...SumBold}
                loading={loading}
                rows={detailsRows}
                monthColumns={monthColumns}
                columns={GenderDetailsColumnDefinition}
                defaultColDef={DefaultColumnOptionsDetails}
                onCellValuesChanged={handleCellValuesChanges}
                grandTotalRow="bottom"
                cellValidationOptions={{
                    columns: ['plannedSplit'],
                    validationFunc: validateSumIs100Percent,
                }}
            />
            <InheritanceTable moduleString="genderSplit" />
        </Stack>
    );
};
