export enum Gender {
    Male = 'male',
    Female = 'female',
    Neutral = 'neutral',
    Other = '?',
}

export const GenderIdToGenderMap = new Map<number, Gender>([
    [1, Gender.Neutral],
    [2, Gender.Female],
    [3, Gender.Male],
    [4, Gender.Other],
]);

export const GenderToGenderIdMap = new Map<Gender, number>(Array.from(GenderIdToGenderMap).map((x) => [x[1], x[0]]));
