import { groupBy } from 'lodash';
import { useMemo } from 'react';
import { Module } from 'src/domain';
import { ClusterManagementUnsavedChanges } from 'src/domain/cluster-management/ClusterManagementUnsavedChanges';
import { ClusterManagementRow } from 'src/domain/models/cluster-management/cluster-management-row';
import { useUnsavedChangesModule } from 'src/hooks/unsaved-changes';
import { UpdateClusterInput } from 'src/infrastructure/rest-api/api-types';
import { UseClusterManagementRowsReturnValue, useClusterManagementRows } from '../table/useClusterManagementRows';

export const useClusterManagementSimulationRows = (): UseClusterManagementRowsReturnValue => {
    const { data, clusters, loading, error } = useClusterManagementRows();

    const [unsavedChanges] = useUnsavedChangesModule<ClusterManagementUnsavedChanges>(Module.ClusterManagement);

    const simulationClusters = unsavedChanges?.clusters ?? clusters ?? [];

    const simulationRows: ClusterManagementRow[] = useMemo(() => {
        return (unsavedChanges?.rows ?? data ?? [])
            .filter((row) => !row.rowId.startsWith('empty'))
            .map((row) => ({
                ...row,
                cluster:
                    simulationClusters.find((cluster) => cluster.clusterId === row.cluster.clusterId) ?? row.cluster,
            }));
    }, [data, unsavedChanges, simulationClusters]);

    const rowsForEmptyClusters: ClusterManagementRow[] = useMemo(() => {
        if (!simulationRows) {
            return [];
        }
        const storesByClusterId = groupBy(simulationRows, (row) => row.cluster.clusterId);

        const emptyClusters = simulationClusters.reduce<UpdateClusterInput[]>((acc, cluster) => {
            if (!storesByClusterId[cluster.clusterId!]) {
                acc.push(cluster);
            }
            return acc;
        }, []);

        return emptyClusters.map((cluster) => {
            return {
                rowId: `empty-${cluster.clusterId}`,
                id: cluster.clusterId!.toString(),
                storeId: -1,
                storeName: 'This cluster has no stores',
                cluster: cluster,
                openingDate: null,
                salesIvNext12Months: 0,
                salesIvPerSquareMeter: 0,
                storeSize: 0,
            };
        });
    }, [simulationRows, simulationClusters]);

    return {
        data: [...simulationRows, ...rowsForEmptyClusters],
        clusters: simulationClusters,
        loading,
        error,
    };
};
