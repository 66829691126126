import { Save } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogProps,
    DialogTitle,
    FormHelperText,
    FormLabel,
    MenuItem,
    Select,
    Stack,
    TextField,
    styled,
} from '@mui/material';
import { useForm } from '@tanstack/react-form';
import { format } from 'date-fns';
import { omit } from 'ramda';
import { FC, useCallback, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { useCompositePartner } from 'src/hooks/partner/useCompositePartner';
import {
    CreateSalesCampaignInput,
    StorefrontTypeModel,
    UpdateSalesCampaignInput,
} from 'src/infrastructure/rest-api/api-types';
import { useApiMutation } from 'src/infrastructure/rest-api/useApi';

type FormValue = CreateSalesCampaignInput & UpdateSalesCampaignInput;

interface SalesCampaignDialogProps extends Omit<DialogProps, 'defaultValue' | 'onClose'> {
    defaultValue: FormValue;
    isEditing: boolean;
    onClose?: (ok: boolean) => void;
    storefrontTypes: StorefrontTypeModel[];
}

export const SalesCampaignDialog: FC<SalesCampaignDialogProps> = ({
    defaultValue,
    storefrontTypes,
    isEditing,
    open,
    onClose,
}) => {
    const compositePartner = useCompositePartner();

    const [createSalesCampaign] = useApiMutation('/api/salescampaigns', 'post', {
        update(data, _variables, queryClient) {
            const path = { partnerId: compositePartner?.id };
            queryClient.setQueryData(['/api/salescampaigns/partners/{partnerId}', path], data);
        },
    });
    const [updateSalesCampaign] = useApiMutation('/api/salescampaigns', 'put', {
        update(data, _variables, queryClient) {
            const path = { partnerId: compositePartner?.id };
            queryClient.setQueryData(['/api/salescampaigns/partners/{partnerId}', path], data);
        },
    });
    const [dialogError, setDialogError] = useState('');
    const form = useForm<FormValue>({
        defaultValues: defaultValue,
        onSubmit: async ({ value }) => {
            try {
                if (!isEditing) {
                    await createSalesCampaign({
                        body: omit(['salesCampaignId'], value),
                    });
                } else {
                    await updateSalesCampaign({
                        body: omit(['partnerCompositeId', 'storefrontTypeId'], value),
                    });
                }

                if (typeof onClose === 'function') {
                    onClose(true);
                }
            } catch (err) {
                let errorMessage = 'Your campaign could not be created. Please try again later.';

                if (typeof err === 'object' && err !== null && 'response' in err) {
                    const apiError = err as { response: { data?: { errors?: { [key: string]: string[] } } } };
                    if (apiError.response && apiError.response.data && apiError.response.data.errors) {
                        const errors = apiError.response.data.errors;
                        const errorMessages = Object.values(errors).flat();
                        if (errorMessages.length > 0) {
                            errorMessage = errorMessages.join(' ');
                        }
                    }
                }

                setDialogError(errorMessage);
            }
        },
    });

    const handleClose = useCallback(() => {
        if (typeof onClose === 'function') {
            onClose(false);
        }
    }, [onClose]);

    return (
        <Dialog open={open} onClose={handleClose} data-testid="salesCampaignsDialog">
            <form
                style={{ paddingTop: '0.5rem' }}
                onSubmit={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    void form.handleSubmit();
                }}
                noValidate
            >
                <DialogTitle>{isEditing ? 'Edit campaign' : 'Add campaign'}</DialogTitle>
                <DialogContent sx={{ width: '400px', maxWidth: '100%', boxSizing: 'border-box' }}>
                    <Stack gap={'0.75rem'}>
                        <form.Field
                            name="name"
                            validators={{
                                onChange: ({ value }) => {
                                    if (!value) {
                                        return 'Campaign name is required';
                                    }
                                },
                            }}
                            children={(field) => (
                                <FormFieldWrapper>
                                    <FormLabel>Campaign name</FormLabel>
                                    <TextField
                                        size="small"
                                        required
                                        value={field.state.value}
                                        onBlur={field.handleBlur}
                                        onChange={(e) => field.handleChange(e.target.value)}
                                        hiddenLabel
                                        error={field.state.meta.isTouched && field.state.meta.errors.length > 0}
                                        helperText={
                                            field.state.meta.isTouched &&
                                            field.state.meta.errors.length > 0 &&
                                            field.state.meta.errors[0]
                                        }
                                        placeholder="Enter campaign name"
                                        disabled={form.state.isSubmitting}
                                    />
                                </FormFieldWrapper>
                            )}
                        ></form.Field>
                        <form.Field
                            name="startDate"
                            validators={{
                                onChange: ({ value }) => {
                                    if (!value) {
                                        return 'Start date is required';
                                    }
                                },
                            }}
                            children={(field) => (
                                <FormFieldWrapper>
                                    <FormLabel>Start date</FormLabel>
                                    <StyledDatePicker
                                        selected={field.state.value ? new Date(field.state.value) : null}
                                        onBlur={field.handleBlur}
                                        onChange={(newDate: Date) => {
                                            return field.handleChange(format(newDate, 'yyyy-MM-dd') ?? '');
                                        }}
                                        onChangeRaw={(event) => event.preventDefault()}
                                        portalId="dialog"
                                        dateFormat={'dd/MM-yyyy'}
                                        placeholderText="Select start date"
                                        error={field.state.meta.isTouched && field.state.meta.errors.length > 0}
                                        disabled={form.state.isSubmitting}
                                        showMonthDropdown
                                        showYearDropdown
                                    />
                                    <FormHelperText
                                        error={field.state.meta.isTouched && field.state.meta.errors.length > 0}
                                    >
                                        {field.state.meta.isTouched && field.state.meta.errors[0]}
                                    </FormHelperText>
                                </FormFieldWrapper>
                            )}
                        ></form.Field>
                        <form.Field
                            name="endDate"
                            validators={{
                                onChange: ({ value }) => {
                                    if (!value) {
                                        return 'End date is required';
                                    }
                                },
                            }}
                            children={(field) => (
                                <form.Subscribe
                                    selector={(state) => state.values.startDate}
                                    children={(startDate) => (
                                        <FormFieldWrapper>
                                            <FormLabel>End date</FormLabel>
                                            <StyledDatePicker
                                                selected={field.state.value ? new Date(field.state.value) : null}
                                                onBlur={field.handleBlur}
                                                onChange={(newDate: Date) =>
                                                    field.handleChange(format(newDate, 'yyyy-MM-dd') ?? '')
                                                }
                                                onChangeRaw={(event) => event.preventDefault()}
                                                minDate={startDate ? new Date(startDate) : null}
                                                portalId="dialog"
                                                dateFormat={'dd/MM-yyyy'}
                                                placeholderText="Select end date"
                                                error={field.state.meta.isTouched && field.state.meta.errors.length > 0}
                                                disabled={form.state.isSubmitting}
                                                showMonthDropdown
                                                showYearDropdown
                                            />
                                            <FormHelperText
                                                error={field.state.meta.isTouched && field.state.meta.errors.length > 0}
                                            >
                                                {field.state.meta.isTouched && field.state.meta.errors[0]}
                                            </FormHelperText>
                                        </FormFieldWrapper>
                                    )}
                                ></form.Subscribe>
                            )}
                        ></form.Field>
                        <form.Field
                            name="storefrontTypeId"
                            validators={{
                                onChange: ({ value }) => {
                                    if (!value) {
                                        return 'Storefront type is required';
                                    }
                                },
                            }}
                            children={(field) => (
                                <FormFieldWrapper>
                                    <FormLabel>Storefront type</FormLabel>
                                    <Select
                                        size="small"
                                        value={field.state.value.toString()}
                                        onBlur={field.handleBlur}
                                        onChange={(event) => field.handleChange(Number(event.target.value))}
                                        renderValue={(value) => {
                                            if (value === 'NaN') {
                                                return <SelectPlaceholder>Select storefront type</SelectPlaceholder>;
                                            }
                                            return storefrontTypes.find(
                                                (storefrontType) => storefrontType.id === Number(value)
                                            )?.name;
                                        }}
                                        error={field.state.meta.isTouched && field.state.meta.errors.length > 0}
                                        disabled={form.state.isSubmitting || isEditing}
                                    >
                                        <MenuItem disabled value={'NaN'}>
                                            Select storefront type
                                        </MenuItem>
                                        {storefrontTypes.map((storefrontType) => (
                                            <MenuItem key={storefrontType.id} value={storefrontType.id}>
                                                {storefrontType.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    <FormHelperText
                                        error={field.state.meta.isTouched && field.state.meta.errors.length > 0}
                                    >
                                        {field.state.meta.isTouched && field.state.meta.errors[0]}
                                    </FormHelperText>
                                </FormFieldWrapper>
                            )}
                        ></form.Field>
                        <FormHelperText error>{dialogError}</FormHelperText>
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <form.Subscribe
                        selector={(state) => [state.canSubmit, state.isSubmitting]}
                        children={([canSubmit, isSubmitting]) => (
                            <>
                                <Button
                                    disabled={isSubmitting}
                                    onClick={() => {
                                        if (typeof onClose === 'function') {
                                            onClose(false);
                                        }
                                    }}
                                >
                                    Cancel
                                </Button>
                                <LoadingButton
                                    loading={isSubmitting}
                                    loadingPosition="start"
                                    startIcon={<Save />}
                                    variant="contained"
                                    type="submit"
                                    disabled={!canSubmit}
                                >
                                    Save
                                </LoadingButton>
                            </>
                        )}
                    ></form.Subscribe>
                </DialogActions>
            </form>
        </Dialog>
    );
};

const SelectPlaceholder = styled(Box)`
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.42;
`;

// Styled datepicker to look like a text field!
const StyledDatePicker = styled(ReactDatePicker, {
    shouldForwardProp(propName) {
        return propName !== 'error';
    },
})<{ error?: boolean }>`
  border-radius: 4px;
  border: 0.8px solid rgba(0, 0, 0, 0.23);
  color: rgba(0, 0, 0, 0.87);
  font-size: 0.9rem;
  width: 100%;
  box-sizing: border-box;
  height: 40px;
  padding: 8.5px 14px;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-size: 16px;

  ${({ error }) =>
      error
          ? `
  border-color: rgb(211, 47, 47) !important;
  outline-color: rgb(211, 47, 47) !important;
  `
          : ''}

  &::-webkit-input-placeholder {
    color: currentColor;
    opacity: 0.42;
  }

  &:hover {
    border: 0.8px solid rgba(0, 0, 0, 0.87);
  }
`;

const FormFieldWrapper = styled(Stack)`
  gap: 0.15rem;
`;
