import { ColDef } from 'ag-grid-community';
import { ComparableCell } from 'src/components/molecules/ComparableCell/ComparableCell';
import { EditablePercentageCell } from 'src/components/molecules/EditableCell/EditableCell';
import { IsEditable } from 'src/components/organisms/DataTable/columns/is-editable';
import { avgAggFunc, sumAggFunc } from 'src/utils/ag-grid/agg-functions';
import { comparableCellAvg, comparableCellSum } from 'src/utils/comparableCellAgg';
import { DefaultColumn, DefaultColumnNumeric } from './default-columns-details';

export const DetailsColumnProductLineRowGroup: ColDef[] = [
    {
        ...DefaultColumn,
        field: 'category',
        hide: true,
        rowGroup: true,
        headerName: 'Productline',
    },
    {
        ...DefaultColumn,
        field: 'subCategory',
        hide: true,
        headerName: 'Category',
        suppressSizeToFit: true,
    },
    {
        ...DefaultColumnNumeric,
        field: 'splitLly',
        headerName: 'Split LLY',
        aggFunc: sumAggFunc,
    },
    {
        ...DefaultColumnNumeric,
        field: 'splitLy',
        headerName: 'Split LY',
        aggFunc: sumAggFunc,
    },
    {
        ...DefaultColumnNumeric,
        field: 'plannedSplit',
        headerName: 'Planned Split',
        cellRenderer: EditablePercentageCell,
        editable: IsEditable,
        aggFunc: sumAggFunc,
    },
    {
        ...DefaultColumnNumeric,
        field: 'plannedIndex',
        headerName: 'Planned Index',
        cellRenderer: ComparableCell,
        aggFunc: comparableCellAvg,
    },
    {
        ...DefaultColumnNumeric,
        field: 'plannedSalesIv',
        headerName: 'Planned Sales iV',
        cellRenderer: ComparableCell,
        aggFunc: comparableCellSum,
    },
    {
        ...DefaultColumnNumeric,
        field: 'salesIvLy',
        headerName: 'Sales iV LY',
        aggFunc: sumAggFunc,
    },
    {
        ...DefaultColumnNumeric,
        field: 'indexLy',
        headerName: 'Index LY',
        aggFunc: avgAggFunc,
    },
    {
        ...DefaultColumnNumeric,
        field: 'gmLy',
        headerName: 'GM % LY',
        aggFunc: avgAggFunc,
    },
];
