import styled from '@emotion/styled';
import { Edit, Lock } from '@mui/icons-material';
import { ICellRendererParams } from 'ag-grid-community';
import { isArray, isNumber } from 'lodash';
import { useMemo } from 'react';
import { isStockOptimalOrCOGS } from 'src/components/views/StockManagement/helpers/module-check';
import { Color } from 'src/domain';
import { ComparableCell } from '../ComparableCell/ComparableCell';

// custom params to be used with cellRendererParams
type EditablePercentageCellParams = ICellRendererParams & {
    ignoreLast?: boolean;
    spacing?: number;
    isStockManagement?: boolean;
    isOptimalOptionCount?: boolean;
    editable?: boolean;
    isModifiedByUserGetter?: (params: ICellRendererParams) => boolean | undefined;
};

interface Props {
    componentProps: EditablePercentageCellParams;
    spacing: number;
    isStockManagement?: boolean;
    isOptimalOptionCount?: boolean;
    isModifiedByUserGetter?: (params: ICellRendererParams) => boolean | undefined;
}

export function EditablePercentageCell(componentProps: EditablePercentageCellParams) {
    const { node, valueFormatted, ignoreLast, spacing } = componentProps;

    const isSumRow = node.lastChild && (node?.data?.category === 'Sum' || node?.data?.gender === 'Sum');
    const isFooter = node.footer;
    const isRowGroupParent = node.key;

    if (isSumRow || isFooter || (node.lastChild && ignoreLast === false)) {
        return <NonEditableCellWrapper>{valueFormatted}</NonEditableCellWrapper>;
    }

    if (isRowGroupParent) {
        return <SpacedNonEditableCellWrapper>{valueFormatted}</SpacedNonEditableCellWrapper>;
    }

    return <EditableCell componentProps={componentProps} spacing={spacing ?? 5} />;
}

export function EditableValueCell(componentProps: EditablePercentageCellParams) {
    const { node, valueFormatted, isStockManagement, isOptimalOptionCount, editable = true } = componentProps;

    const isFooter = node?.data?.category === 'Total' || node?.data?.isFooter;
    const isRowGroupParent = node.key;
    const valueIsUnlimited = isStockManagement && valueFormatted === null;

    if (isStockManagement && isStockOptimalOrCOGS(node.data?.category)) return <ComparableCell {...componentProps} />;

    if (isRowGroupParent || (isFooter && isOptimalOptionCount) || !editable) {
        return <SpacedNonEditableCellWrapper>{valueFormatted}</SpacedNonEditableCellWrapper>;
    }

    if (isFooter) {
        return <NonEditableCellWrapper>{valueIsUnlimited ? 'Unlimited' : valueFormatted}</NonEditableCellWrapper>;
    }

    return (
        <EditableCell
            componentProps={componentProps}
            spacing={componentProps.spacing ?? 15}
            isStockManagement={isStockManagement}
            isOptimalOptionCount={isOptimalOptionCount}
        />
    );
}

function EditableCell(props: Props) {
    const {
        valueFormatted,
        api,
        column,
        value: currentValue,
        node,
        isStockManagement,
        isOptimalOptionCount,
        isModifiedByUserGetter,
    } = props.componentProps;
    const isFooter = node.footer;

    const isModifiedByUser = useMemo(
        () => isModifiedByUserGetter?.(props.componentProps),
        [isModifiedByUserGetter, props.componentProps]
    );

    const valueFormattedIsArray = isArray(valueFormatted);
    const valueFormattedIsNull = valueFormattedIsArray ? valueFormatted[0] === null : valueFormatted === null;
    const valueFormattedIsEmptyString = valueFormattedIsArray ? valueFormatted[0] === '' : valueFormatted === '';

    const valueIsUnlimited = isStockManagement && (valueFormattedIsNull || valueFormattedIsEmptyString);
    const valueIsEmpty = isOptimalOptionCount && (valueFormattedIsNull || valueFormattedIsEmptyString);

    const value = useMemo(() => {
        if (valueIsUnlimited) return 'Unlimited';
        if (valueIsEmpty || valueFormattedIsEmptyString) return '';
        if (valueFormattedIsArray) return valueFormatted[0];
        return valueFormatted ?? currentValue;
    }, [
        currentValue,
        valueFormatted,
        valueFormattedIsArray,
        valueFormattedIsEmptyString,
        valueIsEmpty,
        valueIsUnlimited,
    ]);

    if (isFooter) return <SpacedNonEditableCellWrapper>{valueFormatted}</SpacedNonEditableCellWrapper>;

    return (
        <EditableCellWrapper valueIsUnlimited={valueIsUnlimited ?? valueIsEmpty}>
            {typeof isModifiedByUser === 'boolean' && isModifiedByUser && <LockIcon spacing={props.spacing} />}
            {value}
            <EditIcon
                spacing={props.spacing}
                onClick={() => {
                    if (column !== undefined && isNumber(node.rowIndex)) {
                        api.startEditingCell({
                            rowIndex: node.rowIndex,
                            colKey: column,
                        });
                    }
                }}
            />
        </EditableCellWrapper>
    );
}

const LockIcon = styled(Lock)<{ spacing: number }>`
  margin-right: auto;
  font-size: 14px;
  color: #444;
`;

const EditIcon = styled(Edit)<{ spacing: number }>`
  margin-left: ${(props) => `${props.spacing}px`};
  font-size: 14px;
  color: ${Color.editBlue};
`;

const EditableCellWrapper = styled.div<{ valueIsUnlimited?: boolean }>`
  width: 100%;
  display: flex;
  justify-content: right;
  align-items: center;
  cursor: pointer;
  ${(props) =>
      !props.valueIsUnlimited &&
      `
  color: var(--warning-color--editable, ${Color.editBlue});
  `}
`;

const NonEditableCellWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const SpacedNonEditableCellWrapper = styled(NonEditableCellWrapper)`
  padding-right: 19px;
`;
