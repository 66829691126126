import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { useState } from 'react';

interface SaveSettingsDialogProps {
    open: boolean;
    onClose: () => void;
    onSave: (filterName: string) => void;
    isSaving: boolean;
}

const SaveSettingsDialog = ({ open, onClose, onSave, isSaving }: SaveSettingsDialogProps) => {
    const [settingName, setSettingName] = useState('');

    const handleSave = () => {
        onSave(settingName);
        setSettingName('');
    };

    const handleTextFieldKeyDown = (event: React.KeyboardEvent) => {
        if (event.key === 'Enter') {
            handleSave();
        } else if (event.key === 'Escape') {
            onClose();
        }
    };

    return (
        <Dialog maxWidth={'xs'} fullWidth open={open} onClose={onClose}>
            <DialogTitle>Create filter and column settings</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    label="Setting Name"
                    fullWidth
                    value={settingName}
                    onChange={(e) => setSettingName(e.target.value)}
                    onKeyDown={handleTextFieldKeyDown}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} variant={'outlined'}>
                    Cancel
                </Button>
                <Button onClick={handleSave} variant="contained" autoFocus disabled={!settingName || isSaving}>
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default SaveSettingsDialog;
