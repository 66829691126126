import { useMemo } from 'react';
import { Module } from 'src/domain';
import { UserSettingModel } from 'src/infrastructure/rest-api/api-types';
import { moduleToModuleEnum } from 'src/utils/modules';
import { useCurrentModule } from './useCurrentModule';
import { useUserSettingsApiQuery } from './useUserSettingsApiQuery';

export interface MergedUserSettingModel {
    name: string;
    module: string;
    filterSettingsId: number | null;
    filterSettings: string | null;
    columnSettingsId: number | null;
    columnSettings: string | null;
}

export const useUserSettings = (module?: Module) => {
    const { data: userSettings, loading, error, refetch } = useUserSettingsApiQuery();
    const currentModule = useCurrentModule();
    const moduleToUse = moduleToModuleEnum(module ?? currentModule);

    const { filterSettings, columnSettings } = useMemo(() => {
        const filterSettings: UserSettingModel[] = [];
        const columnSettings: UserSettingModel[] = [];

        if (userSettings) {
            userSettings.forEach((setting: UserSettingModel) => {
                if (setting.module === moduleToUse) {
                    if (setting.settingsType === 'Filters') {
                        filterSettings.push(setting);
                    } else if (setting.settingsType === 'Columns') {
                        columnSettings.push(setting);
                    }
                }
            });
        }

        return { filterSettings, columnSettings };
    }, [userSettings, moduleToUse]);

    const mergedSettings = useMemo(() => {
        return (
            userSettings?.reduce((acc: MergedUserSettingModel[], setting: UserSettingModel) => {
                if (setting.module === moduleToUse) {
                    const existingEntry = acc.find(
                        (item) => item.name === setting.name && item.module === setting.module
                    );

                    if (existingEntry) {
                        if (setting.settingsType === 'Filters') {
                            existingEntry.filterSettingsId = setting.id;
                            existingEntry.filterSettings = setting.settings;
                        } else if (setting.settingsType === 'Columns') {
                            existingEntry.columnSettingsId = setting.id;
                            existingEntry.columnSettings = setting.settings;
                        }
                    } else {
                        acc.push({
                            name: setting.name,
                            module: setting.module,
                            filterSettingsId: setting.settingsType === 'Filters' ? setting.id : null,
                            filterSettings: setting.settingsType === 'Filters' ? setting.settings : null,
                            columnSettingsId: setting.settingsType === 'Columns' ? setting.id : null,
                            columnSettings: setting.settingsType === 'Columns' ? setting.settings : null,
                        });
                    }
                }
                return acc;
            }, [] as MergedUserSettingModel[]) || []
        );
    }, [userSettings, moduleToUse]);

    return {
        mergedSettings,
        filterSettings,
        columnSettings,
        loading,
        error,
        refetch,
    };
};
