import { useReactiveVar } from '@bestseller-bit/retail-planning.utils.reactive-vars';
import { Box, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { ColDef } from 'ag-grid-community';
import { FC, useCallback, useEffect } from 'react';
import { useActiveMonth } from 'src/hooks/useActiveMonth';
import { monthsCacheVar } from 'src/infrastructure/local_state';

export interface MonthSelectorProps {
    months: ColDef[];
}

export const MonthSelector: FC<MonthSelectorProps> = ({ months }) => {
    const [activeMonth, setActiveMonth] = useActiveMonth();
    const [monthsCache, setMonthsCache] = useReactiveVar<ColDef[]>(monthsCacheVar);

    useEffect(() => {
        if (months.length <= 0) {
            return;
        }

        if (monthsCache !== months) {
            if (months.length < monthsCache.length) {
                const firstMonth = months[0]?.field?.split('.')[1] ?? '';
                setActiveMonth(firstMonth);
            }
            setMonthsCache(months);
        }
    }, [months, monthsCache, setActiveMonth, setMonthsCache]);

    const handleChange = useCallback(
        (_event: React.MouseEvent<HTMLElement>, newMonth: string) => {
            // it should not be possible to deselect a month
            if (!newMonth) {
                return;
            }
            setActiveMonth(newMonth);
        },
        [setActiveMonth]
    );

    return (
        <Box maxWidth={'100%'} overflow={'auto'}>
            <ToggleButtonGroup
                data-testid="month-selector"
                color="primary"
                value={activeMonth}
                exclusive
                onChange={handleChange}
                aria-label="Months"
            >
                {months.map(({ field, headerName }, index) => {
                    const fieldName = field?.split('.')[1] ?? '';
                    const [month, year] = headerName?.split(' ') ?? [];
                    return (
                        <ToggleButton
                            data-testid={`month-selector-${fieldName}`}
                            data-monthfield={fieldName}
                            key={fieldName + index}
                            value={fieldName}
                        >
                            {month.slice(0, 3)} {year}
                        </ToggleButton>
                    );
                })}
            </ToggleButtonGroup>
        </Box>
    );
};
