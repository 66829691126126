import { useEffect, useState } from 'react';
import { ScopeEnum } from 'src/domain';
import { useFilteredStores } from '../store';
import { useCurrentStore } from '../store/useCurrentStore';
import { useScope } from '../useScope';

export const useIsUsingProductline = (): boolean => {
    const scope = useScope();
    const currentStore = useCurrentStore();
    const [stores] = useFilteredStores();

    const [doesUseProductLineSplit, setDoesUseProductLineSplit] = useState<boolean>(false);

    // derived state done like this to keep state inbetween changing stores
    useEffect(() => {
        if (scope === ScopeEnum.STORE) {
            if (!currentStore) return;

            const doesStoreUseProductLineSplit = currentStore.useProductLineSplit;

            setDoesUseProductLineSplit(doesStoreUseProductLineSplit);
            return;
        } else {
            const doesAllStoresUseProductLineSplit = stores.every((x) => x.useProductLineSplit);

            setDoesUseProductLineSplit(doesAllStoresUseProductLineSplit);
            return;
        }
    }, [currentStore, scope, stores]);

    return doesUseProductLineSplit;
};
