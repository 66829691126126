import { useCallback } from 'react';
import { GenderIdToGenderMap, TableOverviewRow } from 'src/domain';
import { combineGenderAndProductline } from 'src/utils/combineGenderAndProductline';
import { useGendersApiQuery } from './useGendersApiQuery';
import { useProductLineGroupsApiQuery } from './useProductLineGroupsApiQuery';

export const useAggregatedDataMergerForUnsavedChanges = () => {
    const { data: genders } = useGendersApiQuery();
    const { data: productLines } = useProductLineGroupsApiQuery();

    const merge = useCallback(
        (
            aggregatedData: Record<string, Record<string, TableOverviewRow[]>> | undefined,
            unsavedChanges: Record<string, TableOverviewRow[]> | undefined
        ) => {
            if (!genders || !productLines) {
                return;
            }

            if (!aggregatedData) {
                return;
            }

            if (!unsavedChanges) {
                return aggregatedData;
            }

            const mergedData: Record<string, Record<string, TableOverviewRow[]>> = {};
            Object.keys(aggregatedData).forEach((productlineKey) => {
                const dataByProductline = aggregatedData[productlineKey];
                return Object.keys(dataByProductline).map((genderKey) => {
                    const dataByGender = dataByProductline[genderKey];

                    const gender = genders.find((gender) => gender.id === Number(genderKey));
                    if (!gender) throw new Error(`Gender not found for id ${genderKey}`);
                    const genderFromGender = GenderIdToGenderMap.get(gender.id);
                    const productline = productLines.find((productline) => productline.id === Number(productlineKey));

                    const mergedDataByGender = dataByGender.map((row) => {
                        const key = combineGenderAndProductline(genderFromGender, productline?.name);
                        const unsavedChangesForGenderProductline = unsavedChanges[key];
                        if (!unsavedChangesForGenderProductline) {
                            return row;
                        }

                        const unsavedRow = unsavedChangesForGenderProductline.find(
                            (unsavedRow) => unsavedRow.id === row.id
                        );
                        if (!unsavedRow) {
                            return row;
                        }

                        return unsavedRow;
                    });

                    mergedData[productlineKey] = mergedData[productlineKey] ?? {};
                    mergedData[productlineKey][genderKey] = mergedDataByGender;
                });
            });

            return mergedData;
        },
        [genders, productLines]
    );

    return merge;
};
