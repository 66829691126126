import { get, set } from 'lodash';
import { useMemo } from 'react';
import { EditableValueCell } from 'src/components/molecules/EditableCell/EditableCell';
import {
    defaultColDefNumberWithSpaces,
    defaultNumericColumnOptions,
} from 'src/components/organisms/DataTable/columns/default-options';
import { createDynamicMonthColumns } from 'src/components/organisms/DataTable/columns/utils';
import { isStockManagementCellEditable } from 'src/components/views/StockManagement/helpers/module-check';
import { StockManagementRow } from 'src/domain';
import { ScopeEnum } from 'src/domain/enums';
import { useScope, useWeekCoverOverviewRows } from 'src/hooks';
import { useMarkupDetailsRows } from 'src/hooks/markup/table/useMarkupDetailsRows';
import {
    queryToStockManagement,
    stockManagementCategoriesToTableData,
    stockManagementTotalsToTableData,
} from 'src/mapping/stock-management.mapping';
import { parseNumber } from 'src/utils/formatNumber';
import { numberWithSpaces } from 'src/utils/numberWithSpaces';
import { useStockManagementQuery } from '../queries/useStockMgmtQuery';
import { useStockManagementTableKey } from './useStockManagementTableKey';

export const useStockManagementOverviewRows = () => {
    const { data, styleCategories, error, loading } = useStockManagementQuery();
    const { aggregatedData: aggregatedMarkupData, loading: markupLoading } = useMarkupDetailsRows(true);
    const { aggregatedData: aggregatedWeekCoverData, loading: weekCoverLoading } = useWeekCoverOverviewRows(
        undefined,
        undefined,
        undefined,
        true
    );
    const tableKey = useStockManagementTableKey();
    const splitLevel = useScope();

    const isLoading = loading || markupLoading || weekCoverLoading;

    const tableData = useMemo(() => {
        if (!data || !styleCategories) {
            return;
        }

        const sanitizedData = queryToStockManagement(data, styleCategories);

        if (tableKey === 'categories-table') {
            return stockManagementCategoriesToTableData(sanitizedData?.categoriesList);
        } else {
            if (!aggregatedMarkupData || !aggregatedWeekCoverData) {
                return [];
            }

            const rows = stockManagementTotalsToTableData(
                sanitizedData?.totalsList,
                aggregatedMarkupData,
                aggregatedWeekCoverData,
                styleCategories
            );

            if (splitLevel !== ScopeEnum.STORE)
                return rows.filter((row) => !row.category.includes('Stock Optimal') && !row.category.includes('COGS'));
            return rows;
        }
    }, [aggregatedMarkupData, aggregatedWeekCoverData, data, splitLevel, styleCategories, tableKey]);

    const columns = useMemo(() => {
        const stockManagementData =
            data?.stockManagementSplit === 'Totals' ? data?.stockManagementTotals : data?.stockManagementCategories;
        return stockManagementData
            ? createDynamicMonthColumns<StockManagementRow>(stockManagementData, {
                  cellRenderer: EditableValueCell,
                  cellRendererParams: {
                      spacing: 5,
                      isStockManagement: true,
                  },
                  editable: isStockManagementCellEditable,
                  valueGetter(cell) {
                      if (
                          typeof defaultNumericColumnOptions.valueGetter === 'function' &&
                          !['Minimum', 'Maximum'].includes(cell.data?.category ?? '')
                      ) {
                          return defaultNumericColumnOptions.valueGetter(cell);
                      }

                      let value = get(cell.data, cell.colDef.field!);
                      if (Array.isArray(value)) {
                          value = value[1] !== undefined ? value[1] : value[0];
                      }

                      return value;
                  },
                  valueSetter(cell) {
                      const valueAsNumber = parseNumber(cell.newValue);
                      set(
                          cell.data as object,
                          cell.colDef.field!,
                          isNaN(valueAsNumber) ? cell.newValue : Math.round(valueAsNumber)
                      );
                      return true;
                  },
                  valueFormatter(cell) {
                      if (
                          typeof defaultColDefNumberWithSpaces.valueFormatter === 'function' &&
                          !['Minimum', 'Maximum'].includes(cell.data?.category ?? '')
                      ) {
                          return defaultColDefNumberWithSpaces.valueFormatter(cell);
                      }

                      if (cell.value) {
                          return numberWithSpaces(parseNumber(cell.value));
                      }

                      return '';
                  },
              })
            : [];
    }, [data]);

    return { data: tableData, columns, error, loading: isLoading };
};
