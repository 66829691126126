import { Badge, BadgeProps } from '@mui/material';
import { FC, PropsWithChildren } from 'react';

interface UnsavedChangesBadgeProps extends Pick<BadgeProps, 'anchorOrigin'> {
    hasChanges: boolean;
}

export const UnsavedChangesBadge: FC<UnsavedChangesBadgeProps & PropsWithChildren<unknown>> = ({
    hasChanges,
    anchorOrigin,
    children,
}) => {
    return (
        <Badge variant="dot" anchorOrigin={anchorOrigin} invisible={!hasChanges} color="info">
            {children}
        </Badge>
    );
};
