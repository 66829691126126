import { useMemo } from 'react';
import { ScopeEnum } from 'src/domain';
import { useScope } from 'src/hooks';
import { useStores } from 'src/hooks/store-selection/queries/useStores';
import { mapStyleTimelineDataToOverviewRows } from 'src/mapping/style-timeline.mapping';
import { useStyleTimelineQuery } from '../queries/useStyleTimelineQuery';

export const useStyleTimelineRows = (selectedStorefrontType?: string) => {
    const { styleTimelines, collections, styleCategories, loading, error } =
        useStyleTimelineQuery(selectedStorefrontType);
    const scope = useScope();
    const { data } = useStores();

    const rows = useMemo(() => {
        const overviewRows = mapStyleTimelineDataToOverviewRows(
            styleTimelines,
            collections,
            styleCategories,
            data.allStores,
            scope === ScopeEnum.GLOBAL
        );
        return overviewRows;
    }, [styleTimelines, collections, styleCategories, data.allStores, scope]);

    return { data: rows, collections, loading, error };
};
