import { useApiQuery } from 'src/infrastructure/rest-api/useApi';
import { useCompositePartner } from '../partner/useCompositePartner';

export const usePhaseoutProfiles = () => {
    const partnerComposite = useCompositePartner();

    return useApiQuery('/api/phaseoutprofile/partners/{partnerCompositeId}', 'get', {
        path: {
            partnerCompositeId: partnerComposite?.id,
        },
        enabled: typeof partnerComposite?.id === 'number',
    });
};
