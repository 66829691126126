import { useMemo } from 'react';
import { CategoryOverviewRow, Inheritance, Module } from 'src/domain';
import { mapCategorySplitsToRows } from 'src/mapping/planned-splits.mapping';
import { combineGenderAndProductline } from 'src/utils/combineGenderAndProductline';
import { useCategorySplitOverviewSimulationRows } from '../category-split/simulation/useCategorySplitOverviewSimulationRows';
import { useGender } from '../gender';
import { useProductLine } from '../productline';
import { useUnsavedChangesModule } from '../unsaved-changes';
import { useAggregatedDataMergerForUnsavedChanges } from '../useAggregatedDataMergerForUnsavedChanges';
import { useWeekCoverOverviewRows } from './useWeekCoverOverviewRows';

export const useWeekCoverOverviewSimulationRows = (
    inheritance?: Inheritance,
    genderId?: number,
    productLineId?: number,
    aggregateOnGenderProductlines = false
) => {
    const {
        data: rows,
        aggregatedData: aggregatedRows,
        columns,
        error,
        loading,
    } = useWeekCoverOverviewRows(inheritance, genderId, productLineId, aggregateOnGenderProductlines);
    const [unsavedChanges] = useUnsavedChangesModule<Record<string, CategoryOverviewRow[]>>(Module.WeekCover);
    const mergeUnsavedChanges = useAggregatedDataMergerForUnsavedChanges();

    const gender = useGender();
    const productLine = useProductLine();
    const genderProductlineKey = combineGenderAndProductline(gender, productLine?.name);

    const { data: categorySplitSimulationData } = useCategorySplitOverviewSimulationRows(
        undefined,
        genderId,
        productLine?.id,
        aggregateOnGenderProductlines
    );

    const aggregatedOverviewRows = useMemo(() => {
        if (aggregateOnGenderProductlines) {
            return mergeUnsavedChanges(aggregatedRows, unsavedChanges);
        }
    }, [aggregateOnGenderProductlines, aggregatedRows, mergeUnsavedChanges, unsavedChanges]);

    const overviewRows = useMemo(
        () => unsavedChanges?.[genderProductlineKey] ?? rows,
        [genderProductlineKey, rows, unsavedChanges]
    );

    const tableData = useMemo(() => {
        if (!overviewRows || !categorySplitSimulationData) return;
        return mapCategorySplitsToRows(overviewRows, categorySplitSimulationData);
    }, [categorySplitSimulationData, overviewRows]);

    return {
        data: tableData,
        aggregatedData: aggregatedOverviewRows,
        columns,
        error,
        loading,
    };
};
