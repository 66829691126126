import { useMemo } from 'react';
import { Module } from 'src/domain';
import { OpenToBuyLimitOverviewRow } from 'src/domain/table/open-to-buy-limit.row';
import { useUnsavedChangesModule } from 'src/hooks/unsaved-changes';
import { useOpenToBuyLimitOverviewRows } from '../table/useOpenToBuyLimitOverviewRows';

export const useOpenToBuyLimitOverviewSimulationRows = () => {
    const [unsavedChanges] = useUnsavedChangesModule<OpenToBuyLimitOverviewRow[]>(Module.OpenToBuyLimit);
    const { data, columns, buyingEvents, plannedOn, loading, error } = useOpenToBuyLimitOverviewRows();

    const simulatedData = useMemo(() => {
        if (!data) return;
        if (!unsavedChanges) return data;

        return unsavedChanges ?? data;
    }, [data, unsavedChanges]);

    return { data: simulatedData, buyingEvents, plannedOn, columns, loading, error };
};
