import { useReactiveVar } from '@bestseller-bit/retail-planning.utils.reactive-vars';
import { useMemo } from 'react';
import { useClusterId } from 'src/hooks/cluster';
import { useInitializeIsNoosShareHistorical } from 'src/hooks/historical';
import { useCompositePartner } from 'src/hooks/partner/useCompositePartner';
import { useStoreId } from 'src/hooks/store';
import { selectedPartnerVar } from 'src/infrastructure/local_state';
import { NoosShareModel } from 'src/infrastructure/rest-api/api-types';
import { components } from 'src/infrastructure/rest-api/generated';
import { useNoosShareApiQuery } from './useNoosShareApiQuery';

export type NoosShare = components['schemas']['NoosShareModel'];

export type NoosShareData = {
    storeNoosShare?: NoosShareModel;
    clusterNoosShare?: NoosShareModel;
    partnerNoosShare?: NoosShareModel;
};

export type NoosShareSplitLevelQuery = {
    data: NoosShareData;
    error: Error | null;
    loading: boolean;
};

export const useNoosShareSplitLevelQuery = (): NoosShareSplitLevelQuery | undefined => {
    const storeId = useStoreId();
    const [selectedPartner] = useReactiveVar(selectedPartnerVar);
    const clusterId = useClusterId(selectedPartner);
    const compositePartner = useCompositePartner();

    const storeResponse = useNoosShareApiQuery(storeId, 'Store');
    const clusterResponse = useNoosShareApiQuery(clusterId, 'Cluster');
    const partnerResponse = useNoosShareApiQuery(compositePartner?.id, 'Partner');

    const response = useMemo<NoosShareSplitLevelQuery | undefined>(() => {
        return {
            data: {
                storeNoosShare: storeResponse.data,
                clusterNoosShare: clusterResponse.data,
                partnerNoosShare: partnerResponse.data,
            },
            error: storeResponse.error ?? clusterResponse.error ?? partnerResponse.error,
            loading: storeResponse.loading || clusterResponse.loading || partnerResponse.loading,
        };
    }, [
        storeResponse.data,
        storeResponse.error,
        storeResponse.loading,
        clusterResponse.data,
        clusterResponse.error,
        clusterResponse.loading,
        partnerResponse.data,
        partnerResponse.error,
        partnerResponse.loading,
    ]);

    useInitializeIsNoosShareHistorical(response?.data);

    return response;
};
