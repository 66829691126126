import { useMemo } from 'react';
import { GenderDetailsRow } from 'src/domain';
import { useTurnoverSimulationRows } from 'src/hooks/turnover/simulation/useTurnoverSimulationRows';
import { monthKeyToField } from 'src/utils/monthKeys';
import { calculatePlannedIndex } from 'src/utils/turnover/calculatePlannedIndex';
import { useGenderSplitDetailsRows } from '../table/useGenderSplitDetailsRows';
import { useGenderSplitOverviewSimulationRows } from './useGenderSplitOverviewSimulationRows';

export const useGenderSplitDetailsSimulationRows = () => {
    const { data, error, loading } = useGenderSplitDetailsRows() ?? {};
    const { data: genderSimulationData } = useGenderSplitOverviewSimulationRows();
    const { data: turnoverData } = useTurnoverSimulationRows();

    const simulatedData = useMemo(() => {
        if (!data) return;

        const map = new Map<string, GenderDetailsRow[]>();

        data.forEach((detailsRows, month) =>
            map.set(
                month,
                detailsRows.map((row) => {
                    const plannedSplit =
                        genderSimulationData?.find((unsavedRow) => unsavedRow.id === row.id)?.columns[month] ?? 0;

                    const plannedSalesIv =
                        (turnoverData?.find((change) => {
                            return monthKeyToField(change.monthKey).equalsInsensitive(month);
                        })?.plannedSalesIv ?? 0) *
                        (plannedSplit / 100);

                    return {
                        ...row,
                        plannedSplit,
                        plannedSalesIv: Math.round(plannedSalesIv * 100) / 100,
                        plannedIndex: calculatePlannedIndex(1, plannedSalesIv, row.salesIvLy, row.salesIvLly),
                    };
                })
            )
        );

        return map;
    }, [data, turnoverData, genderSimulationData]);

    return { data: simulatedData, error, loading };
};
