import { ColDef, ICellRendererParams, ValueFormatterParams } from 'ag-grid-community';
import { ComparableCell } from 'src/components/molecules/ComparableCell/ComparableCell';

export const renderMonthCell = (params: ICellRendererParams, column: ColDef) => {
    if (params.node.group) {
        const [_, month] = (column.field ?? '').split('.');
        const value = params.node.aggData[month];

        if (!value) return;
        const isSameValue = value[0] === value[1];

        const formattedValue = isSameValue ? [value[0], undefined] : value;

        return typeof params.colDef?.valueFormatter === 'function' ? (
            params.colDef.valueFormatter({
                ...params,
                value: <ComparableCell {...params} value={formattedValue} />,
            } as ValueFormatterParams)
        ) : (
            <ComparableCell {...params} value={formattedValue} />
        );
    }
    return <ComparableCell {...params} />;
};
