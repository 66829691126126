import styled from '@emotion/styled';
import { Clear, Search } from '@mui/icons-material';
import { InputBase } from '@mui/material';
import { ChangeEvent, ComponentProps, FC } from 'react';
import { Color } from 'src/domain';

interface Props extends ComponentProps<'div'> {
    placeholder: string;
    width: string;
    resetInputField: () => void;
    updateUserInput: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    value: string;
}

const Searchbar: FC<Props> = ({ placeholder, width, resetInputField, updateUserInput, value, ...props }) => {
    return (
        <InputWrapper wrapperWidth={width} {...props}>
            <SearchIconWrapper>
                <Search />
            </SearchIconWrapper>
            <StyledInput
                placeholder={placeholder}
                inputProps={{ 'aria-label': 'search' }}
                value={value}
                onChange={(event) => updateUserInput(event)}
            />
            {value.length > 0 && (
                <ClearIconWrapper>
                    <Clear
                        onClick={() => {
                            resetInputField();
                        }}
                    />
                </ClearIconWrapper>
            )}
        </InputWrapper>
    );
};

const InputWrapper = styled.div<{ wrapperWidth: string }>`
  position: relative;
  width: ${(props) => props.wrapperWidth};
  display: flex;
  align-items: center;
  height: 100%;
`;

const StyledInput = styled(InputBase)`
  background-color: ${Color.white};
  height: 100%;
  font-size: 15px;
  width: 100%;
  padding: 6px 10px 6px 45px;
  border-radius: 4px;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */
  box-shadow:
    0px 3px 1px -2px rgb(0 0 0 / 20%),
    0px 2px 2px 0px rgb(0 0 0 / 14%),
    0px 1px 5px 0px rgb(0 0 0 / 12%);
`;

const SearchIconWrapper = styled.div`
  position: absolute;
  z-index: 1;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 10px;
  color: ${Color.steelGrey};
`;

const ClearIconWrapper = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 10px;
  color: ${Color.steelGrey};
  cursor: pointer;
`;

export default Searchbar;
