import {
    AutocompleteChangeReason,
    AutocompleteRenderInputParams,
    AutocompleteRenderOptionState,
    FilterOptionsState,
    Autocomplete as MuiAutocomplete,
    TextField,
    createFilterOptions,
} from '@mui/material';
import { FC, HTMLAttributes, SyntheticEvent, useMemo } from 'react';
import { Option } from './Option';
import { Item, compare } from './compare';
import { selectAllOption, selectAllValue } from './selectAll';

interface Props {
    options: Item[];
    value: Item[];
    onSelect?: (selected: Item[]) => unknown;
    limitTags?: number | false;
    placeholder?: string;
    disabled?: boolean;
    className?: string;
    hasSelectAll?: boolean;
}

const Autocomplete: FC<Props> = ({
    options,
    value,
    onSelect,
    limitTags = 3,
    placeholder = 'Select items',
    disabled,
    className,
    hasSelectAll = true,
}) => {
    const allSelected = useMemo(() => value.length === options.length, [options, value]);
    const filter = createFilterOptions<Item>();

    const renderOption = (
        props: HTMLAttributes<HTMLLIElement>,
        option: Item,
        { selected }: AutocompleteRenderOptionState
    ) => {
        const isSelectAll = option.value === selectAllValue && allSelected;

        return <Option label={option.label} native={props} selected={selected || isSelectAll} key={option.value} />;
    };

    const renderInput = (params: AutocompleteRenderInputParams) => <TextField {...params} placeholder={placeholder} />;

    const filterOptions = (allOptions: Item[], params: FilterOptionsState<Item>) => {
        const filtered = filter(allOptions, params);

        if (filtered.isEmpty()) return [];

        return [...(hasSelectAll ? [selectAllOption] : []), ...filtered];
    };

    const handleChange = (_event: SyntheticEvent, selectedOptions: Item[], reason: AutocompleteChangeReason) => {
        const isSelectAll = selectedOptions.find((x) => x.value === selectAllValue);

        let result: Item[];

        if (reason === 'clear') result = [];
        else if (isSelectAll && allSelected) result = [];
        else if (isSelectAll && !allSelected) result = options;
        else result = selectedOptions;

        onSelect?.(result);
    };

    return (
        <MuiAutocomplete
            multiple
            limitTags={limitTags === false ? -1 : limitTags}
            options={options}
            value={value}
            disableCloseOnSelect
            getOptionLabel={(option) => option.label}
            onChange={handleChange}
            renderOption={renderOption}
            renderInput={renderInput}
            filterOptions={filterOptions}
            disabled={disabled}
            className={className}
            isOptionEqualToValue={compare}
        />
    );
};

export default Autocomplete;
