import { useCallback, useState } from 'react';
import { Module } from 'src/domain';
import { useSnackbar } from 'src/hooks/snackbar/useSnackbar';
import { AnnouncementTypeEnum } from 'src/infrastructure/rest-api/api-types';
import { useApiMutation } from 'src/infrastructure/rest-api/useApi';
import { moduleToAnnouncementModule } from 'src/utils/modules';

interface Props {
    title: string;
    description: string;
    type: AnnouncementTypeEnum;
    module: Module | null;
}

export const useCreateNewAnnouncement = ({ title, description, type, module }: Props) => {
    const [createAnnouncement] = useApiMutation('/api/announcements', 'post', {
        update(data, _variables, queryClient) {
            queryClient.setQueryData(['/api/announcements'], data);
        },
    });

    const [isUpdating, setIsUpdating] = useState(false);

    const showSnackbar = useSnackbar();

    const creatingAnnouncement = useCallback(async () => {
        setIsUpdating(true);

        const payload = {
            title,
            description,
            announcementTypeEnum: type,
            moduleEnum: moduleToAnnouncementModule(module),
        };
        try {
            const response = await createAnnouncement({
                body: payload,
            });
            showSnackbar(`Announcement '${title}' has been created!`, 'success');
            return response;
        } catch {
            showSnackbar(`Failed to create announcement. Please try again later`, 'error');
        } finally {
            setIsUpdating(false);
        }
    }, [createAnnouncement, title, description, type, module, showSnackbar]);

    return [creatingAnnouncement, { loading: isUpdating }] as const;
};
