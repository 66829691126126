import { ScopeEnum, UnsavedChangesClustersKey, UnsavedChangesPartnersKey, UnsavedChangesStoresKey } from 'src/domain';
import { UnsavedChangesGlobalKey } from 'src/domain/session-storage/global-key';

export const getSplitLevelKey = (scope?: ScopeEnum) => {
    switch (scope) {
        case ScopeEnum.STORE:
            return UnsavedChangesStoresKey;
        case ScopeEnum.CLUSTER:
            return UnsavedChangesClustersKey;
        case ScopeEnum.PARTNER:
            return UnsavedChangesPartnersKey;
        case ScopeEnum.GLOBAL:
            return UnsavedChangesGlobalKey;
        default:
            return UnsavedChangesStoresKey;
    }
};
