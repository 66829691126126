import { isNumber } from 'lodash';
import { useCallback } from 'react';
import { Cell } from 'src/components/organisms/DataTable/types';
import { CategoryOverviewRow, Inheritance, Module } from 'src/domain';
import { UnsavedChangesEvent } from 'src/domain/events/unsaved-changes.event';
import { mergeOverviewRows } from 'src/utils/mergeRows';
import { useSetSelectedInheritance } from '../inheritance/useSetSelectedInheritance';
import { useUnsavedChangesModule } from '../unsaved-changes';
import { useActiveMonth } from '../useActiveMonth';
import { useGenderProductLineKey } from '../useGenderProductLineKey';
import { useCategorySplitOverviewSimulationRows } from './simulation/useCategorySplitOverviewSimulationRows';

export const useCategorySplitHandleCellChanges = () => {
    const [unsavedChanges, setUnsavedChanges] = useUnsavedChangesModule<Record<string, CategoryOverviewRow[]>>(
        Module.CategorySplit
    );

    const [activeMonth] = useActiveMonth();

    const { data: typedData } = useCategorySplitOverviewSimulationRows(Inheritance.Typed) ?? {};
    const { data: originalData } = useCategorySplitOverviewSimulationRows();

    const setSelectedInheritance = useSetSelectedInheritance();

    const genderProductLineKey = useGenderProductLineKey();

    const callback = useCallback(
        (cells: Cell[]) => {
            if (!typedData || !originalData) {
                throw new Error('Data is missing!');
            }

            const mergedRows = mergeOverviewRows(typedData, originalData);

            cells.forEach((cellValue) => {
                const rowIndexToUpdate = mergedRows.findIndex((x) => x.id === cellValue.rowId);
                const fieldName: string | undefined =
                    !cellValue.column || cellValue.column === 'plannedSplit'
                        ? activeMonth
                        : cellValue.column?.split('.')[1];

                const valueIsValid = !isNaN(cellValue.value) && isNumber(Number(cellValue.value));

                if (fieldName) {
                    mergedRows[rowIndexToUpdate] = {
                        ...mergedRows?.[rowIndexToUpdate],
                        columns: {
                            ...mergedRows?.[rowIndexToUpdate].columns,
                            [fieldName]: valueIsValid ? cellValue.value : 0,
                        },
                    };
                }
            });

            const unsaved = {
                ...unsavedChanges,
                ...{ [genderProductLineKey]: mergedRows },
            };

            setUnsavedChanges(unsaved);
            setSelectedInheritance(Inheritance.Typed);
            dispatchEvent(new Event(UnsavedChangesEvent));
        },
        [
            typedData,
            originalData,
            unsavedChanges,
            genderProductLineKey,
            setUnsavedChanges,
            setSelectedInheritance,
            activeMonth,
        ]
    );

    return callback;
};
