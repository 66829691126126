import { ClusterManagementRow } from 'src/domain/models/cluster-management/cluster-management-row';
import { ClusterModel, StoreModel, UpdateClusterInput } from 'src/infrastructure/rest-api/api-types';

export function mapStoresToClusterManagementRows(
    stores: StoreModel[],
    salesIv: Record<string, number>
): ClusterManagementRow[] {
    return stores.map((store) => {
        const salesIvNext12Months = salesIv[store.id] ?? 0;
        return {
            rowId: `${store.cluster.id}-${store.id}`,
            id: store.id.toString(),
            storeId: store.id,
            // TODO: is this safe? If shortname is always two characters, it is safe. Otherwise we need chain shortname to do this in a safe way. Could it be done on the backend instead - seems like this is the case for the store query?
            storeName: store.storeName.slice(5),
            cluster: mapClusterToUpdateClusterInput(store.cluster),
            openingDate: store.storeOpeningDate,
            salesIvNext12Months: salesIvNext12Months,
            salesIvPerSquareMeter:
                typeof store.salesFloorSquaremeters === 'number'
                    ? salesIvNext12Months / store.salesFloorSquaremeters
                    : 0,
            storeSize: store.salesFloorSquaremeters,
        };
    });
}

export function mapClustersToUpdateClusterInput(clusters: ClusterModel[]): UpdateClusterInput[] {
    return clusters.map(mapClusterToUpdateClusterInput);
}

export function mapClusterToUpdateClusterInput(cluster: ClusterModel): UpdateClusterInput {
    return {
        clusterId: cluster.id,
        clusterName: cluster.name,
        description: cluster.description,
        isDefault: cluster.isDefault,
        stores: cluster.stores.map((store) => store.id),
    };
}
