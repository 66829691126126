import { useMemo } from 'react';
import { ClusterManagementRow } from 'src/domain/models/cluster-management/cluster-management-row';
import { UpdateClusterInput } from 'src/infrastructure/rest-api/api-types';
import {
    mapClusterToUpdateClusterInput,
    mapClustersToUpdateClusterInput,
    mapStoresToClusterManagementRows,
} from 'src/mapping/cluster-management.mapping';
import { useClustersByCompositeId } from '../useClustersByCompositeId';
import { useTurnoverForPartnerStores } from '../useTurnoverForPartnerStores';

export interface UseClusterManagementRowsReturnValue {
    data: ClusterManagementRow[];
    clusters: UpdateClusterInput[] | undefined;
    loading: boolean;
    error: Error | null;
}

export const useClusterManagementRows = (): UseClusterManagementRowsReturnValue => {
    const { clusters, stores, loading, error } = useClustersByCompositeId();
    const { salesIvByStoreId, loading: turnoverLoading, error: turnoverError } = useTurnoverForPartnerStores();

    const rows = useMemo(() => {
        if (!stores || !salesIvByStoreId) {
            return [];
        }
        return mapStoresToClusterManagementRows(stores, salesIvByStoreId);
    }, [stores, salesIvByStoreId]);

    const rowsForEmptyClusters: ClusterManagementRow[] = useMemo(() => {
        if (!clusters) {
            return [];
        }

        return clusters
            .filter((cluster) => !cluster.stores.length)
            .map((cluster) => ({
                rowId: `empty-${cluster.id}`,
                id: cluster.id.toString(),
                storeId: -1,
                storeName: 'This cluster has no stores',
                cluster: mapClusterToUpdateClusterInput(cluster),
                openingDate: null,
                salesIvNext12Months: 0,
                salesIvPerSquareMeter: 0,
                storeSize: 0,
            }));
    }, [clusters]);

    return {
        data: [...rows, ...rowsForEmptyClusters],
        clusters: mapClustersToUpdateClusterInput(clusters ?? []),
        loading: loading || turnoverLoading,
        error: error ?? turnoverError,
    };
};
