import { Edit } from '@mui/icons-material';
import { FormLabel, Paper, Popper, Stack, TextField, styled } from '@mui/material';
import {
    CellDoubleClickedEvent,
    CellFocusedEvent,
    CellKeyDownEvent,
    FullWidthCellKeyDownEvent,
    ICellRendererParams,
    ProcessCellForExportParams,
} from 'ag-grid-community';
import { isNumber } from 'lodash';
import { FC, KeyboardEvent, useCallback, useEffect, useRef, useState } from 'react';
import { Color } from 'src/domain';
import { ExtendedSalesCampaignDiscountType } from 'src/infrastructure/rest-api/api-types';
import { isCellEvent } from 'src/utils/ag-grid/events';
import { formatNumber, parseNumber } from 'src/utils/formatNumber';

interface DiscountCellEditorData {
    x: number | undefined;
    y: number | undefined;
    discountType: ExtendedSalesCampaignDiscountType | undefined;
}

interface DiscountCellEditorChangeData {
    x: string;
    y: string;
}

export const DiscountCellRendererEditor: FC<ICellRendererParams<unknown, DiscountCellEditorData>> = ({
    value,
    valueFormatted,
    api,
    column,
    node,
}) => {
    const discountType = value?.discountType;
    const [valueX, setValueX] = useState(value?.x?.toString() ?? '');
    const [valueY, setValueY] = useState(formatNumber(value?.y) ?? '');

    const onValueChanged = useCallback(
        (newValue: DiscountCellEditorData) => {
            const colId = column?.getColId();
            if (colId) {
                node.setDataValue(colId, newValue);
            }
        },
        [column, node]
    );

    useEffect(() => {
        setValueX(value?.x?.toString() ?? '');
        setValueY(formatNumber(value?.y) ?? '');
    }, [value]);

    const [isEditing, setIsEditing] = useState(false);

    const stopEditing = useCallback(
        (cancel?: boolean) => {
            if (!isEditing) {
                return;
            }

            setIsEditing(false);

            if (!cancel) {
                const x = Number(valueX);
                const y = parseNumber(valueY || undefined);

                onValueChanged({
                    x: isNaN(x) ? value?.x : x,
                    y: isNaN(y) ? value?.y : y,
                    discountType,
                });
            }

            const colId = column?.getColId();
            if (cancel && isNumber(node.rowIndex) && colId) {
                // keep current cell focused when cancelling
                api.setFocusedCell(node.rowIndex, colId);
            }
        },
        [api, column, discountType, isEditing, node.rowIndex, onValueChanged, value?.x, value?.y, valueX, valueY]
    );

    const startEditing = useCallback(() => {
        setIsEditing(true);
    }, []);

    const handleUpdate = useCallback(
        (update: Partial<DiscountCellEditorChangeData>) => {
            const x = update.x ?? valueX;
            const y = update.y ?? valueY;

            setValueX(x);
            setValueY(y);
        },
        [valueX, valueY]
    );

    useEffect(() => {
        const handleCellFocus = (event: CellFocusedEvent) => {
            const colId = typeof event.column === 'string' ? event.column : event.column?.getColId();

            if (colId !== column?.getColId() || (event.rowIndex !== node.rowIndex && !isEditing)) {
                stopEditing();
            }
        };

        const handleCellKeyDown = (event: CellKeyDownEvent | FullWidthCellKeyDownEvent<unknown, unknown>) => {
            if (!isCellEvent(event)) {
                return;
            }

            const rowIndex = event.rowIndex;
            const colId = typeof event.column === 'string' ? event.column : event.column?.getColId();

            const keyboardEvent = event.event as KeyboardEvent | undefined;

            if (!keyboardEvent || rowIndex !== node.rowIndex || colId !== column?.getColId()) {
                return;
            }

            const hasModifierKey = keyboardEvent.ctrlKey || keyboardEvent.metaKey || keyboardEvent.shiftKey;

            if (isEditing && ['Delete', 'Backspace', 'Clear'].includes(keyboardEvent.key)) {
                keyboardEvent.preventDefault();
                return;
            }

            if (keyboardEvent.key === 'Escape' && !isEditing) {
                stopEditing(true);
            } else if (keyboardEvent.key === 'Enter') {
                if (!isEditing) {
                    startEditing();
                } else {
                    stopEditing();

                    if (isNumber(node.rowIndex) && node.rowIndex < api.getDisplayedRowCount() - 1) {
                        api.setFocusedCell(node.rowIndex + 1, colId);
                    }
                }
            } else if (keyboardEvent.key.length === 1 && /[a-z0-9]+/i.test(keyboardEvent.key) && !hasModifierKey) {
                if (!isEditing) {
                    if (discountType === 'FIXED_PRICE') {
                        handleUpdate({ y: keyboardEvent.key });
                    } else {
                        handleUpdate({ x: keyboardEvent.key });
                    }
                    startEditing();
                }
            }
        };

        const handleCellDoubleClick = (event: CellDoubleClickedEvent) => {
            const colId = typeof event.column === 'string' ? event.column : event.column?.getColId();
            if (event.rowIndex === node.rowIndex && colId === column?.getColId()) {
                startEditing();
            } else {
                stopEditing();
            }
        };

        const handlePaste = (event: ClipboardEvent) => {
            const cellRanges = api.getCellRanges();

            cellRanges?.forEach((range) => {
                if (!range.startRow || !range.endRow || !node.rowIndex) {
                    return;
                }

                const smallestRowIndex = Math.min(range.startRow.rowIndex, range.endRow.rowIndex);
                const largestRowIndex = Math.max(range.startRow.rowIndex, range.endRow.rowIndex);

                if (
                    node.rowIndex >= smallestRowIndex &&
                    node.rowIndex <= largestRowIndex &&
                    range.columns.some((col) => col.getColId() === column?.getColId())
                ) {
                    const processCellFromClipboard = api.getGridOption('processCellFromClipboard');

                    if (typeof processCellFromClipboard === 'function') {
                        const clipboardData = event.clipboardData;
                        const text = clipboardData?.getData('text/plain');

                        const items = text?.split('\r\n') ?? [];
                        const parsedItems = items.map((text) =>
                            processCellFromClipboard({ value: text } as ProcessCellForExportParams)
                        );

                        const countedIndexPosition = node.rowIndex - smallestRowIndex;
                        const parsedData =
                            parsedItems.length === 1 ? parsedItems[0] : parsedItems[countedIndexPosition];

                        if (parsedData) {
                            onValueChanged(parsedData as DiscountCellEditorData);
                        }
                    }
                }
            });
        };

        api.addEventListener('cellFocused', handleCellFocus);
        api.addEventListener('cellKeyDown', handleCellKeyDown);
        api.addEventListener('cellDoubleClicked', handleCellDoubleClick);
        document.addEventListener('paste', handlePaste);

        return () => {
            // Only remove this if the api is not destroyed - internally AG grid handles cleanup automatically
            if (!api.isDestroyed()) {
                api.removeEventListener('cellFocused', handleCellFocus);
                api.removeEventListener('cellKeyDown', handleCellKeyDown);
                api.removeEventListener('cellDoubleClicked', handleCellDoubleClick);
            }
            document.removeEventListener('paste', handlePaste);
        };
    }, [api, column, discountType, handleUpdate, isEditing, node.rowIndex, onValueChanged, startEditing, stopEditing]);

    const [cellBoxRef, setCellBoxRef] = useState<HTMLDivElement | null>(null);
    const popperRef = useRef<HTMLDivElement | null>(null);

    const handleBlur = useCallback(() => {
        setTimeout(() => {
            if (
                popperRef.current?.contains(document.activeElement) &&
                !document.activeElement?.classList.contains('tab-catcher')
            ) {
                return;
            }
            stopEditing();

            const colId = column?.getColId();
            if (isNumber(node.rowIndex) && colId) {
                api.setFocusedCell(node.rowIndex, colId);
            }
        });
    }, [api, column, node.rowIndex, stopEditing]);

    const handleTextFieldKeyDown = useCallback(
        (event: KeyboardEvent) => {
            if (event.key === 'Enter') {
                stopEditing();

                const colId = column?.getColId();
                setTimeout(() => {
                    if (isNumber(node.rowIndex) && node.rowIndex < api.getDisplayedRowCount() - 1 && colId) {
                        api.setFocusedCell(node.rowIndex + 1, colId);
                    }
                });
            } else if (event.key === 'Tab' && discountType === 'FIXED_PRICE') {
                stopEditing();

                const direction = event.shiftKey ? -1 : 1;

                const columns = api.getColumnState();
                const columnIndex = columns.findIndex((col) => col.colId === column?.getColId());
                const nextColumn = columns[columnIndex + direction];

                setTimeout(() => {
                    if (isNumber(node.rowIndex) && nextColumn.colId) {
                        api.setFocusedCell(node.rowIndex, nextColumn.colId);
                    }
                });
            } else if (event.key === 'Escape') {
                stopEditing(true);
            }
        },
        [api, column, discountType, node.rowIndex, stopEditing]
    );

    if (!isEditing) {
        return (
            <ReadOnlyCell>
                {valueFormatted}
                <EditIcon
                    onClick={() => {
                        startEditing();
                    }}
                />
            </ReadOnlyCell>
        );
    }

    return (
        <>
            {discountType === 'FIXED_PRICE' ? (
                <EditorTextField
                    autoFocus
                    fullWidth
                    placeholder="Enter price"
                    onChange={(event) => handleUpdate({ y: event.target.value })}
                    value={valueY ?? ''}
                    onKeyDown={handleTextFieldKeyDown}
                    onBlur={handleBlur}
                />
            ) : (
                <>
                    {/* When discount type is set, we always have two values X and Y, one represents amount and the other represents price */}
                    <ReadOnlyCell ref={setCellBoxRef}></ReadOnlyCell>
                    {cellBoxRef && (
                        <Popper
                            ref={popperRef}
                            className="ag-custom-component-popup"
                            anchorEl={cellBoxRef}
                            open={true}
                            sx={{ zIndex: 9 }}
                        >
                            <StyledPaper elevation={6}>
                                <TabCatcher className="tab-catcher" />
                                <Stack gap={'0.75rem'}>
                                    <Stack gap={'0.25rem'}>
                                        <StyledFormLabel>Amount</StyledFormLabel>
                                        <EditorTextField
                                            autoFocus
                                            fullWidth
                                            placeholder="Enter amount"
                                            onChange={(event) => handleUpdate({ x: event.target.value })}
                                            value={valueX ?? ''}
                                            onKeyDown={handleTextFieldKeyDown}
                                            onBlur={handleBlur}
                                        />
                                    </Stack>
                                    <Stack gap={'0.25rem'}>
                                        <StyledFormLabel>
                                            {discountType === 'X_FOR_Y' ? 'Amount' : 'Price'}
                                        </StyledFormLabel>
                                        <EditorTextField
                                            fullWidth
                                            placeholder={discountType === 'X_FOR_Y' ? 'Enter amount' : 'Enter price'}
                                            onChange={(event) => handleUpdate({ y: event.target.value })}
                                            value={valueY ?? ''}
                                            onKeyDown={handleTextFieldKeyDown}
                                            onBlur={handleBlur}
                                        />
                                    </Stack>
                                </Stack>
                                {/* This hidden input field should catch focus when tabbing out of the popper element instead of the address bar */}
                                <TabCatcher className="tab-catcher" />
                            </StyledPaper>
                        </Popper>
                    )}
                </>
            )}
        </>
    );
};

const TabCatcher = styled('input')`
  opacity: 0;
  height: 0;
  padding: 0;
  margin: 0;
  border: none;
`;

const StyledPaper = styled(Paper)`
  background-color: #fff;
  padding: 0.5rem 0.75rem;
  border-radius: 4px;
  font-size: 12px;
`;

const EditorTextField = styled(TextField)`
  .MuiInputBase-root {
    font-size: inherit;
    border-radius: 4px;
  }

  .Mui-focused fieldset {
    border-color: rgba(33, 150, 243, 0.4) !important;
  }

  .MuiInputBase-input {
    padding: 8px 14px;
  }
`;

const StyledFormLabel = styled(FormLabel)`
  font-size: inherit;
`;

const EditIcon = styled(Edit)`
  margin-left: 15px;
  font-size: 14px;
`;

const ReadOnlyCell = styled(Stack)`
  padding-left: 12px;
  padding-right: 5px;
  cursor: pointer;
  color: ${Color.editBlue};
  justify-content: flex-end;
  align-items: center;
  flex-direction: row;
  width: 100%;
  height: 100%;
`;
