import { useMemo } from 'react';
import { Inheritance, Module } from 'src/domain';
import { useGenderId } from 'src/hooks/gender';
import { useInheritance } from 'src/hooks/inheritance';
import { useProductLineId } from 'src/hooks/productline';
import { useDeliveryProfileSplitLevelQuery } from './useDeliveryProfileSplitLevelQuery';

export const useDeliveryProfileInheritanceQuery = (inheritance?: Inheritance) => {
    const { data, error, loading } = useDeliveryProfileSplitLevelQuery() ?? {};
    const moduleInheritance = useInheritance(Module.DeliveryProfile);
    const selectedInheritance = inheritance ?? moduleInheritance;

    const selectedGenderId = useGenderId();
    const selectedProductLineId = useProductLineId();

    const sanitizedData = useMemo(() => {
        if (!data) return;

        switch (selectedInheritance) {
            case Inheritance.Typed:
            case Inheritance.Historical:
                return data.storeMarkdown ?? data.clusterMarkdown ?? data.partnerMarkdown;

            case Inheritance.Cluster:
                return data.clusterMarkdown;

            case Inheritance.Partner:
                return data.partnerMarkdown;
        }
        // This should only update whenever there is new data. This way we ensure the data will match the splitlevel.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, selectedInheritance]);

    const filteredData = useMemo(() => {
        if (!sanitizedData) return;
        return sanitizedData?.filter((item) => {
            const isGenderMatch = typeof selectedGenderId === 'number' ? item.genderId === selectedGenderId : true;
            const isProductLineMatch =
                typeof selectedProductLineId === 'number' ? item.productlineGroupId === selectedProductLineId : true;
            return isGenderMatch && isProductLineMatch;
        });
    }, [sanitizedData, selectedGenderId, selectedProductLineId]);

    return { expected: filteredData, deliveryProfileData: sanitizedData, data, error, loading };
};
