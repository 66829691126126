import { tokenKey } from 'src/domain/models/token-key';

export const getToken = () => localStorage.getItem(tokenKey);

export const setToken = (token: string) => localStorage.setItem(tokenKey, token);

export const removeToken = () => localStorage.removeItem(tokenKey);

export const removeMsalToken = () => {
    Object.keys(sessionStorage).forEach((key) => {
        if (key.indexOf('-login.windows.net-accesstoken-') > 0) {
            sessionStorage.removeItem(key);
        }
    });
};
