import { useMemo } from 'react';
import { useBuyingEventPlanningLevel } from 'src/hooks/buying-event/useBuyingEventPlanningLevel';
import { useStyleCategoriesQuery } from 'src/hooks/style-categories/queries/useStyleCategoriesQuery';
import { useGendersApiQuery } from 'src/hooks/useGendersApiQuery';
import { useProductLineGroupsApiQuery } from 'src/hooks/useProductLineGroupsApiQuery';
import { useBuyingEventsApiQuery } from './useBuyingEventsApiQuery';

export const useBuyingEventQuery = () => {
    const { data: genders } = useGendersApiQuery();
    const { data: productLineGroups } = useProductLineGroupsApiQuery();
    const { data: styleCategories } = useStyleCategoriesQuery();
    const { data: buyingEvents, loading, error, refetch } = useBuyingEventsApiQuery();

    const planningLevel = useBuyingEventPlanningLevel();

    const planningLevelList = useMemo(() => {
        switch (planningLevel) {
            case 'ProductLineGroups':
                return productLineGroups;

            case 'Genders':
                return genders;

            case 'StyleCategories':
                return styleCategories;
        }
    }, [planningLevel, genders, productLineGroups, styleCategories]);

    return {
        events: buyingEvents,
        planningLevelList,
        loading: loading,
        error: error,
        refetch: refetch,
    };
};
