import { getISOWeek, getISOWeekYear, setISOWeek, startOfDay, startOfWeek } from 'date-fns';

export const formatDateAsWeekYear = (date: Date) => {
    const week = getISOWeek(date);
    const year = getISOWeekYear(date);
    return Number(`${year}${week.toString().padStart(2, '0')}`);
};

export const formatWeekYearAsDate = (weekYear: number) => {
    const year = Number(weekYear.toString().slice(0, 4));
    const week = Number(weekYear.toString().slice(4, 6));

    // Avoid issues with timezones making the date shift to the previous year by choosing a date that is guaranteed to be in the correct year.
    const date = startOfWeek(setISOWeek(new Date(year, 5, 1), week), { weekStartsOn: 1 });
    return startOfDay(date);
};

export const formatWeekYearAsHumanReadable = (weekYear: number) => {
    const year = weekYear.toString().slice(0, 4);
    const week = weekYear.toString().slice(4, 6);

    return `Week ${week.replace(/^0/, '')}, ${year}`;
};
