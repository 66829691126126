import { useMemo } from 'react';
import { Module, ScopeEnum } from 'src/domain';
import { useCurrentModule } from '../useCurrentModule';
import { useScope } from '../useScope';
import { useAuditLogModuleType } from './useAuditLogModuleType';

export const useIsAuditLogSupported = () => {
    const auditLogModuleType = useAuditLogModuleType();
    const scope = useScope();
    const currentModule = useCurrentModule();

    const isAuditLogSupported = useMemo(() => {
        // cluster audit log button not supported for following modules: turnover, buying events, open to buy limit
        // partner audit log button not supported for following modules: turnover
        // store audit log button not supported for following modules: buying events, open to buy limit

        const isCluster = scope === ScopeEnum.CLUSTER;
        const isPartner = scope === ScopeEnum.PARTNER;

        const isTurnoverModule = currentModule === Module.Turnover;
        const isBuyingEventsModule = currentModule === Module.BuyingEventManagement;
        const isOpenToBuyLimitModule = currentModule === Module.OpenToBuyLimit;
        const isOptimalOptionCountModule = currentModule === Module.OptimalOptionCount;

        const showAuditLogButtonIfCluster =
            isCluster && !isTurnoverModule && !isBuyingEventsModule && !isOpenToBuyLimitModule;
        const showAuditLogButtonIfPartner = isPartner && !isTurnoverModule && !isOptimalOptionCountModule;
        const showAuditLogButtonIfStore = scope === ScopeEnum.STORE && !isBuyingEventsModule && !isOpenToBuyLimitModule;

        return (
            !!auditLogModuleType &&
            (showAuditLogButtonIfCluster || showAuditLogButtonIfPartner || showAuditLogButtonIfStore)
        );
    }, [auditLogModuleType, currentModule, scope]);

    return isAuditLogSupported;
};
