import { Save } from '@mui/icons-material';
import {
    Box,
    Button,
    Checkbox,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogProps,
    DialogTitle,
    FormControl,
    FormControlLabel,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Select from 'src/components/atoms/Select';
import { GroupedOption } from 'src/components/atoms/Select/Select';
import { StyledLoadingButton } from 'src/components/styled/LoadingButton';
import { StyleTimelineSettingsRow } from 'src/domain/table/style-timeline-settings.row';
import { useStyleCategoriesQuery } from 'src/hooks/style-categories/queries/useStyleCategoriesQuery';
import { useBusinessModelGroupsApiQuery } from 'src/hooks/style-timeline-settings/queries/useBusinessModelGroupsApiQuery';
import { useBusinessModelsApiQuery } from 'src/hooks/style-timeline-settings/queries/useBusinessModelsApiQuery';
import { useStorefrontTypesApiQuery } from 'src/hooks/style-timeline-settings/queries/useStorefrontTypesApiQuery';
import { useSubStyleCategoriesApiQuery } from 'src/hooks/style-timeline-settings/queries/useSubStyleCategoriesApiQuery';
import { useProductLineGroupsApiQuery } from 'src/hooks/useProductLineGroupsApiQuery';

export interface EditableStyleTimelineSettingRow
    extends Pick<
        StyleTimelineSettingsRow,
        | 'storefrontTypeId'
        | 'styleCategoryId'
        | 'styleSubCategoryId'
        | 'productLineGroupId'
        | 'businessModelId'
        | 'businessModelGroupId'
    > {
    id?: number;
    lifeSpanWeeks: number | null;
}

export interface DefaultLifeSpanEditDialogProps extends Omit<DialogProps, 'onClose'> {
    data: EditableStyleTimelineSettingRow | null;
    onClose: (data: EditableStyleTimelineSettingRow | null) => void;
    isSaving?: boolean;
}

export interface DefaultLifeSpanEditDialogErrors {
    lifeSpanWeeks?: string;
}

export const DefaultLifeSpanEditDialog: FC<DefaultLifeSpanEditDialogProps> = ({
    open,
    isSaving = false,
    data,
    onClose,
}) => {
    const { data: styleCategories, loading: styleCategoriesLoading } = useStyleCategoriesQuery();
    const { data: storefrontTypesData, loading: storefrontTypesLoading } = useStorefrontTypesApiQuery();
    const { data: styleSubCategoriesData, loading: styleSubCategoriesLoading } = useSubStyleCategoriesApiQuery();
    const { data: productLineGroupsData, loading: productLineGroupsLoading } = useProductLineGroupsApiQuery();
    const { data: businessModelsData, loading: businessModelsLoading } = useBusinessModelsApiQuery();
    const { data: businessModelGroupsData, loading: businessModelGroupsLoading } = useBusinessModelGroupsApiQuery();

    const loading =
        businessModelsLoading ||
        styleCategoriesLoading ||
        storefrontTypesLoading ||
        styleSubCategoriesLoading ||
        productLineGroupsLoading ||
        businessModelGroupsLoading;
    const lifeSpanWeeksTextFieldRef = useRef<HTMLDivElement>(null);

    const [errors, setErrors] = useState<DefaultLifeSpanEditDialogErrors>({});

    const [value, setValue] = useState<EditableStyleTimelineSettingRow>(
        data ?? {
            lifeSpanWeeks: null,
            storefrontTypeId: null,
            styleCategoryId: null,
            businessModelId: null,
            styleSubCategoryId: null,
            productLineGroupId: null,
            businessModelGroupId: null,
        }
    );

    useEffect(() => {
        // clear errors when life span changes
        setErrors((errors) => {
            return {
                ...errors,
                lifeSpanWeeks: undefined,
            };
        });
    }, []);

    const handleStorefrontTypeChange = useCallback((storefrontTypeId: string) => {
        setValue((value) => ({
            ...value,
            storefrontTypeId: storefrontTypeId === '*' ? null : parseInt(storefrontTypeId),
        }));
    }, []);

    const handleStyleCategoryChange = useCallback((styleCategoryId: string) => {
        setValue((value) => ({
            ...value,
            styleCategoryId: styleCategoryId === '*' ? null : parseInt(styleCategoryId),
        }));
    }, []);

    const handleBusinessModelGroupChange = useCallback((id: string) => {
        const isBusinessModelGroup = id.startsWith('group-');

        const businessModelGroupId = isBusinessModelGroup ? id.replace('group-', '') : null;
        const businessModelId = isBusinessModelGroup ? null : id;

        setValue((value) => ({
            ...value,
            businessModelGroupId:
                !businessModelGroupId || businessModelGroupId === '*' ? null : parseInt(businessModelGroupId),
            businessModelId: !businessModelId || businessModelId === '*' ? null : parseInt(businessModelId),
        }));
    }, []);

    const handleStyleSubCategoryChange = useCallback((styleSubCategoryId: string) => {
        setValue((value) => ({
            ...value,
            styleSubCategoryId: styleSubCategoryId === '*' ? null : parseInt(styleSubCategoryId),
        }));
    }, []);

    const handleProductLineGroupChange = useCallback((productLineGroupId: string) => {
        setValue((value) => ({
            ...value,
            productLineGroupId: productLineGroupId === '*' ? null : parseInt(productLineGroupId),
        }));
    }, []);

    const handleClose = useCallback(() => {
        if (typeof onClose === 'function') {
            onClose(null);
        }
    }, [onClose]);

    const handleSave = useCallback(() => {
        if (typeof onClose === 'function') {
            const errors: DefaultLifeSpanEditDialogErrors = {};
            if (value.lifeSpanWeeks === null) {
                errors.lifeSpanWeeks = 'Life span is required';
            } else if (value.lifeSpanWeeks === 0) {
                errors.lifeSpanWeeks = 'Life span must be greater than 0';
            } else if (value.lifeSpanWeeks > 2147483647) {
                errors.lifeSpanWeeks = 'Life span is too large';
            }
            setErrors(errors);

            if (errors.lifeSpanWeeks) {
                if (lifeSpanWeeksTextFieldRef.current) {
                    lifeSpanWeeksTextFieldRef.current.scrollIntoView({ behavior: 'smooth' });
                }
                return;
            }
            onClose(value);
        }
    }, [onClose, value]);

    const handleDefaultLifeSpanChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            const newValue = parseInt(event.target.value);
            const lifeSpanWeeks = isNaN(newValue) ? null : newValue;

            setValue({
                ...value,
                lifeSpanWeeks,
            });
        },
        [value]
    );

    const storefrontTypeOptions = useMemo(() => {
        if (!storefrontTypesData) {
            return [];
        }
        return [{ value: '*', display: 'All', isHeader: false }].concat(
            storefrontTypesData
                .filter((frontType) => frontType.name !== '?')
                .toSorted((a, b) => (a.name ?? '').localeCompare(b.name ?? ''))
                .map((x) => ({ value: x.id.toString(), display: x.name, isHeader: false }) as GroupedOption)
        );
    }, [storefrontTypesData]);

    const businessModelGroupOptions = useMemo(() => {
        if (!businessModelGroupsData || !businessModelsData) {
            return [];
        }

        const options: GroupedOption[] = [{ value: '*', display: 'All', isHeader: false }];

        businessModelGroupsData.forEach((group) => {
            if (group.name !== '?') {
                options.push({ value: `group-${group.id.toString()}`, display: group.name, isHeader: true });
                businessModelsData
                    .filter((x) => x.group === group.name && x.name !== '?')
                    .forEach((x) => {
                        options.push({ value: x.id.toString(), display: x.name, isHeader: false });
                    });
            }
        });

        return options;
    }, [businessModelGroupsData, businessModelsData]);

    const styleCategoryOptions = useMemo(() => {
        if (!styleCategories) {
            return [];
        }
        return [{ value: '*', display: 'All', isHeader: false }].concat(
            styleCategories
                .filter((styleCategory) => styleCategory.name !== '?')
                .toSorted((a, b) => (a.order ?? -1) - (b.order ?? -1))
                .map((x) => ({ value: x.id.toString(), display: x.name, isHeader: false }) as GroupedOption)
        );
    }, [styleCategories]);

    const styleSubCategoryOptions = useMemo(() => {
        if (!styleSubCategoriesData) {
            return [];
        }
        return [{ value: '*', display: 'All', isHeader: false }].concat(
            styleSubCategoriesData
                .filter((styleSubCategory) => styleSubCategory.name !== '?')
                .toSorted((a, b) => (a.name ?? '').localeCompare(b.name ?? ''))
                .map((x) => ({ value: x.id.toString(), display: x.name, isHeader: false }) as GroupedOption)
        );
    }, [styleSubCategoriesData]);

    const productLineGroupOptions = useMemo(() => {
        if (!productLineGroupsData) {
            return [];
        }
        return [{ value: '*', display: 'All', isHeader: false }].concat(
            productLineGroupsData
                .filter((productLineGroup) => productLineGroup.name !== '?')
                .toSorted((a, b) => (a.name ?? '').localeCompare(b.name ?? ''))
                .map(
                    (x) =>
                        ({
                            value: x.id.toString(),
                            display: x.name,
                            isHeader: false,
                        }) as GroupedOption
                )
        );
    }, [productLineGroupsData]);

    return (
        <Dialog open={open} title="Add new rule">
            <DialogTitle>Add new rule</DialogTitle>
            {loading ? (
                <DialogContent>
                    <CircularProgress />
                </DialogContent>
            ) : (
                <>
                    <DialogContent>
                        <Stack gap={'1rem'} pt={'0.5rem'}>
                            <Stack gap={'1rem'} mb={'1rem'}>
                                <Typography variant="body2" color="textSecondary" noWrap={false}>
                                    Add a new rule to the default life span settings. You can match on multiple
                                    parameters.
                                </Typography>
                                <Typography variant="body2" color="textSecondary" noWrap={false}>
                                    It is also possible to broaden the rule by selecting &quot;All&quot; in any of the
                                    dropdowns.
                                </Typography>
                            </Stack>
                            <Select
                                useMargin={false}
                                label="Storefront type"
                                activeItem={value?.storefrontTypeId?.toString() ?? '*'}
                                onSelect={handleStorefrontTypeChange}
                                options={storefrontTypeOptions}
                                required
                            />
                            <Select
                                useMargin={false}
                                label="Style category"
                                activeItem={value?.styleCategoryId?.toString() ?? '*'}
                                onSelect={handleStyleCategoryChange}
                                options={styleCategoryOptions}
                                required
                            />
                            <Select
                                useMargin={false}
                                label="Style sub category"
                                activeItem={value?.styleSubCategoryId?.toString() ?? '*'}
                                onSelect={handleStyleSubCategoryChange}
                                options={styleSubCategoryOptions}
                                required
                            />
                            <Select
                                useMargin={false}
                                label="Product line group"
                                activeItem={value?.productLineGroupId?.toString() ?? '*'}
                                onSelect={handleProductLineGroupChange}
                                options={productLineGroupOptions}
                                required
                            />
                            <Select
                                useMargin={false}
                                label="Business model"
                                activeItem={`${value.businessModelGroupId ? `group-` : ''}${value.businessModelGroupId ?? value?.businessModelId ?? '*'}`}
                                onSelect={handleBusinessModelGroupChange}
                                options={businessModelGroupOptions}
                                isHeaderSelectable
                            />
                            <Stack direction="row" gap={'0.75rem'}>
                                <TextField
                                    ref={lifeSpanWeeksTextFieldRef}
                                    fullWidth
                                    label="Default Life Span"
                                    size="small"
                                    type="number"
                                    inputProps={{ pattern: '[0-9]*', min: '1' }}
                                    required
                                    helperText={
                                        errors.lifeSpanWeeks
                                            ? errors.lifeSpanWeeks
                                            : 'The default life span in weeks for this rule'
                                    }
                                    disabled={value.lifeSpanWeeks !== null && value.lifeSpanWeeks < 0}
                                    value={
                                        value?.lifeSpanWeeks !== null && value.lifeSpanWeeks >= 0
                                            ? value.lifeSpanWeeks
                                            : ''
                                    }
                                    error={!!errors.lifeSpanWeeks}
                                    onChange={handleDefaultLifeSpanChange}
                                />
                                <Box>
                                    <FormControl>
                                        <FormControlLabel
                                            label={'Indefinitely available?'}
                                            control={
                                                <Checkbox
                                                    checked={(value.lifeSpanWeeks ?? 0) < 0}
                                                    onChange={() =>
                                                        setValue({
                                                            ...value,
                                                            lifeSpanWeeks: (value.lifeSpanWeeks ?? 0) < 0 ? null : -1,
                                                        })
                                                    }
                                                ></Checkbox>
                                            }
                                        />
                                    </FormControl>
                                </Box>
                            </Stack>
                        </Stack>
                    </DialogContent>
                    <DialogActions>
                        <Button color="primary" variant="outlined" onClick={handleClose} disabled={isSaving}>
                            Cancel
                        </Button>
                        <StyledLoadingButton
                            color="primary"
                            variant="contained"
                            onClick={handleSave}
                            loading={isSaving}
                            loadingPosition="start"
                            startIcon={<Save />}
                        >
                            Save
                        </StyledLoadingButton>
                    </DialogActions>
                </>
            )}
        </Dialog>
    );
};
