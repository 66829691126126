import { makeVar } from '@bestseller-bit/retail-planning.utils.reactive-vars';
import { ColDef } from 'ag-grid-community';
import {
    ChainClusterPartnersMap,
    ChainCountryPartnersMap,
    IdToNameListMap,
    Partner,
    PartnerToCompositeMap,
    SelectedGenderMap,
    SelectedInheritanceMap,
    SelectedProductLineMap,
    SelectedStockManagementTable,
} from 'src/domain';
import { ComparableStoreTypeEnum } from 'src/domain/enums/comparableStoreTypeEnum';
import { StoreModel } from 'src/infrastructure/rest-api/api-types';
import { SerializeMap } from 'src/utils/SerializeMap';
import { formatDateToMonthKey, monthKeyToField } from 'src/utils/monthKeys';

export const selectedPartnerVar = makeVar<Partner | undefined>(undefined);

export const activeMonthVar = makeVar(monthKeyToField(formatDateToMonthKey(new Date())));
export const activeYearVar = makeVar(new Date().getFullYear());
export const monthsCacheVar = makeVar<ColDef[]>([]);

export const selectedStockManagementTable = makeVar<SelectedStockManagementTable>(new Map());

export const chainIdToNameListVar = makeVar<IdToNameListMap>(new Map());
export const clusterIdToNameListVar = makeVar<IdToNameListMap>(new Map());
export const partnerIdToNameListVar = makeVar<IdToNameListMap>(new Map());
export const countryIdToNameListVar = makeVar<IdToNameListMap>(new Map());
export const partnerToCompositeVar = makeVar<PartnerToCompositeMap>(new SerializeMap());
export const ownRetailChainCountryPartnersVar = makeVar<ChainCountryPartnersMap>(new SerializeMap());
export const ownRetailChainClusterPartnersVar = makeVar<ChainClusterPartnersMap>(new SerializeMap());
export const partnerRetailChainCountryPartnersVar = makeVar<ChainCountryPartnersMap>(new SerializeMap());
export const partnerRetailChainClusterPartnersVar = makeVar<ChainClusterPartnersMap>(new SerializeMap());
export const semiOwnedChainCountryPartnersVar = makeVar<ChainCountryPartnersMap>(new SerializeMap());
export const semiOwnedChainClusterPartnersVar = makeVar<ChainClusterPartnersMap>(new SerializeMap());
export const selectedInheritanceMapVar = makeVar<SelectedInheritanceMap>(new SerializeMap());
export const selectedGenderMapVar = makeVar<SelectedGenderMap>(new SerializeMap());
export const selectedProductLineMapVar = makeVar<SelectedProductLineMap>(new SerializeMap());

export const storefrontTypeVar = makeVar<string>('Chain Storefront');
export const comparableStoreTypeVar = makeVar<ComparableStoreTypeEnum>(ComparableStoreTypeEnum.AllStores);

// initializer vars
export const filteredStoresVar = makeVar<StoreModel[]>([]);
export const filteredStoresLoadingVar = makeVar<boolean>(false);
