import { useMemo } from 'react';
import { Options, getInheritanceOptions } from 'src/utils/inheritance-options';
import { useCluster } from '../cluster';
import { useCountry } from '../country/useCountry';
import { useCurrentStore } from '../store';
import { useCurrentModule } from '../useCurrentModule';
import { useScope } from '../useScope';
import { useStoreType } from '../useStoreType';

export const useInheritanceOptions = (opts: Options, field?: string | null) => {
    const currentScope = useScope();
    const scope = opts.scope ?? currentScope;

    const storeType = useStoreType();

    const store = useCurrentStore();
    const storeName = opts.storeName ?? store?.storeName;

    const country = useCountry();
    const countryName = opts.countryName ?? country ?? store?.country?.name;

    const cluster = useCluster();
    const clusterName = opts.clusterName ?? cluster ?? store?.cluster?.name;

    const currentModule = useCurrentModule();
    const module = opts.module ?? currentModule;

    const options = useMemo(
        () => getInheritanceOptions({ scope, storeName, countryName, clusterName, module, storeType }, field),
        [scope, storeName, countryName, clusterName, module, storeType, field]
    );

    return options;
};
