import { useMemo } from 'react';
import { useFilterContext } from 'src/contexts/FilterContext';
import { StoreType } from 'src/infrastructure/rest-api/api-types';
import { useQueryParams } from './useQueryParams';

export const useStoreType = () => {
    const [filterContext] = useFilterContext();
    const { storeType: storeTypeParam } = useQueryParams();

    const storeTypeFilter = useMemo((): StoreType => {
        if (filterContext.activeTab.ownStoresTab) {
            return 'OWN_STORE';
        }
        if (filterContext.activeTab.semiOwnedTab) {
            return 'SEMI_OWNED_STORE';
        }
        if (filterContext.activeTab.partnersTab) {
            return 'PARTNER_STORE';
        }
        if (filterContext.activeTab.wholesaleTab) {
            return 'WHOLESALE';
        }
        if (filterContext.activeTab.ownEComTab) {
            return 'OWN_ECOM';
        }
        return 'OTHER';
    }, [filterContext.activeTab]);

    const storeType = useMemo(() => {
        if (storeTypeParam) {
            return storeTypeParam as StoreType;
        }
        return storeTypeFilter;
    }, [storeTypeFilter, storeTypeParam]);

    return storeType;
};
