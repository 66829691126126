import { useMemo } from 'react';
import { Inheritance, Module } from 'src/domain';
import { useGenderId } from 'src/hooks/gender';
import { useInheritance } from 'src/hooks/inheritance';
import { useProductLineId } from 'src/hooks/productline';
import { useMarkupLevelQuery } from './useMarkupLevelQuery';

export const useMarkupInheritanceQuery = (inheritance?: Inheritance, genderId?: number, productLineId?: number) => {
    const { data, error, loading } = useMarkupLevelQuery() ?? { loading: false };
    const moduleInheritance = useInheritance(Module.Markup);
    const selectedInheritance = inheritance ?? moduleInheritance;

    const moduleGenderId = useGenderId();
    const moduleProductLineId = useProductLineId();

    const selectedGenderId = genderId === null ? undefined : (genderId ?? moduleGenderId);
    const selectedProductLineId = productLineId === null ? undefined : (productLineId ?? moduleProductLineId);

    const sanitizedData = useMemo(() => {
        if (!data) return;

        switch (selectedInheritance) {
            case Inheritance.Typed:
            case Inheritance.Historical:
                return data.storeMarkup ?? data.clusterMarkup ?? data.partnerMarkup;

            case Inheritance.Cluster:
                return data.clusterMarkup;

            case Inheritance.Partner:
                return data.partnerMarkup;
        }
        // This should only update whenever there is new data. This way we ensure the data will match the splitlevel.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, selectedInheritance]);

    const filteredData = useMemo(() => {
        if (!sanitizedData) return;
        return sanitizedData.filter((item) => {
            const isGenderMatch = typeof selectedGenderId === 'number' ? item.genderId === selectedGenderId : true;
            const isProductLineMatch =
                typeof selectedProductLineId === 'number' ? item.productlineGroupId === selectedProductLineId : true;
            return isGenderMatch && isProductLineMatch;
        });
    }, [sanitizedData, selectedGenderId, selectedProductLineId]);

    return {
        expected: filteredData,
        sanitizedData,
        data,
        error,
        loading,
    };
};
