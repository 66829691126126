import styled from '@emotion/styled';
import { FC, PropsWithChildren } from 'react';
import { AuditLogButton } from 'src/components/molecules/AuditLogButton/AuditLogButton';
import DiscardButton from 'src/components/molecules/DiscardButton';
import SaveButton from 'src/components/molecules/SaveButton';
import { useCanReset, useCanSetInheritance, useUnsavedChangesModule } from 'src/hooks';
import { useIsAuditLogSupported } from 'src/hooks/audit-log/useIsAuditLogSupported';
import { getButtonTooltips } from 'src/utils/getTooltipText';

export enum ActionButtonsChildrenPlacement {
    Left = 'left',
    Right = 'right',
}

export interface ActionButtonsProps {
    loading?: boolean;
    saveLoading?: boolean;
    discardDisabled?: boolean;
    saveDisabled?: boolean;
    saveTooltip?: string;
    discardTooltip?: string;
    resetDisabled?: boolean;
    setInheritanceDisabled?: boolean;
    onDiscard?: () => unknown;
    onSave?: () => unknown;
    onReset?: () => unknown;
    onSetInheritance?: () => unknown;
    childrenPlacement?: ActionButtonsChildrenPlacement;
    showSaveButton?: boolean;
    showDiscardButton?: boolean;
    hasUnsavedChanges?: boolean;
}

export const ActionButtons: FC<PropsWithChildren<ActionButtonsProps>> = ({
    discardDisabled,
    loading,
    onDiscard,
    onReset,
    onSave,
    onSetInheritance,
    hasUnsavedChanges,
    resetDisabled,
    saveDisabled,
    saveLoading,
    saveTooltip,
    discardTooltip,
    setInheritanceDisabled,
    children,
    childrenPlacement = ActionButtonsChildrenPlacement.Left,
    showDiscardButton = true,
    showSaveButton = true,
}) => {
    const [unsavedChanges] = useUnsavedChangesModule();
    const hasUnsavedChangesActual = typeof hasUnsavedChanges === 'boolean' ? hasUnsavedChanges : !!unsavedChanges;

    const canSave = hasUnsavedChangesActual;
    const canSetInheritance = useCanSetInheritance();
    const canReset = useCanReset();

    const generatedTooltips = getButtonTooltips(hasUnsavedChangesActual);

    const finalSaveTooltip = saveTooltip ?? generatedTooltips.saveTooltip;
    const finalDiscardTooltip = discardTooltip ?? generatedTooltips.discardTooltip;

    const showAuditLogButton = useIsAuditLogSupported();

    return (
        <ButtonContainer>
            {childrenPlacement === ActionButtonsChildrenPlacement.Left && children}
            {showAuditLogButton && <AuditLogButton loading={loading} />}
            {showDiscardButton && (
                <DiscardButton
                    tooltip={finalDiscardTooltip}
                    loading={loading}
                    disabled={!!loading || !!discardDisabled || (!hasUnsavedChangesActual && !canReset)}
                    onClick={onDiscard}
                />
            )}
            {showSaveButton && (
                <SaveButton
                    loading={loading}
                    saveButtonLoading={saveLoading}
                    saveButtonTooltip={finalSaveTooltip}
                    saveDisabled={saveDisabled ?? !canSave}
                    setInheritanceDisabled={setInheritanceDisabled ?? !canSetInheritance}
                    resetDisabled={resetDisabled ?? !canReset}
                    onSave={onSave}
                    onReset={onReset}
                    onSetInheritance={onSetInheritance}
                />
            )}
            {childrenPlacement === ActionButtonsChildrenPlacement.Right && children}
        </ButtonContainer>
    );
};

const ButtonContainer = styled.div`
  display: flex;
  gap: 16px;
  @media screen and (min-width: 1550px) {
    max-height: 40px;
  }
`;
