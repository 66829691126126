import { useMemo } from 'react';
import { Module } from 'src/domain';
import { combineGenderAndProductline } from 'src/utils/combineGenderAndProductline';
import { useGender } from '../gender';
import { useProductLine } from '../productline';
import { useCurrentModule } from '../useCurrentModule';

export const useCurrentGenderProductlineKey = (module?: Module) => {
    const currentModule = useCurrentModule();
    const moduleToUse = module ?? currentModule;

    const gender = useGender(moduleToUse);
    const productLine = useProductLine(moduleToUse);

    const genderProductlineKey = useMemo(
        () => combineGenderAndProductline(gender, productLine?.name),
        [gender, productLine?.name]
    );

    return genderProductlineKey;
};
