import { useMemo } from 'react';
import { ScopeEnum } from 'src/domain';
import { useIsUsingGender } from 'src/hooks/gender/useIsUsingGender';
import { useIsUsingProductline } from 'src/hooks/productline';
import { useScope } from 'src/hooks/useScope';
import { EntityTypeEnum } from 'src/infrastructure/rest-api/api-types';
import { useApiQuery } from 'src/infrastructure/rest-api/useApi';

export const useGenderSplitApiQuery = (entityId: number | string | null | undefined, entityType: EntityTypeEnum) => {
    const isUsingGenderSplit = useIsUsingGender();
    const isUsingProductLineSplit = useIsUsingProductline();

    const scope = useScope();

    const scopeMatches = useMemo(() => {
        switch (entityType) {
            case 'Store':
                return scope === ScopeEnum.STORE;
            case 'Cluster':
                return scope === ScopeEnum.CLUSTER || scope === ScopeEnum.STORE;
            case 'Partner':
                return scope === ScopeEnum.PARTNER || scope === ScopeEnum.STORE;
            default:
                return false;
        }
    }, [entityType, scope]);

    const { data, loading, error } = useApiQuery('/api/gendersplit', 'get', {
        query: {
            entityId: Number(entityId),
            entityType,
        },
        enabled:
            isUsingGenderSplit && !isUsingProductLineSplit && !!entityId && !isNaN(Number(entityId)) && scopeMatches,
    });

    return { data, loading, error };
};
