import { Module } from 'src/domain';
import { useUnsavedChangesModule } from 'src/hooks/unsaved-changes';
import { useIsWithinTreshhold } from '../useIsWithinTreshhold';

export const useCanSave = () => {
    const [unsavedChanges] = useUnsavedChangesModule(Module.OptimalOptionCount);
    const isWithinTreshhold = useIsWithinTreshhold();

    return isWithinTreshhold && !!unsavedChanges;
};
