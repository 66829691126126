import styled from '@emotion/styled';
import { FC } from 'react';
import Header from 'src/components/atoms/Header';
import Paragraph from 'src/components/atoms/Paragraph';
import { Color, Headings } from 'src/domain';

interface StoreInfoProps {
    title: string;
    content: string;
    compact?: boolean;
}

export const StoreInfo: FC<StoreInfoProps> = ({ title, content, compact = false }) => {
    return (
        <InfoWrapper>
            <StyledH3 heading={Headings.h3} compact={compact}>
                {title}
            </StyledH3>
            <Paragraph>{content}</Paragraph>
        </InfoWrapper>
    );
};

const InfoWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 3px;
  margin-bottom: 3px;
`;

const StyledH3 = styled(Header)<{ compact: boolean }>`
  font-size: 14px;
  font-weight: bold;
  color: ${Color.antracit};
  margin-right: 10px;
  width: ${(props) => (props.compact ? '40px' : '60px')};
  text-align: left;
`;
