import { useMemo } from 'react';
import { useCurrentId, useSelectedStockManagementTable } from 'src/hooks';

export const useSelectedTable = () => {
    const [selectedTable] = useSelectedStockManagementTable();
    const id = useCurrentId();

    // set table when default updates
    return useMemo(() => {
        if (id) {
            return selectedTable.get(id);
        }
    }, [selectedTable, id]);
};
