import { Module } from 'src/domain';
import { AnnouncementModuleEnum, InheritanceLevelModel, ModuleEnum } from 'src/infrastructure/rest-api/api-types';

export function announcementModuleToModule(module: AnnouncementModuleEnum | null | undefined): Module | null {
    switch (module) {
        case 'SalesBudget':
            return Module.Turnover;
        case 'CategorySplit':
            return Module.CategorySplit;
        case 'BuyingEvent':
            return Module.BuyingEventManagement;
        case 'DeliveryProfile':
            return Module.DeliveryProfile;
        case 'GenderProductLineSplit':
            return Module.ProductLineSplit;
        case 'GenderSplit':
            return Module.GenderSplit;
        case 'Markdown':
            return Module.Markdown;
        case 'Markup':
            return Module.Markup;
        case 'NoosShare':
            return Module.NoosShare;
        case 'OpenToBuyLimit':
            return Module.OpenToBuyLimit;
        case 'StockManagement':
            return Module.StockManagement;
        case 'StyleTimeline':
            return Module.StyleTimeline;
        case 'WeekCover':
            return Module.WeekCover;
        default:
            return null;
    }
}

export const moduleToAnnouncementModule = (module: Module | null): AnnouncementModuleEnum | null => {
    switch (module) {
        case Module.Turnover:
            return 'SalesBudget';
        case Module.CategorySplit:
            return 'CategorySplit';
        case Module.BuyingEventManagement:
            return 'BuyingEvent';
        case Module.DeliveryProfile:
            return 'DeliveryProfile';
        case Module.ProductLineSplit:
            return 'GenderProductLineSplit';
        case Module.GenderSplit:
            return 'GenderSplit';
        case Module.Markdown:
            return 'Markdown';
        case Module.Markup:
            return 'Markup';
        case Module.NoosShare:
            return 'NoosShare';
        case Module.OpenToBuyLimit:
            return 'OpenToBuyLimit';
        case Module.StockManagement:
            return 'StockManagement';
        case Module.StyleTimeline:
            return 'StyleTimeline';
        case Module.WeekCover:
            return 'WeekCover';
        default:
            return null;
    }
};

export function moduleToSplitKey(
    module: Module
): keyof Omit<InheritanceLevelModel, 'id' | 'entityId' | 'entityType' | 'noosShare' | 'salesBudget'> | null {
    switch (module) {
        case Module.CategorySplit:
            return 'categorySplit';
        case Module.GenderSplit:
            return 'genderSplit';
        case Module.ProductLineSplit:
            return 'productLineSplit';
        case Module.Markup:
            return 'markup';
        case Module.Markdown:
            return 'markdown';
        case Module.WeekCover:
            return 'weekCover';
        case Module.DeliveryProfile:
            return 'deliveryProfile';
    }
    return null;
}

export const moduleToModuleEnum = (currentModule: Module | undefined): ModuleEnum | null => {
    switch (currentModule) {
        case Module.StyleTimeline:
            return 'StyleTimeline';
        case Module.SalesCampaignsPlanning:
            return 'SalesCampaign';
        default:
            return null;
    }
};
