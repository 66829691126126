import styled from '@emotion/styled';
import { FormControlLabel, Switch } from '@mui/material';
import { FC, useCallback, useEffect, useState } from 'react';

interface Props {
    label: string;
    handleSelection: (active: boolean) => void;
    active?: boolean;
}

export const SwitchButton: FC<Props> = ({ label, handleSelection, active = false }) => {
    const [checked, setChecked] = useState(active);

    useEffect(() => {
        setChecked(active);
    }, [active]);

    const onChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            setChecked(event.target.checked);
            handleSelection(event.target.checked);
        },
        [handleSelection]
    );

    return (
        <Control
            label={label}
            labelPlacement={'start'}
            control={<Switch checked={checked} onChange={onChange} data-testid="switch-button" />}
        ></Control>
    );
};

const Control = styled(FormControlLabel)`
  .MuiFormControlLabel-label {
    margin-right: 10px;
    font-weight: bold;
  }
`;
