/**
 * Merges array of arrays with provided merge function
 * @param args Array containing the array of items to merge
 * @param mergeFn Merge function to run on each element in the array
 * @returns New array with merged vallues
 */
export function mergeMap<T, U>(args: T[][], mergeFn: (args: T[], index: number) => U): U[] {
    if (args.isEmpty()) return [];

    const lengthOfLongest = Math.max(...args.map((x) => x.length));

    // run map function on each element
    const results: U[] = [];

    for (let i = 0; i < lengthOfLongest; i += 1) {
        const arrayOfCurrentValues = args.map((x) => x[i]);
        const mappedResult = mergeFn(arrayOfCurrentValues, i);
        results.push(mappedResult);
    }

    return results;
}
