import _ from 'lodash';
import { memo, useCallback, useMemo } from 'react';
import { ProductLine } from 'src/domain';
import { useGenderProductlinesWithChanges, useProductLine } from 'src/hooks';
import { useSetSelectedProductLine } from 'src/hooks/productline/useSetSelectedProductLine';
import { useProductLineGroupsApiQuery } from 'src/hooks/useProductLineGroupsApiQuery';
import { ExclusiveToggleButtonGroup, ToggleButtonInput } from '../ToggleButtonGroup';

interface Props {
    data?: ProductLine[];
    onSelect?: (id: number) => void;
    changes?: ProductLine[];
}

/**
 * Memoize the component and only re-render when props actually change
 * This is needed since the productline array reference might change
 * without the actual content changing causing unwanted rerenders
 */
export const ProductlineToggleButtonGroup = memo(
    ({ data, onSelect, changes }: Props) => {
        const { data: currentProductlines } = useProductLineGroupsApiQuery();
        const defaultProductline = useProductLine();

        const setSelectedProductline = useSetSelectedProductLine();

        const handleCallback = useCallback(
            (id: number) => {
                onSelect?.(id);

                const newProductline = currentProductlines?.find((productline) => productline.id === id);
                if (!newProductline) return;

                setSelectedProductline(newProductline);
            },
            [onSelect, currentProductlines, setSelectedProductline]
        );

        const productlinesToUse = data ?? currentProductlines;

        const genderProductlinesWithChangesMap = useGenderProductlinesWithChanges();
        const productLinesWithChanges = useMemo(
            () => productlinesToUse?.filter((x) => genderProductlinesWithChangesMap?.has(x.name ?? '')),
            [genderProductlinesWithChangesMap, productlinesToUse]
        );

        const changesToUse = changes ?? productLinesWithChanges;

        const chainProductLines = productlinesToUse?.map<ToggleButtonInput>((productLine) => ({
            callbackFunction: handleCallback,
            id: productLine.id,
            name: productLine.name ?? '',
            hasChanges: changesToUse?.includes(productLine),
        }));

        return (
            <>
                {chainProductLines && (
                    <ExclusiveToggleButtonGroup
                        header="Productlines"
                        buttonInputs={chainProductLines}
                        defaultValue={defaultProductline?.name}
                    />
                )}
            </>
        );
    },
    (oldProps, newProps) => newProps.data === undefined || _.isEqual(oldProps, newProps)
);
