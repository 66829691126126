import { useMemo } from 'react';
import { Store } from 'src/domain/models/store/store';
import { useFilteredStores } from 'src/hooks/store';
import { mapOptimalOptionCountStoreRows } from 'src/mapping/optimal-option-count.mapping';
import { useOptimalOptionCountQuery } from '../query/useOptimalOptionCountQuery';

export const useOptimalOptionCountStoreRows = () => {
    const { data, error, loading } = useOptimalOptionCountQuery();
    const [filteredStores] = useFilteredStores();
    const stores = useMemo(() => {
        return filteredStores?.reduce<Store[]>((acc, store) => {
            acc.push({
                storeName: store.storeName,
                storeId: store.id,
                salesFloorSquaremeters: store.salesFloorSquaremeters,
            } as Store);
            return acc;
        }, []);
    }, [filteredStores]);

    const tableData = useMemo(() => {
        return mapOptimalOptionCountStoreRows(data, stores);
    }, [data, stores]);

    return { data: tableData, error, loading };
};
