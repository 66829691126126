import { ArrowBack, Edit, Fullscreen, FullscreenExit } from '@mui/icons-material';
import { Box, Button, CircularProgress, IconButton, Stack, Tooltip, Typography, styled } from '@mui/material';
import { CellKeyDownEvent, GetContextMenuItemsParams, GridApi, GridReadyEvent } from 'ag-grid-community';
import { differenceInDays, parseISO } from 'date-fns';
import { FC, useCallback, useState } from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
import Container from 'src/components/atoms/Container';
import { CountStatusBar } from 'src/components/molecules/CountStatusBar/CountStatusBar';
import { ActionButtons } from 'src/components/organisms/ActionButtons/ActionsButtons';
import { CachedImageProvider } from 'src/components/organisms/CachedImage/CachedImage';
import { DataTable } from 'src/components/organisms/DataTable/DataTable';
import { Cell } from 'src/components/organisms/DataTable/types';
import { TableWrapper } from 'src/components/styled/TableWrapper';
import { Headings, Module } from 'src/domain';
import { useFilteredStores, useUnsavedChangesModule } from 'src/hooks';
import { useDiscardChangesModule } from 'src/hooks/discard-changes/useDiscardChangesModule';
import { useSalesCampaignPlanningCellValuesChanged } from 'src/hooks/sales-campaigns-planning/useSalesCampaignPlanningCellValuesChanged';
import { useSalesCampaignPlanningSaveChanges } from 'src/hooks/sales-campaigns-planning/useSalesCampaignPlanningSaveChanges';
import { useSalesCampaignSimulationRows } from 'src/hooks/sales-campaigns-planning/useSalesCampaignSimulationRows';
import { useStorefrontTypesApiQuery } from 'src/hooks/style-timeline-settings/queries/useStorefrontTypesApiQuery';

import { BrandImage } from '@bestseller-bit/frontend-community.components.brand-image';
import Header from 'src/components/atoms/Header';
import { ColumnSettingsManagerButton } from 'src/components/organisms/ActionButtons/ColumnSettingsManagerButton';
import { SalesCampaignOverviewRow } from 'src/domain/table/sales-campaign-overview.row';
import { StyleTimelineOverviewRow } from 'src/domain/table/style-timeline-overview-row';
import { useStyleTimelineSaveChanges } from 'src/hooks/style-timeline/save-changes/useStyleTimelineSaveChanges';
import { CreateSalesCampaignInput, UpdateSalesCampaignInput } from 'src/infrastructure/rest-api/api-types';
import { generateContextMenuItems } from 'src/utils/ag-grid/getContextMenuItems';
import {
    fillOperationForColumn,
    getEmptyValue,
    handleClearCellValue,
    handleCopyValueToVisibleRows,
    handleMarkAsInfinitelyAvailable,
} from 'src/utils/ag-grid/styleTimelineHelper';
import { SalesCampaignDialog } from '../SalesCampaigns/CreateCampaignDialog';
import { StyleTimeline } from '../StyleTimeline/StyleTimeline';
import { columns, defaultColDef } from './data/columns';

export const SalesCampaignsPlanning: FC = () => {
    const [fullscreen, setFullscreen] = useState(false);
    const [gridApi, setGridApi] = useState<GridApi | null>(null);

    const { id: salesCampaignId } = useParams();

    const {
        data: simulationRows,
        salesCampaign,
        loading: rowsLoading,
        error,
    } = useSalesCampaignSimulationRows(Number(salesCampaignId));

    const discardChangesSalesCampaigns = useDiscardChangesModule(Module.SalesCampaignsPlanning);
    const discardChangesStyleTimeline = useDiscardChangesModule(Module.StyleTimeline);
    const discardChanges = useCallback(() => {
        // Ensure the discards are queued in the correct order
        Promise.resolve()
            .then(() => discardChangesSalesCampaigns())
            .then(() => discardChangesStyleTimeline());
    }, [discardChangesSalesCampaigns, discardChangesStyleTimeline]);

    const [unsavedChangesSalesCampaignPlanning] = useUnsavedChangesModule<SalesCampaignOverviewRow[]>(
        Module.SalesCampaignsPlanning
    );
    const [unsavedChangesStyleTimeline] = useUnsavedChangesModule<StyleTimelineOverviewRow[]>(Module.StyleTimeline);

    const hasUnsavedChanges = !!unsavedChangesSalesCampaignPlanning || !!unsavedChangesStyleTimeline;

    const [saveChangesSalesCampaign, { isSaving: isSavingSalesCampaign }] = useSalesCampaignPlanningSaveChanges();
    const [saveChangesStyleTimeline, { isSaving: isSavingStyleTimeline }] = useStyleTimelineSaveChanges(
        salesCampaign?.storefrontType.name
    );
    const isSaving = isSavingSalesCampaign || isSavingStyleTimeline;
    const saveChanges = useCallback(() => {
        // Ensure the saves are queued in the correct order
        return Promise.resolve()
            .then(() => saveChangesStyleTimeline())
            .then(() => saveChangesSalesCampaign());
    }, [saveChangesSalesCampaign, saveChangesStyleTimeline]);

    const handleCellValuesChanged = useSalesCampaignPlanningCellValuesChanged();

    const [editableSalesCampaign, setEditableSalesCampaign] = useState<
        (UpdateSalesCampaignInput & CreateSalesCampaignInput) | null
    >();
    const { data: storefrontTypes, loading: storefrontTypesLoading } = useStorefrontTypesApiQuery();

    const loading = rowsLoading || storefrontTypesLoading;

    const [filteredStores] = useFilteredStores();
    const brandNumber = filteredStores?.[0]?.chain.brandNumber;

    const handleCellKeyDown = useCallback(
        (event: CellKeyDownEvent) => {
            if (!event.event) {
                return;
            }

            const keyboardEvent = event.event as unknown as KeyboardEvent;

            const colId = event.column.getColId();
            const shouldHandle = ['discountPercentage', 'discount'].includes(colId) && !event.event.defaultPrevented;

            if (shouldHandle && ['Backspace', 'Clear', 'Delete'].includes(keyboardEvent.key)) {
                event.event.preventDefault();
                const changes: Cell<unknown>[] = [
                    {
                        value:
                            colId === 'discount'
                                ? {
                                      x: null,
                                      y: null,
                                      discountType: 'FIXED_PRICE',
                                  }
                                : null,
                        rowId: event.node.data['rowId'],
                        column: colId,
                        category: undefined,
                        footer: undefined,
                    },
                ];
                handleCellValuesChanged(changes);
            }
        },
        [handleCellValuesChanged]
    );

    const handleGridReady = useCallback((event: GridReadyEvent) => {
        setGridApi(event.api);
    }, []);

    const copyValueToVisibleRows = useCallback(
        (params: GetContextMenuItemsParams) => handleCopyValueToVisibleRows(params, handleCellValuesChanged),
        [handleCellValuesChanged]
    );

    const clearCellValue = useCallback(
        (params: Partial<GetContextMenuItemsParams>) =>
            handleClearCellValue(params, getEmptyValue, handleCellValuesChanged),
        [handleCellValuesChanged]
    );

    const markAsInfinitelyAvailable = useCallback(
        (params: GetContextMenuItemsParams) => handleMarkAsInfinitelyAvailable(params, handleCellValuesChanged),
        [handleCellValuesChanged]
    );

    const getContextMenuItems = useCallback(
        (params: GetContextMenuItemsParams) =>
            generateContextMenuItems(params, clearCellValue, copyValueToVisibleRows, markAsInfinitelyAvailable),
        [clearCellValue, copyValueToVisibleRows, markAsInfinitelyAvailable]
    );

    return (
        <>
            <Stack gap={'1rem'} height="100%">
                <Box>
                    <RouterLink
                        to={{
                            pathname: '/sales-campaigns',
                            search: location.search,
                        }}
                    >
                        <Button variant="text" startIcon={<ArrowBack />}>
                            Back to campaigns list
                        </Button>
                    </RouterLink>
                </Box>

                <HeaderBox>
                    {!salesCampaign && <CircularProgress />}
                    {salesCampaign && (
                        <>
                            <HeaderBoxItem sx={{ gridTemplateColumns: '1fr' }}>
                                <Stack alignItems={'flex-end'}>
                                    <IconButton
                                        size={'small'}
                                        onClick={() =>
                                            setEditableSalesCampaign({
                                                endDate: salesCampaign.endDate,
                                                salesCampaignId: salesCampaign.id,
                                                startDate: salesCampaign.startDate,
                                                name: salesCampaign.name,
                                                partnerCompositeId: 0,
                                                storefrontTypeId: salesCampaign.storefrontType?.id ?? 0,
                                            })
                                        }
                                    >
                                        <Edit fontSize={'small'} color={'secondary'} />
                                    </IconButton>
                                </Stack>
                            </HeaderBoxItem>
                            <HeaderBoxItem>
                                <Typography>Campaign:</Typography>
                                <Typography>{salesCampaign?.name}</Typography>
                            </HeaderBoxItem>
                            <HeaderBoxItem>
                                <Typography>Start:</Typography>
                                <Typography>{salesCampaign?.startDate}</Typography>
                            </HeaderBoxItem>
                            <HeaderBoxItem>
                                <Typography>End:</Typography>
                                <Typography>{salesCampaign?.endDate}</Typography>
                            </HeaderBoxItem>
                            <HeaderBoxItem>
                                <Typography>Duration:</Typography>
                                <Typography>
                                    {differenceInDays(
                                        parseISO(salesCampaign?.endDate),
                                        parseISO(salesCampaign?.startDate)
                                    )}{' '}
                                    days
                                </Typography>
                            </HeaderBoxItem>
                        </>
                    )}
                    <HeaderBoxItem marginLeft="auto" maxWidth={150}>
                        {brandNumber && <BrandImage productLineNumber={brandNumber} />}
                    </HeaderBoxItem>
                </HeaderBox>
                <StyledContainer
                    style={{
                        height: '100%',
                        minHeight: 750,
                        zIndex: fullscreen ? 3 : 0,
                        position: fullscreen ? 'fixed' : 'static',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        overflow: 'auto',
                    }}
                >
                    <Stack direction="row" justifyContent={'flex-end'}>
                        <Stack marginRight="auto" justifyContent={'center'}>
                            <Header heading={Headings.h2}>Sales campaign planning</Header>
                        </Stack>
                        <ActionButtons
                            hasUnsavedChanges={hasUnsavedChanges}
                            onDiscard={discardChanges}
                            onSave={saveChanges}
                            onSetInheritance={saveChanges}
                            onReset={saveChanges}
                            saveLoading={isSaving}
                            loading={loading || isSaving}
                        >
                            <IconButton color="primary" onClick={() => setFullscreen((prev) => !prev)}>
                                {!fullscreen ? (
                                    <Tooltip title="Open fullscreen">
                                        <Fullscreen />
                                    </Tooltip>
                                ) : (
                                    <Tooltip title="Close fullscreen">
                                        <FullscreenExit />
                                    </Tooltip>
                                )}
                            </IconButton>
                            <ColumnSettingsManagerButton gridApi={gridApi} />
                        </ActionButtons>
                    </Stack>

                    {error && <Typography color="error">{error.message}</Typography>}

                    <TableWrapper className="ag-theme-alpine-custom-compact small-font" flex={1}>
                        <CachedImageProvider>
                            <DataTable
                                fillOperation={fillOperationForColumn}
                                loading={loading}
                                defaultColDef={defaultColDef}
                                onGridReady={handleGridReady}
                                columns={columns}
                                rows={simulationRows}
                                isNumberTable={false}
                                autoSizeToFit={false}
                                suppressAutoSize={true}
                                onCellValuesChanged={handleCellValuesChanged}
                                domLayout="normal"
                                onCellKeyDown={handleCellKeyDown}
                                getContextMenuItems={getContextMenuItems}
                                gridOptions={{
                                    statusBar: {
                                        statusPanels: [
                                            {
                                                statusPanel: CountStatusBar,
                                                align: 'right',
                                            },
                                        ],
                                    },
                                }}
                                sideBar={{
                                    position: 'left',
                                    toolPanels: [
                                        {
                                            id: 'filters',
                                            labelDefault: 'Filters',
                                            labelKey: 'filters',
                                            iconKey: 'filter',
                                            toolPanel: 'agFiltersToolPanel',
                                            toolPanelParams: {
                                                suppressExpandAll: false,
                                            },
                                        },
                                        {
                                            id: 'columns',
                                            labelDefault: 'Columns',
                                            labelKey: 'columns',
                                            iconKey: 'columns',
                                            toolPanel: 'agColumnsToolPanel',
                                            toolPanelParams: {
                                                suppressExpandAll: false,
                                            },
                                        },
                                    ],
                                }}
                            />
                        </CachedImageProvider>
                    </TableWrapper>
                </StyledContainer>

                <StyleTimeline
                    header={<Header heading={Headings.h2}>Style timeline</Header>}
                    disableGlobalPlanning={true}
                    disableStorefrontTypeSelect={true}
                    disableActionButtons={true}
                    storefrontType={salesCampaign?.storefrontType.name}
                />
            </Stack>

            {editableSalesCampaign && storefrontTypes && (
                <SalesCampaignDialog
                    open={!!editableSalesCampaign}
                    defaultValue={editableSalesCampaign}
                    storefrontTypes={storefrontTypes}
                    onClose={() => setEditableSalesCampaign(null)}
                    isEditing
                />
            )}
        </>
    );
};

const StyledContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
`;

const HeaderBox = styled(Stack)`
  flex-direction: row;
  flex-wrap: wrap;
  box-sizing: border-box;
  width: 100%;
  gap: 2rem;
  background-color: #fff;
  padding: 1.5rem;
  border-radius: 0.25rem;
`;

const HeaderBoxItem = styled(Box)`
  display: flex;
  gap: 0.5rem;
  align-items: center;

  & > *:first-of-type {
    font-weight: 600;
  }
`;
