import { useEffect, useState } from 'react';

export function usePromise<T>(promise: Promise<T> | undefined) {
    const [state, setState] = useState<T | undefined>();

    useEffect(() => {
        if (!promise) {
            return;
        }

        promise.then((result) => {
            setState(result);
        });
    }, [promise]);

    return state;
}
