import { Inheritance, Module } from 'src/domain';

export function getInheritanceWarning(
    module: Module | undefined,
    previousInheritance: Inheritance | undefined,
    newInheritance: Inheritance | undefined | null
) {
    if (!module || !previousInheritance || !newInheritance) return '';

    switch (module) {
        case Module.Turnover:
            if (previousInheritance === Inheritance.Typed && newInheritance !== Inheritance.Typed) {
                return 'Your typed data will be lost when changing inheritance';
            }
            break;
        case Module.NoosShare:
            if (
                previousInheritance === Inheritance.Typed &&
                [Inheritance.NoosDDR, Inheritance.NoosHistoricalOrders, Inheritance.Historical].includes(newInheritance)
            ) {
                return 'Your typed data will be lost when resetting to historical';
            }
            break;
        default:
            if (previousInheritance === Inheritance.Typed && newInheritance === Inheritance.Historical) {
                return 'Your typed data will be lost when resetting to historical';
            }
    }
}
