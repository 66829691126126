import { useMemo } from 'react';
import { Inheritance, Module, TableOverviewRow } from 'src/domain';
import { useGenderId, useInheritance, useProductLine, useProductLineId } from 'src/hooks';
import { useCategorySplitOverviewSimulationRows } from 'src/hooks/category-split/simulation/useCategorySplitOverviewSimulationRows';
import { useUnsavedChangesModule } from 'src/hooks/unsaved-changes';
import { useAggregatedDataMergerForUnsavedChanges } from 'src/hooks/useAggregatedDataMergerForUnsavedChanges';
import { useGenderProductLineKey } from 'src/hooks/useGenderProductLineKey';
import { mapCategorySplitsToRows } from 'src/mapping/planned-splits.mapping';
import { useMarkupOverviewRows } from '../table/useMarkupOverviewRows';

export const useMarkupOverviewSimulationRows = (inheritance?: Inheritance, aggregateOnGenderProductlines = false) => {
    const genderId = useGenderId(Module.Markup);
    const productLineId = useProductLineId(Module.Markup);
    const productLine = useProductLine(Module.Markup);

    const [unsavedChanges] = useUnsavedChangesModule<Record<string, TableOverviewRow[]>>(Module.Markup);
    const { data: categorySplitSimulationData } = useCategorySplitOverviewSimulationRows(
        undefined,
        genderId,
        productLine?.id,
        aggregateOnGenderProductlines
    );
    const { data, aggregatedData, columns, loading, error } = useMarkupOverviewRows(
        inheritance,
        genderId,
        productLineId,
        aggregateOnGenderProductlines
    ) ?? { loading: false };
    const genderProductLineKey = useGenderProductLineKey();
    const mergeUnsavedChanges = useAggregatedDataMergerForUnsavedChanges();

    const moduleInheritance = useInheritance(Module.Markup);
    const selectedInheritance = inheritance ?? moduleInheritance;

    const isTyped = selectedInheritance === Inheritance.Typed;

    const unsavedChangesGenderProductLine = unsavedChanges?.[genderProductLineKey];
    const hasUnsavedChanges = !!unsavedChangesGenderProductLine;
    const overviewRows = isTyped && hasUnsavedChanges ? unsavedChangesGenderProductLine : data;

    const aggregatedOverviewRows = useMemo(() => {
        if (aggregateOnGenderProductlines) {
            return mergeUnsavedChanges(aggregatedData, unsavedChanges);
        }
    }, [aggregateOnGenderProductlines, aggregatedData, mergeUnsavedChanges, unsavedChanges]);

    const tableData = useMemo(() => {
        if (!overviewRows || !categorySplitSimulationData) return;
        return mapCategorySplitsToRows(overviewRows, categorySplitSimulationData);
    }, [categorySplitSimulationData, overviewRows]);

    return { data: tableData, aggregatedData: aggregatedOverviewRows, columns, loading, error };
};
