import { ThemeProvider, createTheme } from '@mui/material';
import { FC, PropsWithChildren } from 'react';
import { Color } from './domain';

const theme = createTheme({
    palette: {
        primary: {
            main: Color.editBlue,
        },
        secondary: {
            main: Color.secondary,
            contrastText: '#fff',
        },
        info: {
            main: Color.editBlue,
        },
    },
    components: {
        MuiTypography: {
            defaultProps: {
                noWrap: true,
            },
            styleOverrides: {
                root: {
                    fontFamily: [
                        '-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif',
                    ],
                },
            },
        },
        MuiBadge: {
            styleOverrides: {
                anchorOriginBottomLeftRectangular: {
                    position: 'absolute',
                    left: '4px',
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: 'none',
                },
            },
        },
    },
});

export const CustomThemeProvider: FC<PropsWithChildren<unknown>> = ({ children }) => {
    return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};
