import { useMemo } from 'react';
import { months } from 'src/domain';
import { useOpenToBuyLimitOverviewSimulationRows } from './simulation/useOpenToBuyLimitOverviewSimulationRow';

export const useIsWithinTreshhold = () => {
    const { data: rows, plannedOn } = useOpenToBuyLimitOverviewSimulationRows();

    const withinTreshhold = useMemo(() => {
        let result = true;
        plannedOn?.forEach((plan) => {
            const filteredRows = rows?.filter((row) => row.category.valueOf() === plan.name?.valueOf());
            let sum = 0;

            months.forEach((month) => {
                const rows = filteredRows?.map((row) => ({
                    // TODO: Check if month is editable, so we can skip summing non-editable months later - this seems a bit flaky, since it is implicitly relying on the key to not be present. If set to zero or undefined instead, this check will fail. See also createOpenToBuyLimitColumns.
                    isEditable: Object.prototype.hasOwnProperty.call(row.columns, month.toLowerCase()),
                    value: row.columns[month.toLowerCase()] ?? 0,
                }));

                // If all monhts are uneditable, make verification pass by assuming sum is 100.
                if (rows?.every((row) => !row.isEditable)) {
                    sum = 100;
                } else {
                    // Otherwise sum all values
                    sum = rows?.reduce((acc, row) => acc + row.value, 0) ?? 0;
                }

                if (sum !== 100) {
                    result = false;
                }
                sum = 0;
            });
        });
        return result;
    }, [plannedOn, rows]);
    return withinTreshhold;
};
