import { Delete } from '@mui/icons-material';
import { FC } from 'react';
import { StyledDiscardButton } from 'src/components/styled/DiscardButton';

interface Props {
    tooltip?: string;
    loading?: boolean;
    disabled?: boolean;
    onClick?: () => unknown;
}

const DiscardButton: FC<Props> = ({ tooltip, loading, disabled, onClick }) => {
    return (
        <>
            <StyledDiscardButton
                disabled={disabled ?? loading}
                tooltip={tooltip}
                color={'error'}
                size={'small'}
                variant={'contained'}
                startIcon={<Delete />}
                onClick={onClick}
            >
                Discard changes
            </StyledDiscardButton>
        </>
    );
};

export default DiscardButton;
