import { ErrorOutlined } from '@mui/icons-material';
import { Box, CircularProgress, styled } from '@mui/material';
import { debounce } from 'lodash';
import { FC, PropsWithChildren, createContext, forwardRef, useContext, useEffect, useState } from 'react';
import brokenImageUrl from 'src/assets/img/broken-image.png';

type SubscriberCallback = {
    next: (value: string) => void;
    error: (err: Error) => void;
};

class ImageCache {
    private maxProcessing = 200;

    // TODO: Should we have a max cache size?
    private cache: Record<string, string> = {};
    private fetching: Record<string, boolean> = {};
    // FIFO queue - first in first out
    private processingQueue: string[] = [];
    // FIFO queue - first in first out
    private waitingQueue: string[] = [];
    private subscribers: Record<string, SubscriberCallback[]> = {};

    private intervalId: NodeJS.Timeout | undefined;

    constructor() {
        this.intervalId = setInterval(() => {
            this.processItems();
        }, 500);
    }

    destroy() {
        this.cache = {};
        this.fetching = {};
        this.processingQueue = [];
        this.waitingQueue = [];
        this.subscribers = {};
        this.intervalId && clearInterval(this.intervalId);
    }

    notify(src: string, objectURL: string) {
        if (this.subscribers[src]) {
            this.subscribers[src].forEach((callback) => callback.next(objectURL));
            delete this.subscribers[src];
        }
    }

    notifyError(src: string, err: Error) {
        if (this.subscribers[src]) {
            this.subscribers[src].forEach((callback) => callback.error(err));
            delete this.subscribers[src];
        }
    }

    subscribe(src: string, callback: SubscriberCallback) {
        if (!this.subscribers[src]) {
            this.subscribers[src] = [];
        }
        this.subscribers[src].push(callback);
    }

    private fillProcessingQueue() {
        while (this.processingQueue.length < this.maxProcessing && this.waitingQueue.length > 0) {
            const item = this.waitingQueue.shift();
            if (item) {
                this.processingQueue.push(item);
            }
        }
    }

    private processItems() {
        this.fillProcessingQueue();

        this.processingQueue.forEach((src) => {
            if (this.fetching[src]) {
                return;
            } else {
                this.fetching[src] = true;
                fetch(src)
                    .then((response) => response.blob())
                    .then((blob) => {
                        const objectURL = URL.createObjectURL(blob);
                        this.cache[src] = objectURL;
                        this.notify(src, objectURL);
                    })
                    .catch((err) => {
                        this.notifyError(src, err);
                    })
                    .finally(() => {
                        // remove from processing queue
                        this.fetching[src] = false;
                        this.processingQueue = this.processingQueue.filter((s) => s !== src);
                    });
            }
        });
    }

    processItemsDebounced = debounce(this.processItems, 100);

    queueRequest(src: string) {
        if (!this.cache[src]) {
            // Check if item is already queued
            const item = [...this.processingQueue, ...this.waitingQueue].find((s) => s === src);
            if (!item) {
                this.waitingQueue.push(src);
                this.processItemsDebounced();
            }
        } else {
            this.notify(src, this.cache[src]);
        }
    }
}

const CachedImageContext = createContext<ImageCache | null>(null);

export const CachedImageProvider: FC<PropsWithChildren<unknown>> = ({ children }) => {
    const [imageCache, setImageCache] = useState<ImageCache | null>(null);
    useEffect(() => {
        const imageCache = new ImageCache();
        setImageCache(imageCache);

        return () => {
            imageCache.destroy();
        };
    }, []);
    return <CachedImageContext.Provider value={imageCache}>{children}</CachedImageContext.Provider>;
};

export const CachedImage = forwardRef<HTMLImageElement, React.ImgHTMLAttributes<HTMLImageElement>>(
    ({ src, ...props }, ref) => {
        const imageCache = useContext(CachedImageContext);
        const [cachedSrc, setCachedSrc] = useState<string | undefined>(undefined);
        const [error, setError] = useState(false);
        useEffect(() => {
            if (src && imageCache) {
                // subscribe to cache first
                imageCache.subscribe(src, {
                    next: (url) => {
                        setCachedSrc(url);
                    },
                    error: (err) => {
                        setError(true);
                        // Log errors in order to track them in datadog
                        // biome-ignore lint/suspicious/noConsole: provide better reason
                        console.error(err);
                    },
                });

                // queue image request
                imageCache.queueRequest(src);
            }
        }, [imageCache, src]);

        const loading = src && !cachedSrc;
        return (
            <ImageWrapper>
                <img
                    ref={ref}
                    {...props}
                    src={cachedSrc ?? brokenImageUrl}
                    style={{ opacity: loading || error ? 0 : 1 }}
                />
                {loading && <CircularProgress size={16} className={'loading-indicator'} />}
                {error && <ErrorOutlined fontSize="small" className={'error-icon'} />}
            </ImageWrapper>
        );
    }
);

const ImageWrapper = styled(Box)`
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 100%;
  grid-template-areas: 'image';
  height: 100%;
  width: 100%;

  .loading-indicator,
  .error-icon {
    grid-area: image;
    justify-self: center;
    align-self: center;
  }

  img {
    grid-area: image;
    justify-self: center;
    align-self: center;
  }
`;
