import { useDefaultInheritance, useInheritance } from './inheritance';

export const useCanReset = () => {
    const defaultInheritance = useDefaultInheritance();
    const inheritance = useInheritance();

    const canSet = defaultInheritance !== inheritance;

    return canSet;
};
