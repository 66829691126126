import { useMemo } from 'react';
import { ScopeEnum } from 'src/domain';
import { components } from 'src/infrastructure/rest-api/generated';
import { useScope } from './useScope';

export const useEntityType = (selectedScope?: ScopeEnum): components['schemas']['EntityTypeEnum'] | undefined => {
    const scope = useScope();
    const scopeToUse = selectedScope ?? scope;

    return useMemo(() => {
        switch (scopeToUse) {
            case ScopeEnum.PARTNER:
                return 'Partner';
            case ScopeEnum.CLUSTER:
                return 'Cluster';
            case ScopeEnum.STORE:
                return 'Store';
            default:
                return;
        }
    }, [scopeToUse]);
};
