import { useMemo } from 'react';
import { ScopeEnum } from 'src/domain';
import { useClusterId } from 'src/hooks/cluster';
import { useCompositePartner } from 'src/hooks/partner/useCompositePartner';
import { useStoreId } from 'src/hooks/store';
import { useScope } from 'src/hooks/useScope';
import { useSelectedPartner } from 'src/hooks/useSelectedPartner';
import { components } from 'src/infrastructure/rest-api/generated';
import { useAuditLogModuleType } from '../useAuditLogModuleType';
import { useIsAuditLogSupported } from '../useIsAuditLogSupported';
import { useAuditLogApiQuery } from './useAuditLogApiQuery';

export type AuditOverviewModel = components['schemas']['AuditOverviewModel'];

export type AuditLogOverviewQuery = {
    data: AuditOverviewModel[] | undefined;
    error: Error | null;
    loading: boolean;
};

export const useAuditLogOverviewQuery = (enableFetch = false) => {
    const storeId = useStoreId();
    const scope = useScope();
    const [partner] = useSelectedPartner();
    const partnerComposite = useCompositePartner(partner);
    const clusterId = useClusterId(partner);
    const moduleType = useAuditLogModuleType();
    const isAuditLogSupported = useIsAuditLogSupported();
    const enable = isAuditLogSupported && enableFetch;

    const storeResponse = useAuditLogApiQuery(storeId, 'Store', moduleType, enable);
    const clusterResponse = useAuditLogApiQuery(clusterId, 'Cluster', moduleType, enable);
    const partnerResponse = useAuditLogApiQuery(partnerComposite?.id, 'Partner', moduleType, enable);

    const current = useMemo<AuditLogOverviewQuery | undefined>(() => {
        switch (scope) {
            case ScopeEnum.STORE:
                return {
                    data: storeResponse.data,
                    error: storeResponse.error,
                    loading: storeResponse.loading,
                };

            case ScopeEnum.CLUSTER:
                return {
                    data: clusterResponse.data,
                    error: clusterResponse.error,
                    loading: clusterResponse.loading,
                };

            case ScopeEnum.PARTNER:
                return {
                    data: partnerResponse.data,
                    error: partnerResponse.error,
                    loading: partnerResponse.loading,
                };
            default:
                return;
        }
    }, [
        scope,
        clusterResponse.data,
        clusterResponse.error,
        clusterResponse.loading,
        partnerResponse.data,
        partnerResponse.error,
        partnerResponse.loading,
        storeResponse.data,
        storeResponse.error,
        storeResponse.loading,
    ]);

    return current;
};
