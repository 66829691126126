import { isNumber } from 'lodash';
import { useMemo } from 'react';
import { Module } from 'src/domain';
import { SalesCampaignOverviewRow } from 'src/domain/table/sales-campaign-overview.row';
import {
    calculateDiscountedSalesPrice,
    calculateNewGrossMarginPercent,
} from 'src/utils/gross-margin/calculateGrossMargin';
import { calculatePercentageDifference } from 'src/utils/math';
import { useVat } from '../turnover/useVat';
import { useUnsavedChangesModule } from '../unsaved-changes/useUnsavedChangesModule';
import { useSalesCampaignRows } from './useSalesCampaignRows';

export const useSalesCampaignSimulationRows = (salesCampaignId: number) => {
    const { allData, salesCampaign, loading, error } = useSalesCampaignRows(salesCampaignId);
    const [unsavedChanges] = useUnsavedChangesModule<SalesCampaignOverviewRow[]>(Module.SalesCampaignsPlanning);
    const vat = useVat();

    const simulationData = useMemo(() => {
        if (!unsavedChanges) {
            return allData;
        }

        return allData.map((row) => {
            const actualRow: SalesCampaignOverviewRow = unsavedChanges.find((change) => change.id === row.id) ?? row;

            const newSalesPrice = isNumber(actualRow.discount?.valueY)
                ? calculateDiscountedSalesPrice(actualRow, actualRow.discount)
                : null;

            return {
                ...actualRow,
                newSalesPrice: newSalesPrice === actualRow.recommendedRetailPriceLcy ? null : newSalesPrice,
                newGMPercent: actualRow.discount
                    ? calculateNewGrossMarginPercent(actualRow, actualRow.discount, vat)
                    : null,
                discountPercentage: calculatePercentageDifference(newSalesPrice, actualRow.recommendedRetailPriceLcy),
            } as SalesCampaignOverviewRow;
        });
    }, [allData, unsavedChanges, vat]);

    const eligibleForSale = useMemo(
        () => simulationData?.filter((row) => row.eligibleForSale || isNumber(row.discount?.valueY)),
        [simulationData]
    );

    return {
        data: eligibleForSale ?? [],
        allData: simulationData,
        salesCampaign,
        loading,
        error,
    };
};
