import { format } from 'date-fns';
import { formatWeekYearAsDate } from './formatAsWeekYear';

// yearWeek to Month, Year
export const formatWeekYearAsMonthYear = (weekYear: number) => {
    const date = formatWeekYearAsDate(weekYear);
    return format(date, 'MMMM, yyyy');
};

// yearMonth to Month, Year
export const formatMonthYearAsHumanReadable = (monthYear: number) => {
    const date = formatMonthYearAsDate(monthYear);
    return format(date, 'MMMM, yyyy');
};

// yearWeek to yearMonth
export const formatWeekYearToMonthYear = (weekYear: number) => {
    const date = formatWeekYearAsDate(weekYear);
    const monthYear = formatDateAsMonthYear(date);
    return monthYear;
};

// date to yearMonth
export const formatDateAsMonthYear = (date: Date) => {
    if (!date) return null;
    return format(date, 'yyyyMM');
};

// yearMonth to Date
export const formatMonthYearAsDate = (monthYear: number) => {
    const year = Math.floor(monthYear / 100);
    const month = monthYear % 100;
    return new Date(year, month - 1);
};
