import { isNumber } from 'lodash';
import { useCallback } from 'react';
import { Cell } from 'src/components/organisms/DataTable/types';
import { updateRow } from 'src/components/views/Turnover/data/turnover';
import { Inheritance, Module, TurnoverRow } from 'src/domain';
import { UnsavedChangesEvent } from 'src/domain/events/unsaved-changes.event';
import { useSetSelectedInheritance, useStoreId, useUnsavedChangesModule } from 'src/hooks';
import { mergeMap } from 'src/utils/mergeMap';
import { useTurnoverRows } from './table/useTurnoverRows';

export const useTurnoverHandleCellValueChanged = () => {
    const { data: originalData } = useTurnoverRows() ?? {};
    const storeId = useStoreId();
    const [unsavedChanges, setUnsavedChanges] = useUnsavedChangesModule<TurnoverRow[]>(Module.Turnover);

    const setSelectedInheritance = useSetSelectedInheritance();

    const callback = useCallback(
        async (cellValues: Cell[]) => {
            if (!originalData) {
                throw new Error('Data is missing!');
            }

            if (!storeId) {
                throw new Error(`Store ID is missing`);
            }

            // Merge original data with unsaved changes - if any
            const data = mergeMap([originalData, unsavedChanges ?? []], ([originalRow, unsavedChangesRow]) => {
                return {
                    ...originalRow,
                    ...unsavedChangesRow,
                };
            });

            // For each cell value, update the row in the data array
            cellValues.forEach((cellValue) => {
                const originalRowData = originalData.find((x) => x.id === cellValue.rowId);
                const rowIndexToUpdate = data.findIndex((x) => x.id === cellValue.rowId) ?? -1;
                const rowData = data[rowIndexToUpdate];
                const valueIsValid = !isNaN(cellValue.value) && isNumber(Number(cellValue.value));

                if (originalRowData && rowData && cellValue.column) {
                    data[rowIndexToUpdate] = updateRow(
                        cellValue.column,
                        rowData,
                        valueIsValid ? cellValue.value : 0,
                        originalRowData.plannedSalesIv
                    );
                }
            });

            setUnsavedChanges(data);
            setSelectedInheritance(Inheritance.Typed);
            dispatchEvent(new Event(UnsavedChangesEvent));
        },
        [originalData, storeId, unsavedChanges, setUnsavedChanges, setSelectedInheritance]
    );

    return callback;
};
