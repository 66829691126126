import { useMemo } from 'react';
import { Module } from 'src/domain';
import { useCurrentModule } from '../useCurrentModule';
import { useDefaultInheritance } from './useDefaultInheritance';
import { useSelectedInheritance } from './useSelectedInheritance';

export const useInheritance = (selectedModule?: Module) => {
    const currentModule = useCurrentModule();
    const module = selectedModule ?? currentModule;

    const defaultInheritance = useDefaultInheritance(module);
    const selectedInheritance = useSelectedInheritance(module);

    return useMemo(() => selectedInheritance ?? defaultInheritance, [defaultInheritance, selectedInheritance]);
};
