import { useMemo } from 'react';
import { ScopeEnum } from 'src/domain';
import { StoreModel } from 'src/infrastructure/rest-api/api-types';
import { useChain } from '../chain/useChain';
import { useCluster } from '../cluster';
import { useCountry } from '../country/useCountry';
import { useStores } from '../store-selection/queries/useStores';
import { useScope } from '../useScope';
import { useStoreType } from '../useStoreType';

export const useCurrentStoresList = () => {
    const { data } = useStores();

    const scope = useScope();
    const chain = useChain();
    const country = useCountry();
    const cluster = useCluster();
    const expectedStoreType = useStoreType();

    const storesList: StoreModel[] = useMemo(() => {
        if (!data?.allStores) {
            return [];
        }

        return data?.allStores?.filter((store) => {
            const chainAndStoreTypeMatches = store.chain?.name === chain && store.storeType === expectedStoreType;
            const countryMatches = scope === ScopeEnum.PARTNER && country ? store.country?.name === country : true;
            const clusterMatches = scope === ScopeEnum.CLUSTER && cluster ? store.cluster?.name === cluster : true;

            return chainAndStoreTypeMatches && countryMatches && clusterMatches;
        });
    }, [chain, cluster, country, data?.allStores, expectedStoreType, scope]);

    return storesList;
};
