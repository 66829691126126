import styled from '@emotion/styled';
import { Box } from '@mui/material';
import { ColDef, GridReadyEvent } from 'ag-grid-community';
import { FC, useCallback, useEffect, useRef } from 'react';
import { PaginationStatusBar } from 'src/components/molecules/PaginationStatusBar/PaginationStatusBar';
import { DataTable } from 'src/components/organisms/DataTable/DataTable';
import { StyleWarningsRow } from 'src/domain/table/style-warnings-row';
import { defaultColDef } from '../data/columns';
import { DataTableNoRows } from '../table/DataTableNoRows';

type Props = {
    data: StyleWarningsRow[];
    columns: ColDef[];
    loading: boolean;
    description: string;
};

export const TabPanel: FC<Props> = ({ data, loading, columns, description }) => {
    const gridReadyEventRef = useRef<GridReadyEvent | undefined>(undefined);

    const handleGridReady = useCallback((event: GridReadyEvent) => {
        gridReadyEventRef.current = event;
    }, []);

    useEffect(() => {
        setTimeout(() => {
            if (data.length > 0 && !loading) {
                gridReadyEventRef.current?.api.hideOverlay();
            } else {
                gridReadyEventRef.current?.api.showNoRowsOverlay();
            }
        });
    }, [loading, data.length, data]);

    return (
        <ContentWrapper>
            <Content>
                <Description>{description}</Description>
                <StyledTableWrapper className="ag-theme-alpine-custom-compact">
                    <StyledDataTable
                        onGridReady={handleGridReady}
                        pagination={true}
                        paginationPageSize={50}
                        suppressPaginationPanel={true}
                        isNumberTable={false}
                        domLayout="autoHeight"
                        loading={loading}
                        defaultColDef={defaultColDef}
                        rows={data}
                        columns={columns}
                        noRowsOverlayComponent={() => <DataTableNoRows noData={data.length === 0 && !loading} />}
                        gridOptions={{
                            paginationNumberFormatter(params) {
                                const total = params.api.getModel().getRowCount();
                                if (params.value === total) return `${params.value} styles`;
                                return `${params.value}`;
                            },
                            statusBar: {
                                statusPanels: [
                                    {
                                        statusPanel: PaginationStatusBar,
                                        align: 'right',
                                    },
                                ],
                            },
                        }}
                    />
                </StyledTableWrapper>
            </Content>
        </ContentWrapper>
    );
};

const ContentWrapper = styled.div`
  background-color: #fff;
  border-radius: 5px;
  border-top-left-radius: 0px;
  padding: 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 0px 34px;
  flex: 1;
  min-height: 0;
`;

const Description = styled.div`
  max-width: 40%;
  font-size: 12px;
`;

const StyledTableWrapper = styled(Box)`
  height: 100%;
  .ag-root-wrapper {
    background-color: #fafafa;
  }

  .ag-row {
    background-color: #fff;
  }

  .ag-pivot-mode-panel,
  .ag-last-column-drop {
    display: none;
  }
`;

const StyledDataTable = styled(DataTable)`
  .ag-header-cell-label {
    font-size: 10px !important;
  }
`;
