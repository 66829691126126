import styled from '@emotion/styled';
import {
    BuildCircleOutlined,
    ErrorOutline,
    InfoOutlined,
    Launch,
    MonitorHeart,
    NewReleasesOutlined,
} from '@mui/icons-material';
import {
    Timeline,
    TimelineConnector,
    TimelineContent,
    TimelineDot,
    TimelineItem,
    TimelineSeparator,
    timelineItemClasses,
} from '@mui/lab';
import { Box, Stack, Typography } from '@mui/material';
import { orderBy } from 'lodash';
import { FC, useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { StyledLoadingButton } from 'src/components/styled/LoadingButton';
import { Color } from 'src/domain';
import { useCurrentModule } from 'src/hooks/useCurrentModule';
import { useHasDevTools } from 'src/hooks/useHasDevTools';

import { Module } from 'src/domain';
import { useAnnouncementsApiQuery } from 'src/hooks/announcements/queries/useAnnouncementApiQuery';
import { AnnouncementTypeEnum } from 'src/infrastructure/rest-api/api-types';
import { mapAnnouncements } from 'src/mapping/announcements.mapping';

export const StatusWidget: FC = () => {
    const { data } = useAnnouncementsApiQuery();
    const [isOpen, setIsOpen] = useState(false);
    const navigate = useNavigate();

    const hasDevTools = useHasDevTools();

    const currentModule = useCurrentModule();
    const moduleIsStoreSelection = useMemo(
        () => currentModule === Module.StoreSelection || currentModule === undefined,
        [currentModule]
    );

    const announcements = useMemo(() => mapAnnouncements(data), [data]);
    const filteredAnnouncements = useMemo(
        () =>
            orderBy(
                announcements.filter((announcement) => !announcement.resolved),
                ['created'],
                ['desc']
            ),
        [announcements]
    );

    const statusColor = useMemo(() => {
        if (filteredAnnouncements.some((announcement) => announcement.type === 'MajorIncident')) {
            return '#D9512C';
        }
        if (filteredAnnouncements.some((announcement) => announcement.type === 'MinorIncident')) {
            return '#DBAB09';
        }
        return '#84B96B';
    }, [filteredAnnouncements]);

    const announcementColor = (announcementType: AnnouncementTypeEnum) => {
        switch (announcementType) {
            case 'Information':
            case 'Maintenance':
                return '#4472C4';

            case 'MinorIncident':
                return '#DBAB09';
            case 'MajorIncident':
                return '#D9512C';
        }
    };

    const toggleOpen = useCallback(() => {
        setIsOpen(!isOpen);
    }, [isOpen]);

    return (
        <Wrapper sx={{ zIndex: isOpen ? 5 : 1 }}>
            <StatusToggle onClick={toggleOpen} hasDevTools={hasDevTools}>
                <MonitorHeartIcon fontSize={'small'} sx={{ color: statusColor }} />
                <Typography>Status</Typography>
            </StatusToggle>
            <Stack
                direction={'column'}
                gap={3}
                sx={{
                    transition: 'width 0.3s ease-in-out',
                    transitionProperty: 'width, padding',
                    width: isOpen ? 'calc(300px + 1.5rem)' : 0,
                    padding: '1rem 0',
                    overflow: 'hidden',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                }}
            >
                {moduleIsStoreSelection ? (
                    <Typography variant="h4" component={'h6'} sx={{ height: 'fit-content' }}>
                        System Status
                    </Typography>
                ) : (
                    <Typography variant="h4" component={'h6'} sx={{ height: 'fit-content', paddingTop: '50px' }}>
                        System Status
                    </Typography>
                )}
                <StatusButton
                    endIcon={<Launch />}
                    onClick={() => {
                        navigate('/status');
                    }}
                >
                    Check status page
                </StatusButton>
                <TimelineWrapper>
                    {announcements.length > 0 ? (
                        <Timeline
                            sx={{
                                [`& .${timelineItemClasses.root}:before`]: {
                                    flex: 0,
                                    padding: 0,
                                },
                                padding: 0,
                                width: '100%',
                            }}
                        >
                            {filteredAnnouncements.map((announcement) => (
                                <TimelineItem key={announcement.created?.toString()}>
                                    <TimelineSeparator>
                                        <TimelineItemDot
                                            sx={{
                                                backgroundColor: announcementColor(announcement.type),
                                                color: 'white',
                                                boxShadow: 'none',
                                            }}
                                        >
                                            {announcement.type === 'Information' && (
                                                <InfoOutlined sx={{ fontSize: '25px' }} />
                                            )}
                                            {announcement.type === 'Maintenance' && (
                                                <BuildCircleOutlined sx={{ fontSize: '25px' }} />
                                            )}
                                            {announcement.type === 'MajorIncident' && (
                                                <NewReleasesOutlined sx={{ fontSize: '25px' }} />
                                            )}
                                            {announcement.type === 'MinorIncident' && (
                                                <ErrorOutline sx={{ fontSize: '25px' }} />
                                            )}
                                        </TimelineItemDot>
                                        <TimelineConnector sx={{ backgroundColor: '#D5D6DC' }} />
                                    </TimelineSeparator>
                                    <TimelineContent sx={{ paddingBottom: '20px', paddingTop: '0px', paddingLeft: 2 }}>
                                        <TitleWrapper>
                                            <AnnouncementTitle variant="h6" type={announcement.type}>
                                                {announcement.title}
                                            </AnnouncementTitle>
                                        </TitleWrapper>
                                        <Typography sx={{ color: '#787C8D' }}>
                                            {(announcement.modified
                                                ? announcement.modified
                                                : announcement.created
                                            ).toLocaleTimeString([], {
                                                year: 'numeric',
                                                month: 'short',
                                                day: 'numeric',
                                                hour: '2-digit',
                                                minute: '2-digit',
                                            })}
                                        </Typography>
                                        <Typography sx={{ whiteSpace: 'pre-wrap' }}>
                                            {announcement.description}
                                        </Typography>
                                    </TimelineContent>
                                </TimelineItem>
                            ))}
                        </Timeline>
                    ) : (
                        <Typography variant="h6" component={'h6'} sx={{ textAlign: 'center' }}>
                            System fully operational
                        </Typography>
                    )}
                </TimelineWrapper>
            </Stack>
        </Wrapper>
    );
};

const StatusToggle = styled(Box, {
    shouldForwardProp(propName) {
        return !['hasDevTools'].includes(propName);
    },
})<{ hasDevTools: boolean }>`
  cursor: pointer;
  position: absolute;
  top: ${(props) => (props.hasDevTools ? '180px' : '60px')};
  left: -33px;
  padding: 0.75rem 0.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;

  writing-mode: vertical-lr;
  text-orientation: mixed;

  background-color: white;
  border-radius: 5px 0 0 5px;
  border: 1px solid #e0e0e0;
  border-right: none;
`;

const TimelineWrapper = styled.div`
  overflow-y: scroll;
  overflow-x: hidden;
  width: 100%;
  padding: 1rem;
  padding-left: 1.5rem;
  box-sizing: border-box;
  flex: 1;
`;

const StatusButton = styled(StyledLoadingButton)`
  color: ${Color.white};
  font-weight: bold;
  padding: 0.5rem 1rem;
  margin-top: -5px;
  height: 40px;
  &:hover {
    background-color: ${Color.editBlue};
    opacity: 0.8;
  }
`;

const MonitorHeartIcon = styled(MonitorHeart)`
  transform: rotate(90deg);
`;

const TimelineItemDot = styled(TimelineDot)`
  margin: 0;
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const AnnouncementTitle = styled(Typography)<{ type: AnnouncementTypeEnum }>`
  white-space: pre-wrap;
  color: ${(props) => {
      if (props.type === 'MajorIncident') {
          return '#D9512C';
      }
      if (props.type === 'MinorIncident') {
          return '#DBAB09';
      }
      return '#4472C4';
  }};
`;

const Wrapper = styled(Box)`
  height: 100%;
  position: fixed;
  right: 0;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  background-color: white;
  box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.2);
  box-sizing: border-box;

  transition: width 0.3s ease-in-out;
  transition-property: width, padding;
`;
