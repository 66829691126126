import { Inheritance, RemoveTypenameField, TurnoverRow } from 'src/domain';
import { ComparableStoreTypeEnum } from 'src/domain/enums/comparableStoreTypeEnum';
import { TableOverviewRow } from 'src/domain/table/table-overview-row';
import { VatOverviewRow } from 'src/domain/table/vat-overview-row';

import {
    TurnoverExpectedModel,
    TurnoverOverviewSummarizedModel,
    TurnoverSummarizedModel,
} from 'src/infrastructure/rest-api/api-types';
import { buildMonthTitle } from 'src/utils/buildMonth';
import { monthKeyToField } from 'src/utils/monthKeys';
import { calculateIndexLy } from 'src/utils/turnover/calculateIndexLy';
import { calculatePlannedIndex } from 'src/utils/turnover/calculatePlannedIndex';

export type ExpectedSalesData = RemoveTypenameField<TurnoverExpectedModel | TurnoverSummarizedModel> | null | undefined;

export type ExpectedSalesDataComparable = {
    storeData: (TurnoverExpectedModel | null | undefined)[] | null | undefined;
    comparables: TurnoverOverviewSummarizedModel | null | undefined;
    nonComparables: TurnoverOverviewSummarizedModel | null | undefined;
};

// biome-ignore lint/suspicious/noExplicitAny: we need to use any
function hasId(value: any): value is { id: number } {
    return value && typeof value.id === 'number';
}

function groupByMonth(expectedSales: ExpectedSalesData[] | null | undefined): Map<number, TurnoverRow> {
    if (!expectedSales) {
        return new Map();
    }

    const byMonth = new Map<number, TurnoverRow>();

    // Make calulations based on expected sales
    for (let i = 0; i < expectedSales.length; i++) {
        const sale = expectedSales[i];
        if (!sale) {
            continue;
        }

        const monthKey = sale.monthKey;
        const plannedSalesIv = sale.salesExpectediVlcy ?? 0;
        const netSaleslcyLY = sale.netSaleslcyLY ?? 0;
        const salesIvLy = sale.salesiVlcyLY;
        const salesIvLly = sale.salesiVlcyLLY ?? 0;
        const fccInitial = sale.fccInitial ?? 0;
        const fccRevised = sale.fccRevised ?? 0;
        const fccInitialReused = isTurnoverExpectedModel(sale) ? sale.fccInitialReused : false;
        const fccRevisedReused = isTurnoverExpectedModel(sale) ? sale.fccRevisedReused : false;
        const fccInitialBudgetYear =
            (isTurnoverExpectedModel(sale) ? sale.fccInitialBudgetYear : undefined) ?? undefined;
        const fccRevisedBudgetYear =
            (isTurnoverExpectedModel(sale) ? sale.fccRevisedBudgetYear : undefined) ?? undefined;
        const historicalFccBudgets =
            (isTurnoverExpectedModelWithHistoricalBudgets(sale) ? sale.historicalFccBudgets : []) ?? [];

        const indexLy = calculateIndexLy(salesIvLy, salesIvLly);
        const plannedIndex = calculatePlannedIndex(1, plannedSalesIv, salesIvLy, salesIvLly);

        if (typeof monthKey === 'number') {
            byMonth.set(monthKey, {
                id: hasId(sale) ? sale.id : i,
                month: buildMonthTitle(i, monthKey.toString().slice(4, 6), monthKey.toString().slice(0, 4)),
                monthKey,
                plannedSalesIv,
                salesIvLy,
                salesIvLly,
                indexLy,
                netSaleslcyLY,
                plannedIndex,
                hasBeenChanged: false,
                fccInitial,
                fccRevised,
                fccInitialReused,
                fccRevisedReused,
                fccInitialBudgetYear,
                fccRevisedBudgetYear,
                historicalFccBudgets,
            });
        }
    }

    return byMonth;
}

export function mapComparableSalesDataToExpected(
    expectedSales: ExpectedSalesDataComparable | null | undefined,
    comparableStoreType: ComparableStoreTypeEnum
): ExpectedSalesData[] {
    if (!expectedSales) {
        return [];
    }
    return (
        expectedSales.storeData ??
        (comparableStoreType === ComparableStoreTypeEnum.AllStores
            ? [...(expectedSales.comparables?.turnover ?? []), ...(expectedSales.nonComparables?.turnover ?? [])]
            : comparableStoreType === ComparableStoreTypeEnum.Comparables
              ? (expectedSales.comparables?.turnover ?? [])
              : (expectedSales.nonComparables?.turnover ?? []))
    );
}

export const getHistoricalFccBudgets = (existing: ExpectedSalesData, turnover: ExpectedSalesData) => {
    const existingHFccBs = isTurnoverExpectedModelWithHistoricalBudgets(existing) ? existing.historicalFccBudgets : [];
    const turnoverHFccBs = isTurnoverExpectedModelWithHistoricalBudgets(turnover) ? turnover.historicalFccBudgets : [];
    return [...(existingHFccBs ?? []), ...(turnoverHFccBs ?? [])];
};

export function mergeTurnoverByMonthKey(turnover: ExpectedSalesData[]): ExpectedSalesData[] {
    return turnover.reduce<ExpectedSalesData[]>((acc, turnover) => {
        const existingInx = acc.findIndex((t) => t?.monthKey === turnover?.monthKey);
        if (existingInx < 0) {
            acc.push(turnover);
        } else {
            const existing = acc[existingInx];
            acc.splice(existingInx, 1, {
                ...existing,
                salesiVlcyLY: (existing?.salesiVlcyLY ?? 0) + (turnover?.salesiVlcyLY ?? 0),
                salesiVlcyLLY: (existing?.salesiVlcyLLY ?? 0) + (turnover?.salesiVlcyLLY ?? 0),
                salesExpectediVlcy: (existing?.salesExpectediVlcy ?? 0) + (turnover?.salesExpectediVlcy ?? 0),
                netSaleslcyLY: (existing?.netSaleslcyLY ?? 0) + (turnover?.netSaleslcyLY ?? 0),
                historicalFccBudgets: getHistoricalFccBudgets(existing, turnover),
                // ! FIXME: Should we summarize fccInitial and fccRevised?
                // ! FIXME: Should we recalculate isIndex100?
            } as ExpectedSalesData);
        }

        return acc;
    }, []);
}

export function mapTurnoverToTableData(
    expectedSales: ExpectedSalesDataComparable | null | undefined,
    comparableStoreType: ComparableStoreTypeEnum
): TurnoverRow[] {
    if (!expectedSales) {
        return [];
    }

    const turnover = mergeTurnoverByMonthKey(mapComparableSalesDataToExpected(expectedSales, comparableStoreType));
    const groupedByMonthData = groupByMonth(turnover ?? []);
    const rows: TurnoverRow[] = Array.from(groupedByMonthData.values()).sort((a, b) => a.monthKey - b.monthKey);

    return rows;
}

export function mapTurnoverToTableOverviewRow(turnover: TurnoverRow[] | null | undefined) {
    return turnover?.reduce(
        (row, turnoverRow) => {
            const fieldName = monthKeyToField(turnoverRow.monthKey);
            row.columns[fieldName] = turnoverRow.plannedSalesIv;
            row.columns[`${fieldName}Id`] = turnoverRow.id;
            return row;
        },
        { columns: {} } as TableOverviewRow
    );
}

export function mapExpectedSalesToVat(expectedSales: ExpectedSalesData[] | null | undefined): VatOverviewRow {
    const groupedByMonth = groupByMonth(expectedSales);

    return Array.from(groupedByMonth.entries()).reduce((acc, [month, row]) => {
        const monthFieldName = monthKeyToField(month);

        const salesIv = typeof row.salesIvLy === 'number' ? row.salesIvLy : 0;

        const vatDecimal = salesIv / row.netSaleslcyLY;
        const vat = Math.round(vatDecimal * 100) / 100;

        return {
            ...acc,
            [monthFieldName]: vat,
        };
    }, {} as VatOverviewRow);
}

export function mapTurnoverInheritance(
    data: TurnoverExpectedModel[] | null,
    inheritance: Inheritance | undefined
): ExpectedSalesData[] {
    if (!data) return [];
    const result = Array.from(data.entries()).reduce<ExpectedSalesData[]>((acc, [_, object]) => {
        if (object?.monthKey === undefined) return acc;

        switch (inheritance) {
            case Inheritance.FccInitial:
                acc.push({
                    fccInitial: object?.fccInitial,
                    fccRevised: object?.fccRevised,
                    monthKey: object?.monthKey,
                    netSaleslcyLY: object?.netSaleslcyLY,
                    salesExpectediVlcy: object?.fccInitial,
                    salesiVlcyLLY: object?.salesiVlcyLLY,
                    salesiVlcyLY: object?.salesiVlcyLY,
                    fccInitialReused: object?.fccInitialReused,
                    fccRevisedReused: object?.fccRevisedReused,
                    fccInitialBudgetYear: object?.fccInitialBudgetYear,
                    fccRevisedBudgetYear: object?.fccRevisedBudgetYear,
                    id: object?.id,
                    storeId: object?.storeId,
                    historicalFccBudgets: object.historicalFccBudgets,
                });
                break;

            case Inheritance.FccRevised:
                acc.push({
                    fccInitial: object?.fccInitial,
                    fccRevised: object?.fccRevised,
                    monthKey: object?.monthKey,
                    netSaleslcyLY: object?.netSaleslcyLY,
                    salesExpectediVlcy: object?.fccRevised,
                    salesiVlcyLLY: object?.salesiVlcyLLY,
                    salesiVlcyLY: object?.salesiVlcyLY,
                    fccInitialReused: object?.fccInitialReused,
                    fccRevisedReused: object?.fccRevisedReused,
                    fccInitialBudgetYear: object?.fccInitialBudgetYear,
                    fccRevisedBudgetYear: object?.fccRevisedBudgetYear,
                    id: object?.id,
                    storeId: object?.storeId,
                    historicalFccBudgets: object.historicalFccBudgets,
                });
                break;

            case Inheritance.Historical:
                acc.push({
                    fccInitial: object?.fccInitial,
                    fccRevised: object?.fccRevised,
                    monthKey: object?.monthKey,
                    netSaleslcyLY: object?.netSaleslcyLY,
                    salesExpectediVlcy: object?.salesiVlcyLY,
                    salesiVlcyLLY: object?.salesiVlcyLLY,
                    salesiVlcyLY: object?.salesiVlcyLY,
                    fccInitialReused: object?.fccInitialReused,
                    fccRevisedReused: object?.fccRevisedReused,
                    fccInitialBudgetYear: object?.fccInitialBudgetYear,
                    fccRevisedBudgetYear: object?.fccRevisedBudgetYear,
                    id: object?.id,
                    storeId: object?.storeId,
                    historicalFccBudgets: object.historicalFccBudgets,
                });
                break;

            case Inheritance.HistoricalLLY:
                acc.push({
                    fccInitial: object?.fccInitial,
                    fccRevised: object?.fccRevised,
                    monthKey: object?.monthKey,
                    netSaleslcyLY: object?.netSaleslcyLY,
                    salesExpectediVlcy: object?.salesiVlcyLLY,
                    salesiVlcyLLY: object?.salesiVlcyLLY,
                    salesiVlcyLY: object?.salesiVlcyLY,
                    fccInitialReused: object?.fccInitialReused,
                    fccRevisedReused: object?.fccRevisedReused,
                    fccInitialBudgetYear: object?.fccInitialBudgetYear,
                    fccRevisedBudgetYear: object?.fccRevisedBudgetYear,
                    id: object?.id,
                    storeId: object?.storeId,
                    historicalFccBudgets: object.historicalFccBudgets,
                });
                break;

            default:
                acc.push({
                    fccInitial: object?.fccInitial,
                    fccRevised: object?.fccRevised,
                    monthKey: object?.monthKey,
                    netSaleslcyLY: object?.netSaleslcyLY,
                    salesExpectediVlcy: object?.salesExpectediVlcy,
                    salesiVlcyLLY: object?.salesiVlcyLLY,
                    salesiVlcyLY: object?.salesiVlcyLY,
                    fccInitialReused: object?.fccInitialReused,
                    fccRevisedReused: object?.fccRevisedReused,
                    fccInitialBudgetYear: object?.fccInitialBudgetYear,
                    fccRevisedBudgetYear: object?.fccRevisedBudgetYear,
                    id: object?.id,
                    storeId: object?.storeId,
                    historicalFccBudgets: object.historicalFccBudgets,
                });
                break;
        }
        return acc;
    }, []);
    return result;
}

// biome-ignore lint/suspicious/noExplicitAny: we need to use any
const isTurnoverExpectedModel = (value: any): value is TurnoverExpectedModel => {
    return value && value.fccInitialReused;
};
// biome-ignore lint/suspicious/noExplicitAny: we need to use any
const isTurnoverExpectedModelWithHistoricalBudgets = (value: any): value is TurnoverExpectedModel => {
    return value && value.historicalFccBudgets;
};
