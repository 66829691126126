import styled from '@emotion/styled';
import { CircularProgress } from '@mui/material';
import { FC } from 'react';
import CenteredContainer from 'src/components/atoms/CenteredContainer';
import { Color } from 'src/domain';

export const DataTableLoading: FC = () => {
    return (
        <StyledContainer>
            <Loader size="16px" />
            <Text>Loading...</Text>
        </StyledContainer>
    );
};

const StyledContainer = styled(CenteredContainer)`
  border: lightgrey solid 1px;
`;

const Loader = styled(CircularProgress)`
  color: ${Color.midnightGrey};
`;

const Text = styled.p`
  margin-left: 12px;
`;
