import { useContext, useEffect, useMemo } from 'react';
import { Module, ScopeEnum } from 'src/domain';
import { ProductLineSplitLevelQuery } from '../productline-split/queries/useProductLineSplitLevelQuery';
import { useScope } from '../useScope';
import { IsHistoricalContext } from './IsHistoricalContext';

export const useIsProductLineSplitHistorical = (data: ProductLineSplitLevelQuery['data'] | undefined) => {
    const scope = useScope();
    const isTyped = useMemo(() => {
        switch (scope) {
            case ScopeEnum.STORE:
                return data?.storeProductLineSplit?.some((x) => !!x?.modifiedByUser);
            case ScopeEnum.CLUSTER:
                return data?.clusterProductLineSplit?.some((x) => !!x?.modifiedByUser);
            case ScopeEnum.PARTNER:
                return data?.partnerProductLineSplit?.some((x) => !!x?.modifiedByUser);
        }
    }, [data?.clusterProductLineSplit, data?.partnerProductLineSplit, data?.storeProductLineSplit, scope]);

    // invert isTyped but keep undefined
    if (isTyped === true) return false;
    if (isTyped === false) return true;
    return undefined;
};

export const useInitializeIsProductlineSplitHistorical = (data: ProductLineSplitLevelQuery['data'] | undefined) => {
    const [isHistoricalMap, setIsHistoricalMap] = useContext(IsHistoricalContext);

    const isProductlineSplitHistorical = useIsProductLineSplitHistorical(data);

    useEffect(() => {
        if (isProductlineSplitHistorical === undefined) return;
        if (isHistoricalMap.get(Module.ProductLineSplit) === isProductlineSplitHistorical) return;

        isHistoricalMap.set(Module.ProductLineSplit, isProductlineSplitHistorical);
        setIsHistoricalMap(new Map(isHistoricalMap));
    }, [isProductlineSplitHistorical, isHistoricalMap, setIsHistoricalMap]);
};
