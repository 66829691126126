import { CustomFilterProps } from 'ag-grid-react';
import { endOfWeek, subYears } from 'date-fns';
import { FC } from 'react';
import { DateFilterBase } from 'src/components/organisms/DateFilterBase/DateFilterBase';
import { formatDateAsWeekYear, formatWeekYearAsHumanReadable } from 'src/utils/formatAsWeekYear';
import { WeekPicker } from '../WeekPickerCellEditor/WeekPickerCellEditor';

export const WeekFilter: FC<CustomFilterProps> = (props) => {
    const getEndExcludeDateIntervals = (startDate: Date | null) => {
        if (startDate) {
            return [
                {
                    start: subYears(startDate, 200),
                    end: endOfWeek(startDate, { weekStartsOn: 1 }),
                },
            ];
        }
        return [];
    };

    return (
        <DateFilterBase
            {...props}
            dateFormatter={(date: Date) => Number(formatDateAsWeekYear(date))}
            DatePickerComponent={WeekPicker}
            formatDate={formatWeekYearAsHumanReadable}
            getEndExcludeDateIntervals={getEndExcludeDateIntervals}
        />
    );
};
