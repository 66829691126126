import { isNumber } from 'lodash';
import { SalesCampaignOverviewRow } from 'src/domain/table/sales-campaign-overview.row';

export interface SummaryData {
    totalExpectedSalesIv: number;
    totalStockValue: number;
    totalStockValueOnSale: number;
    totalStockValueNotOnSale: number;
    totalStockQuantity: number;
    totalStockQuantityOnSale: number;
    totalStockQuantityNotOnSale: number;
    percentageStockValueOnSale: number;
    percentageStockQuantityOnSale: number;
    avgMarkDown: number;
    stylesOnSale: number;
    styleOptionsOnSale: number;
    totalSalesPrice: number;
    totalRecommendedRetailPrice: number;
    totalSalesPriceOnSale: number;
    totalCogs: number;
    totalCogsOnSale: number;
}

interface SalesCampaignOverviewRowWithNewSalesPrice extends Omit<SalesCampaignOverviewRow, 'newSalesPrice'> {
    newSalesPrice: number;
}

export const calculateSummary = (salesCampaigns: SalesCampaignOverviewRow[]): SummaryData => {
    const onSale = salesCampaigns.filter((row) => isOnSale(row)) as SalesCampaignOverviewRowWithNewSalesPrice[];

    const totalStockValue = salesCampaigns.reduce((acc, row) => acc + row.stockValueLcy, 0);

    const totalStockQuantity = salesCampaigns.reduce((acc, row) => acc + row.stockQuantity, 0);

    const totalStockValueOnSale = onSale.reduce((acc, row) => {
        return acc + row.stockValueLcy;
    }, 0);

    const totalStockQuantityOnSale = onSale.reduce((acc, row) => {
        return acc + row.stockQuantity;
    }, 0);

    const totalEpectedSalesIv = onSale.reduce((acc, row) => {
        const itemPrice = row.newSalesPrice ?? row.recommendedRetailPriceLcy ?? 0;
        const totalPrice = itemPrice * row.stockQuantity;
        return acc + totalPrice;
    }, 0);

    const totalStockValueNotOnSale = totalStockValue - totalStockValueOnSale;
    const totalStockQuantityNotOnSale = totalStockQuantity - totalStockQuantityOnSale;
    const percentageStockValueOnSale = (totalStockValueOnSale / totalStockValue) * 100;
    const percentageStockQuantityOnSale = (totalStockQuantityOnSale / totalStockQuantity) * 100;

    const totalRecommendedRetailPriceOnSale = onSale.reduce((acc, row) => {
        if (isNumber(row.recommendedRetailPriceLcy) && !isNaN(acc)) {
            return acc + row.recommendedRetailPriceLcy;
        }
        return NaN;
    }, 0);

    const totalSalesPriceOnSale = onSale.reduce((acc, row) => {
        return acc + row.newSalesPrice;
    }, 0);

    const totalSalesPrice = salesCampaigns.reduce((acc, row) => {
        if (isNaN(acc)) {
            return acc;
        }

        if (isOnSale(row)) {
            return acc + row.newSalesPrice;
        }

        if (isNumber(row.recommendedRetailPriceLcy)) {
            return acc + row.recommendedRetailPriceLcy;
        }

        return NaN;
    }, 0);

    const totalRecommendedRetailPrice = salesCampaigns.reduce((acc, row) => {
        if (isNumber(row.recommendedRetailPriceLcy) && !isNaN(acc)) {
            return acc + row.recommendedRetailPriceLcy;
        }
        return NaN;
    }, 0);

    const totalCogs = salesCampaigns.reduce((acc, row) => {
        return acc + row.stockValueLcy / row.stockQuantity;
    }, 0);

    const totalCogsOnSale = onSale.reduce((acc, row) => {
        if (isOnSale(row)) {
            return acc + row.stockValueLcy / row.stockQuantity;
        }
        return acc;
    }, 0);

    const avgMarkDown =
        ((totalRecommendedRetailPriceOnSale - totalSalesPriceOnSale) / totalRecommendedRetailPriceOnSale) * 100;

    const stylesOnSale = onSale.reduce<number[]>((acc, row) => {
        if (!acc.includes(row.styleNumber)) {
            acc.push(row.styleNumber);
        }
        return acc;
    }, []);

    return {
        percentageStockQuantityOnSale,
        percentageStockValueOnSale,
        totalExpectedSalesIv: totalEpectedSalesIv,
        totalStockQuantity,
        totalStockQuantityNotOnSale,
        totalStockQuantityOnSale,
        totalStockValue,
        totalStockValueNotOnSale,
        totalStockValueOnSale,
        avgMarkDown,
        stylesOnSale: stylesOnSale.length,
        styleOptionsOnSale: onSale.length,
        totalSalesPrice,
        totalSalesPriceOnSale,
        totalRecommendedRetailPrice,
        totalCogs,
        totalCogsOnSale,
    };
};

export function isOnSale(row: SalesCampaignOverviewRow): row is SalesCampaignOverviewRow & { newSalesPrice: number } {
    return isNumber(row.newSalesPrice);
}
