import { Button, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle } from '@mui/material';
import { FC, MouseEvent, useCallback, useMemo } from 'react';
import { ScopeEnum } from 'src/domain';
import { useScope } from 'src/hooks';
import { InheritanceHelpClusterCountry } from './InheritanceHelpClusterCountry';
import { InheritanceHelpStore } from './InheritanceHelpStore';

export const InheritanceHelpDialog: FC<DialogProps> = (props) => {
    const scope = useScope();

    const inheritanceHelpContent = useMemo(() => {
        switch (scope) {
            case ScopeEnum.STORE:
                return <InheritanceHelpStore />;
            case ScopeEnum.PARTNER:
            case ScopeEnum.CLUSTER:
                return <InheritanceHelpClusterCountry />;
        }
        return null;
    }, [scope]);

    const handleClose = useCallback(
        (event: MouseEvent) => {
            if (typeof props.onClose === 'function') {
                props.onClose(event, 'escapeKeyDown');
            }
        },
        [props]
    );

    return (
        <Dialog {...props} scroll={'paper'} aria-labelledby="inheritance-help-dialog">
            <DialogTitle id="inheritance-help-dialog">Inheritance description</DialogTitle>
            <DialogContent>{inheritanceHelpContent}</DialogContent>
            <DialogActions>
                <Button onClick={handleClose} autoFocus>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};
