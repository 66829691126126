import { useCallback, useState } from 'react';
import { Module, ScopeEnum } from 'src/domain';
import {
    StyleTimelineRowUpdateEventPayload,
    StyleTimelineRowUpdatedEvent,
} from 'src/domain/events/styletimeline-row-updated.event';
import { StyleTimelineOverviewRow } from 'src/domain/table/style-timeline-overview-row';
import { useChainId } from 'src/hooks/chain';
import { useDiscardChangesModule } from 'src/hooks/discard-changes/useDiscardChangesModule';
import { useCompositePartner } from 'src/hooks/partner/useCompositePartner';
import defaultErrorMessage from 'src/hooks/snackbar/defaultErrorMessage';
import { useSnackbar } from 'src/hooks/snackbar/useSnackbar';
import { useUnsavedChangesModule } from 'src/hooks/unsaved-changes';
import { useScope } from 'src/hooks/useScope';
import { StyleTimelineExitWeekType, UpdateStyleTimelineInput } from 'src/infrastructure/rest-api/style-timeline';
import { useApiMutation } from 'src/infrastructure/rest-api/useApi';
import { useStorefrontType } from '../useStorefrontType';

export const toStyleTimelineInput = (change: StyleTimelineOverviewRow): UpdateStyleTimelineInput => {
    const exitWeek = change.exitWeek ? Number(change.exitWeek) : null;
    const packawayStartWeek = change.packaway?.split('-')[0];
    const packawayEndWeek = change.packaway?.split('-')[1];
    const comment = change.comment ? change.comment : '';
    const eligibleForSale = change.eligibleForSale;
    const stylePriority = change.stylePriority ? change.stylePriority : null;

    return {
        exitWeekType: exitWeek ? 'WEEK_KEY' : (change.exitWeekType as StyleTimelineExitWeekType),
        id: change.id,
        exitWeek: exitWeek,
        storePlacement: change.storePlacement ?? null,
        storePlacementDetail: change.storePlacementDetail ?? null,
        packawayStartWeek: packawayStartWeek ? parseInt(packawayStartWeek) : null,
        packawayEndWeek: packawayEndWeek ? parseInt(packawayEndWeek) : null,
        comment,
        eligibleForSale,
        stylePriority,
        phaseoutProfileId: change.phaseoutProfileId ?? null,
    };
};

export const useStyleTimelineSaveChanges = (selectedStorefrontType?: string) => {
    const discardChanges = useDiscardChangesModule(Module.StyleTimeline);
    const defaultStorefrontType = useStorefrontType();
    const storefrontType = selectedStorefrontType ?? defaultStorefrontType;
    const chainId = useChainId();
    const partnerComposite = useCompositePartner();
    const scope = useScope();
    const [isSaving, setIsSaving] = useState(false);

    const [mutateStyleTimeline] = useApiMutation('/api/styletimeline/partners', 'put', {
        update(data, _variables, queryClient) {
            if (scope === ScopeEnum.GLOBAL) {
                queryClient.setQueryData(
                    [
                        '/api/styletimeline/chains/{chainId}',
                        {
                            path: { chainId: chainId },
                            query: {
                                storefrontType,
                            },
                        },
                    ],
                    data
                );
            } else if (scope === ScopeEnum.PARTNER) {
                queryClient.setQueryData(
                    [
                        '/api/styletimeline/partners/{partnerCompositeId}',
                        {
                            path: { partnerCompositeId: partnerComposite?.id },
                            query: {
                                storefrontType,
                            },
                        },
                    ],
                    data
                );
            }
        },
    });
    const showSnackbar = useSnackbar();

    const [unsavedChanges] = useUnsavedChangesModule<StyleTimelineOverviewRow[]>(Module.StyleTimeline);

    const sendUpdateQuery = useCallback(async () => {
        const changes = unsavedChanges ?? [];
        return mutateStyleTimeline({
            body: changes.map(toStyleTimelineInput),
        });
    }, [mutateStyleTimeline, unsavedChanges]);

    const saveStyleTimeline = useCallback(async () => {
        setIsSaving(true);
        try {
            const updatedRows = await sendUpdateQuery();
            dispatchEvent(
                new CustomEvent<StyleTimelineRowUpdateEventPayload>(StyleTimelineRowUpdatedEvent, {
                    detail: {
                        rows: updatedRows ?? [],
                    },
                })
            );
            discardChanges();
            showSnackbar('Your changes have been saved', 'success');
        } catch {
            showSnackbar(defaultErrorMessage, 'error');
        } finally {
            setIsSaving(false);
        }
    }, [discardChanges, sendUpdateQuery, showSnackbar]);

    return [saveStyleTimeline, { isSaving }] as const;
};
