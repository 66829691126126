import { Skeleton } from '@mui/material';
import { isNumber } from 'lodash';
import { FC } from 'react';
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { StockManagementChartRow } from 'src/domain';
import { numberWithSpaces } from 'src/utils/numberWithSpaces';

interface Props {
    data: StockManagementChartRow[];
    loading?: boolean;
}
export const StockManagementLineChart: FC<Props> = ({ data, loading }) => {
    if (loading) return <Skeleton variant="rectangular" width={'100%'} height={400} />;
    return (
        <ResponsiveContainer width="100%" height={400}>
            <LineChart width={730} height={250} data={data} margin={{ top: 25, left: 20, right: 20, bottom: 90 }}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="month" angle={-45} textAnchor="end" />
                <YAxis />
                <Tooltip
                    formatter={(value) => {
                        if (isNumber(value)) return numberWithSpaces(value);
                        return value;
                    }}
                />
                <Legend verticalAlign="top" height={36} />
                <Line connectNulls type="monotone" dataKey="stockMinimum" name="Stock Minimum" stroke="#003f5c" />
                <Line connectNulls type="monotone" dataKey="stockMaximum" name="Stock Maximum" stroke="#7a5195" />
                <Line type="monotone" dataKey="stockOptimal" name="Stock Optimal" stroke="#ef5675" />
                <Line type="monotone" dataKey="stockOptimalAdjusted" name="Stock Optimal Adj." stroke="#ffa600" />
            </LineChart>
        </ResponsiveContainer>
    );
};
