import { useStoreId } from 'src/hooks/store';
import { useScope } from 'src/hooks/useScope';
import { useApiQuery } from 'src/infrastructure/rest-api/useApi';

export const useStoreStyleTimelineQuery = () => {
    const scope = useScope();
    const storeId = useStoreId();

    const response = useApiQuery('/api/styletimeline/stores/{storeId}', 'get', {
        path: {
            storeId: Number(storeId),
        },
        enabled: !!storeId && scope === 'STORE',
    });

    return response;
};
