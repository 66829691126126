import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, Stack, Tooltip } from '@mui/material';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import { MathJax } from 'better-react-mathjax';
import { FC, useMemo } from 'react';
import Spacer from 'src/components/atoms/Spacer';
import { Module, Spacings } from 'src/domain/enums';
import { useCurrentModule } from 'src/hooks';
import { LockIconTip } from './LockIconTip';
import { Tip } from './Tip';
import { ToolTipText } from './ToolTipText';

export const InheritanceHelpClusterCountry: FC = () => {
    const currentModule = useCurrentModule();

    const noosHelp =
        'There are three different historical Noos inheritance options. They behave like any other historical inheritance. The difference between the three are:';
    const hasHistoricals_rollsToFixed_CellLock =
        'A Country/Cluster inheriting from "Fixed" has had changes made to its values by a user. Cells with user entries will be fixed. All other cells will update automatically. New months will have last years "Fixed" values.';
    const hasNoHistoricals_rollsToFixed_NoLock =
        'A Country/Cluster inheriting from "Fixed" has had changes made to its values by a user. This module has no historical values. All values are fixed and will not update automatically. New months will have last years "Fixed" values.';
    const hasHistoricals_rollsToHistorical_CellLock =
        'A Country/Cluster inheriting from "Typed" has had changes made to its values by a user. Cells with user entries will be locked. All other cells will update automatically. New months will have historical values.';
    const hasHistoricals_rollsToHistorical_ColumnLock =
        'A Country/Cluster inheriting from "Typed" has had changes made to its values by a user. Columns with user entries will be locked. All other columns will update automatically. New months will have historical values.';

    const typedDescription = useMemo(() => {
        switch (currentModule) {
            //Rolls to Historicals - Column Lock
            case Module.CategorySplit:
                return hasHistoricals_rollsToHistorical_ColumnLock;
            case Module.GenderSplit:
                return hasHistoricals_rollsToHistorical_ColumnLock;
            case Module.ProductLineSplit:
                return hasHistoricals_rollsToHistorical_ColumnLock;
            //Rolls to Historicals - Cell Lock
            case Module.NoosShare:
                return hasHistoricals_rollsToHistorical_CellLock;
            //Rolls to Fixed (Cannibalization)
            case Module.Markup:
                return hasHistoricals_rollsToFixed_CellLock;
            case Module.Markdown:
                return hasHistoricals_rollsToFixed_CellLock;
            case Module.WeekCover:
                return hasNoHistoricals_rollsToFixed_NoLock;
            case Module.DeliveryProfile:
                return hasNoHistoricals_rollsToFixed_NoLock;
        }
        //Modules without Inheritance
        return null;
    }, [currentModule]);

    const typedOrFixed = useMemo(() => {
        switch (currentModule) {
            case Module.Markup:
            case Module.Markdown:
            case Module.WeekCover:
            case Module.DeliveryProfile:
                return 'FIXED:';
        }
        return 'TYPED: ';
    }, [currentModule]);

    const showHistorical = useMemo(() => {
        switch (currentModule) {
            case Module.WeekCover:
            case Module.DeliveryProfile:
                return null;
        }
        return (
            <Typography noWrap={false}>
                <Typography component="span" noWrap={false} fontWeight={'bold'}>
                    HISTORICAL:
                </Typography>{' '}
                A Country/Cluster inheriting from historical will automatically update its values monthly to the latest
                collective historical values of the selected operational responsible.
            </Typography>
        );
    }, [currentModule]);

    const showNoosHelp = useMemo(() => {
        switch (currentModule) {
            case Module.NoosShare:
                return (
                    <Stack spacing={2}>
                        <Typography noWrap={false}>
                            <Typography component="span" fontWeight={'bold'}>
                                NOOS:
                            </Typography>{' '}
                            {noosHelp}
                        </Typography>

                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography fontWeight={'normal'}>NOOS Share:</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography component="span" noWrap={false}>
                                    Is based on historical noos sales in country/cluster stores. The calculation is
                                    based on the following formula: <Spacer spacing={Spacings.xSmall} vertical />
                                    <MathJax inline>
                                        {
                                            '\\({\\text{Noos category sales} \\over \\text{Category sales}} \\times 100\\)'
                                        }
                                    </MathJax>
                                </Typography>
                            </AccordionDetails>
                        </Accordion>

                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel2a-header"
                            >
                                <Typography fontWeight={'normal'}>Auto replenishment share:</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography component="span" noWrap={false}>
                                    Is based on historical noos sales in country/cluster stores. Only sales marked as
                                    auto replenished (Demand Driven Replenishment) will be included here. The
                                    calculation is based on the following formula:{' '}
                                    <Spacer spacing={Spacings.xSmall} vertical />
                                    <MathJax inline>
                                        {'\\({\\text{DDR category sales} \\over \\text{Category sales}} \\times 100\\)'}
                                    </MathJax>
                                </Typography>
                            </AccordionDetails>
                        </Accordion>

                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel2a-header"
                            >
                                <Typography fontWeight={'normal'}>Auto replenishment order share:</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography component="span" noWrap={false}>
                                    Is based on historical noos sales orders for country/cluster stores. Only
                                    automatically generated sales orders will be included here. The calculation is based
                                    on the value of completed orders filtered with Sales Channel L2:{' '}
                                    <Spacer spacing={Spacings.xSmall} vertical />
                                    <MathJax inline>
                                        {
                                            '\\({\\text{Auto Replenishment orders} \\over \\text{COGS from retail sales data}} \\times 100\\)'
                                        }
                                    </MathJax>
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                    </Stack>
                );
        }
    }, [currentModule]);

    return (
        <Stack spacing={2}>
            <Typography noWrap={false}>
                Inheritance determines where your Country/Cluster gets its values from, and is set on a{' '}
                <Tooltip title="Example: category split and NOOS share are different modules">
                    <ToolTipText>module</ToolTipText>
                </Tooltip>{' '}
                and operational responsible level. The Country/Cluster will by default inherit from the collective
                historical data of the selected operational responsible.
            </Typography>

            <Tip prefix="Note:">
                Countries/Clusters are NOT based on the whole Country/Cluster, but on an individual operational
                responsible within.
            </Tip>

            <Typography noWrap={false}>Inheritance options:</Typography>

            {showHistorical}
            {showNoosHelp}

            <Typography noWrap={false}>
                <Typography component="span" fontWeight={'bold'}>
                    {typedOrFixed}
                </Typography>{' '}
                {typedDescription}
            </Typography>

            <LockIconTip />

            <Tip>
                Changes made on a Country/Cluster will affect all stores inheriting from it! This means you don&apos;t
                have to manually update each store with new values.
            </Tip>
        </Stack>
    );
};
