import { useMemo } from 'react';
import { ScopeEnum } from 'src/domain';
import { useQueryParams } from './useQueryParams';

export const useScope = () => {
    const { storeId, type } = useQueryParams();

    return useMemo(() => {
        const storeIdCaseInsensitive = storeId?.toLowerCase();
        const typeCaseInsensitive = type?.toLowerCase();

        if (storeIdCaseInsensitive === 'global') return ScopeEnum.GLOBAL;
        if (storeIdCaseInsensitive !== 'overview') return ScopeEnum.STORE;
        if (typeCaseInsensitive === 'cluster') return ScopeEnum.CLUSTER;
        if (typeCaseInsensitive === 'country') return ScopeEnum.PARTNER;
    }, [storeId, type]);
};
