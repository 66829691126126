import { useMemo } from 'react';
import { IntersectionOptionalFields, ScopeEnum } from 'src/domain';
import { useScope } from 'src/hooks/useScope';
import { PartnerStyleTimelineModel, StoreStyleTimelineModel } from 'src/infrastructure/rest-api/style-timeline';
import { useGlobalStyleTimelineQuery } from './useGlobalStyleTimelineQuery';
import { usePartnerStyleTimelineQuery } from './usePartnerStyleTimelineQuery';
import { useStoreStyleTimelineQuery } from './useStoreStyleTimelineQuery';

export type IntersectedStyleTimelineData = IntersectionOptionalFields<
    StoreStyleTimelineModel,
    PartnerStyleTimelineModel
>;

export type StyleTimelineLevelQuery = {
    data: (PartnerStyleTimelineModel | StoreStyleTimelineModel)[] | undefined;
    error: Error | null;
    loading: boolean;
};

export const useStyleTimelineLevelQuery = (selectedStorefrontType?: string): StyleTimelineLevelQuery | undefined => {
    const scope = useScope();

    const { data: storeStyleTimeline, error: storeError, loading: storeLoading } = useStoreStyleTimelineQuery();
    const {
        data: partnerStyleTimeline,
        error: partnerError,
        loading: partnerLoading,
    } = usePartnerStyleTimelineQuery(selectedStorefrontType);
    const { data: globalStyleTimeline, error: globalError, loading: globalLoading } = useGlobalStyleTimelineQuery();

    const current = useMemo<StyleTimelineLevelQuery | undefined>(() => {
        switch (scope) {
            case ScopeEnum.STORE:
                return { data: storeStyleTimeline, error: storeError, loading: storeLoading };

            case ScopeEnum.PARTNER:
                return { data: partnerStyleTimeline, error: partnerError, loading: partnerLoading };
            case ScopeEnum.GLOBAL:
                return { data: globalStyleTimeline, error: globalError, loading: globalLoading };
            default:
                return;
        }
    }, [
        globalError,
        globalLoading,
        globalStyleTimeline,
        partnerError,
        partnerLoading,
        partnerStyleTimeline,
        scope,
        storeError,
        storeLoading,
        storeStyleTimeline,
    ]);

    return current;
};
