import { Stack } from '@mui/material';
import { ICellRendererParams } from 'ag-grid-community';
import { FC, useMemo } from 'react';
import Container from 'src/components/atoms/Container';
import Header from 'src/components/atoms/Header';
import { DataTable } from 'src/components/organisms/DataTable/DataTable';
import { calculateAveragesWithPlannedSplits } from 'src/components/organisms/DataTable/aggregators/average-total.agg';
import { ColumnsDetailsNoos } from 'src/components/organisms/DataTable/columns/columns-details-noos';
import { defaultCategoryColumn } from 'src/components/organisms/DataTable/columns/columns-overview-sum';
import {
    DefaultColumnOptionsDetails,
    DefaultColumnsOptionsOverviewPercentage,
} from 'src/components/organisms/DataTable/columns/default-options';
import { averageCellRenderer } from 'src/components/organisms/DataTable/helpers/average-cell-renderer';
import { SumBold } from 'src/components/organisms/DataTable/options/sum-bold';
import DetailsTable from 'src/components/organisms/DetailsTable';
import InheritanceDropdown from 'src/components/organisms/InheritanceDropdown';
import { InheritanceTable } from 'src/components/organisms/InheritanceTable/InheritanceTable';
import Topbar from 'src/components/organisms/Topbar';
import { StyledHeaderWrapper } from 'src/components/styled/StyledHeaderWrapper';
import { TableWrapper } from 'src/components/styled/TableWrapper';
import { Headings, Module } from 'src/domain';
import { NoosShareDetailsRow } from 'src/domain/table/noos-share.row';
import { useHasUnsavedChangesGenderProductline } from 'src/hooks';
import { useDiscardChangesModule } from 'src/hooks/discard-changes/useDiscardChangesModule';
import { useNoosShareReset } from 'src/hooks/noos-share/reset/useNoosShareReset';
import { useNoosShareSaveChanges } from 'src/hooks/noos-share/save-changes/useNoosShareSaveChanges';
import { useNoosShareDetailsSimulationRows } from 'src/hooks/noos-share/simulation/useNoosShareDetailsSimulationRows';
import { useNoosShareOverviewSimulationRows } from 'src/hooks/noos-share/simulation/useNoosShareOverviewSimulationRows';
import { useNoosShareUpdateInheritance } from 'src/hooks/noos-share/split-level/useNoosShareUpdateInheritance';
import { useNoosShareDetailsRows } from 'src/hooks/noos-share/table/useNoosShareDetailsRows';
import { useNoosShareDetailsTableColumnNames } from 'src/hooks/noos-share/useNoosShareDetailsTableColumnNames';
import { useNoosShareHandleCellChanges } from 'src/hooks/noos-share/useNoosShareHandleCellChanges';
import { useModulePlanningRange } from 'src/hooks/useModulePlanningRange';
import { mergeTableDetailsRowValuesToTuples } from 'src/mapping/table.mapping';

const NoosShare: FC = () => {
    const {
        data: overviewData,
        loading: overviewLoading,
        columns: monthColumns,
    } = useNoosShareOverviewSimulationRows();
    const { data: simulatedDetailsData, loading: detailsLoading } = useNoosShareDetailsSimulationRows();
    const { data: detailsData } = useNoosShareDetailsRows();
    const planningRange = useModulePlanningRange(Module.NoosShare);

    const handleCellValuesChanges = useNoosShareHandleCellChanges();
    const { updateInheritance, loading: updateInheritanceLoading } = useNoosShareUpdateInheritance();
    const { resetNoosShare, loading: resetLoading } = useNoosShareReset();

    const [saveChanges, { loading: isSaving }] = useNoosShareSaveChanges();
    const onDiscardChanges = useDiscardChangesModule(Module.NoosShare);

    const hasUnsavedChanges = useHasUnsavedChangesGenderProductline();
    const columnNames = useNoosShareDetailsTableColumnNames();

    const loading = overviewLoading || detailsLoading || updateInheritanceLoading || resetLoading || isSaving;

    const overviewRows = !loading ? overviewData : undefined;

    const columns = useMemo(() => {
        return [
            {
                ...defaultCategoryColumn,
                cellRenderer: (params: ICellRendererParams) => {
                    return params.value ?? 'Average';
                },
            },
            ...monthColumns.map((column) => {
                return {
                    ...column,
                    cellRenderer: (params: ICellRendererParams) => averageCellRenderer(column, params),
                };
            }),
        ];
    }, [monthColumns]);

    const detailsRows = useMemo(() => {
        if (loading) {
            return;
        }

        return mergeTableDetailsRowValuesToTuples<NoosShareDetailsRow, NoosShareDetailsRow>(
            detailsData,
            simulatedDetailsData,
            ['plannedValue']
        );
    }, [detailsData, simulatedDetailsData, loading]);

    return (
        <Stack spacing={2}>
            <Topbar
                onDiscard={onDiscardChanges}
                onSave={saveChanges}
                saveDisabled={!hasUnsavedChanges}
                onReset={resetNoosShare}
                onSetInheritance={updateInheritance}
                loading={loading}
                saveLoading={isSaving}
            />

            <Container>
                <StyledHeaderWrapper>
                    <Header heading={Headings.h2}>NOOS Share</Header>
                    <InheritanceDropdown />
                </StyledHeaderWrapper>
                <TableWrapper className="ag-theme-alpine-custom-compact">
                    <DataTable
                        autoSizeToFit={planningRange === 'TWELVE_MONTHS'}
                        headerHeight={60}
                        columns={columns}
                        rows={overviewRows}
                        defaultColDef={DefaultColumnsOptionsOverviewPercentage}
                        onCellValuesChanged={handleCellValuesChanges}
                        loading={loading}
                        getGroupRowAgg={calculateAveragesWithPlannedSplits}
                        grandTotalRow="bottom"
                    />
                </TableWrapper>
            </Container>
            <DetailsTable
                {...SumBold}
                key={'details-table'}
                title={'Noos Share Details'}
                monthColumns={monthColumns}
                loading={loading}
                rows={detailsRows}
                columns={ColumnsDetailsNoos(columnNames)}
                defaultColDef={DefaultColumnOptionsDetails}
                onCellValuesChanged={handleCellValuesChanges}
                suppressAggFuncInHeader
            />
            <InheritanceTable moduleString="noosShare" />
        </Stack>
    );
};

export default NoosShare;
