import { ColDef } from 'ag-grid-community';
import { EditablePercentageCell } from 'src/components/molecules/EditableCell/EditableCell';
import { IsEditable } from './is-editable';

const DefaultColumn: ColDef = {
    suppressAutoSize: true,
    suppressHeaderMenuButton: true,
};

const DefaultColumnEditable: ColDef = {
    ...DefaultColumn,
    cellRenderer: EditablePercentageCell,
    editable: IsEditable,
    type: 'numericColumn',
};

export const DefaultColumnsOverview: ColDef[] = [
    {
        ...DefaultColumn,
        field: 'category',
        width: 150,
        headerName: 'Category',
        suppressSizeToFit: true,
    },
    {
        ...DefaultColumnEditable,
        field: 'january',
        headerName: 'January',
    },
    {
        ...DefaultColumnEditable,
        field: 'february',
        headerName: 'February',
    },
    {
        ...DefaultColumnEditable,
        field: 'march',
        headerName: 'March',
    },
    {
        ...DefaultColumnEditable,
        field: 'april',
        headerName: 'April',
    },
    {
        ...DefaultColumnEditable,
        field: 'may',
        headerName: 'May',
    },
    {
        ...DefaultColumnEditable,
        field: 'june',
        headerName: 'June',
    },
    {
        ...DefaultColumnEditable,
        field: 'july',
        headerName: 'July',
    },
    {
        ...DefaultColumnEditable,
        field: 'august',
        headerName: 'August',
    },
    {
        ...DefaultColumnEditable,
        field: 'september',
        headerName: 'September',
        type: 'numericColumn',
    },
    {
        ...DefaultColumnEditable,
        field: 'october',
        headerName: 'October',
        type: 'numericColumn',
    },
    {
        ...DefaultColumnEditable,
        field: 'november',
        headerName: 'November',
        type: 'numericColumn',
    },
    {
        ...DefaultColumnEditable,
        field: 'december',
        headerName: 'December',
        type: 'numericColumn',
    },
];
