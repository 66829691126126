import { Badge, Stack, Typography, useTheme } from '@mui/material';
import { ICellRendererParams } from 'ag-grid-community';
import { FC } from 'react';
import { usePromise } from 'src/hooks/usePromise';
import { LightTooltip } from '../LightToolTip/LightToolTip';
import { ListCellRendererToolTip } from './ListCellRendererToolTip';

export interface ListCellRendererProps extends ICellRendererParams<unknown, string[]> {
    visibleCount?: number;
    onlyShowMatchesWhenFiltering?: boolean;
}

export const ListCellRenderer: FC<ListCellRendererProps> = ({
    value,
    api,
    column,
    visibleCount = 1,
    onlyShowMatchesWhenFiltering = false,
}) => {
    const theme = useTheme();

    let filteredValue = value;

    //! FIXME: When we upgrade to react 19, we should replace this with use(...) hook instead
    const columnFilter = usePromise(column && api.getColumnFilterInstance(column));
    if (onlyShowMatchesWhenFiltering && column && columnFilter?.isFilterActive()) {
        const filterModel = api.getFilterModel();
        const filterValue = filterModel?.[column.getColId()]?.values;
        filteredValue = value?.filter((item) => filterValue?.includes(item)) ?? value;
    }

    const visible = filteredValue?.slice(0, visibleCount) ?? [];
    const hidden = filteredValue?.slice(visibleCount) ?? [];

    return (
        <LightTooltip title={<ListCellRendererToolTip items={filteredValue} />}>
            <Stack direction="row" alignItems={'center'} width="100%" gap={'0.5rem'} data-testid="ListCellRenderer">
                {visible.length > 0 && (
                    <>
                        {visible.map((item) => (
                            <Typography
                                key={item}
                                fontSize={'inherit'}
                                textOverflow={'ellipsis'}
                                flex="1"
                                data-testid="visible-item"
                            >
                                {item}
                            </Typography>
                        ))}
                        {hidden.length > 0 && (
                            <Badge
                                data-testid="badge"
                                badgeContent={`+${hidden.length}`}
                                sx={{
                                    flexShrink: '1',
                                    '.MuiBadge-badge': {
                                        backgroundColor: theme.palette.grey['300'],
                                        position: 'static',
                                        transform: 'none',
                                    },
                                }}
                            />
                        )}
                    </>
                )}
            </Stack>
        </LightTooltip>
    );
};
