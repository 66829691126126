import { useCallback, useState } from 'react';
import { BulkEditRow } from 'src/components/organisms/Drawer/BulkEditDrawer/BulkEditDrawerSection';
import { Module, ScopeEnum } from 'src/domain';
import { useClusterId } from 'src/hooks/cluster';
import { useDiscardChangesModule } from 'src/hooks/discard-changes/useDiscardChangesModule';
import { useInheritance } from 'src/hooks/inheritance';
import { useCompositePartner } from 'src/hooks/partner/useCompositePartner';
import { useChangesSnackbar } from 'src/hooks/snackbar/useChangesSnackbar';
import { useUpdateSplitLevel } from 'src/hooks/split-levels/useUpdateSplitLevel';
import { useStoreId } from 'src/hooks/store';
import { useScope } from 'src/hooks/useScope';
import { useSelectedPartner } from 'src/hooks/useSelectedPartner';
import { UpdateOperationalResponsibleTurnoverInput } from 'src/infrastructure/rest-api/api-types';
import { useApiMutation } from 'src/infrastructure/rest-api/useApi';
import { useTurnoverQuery } from '../queries/useTurnoverQuery';
import { useTurnoverSimulationRows } from '../simulation/useTurnoverSimulationRows';

export const useTurnoverSaveChanges = (input?: BulkEditRow[]) => {
    const scope = useScope();
    const partnerComposite = useCompositePartner();
    const [partner] = useSelectedPartner();
    const clusterId = useClusterId(partner);
    const storeId = useStoreId();
    const { partnerRefetch } = useTurnoverQuery() ?? {};

    const [updateStoreTurnover] = useApiMutation('/api/turnover/stores', 'put', {
        update(data, _variables, queryClient) {
            queryClient.setQueryData([`/api/turnover/stores/${storeId}`, undefined], data);
            queryClient.invalidateQueries({ queryKey: [`/api/turnover/partners/${partnerComposite?.id}`] });
            queryClient.invalidateQueries({ queryKey: [`/api/turnover/clusters/${clusterId}`] });
        },
    });

    const [bulkUpdateTurnover] = useApiMutation('/api/turnover/partners/{partnerId}', 'put');

    const { updateSplitLevel: updateStoreSplitLevel } = useUpdateSplitLevel();

    const inheritance = useInheritance();

    const showSnackbar = useChangesSnackbar();
    const discardChanges = useDiscardChangesModule(Module.Turnover);

    const { data: valuesToUpdate } = useTurnoverSimulationRows();
    const [isUpdating, setIsUpdating] = useState(false);

    const sendUpdateQuery = useCallback(async () => {
        if (!valuesToUpdate || !inheritance) {
            return;
        }

        switch (scope) {
            case ScopeEnum.STORE:
                await updateStoreSplitLevel(inheritance);

                return updateStoreTurnover({
                    body: valuesToUpdate.map((row) => ({
                        id: row.id,
                        salesiVLcy: row.plannedSalesIv,
                    })),
                });

            case ScopeEnum.PARTNER:
                if (!partnerComposite || !input || !input?.length) return;
                return bulkUpdateTurnover({
                    path: { partnerId: partnerComposite.id },
                    body: input?.reduce<UpdateOperationalResponsibleTurnoverInput[]>((acc, row) => {
                        if (row.value === null || row.value === undefined) return acc;
                        acc.push({
                            monthKey: row.monthKey,
                            percentageChange: Number(row.value),
                        });
                        return acc;
                    }, []),
                }).then(() => {
                    if (partnerRefetch) {
                        return partnerRefetch();
                    }
                });
        }

        await updateStoreSplitLevel(inheritance);

        return updateStoreTurnover({
            body: valuesToUpdate.map((row) => ({
                id: row.id,
                salesiVLcy: row.plannedSalesIv,
            })),
        });
    }, [
        valuesToUpdate,
        inheritance,
        scope,
        updateStoreSplitLevel,
        updateStoreTurnover,
        partnerComposite,
        input,
        bulkUpdateTurnover,
        partnerRefetch,
    ]);

    const updateTurnover = useCallback(async () => {
        setIsUpdating(true);

        try {
            const response = await sendUpdateQuery();
            discardChanges();
            showSnackbar();
            return response;
        } catch {
            showSnackbar(true);
        } finally {
            setIsUpdating(false);
        }
    }, [discardChanges, sendUpdateQuery, showSnackbar]);

    return [updateTurnover, { loading: isUpdating }] as const;
};
