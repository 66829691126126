import { Delete, Save, SaveAs } from '@mui/icons-material';
import {
    Box,
    Button,
    Divider,
    FormControl,
    FormHelperText,
    IconButton,
    InputLabel,
    ListItemText,
    MenuItem,
    Select,
    SelectChangeEvent,
    styled,
} from '@mui/material';
import { GridApi } from 'ag-grid-community';
import { useState } from 'react';
import SaveSettingsDialog from 'src/components/molecules/SaveSettingsDialog/SaveSettingsDialog';
import { Module } from 'src/domain';
import { useDialog } from 'src/hooks/useDialog';
import { useSaveUserSettings } from 'src/hooks/useSaveUserSettings';
import { MergedUserSettingModel, useUserSettings } from 'src/hooks/useUserSettings';
import { ConfirmationDialog } from '../ConfirmationDialog/ConfirmationDialog';

interface SettingsDropdownProps {
    gridApi: GridApi | null;
    module?: Module;
}
const SettingsDropdown = ({ gridApi, module }: SettingsDropdownProps) => {
    const { mergedSettings } = useUserSettings(module);

    const [selectedSettingName, setSelectedSettingName] = useState<string>('');
    const [settingToDelete, setSettingToDelete] = useState<MergedUserSettingModel | null>(null);
    const { saveUserSettings, updateUserSettings, deleteUserSettings, isSaving } = useSaveUserSettings(module);
    const { isOpen: isSaveDialogOpen, openDialog: openSaveDialog, closeDialog: closeSaveDialog } = useDialog();
    const { isOpen: isDeleteDialogOpen, openDialog: openDeleteDialog, closeDialog: closeDeleteDialog } = useDialog();

    const handleSelectSetting = (e: SelectChangeEvent) => {
        const selectedName = e.target.value;
        if (!selectedName) return;

        setSelectedSettingName(selectedName);

        const selectedSetting = mergedSettings.find((setting) => setting.name === selectedName);
        if (gridApi && selectedSetting) {
            if (selectedSetting.filterSettings) {
                const filterModel = JSON.parse(selectedSetting.filterSettings);
                gridApi.setFilterModel(filterModel);
            }
            if (selectedSetting.columnSettings) {
                const columnState = JSON.parse(selectedSetting.columnSettings);
                gridApi.applyColumnState({ state: columnState, applyOrder: true });
            }
        }
    };

    const clearSettings = () => {
        setSelectedSettingName('');
        if (gridApi) {
            gridApi.setFilterModel(null);
            gridApi.resetColumnState();
        }
    };

    const handleSave = async () => {
        if (gridApi) {
            if (selectedSettingName) {
                const selectedSetting = mergedSettings.find((setting) => setting.name === selectedSettingName);
                if (!selectedSetting) return;

                const currentFilterModel = gridApi.getFilterModel();
                const currentColumnState = gridApi.getColumnState();

                const updatePromises = [];

                if (selectedSetting.filterSettingsId) {
                    updatePromises.push(
                        await updateUserSettings(
                            selectedSetting.filterSettingsId,
                            selectedSettingName,
                            JSON.stringify(currentFilterModel)
                        )
                    );
                } else {
                    updatePromises.push(
                        await saveUserSettings(selectedSettingName, JSON.stringify(currentFilterModel), 'Filters')
                    );
                }

                if (selectedSetting.columnSettingsId) {
                    updatePromises.push(
                        await updateUserSettings(
                            selectedSetting.columnSettingsId,
                            selectedSettingName,
                            JSON.stringify(currentColumnState)
                        )
                    );
                } else {
                    updatePromises.push(
                        await saveUserSettings(selectedSettingName, JSON.stringify(currentColumnState), 'Columns')
                    );
                }

                return await Promise.all(updatePromises).finally(() => closeSaveDialog());
            } else {
                openSaveDialog();
            }
        }
    };

    const handleSaveAs = () => {
        if (gridApi) {
            openSaveDialog();
        }
    };

    const handleSaveSetting = async (filterName: string) => {
        if (!gridApi) return;

        const promises = [];
        const currentFilterModel = gridApi.getFilterModel();
        const currentColumnState = gridApi.getColumnState();

        if (currentFilterModel) {
            promises.push(await saveUserSettings(filterName, JSON.stringify(currentFilterModel), 'Filters'));
        }

        if (currentColumnState) {
            promises.push(await saveUserSettings(filterName, JSON.stringify(currentColumnState), 'Columns'));
        }

        return await Promise.all(promises)
            .then(() => {
                setSelectedSettingName(filterName);
            })
            .finally(() => {
                closeSaveDialog();
            });
    };

    const handleDeleteButtonClick = (setting: MergedUserSettingModel, event: React.MouseEvent) => {
        event.stopPropagation();
        setSettingToDelete(setting);
        openDeleteDialog();
    };

    const handleDeleteSetting = async () => {
        if (!settingToDelete) return;

        const deletePromises = [];

        if (settingToDelete.filterSettingsId) {
            deletePromises.push(await deleteUserSettings(settingToDelete.filterSettingsId));
        }

        if (settingToDelete.columnSettingsId) {
            deletePromises.push(await deleteUserSettings(settingToDelete.columnSettingsId));
        }

        return await Promise.all(deletePromises)
            .then(() => {
                clearSettings();
            })
            .finally(() => {
                closeDeleteDialog();
            });
    };
    return (
        <>
            <Box>
                <FormControl size="small">
                    <InputLabel>Filter and column settings</InputLabel>
                    <Select
                        size="small"
                        sx={{ width: '250px', height: '40px' }}
                        value={selectedSettingName}
                        onChange={handleSelectSetting}
                        label="Filter and column settings"
                    >
                        {mergedSettings.map((setting) => (
                            <CustomMenuItem key={setting.name} value={setting.name}>
                                <ListItemText>{setting.name}</ListItemText>

                                <CustomDeleteButton
                                    className="delete-icon"
                                    edge="end"
                                    onClick={(event) => {
                                        handleDeleteButtonClick(setting, event);
                                    }}
                                >
                                    <Delete fontSize="small" />
                                </CustomDeleteButton>
                            </CustomMenuItem>
                        ))}

                        <Divider />
                        <MenuItem sx={{ paddingY: 0 }} onClick={handleSave} disabled={isSaving}>
                            <Button variant="text" startIcon={<Save />}>
                                Save
                            </Button>
                        </MenuItem>
                        <MenuItem sx={{ paddingY: 0 }} onClick={handleSaveAs}>
                            <Button variant="text" startIcon={<SaveAs />}>
                                Save as...
                            </Button>
                        </MenuItem>
                    </Select>
                    {selectedSettingName && (
                        <FormHelperText sx={{ display: 'flex', justifyContent: 'flex-end', margin: '0px 4px' }}>
                            <Button sx={{ padding: 0, textDecoration: 'underline' }} onClick={clearSettings}>
                                Clear settings
                            </Button>
                        </FormHelperText>
                    )}
                </FormControl>
            </Box>

            <SaveSettingsDialog
                open={isSaveDialogOpen}
                onClose={closeSaveDialog}
                onSave={handleSaveSetting}
                isSaving={isSaving}
            />
            <ConfirmationDialog
                open={isDeleteDialogOpen}
                title="Delete Setting"
                content={`Are you sure you want to delete setting "${settingToDelete?.name}", this action can not be undone.`}
                onCancel={closeDeleteDialog}
                onOk={handleDeleteSetting}
            />
        </>
    );
};
export default SettingsDropdown;

const CustomMenuItem = styled(MenuItem)(() => ({
    maxWidth: '250px',
    minHeight: '40px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '&:hover .delete-icon': {
        visibility: 'visible',
        display: 'inline-flex',
    },
    '.delete-icon': {
        visibility: 'hidden',
        display: 'none',
    },
}));

const CustomDeleteButton = styled(IconButton)(() => ({
    visibility: 'hidden',
    display: 'none',
    padding: '2px',
}));
