import { ColDef, ICellRendererParams, ValueFormatterParams } from 'ag-grid-community';

export function averageCellRenderer(column: ColDef, params: ICellRendererParams) {
    // Apparently the getGroupRowAgg is calculated but not displayed, so we handle it manually
    if (params.node.group) {
        const [_, month] = (params.colDef?.field ?? '').split('.');
        return typeof params.colDef?.valueFormatter === 'function'
            ? params.colDef.valueFormatter({
                  ...params,
                  value: params.node.aggData[month],
              } as ValueFormatterParams)
            : params.node.aggData[month];
    }

    return column.cellRenderer(params);
}
