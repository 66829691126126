import { Gender } from 'src/domain';

export const GenderProductLineDelimiter = '-';

export const combineGenderAndProductline = (
    gender: Gender | undefined | null,
    productLineName: string | undefined | null
) => {
    return productLineName + GenderProductLineDelimiter + gender;
};
