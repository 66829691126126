import React, { createContext, FC, PropsWithChildren, useState } from 'react';

export const MockContext = createContext<
    [Record<string, unknown>, React.Dispatch<React.SetStateAction<Record<string, unknown>>>]
>([
    {},
    // biome-ignore lint/suspicious/noEmptyBlockStatements: <explanation>
    () => {},
]);

export const MockProvider: FC<PropsWithChildren<unknown>> = ({ children }) => {
    const contextValue = useState({});
    return <MockContext.Provider value={contextValue}>{children}</MockContext.Provider>;
};
