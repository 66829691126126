import { styled } from '@mui/material';
import { CustomCellEditorProps } from 'ag-grid-react';
import { isNumber } from 'lodash';
import { ChangeEvent, FC, useCallback, useEffect, useRef } from 'react';
import { StyleTimelineExitWeekType } from 'src/infrastructure/rest-api/style-timeline';

export const LifeSpanCellEditor: FC<CustomCellEditorProps> = ({ value, eventKey, onValueChange }) => {
    const inputRef = useRef<HTMLInputElement>(null);

    const handleChange = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            const lifeSpan = event.target.value ? Number(event.target.value) : null;
            const exitWeekType: StyleTimelineExitWeekType = isNumber(lifeSpan) ? 'WEEK_KEY' : 'EMPTY';

            onValueChange({
                lifeSpan,
                exitWeekType,
            });
        },
        [onValueChange]
    );

    useEffect(() => {
        if (!eventKey) {
            return;
        }
        const eventKeyAsNumber = Number(eventKey);
        if (!isNaN(eventKeyAsNumber)) {
            onValueChange({
                lifeSpan: eventKeyAsNumber,
                exitWeekType: 'WEEK_KEY',
            });
        }
    }, [eventKey, onValueChange]);

    useEffect(() => {
        inputRef.current?.select();
    }, []);

    return <LifeSpanInput ref={inputRef} type="text" autoFocus value={value?.lifeSpan ?? ''} onChange={handleChange} />;
};

const LifeSpanInput = styled('input')`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
`;
