import { useReactiveVar } from '@bestseller-bit/retail-planning.utils.reactive-vars';
import styled from '@emotion/styled';
import { History, Loop, PriorityHigh } from '@mui/icons-material';
import { Box } from '@mui/material';
import { ColDef, IDetailCellRendererParams } from 'ag-grid-community';
import { format } from 'date-fns';
import { useMemo } from 'react';
import { ComparableCell } from 'src/components/molecules/ComparableCell/ComparableCell';
import { IconCell } from 'src/components/molecules/IconCell/IconCell';
import { DefaultColumn } from 'src/components/organisms/DataTable/columns/default-columns-details';
import { ScopeEnum } from 'src/domain';
import { useScope } from 'src/hooks/useScope';
import { useStoreType } from 'src/hooks/useStoreType';
import { comparableStoreTypeVar } from 'src/infrastructure/local_state';
import { FccSalesBudgetModel } from 'src/infrastructure/rest-api/api-types';
import { ExpectedSalesDataComparable, mapTurnoverToTableData } from 'src/mapping/turnover-mapping';
import { numberWithSpaces } from 'src/utils/numberWithSpaces';
import { useTurnoverQuery } from '../queries/useTurnoverQuery';

export const useTurnoverRows = () => {
    const { expectedSalesComparable, loading, error } = useTurnoverQuery() ?? {};
    const [comparableStoreType] = useReactiveVar(comparableStoreTypeVar);
    const tableData = useMemo(
        () => mapTurnoverToTableData(expectedSalesComparable as ExpectedSalesDataComparable, comparableStoreType),
        [expectedSalesComparable, comparableStoreType]
    );
    const storeType = useStoreType();
    const scope = useScope();

    const columns = useMemo(
        (): ColDef[] => [
            {
                ...DefaultColumn,
                field: 'month',
                width: 175,
                headerName: 'Month',
            },
            {
                ...DefaultColumn,
                type: 'numericColumn',
                field: 'fccInitial',
                width: 125,
                headerName: 'FCC Initial iV',
                headerTooltip: 'FCC Initial including VAT',
                hide: storeType !== 'OWN_STORE',
                cellRenderer: IconCell,
                cellRendererParams: (params: IDetailCellRendererParams) => {
                    const historicalInitBudgets = params.data.historicalFccBudgets.filter(
                        (budget: FccSalesBudgetModel) => budget.budgetSource === 'BudgetInit'
                    );
                    return {
                        showIcon:
                            scope === ScopeEnum.STORE
                                ? shouldShowIcon(params?.data?.fccInitialBudgetYear, params?.data?.fccInitialReused)
                                : false,
                        tooltip: historicalInitBudgets.length
                            ? getHistoricalFccBudgetsTooltip(historicalInitBudgets)
                            : getToolTip(params?.data?.fccInitialBudgetYear),
                        icon: historicalInitBudgets.length ? (
                            <HistoryIcon />
                        ) : (
                            getIcon(params?.data?.fccInitialBudgetYear)
                        ),
                    };
                },
            },
            {
                ...DefaultColumn,
                type: 'numericColumn',
                field: 'fccRevised',
                width: 135,
                headerName: 'FCC Revised iV',
                headerTooltip: 'FCC Revised including VAT',
                hide: storeType !== 'OWN_STORE',
                cellRenderer: IconCell,
                cellRendererParams: (params: IDetailCellRendererParams) => {
                    const historicalRevBudgets = params.data.historicalFccBudgets.filter(
                        (budget: FccSalesBudgetModel) => budget.budgetSource === 'BudgetRev'
                    );
                    return {
                        showIcon:
                            scope === ScopeEnum.STORE
                                ? shouldShowIcon(params?.data?.fccRevisedBudgetYear, params?.data?.fccRevisedReused)
                                : false,
                        tooltip: historicalRevBudgets.length
                            ? getHistoricalFccBudgetsTooltip(historicalRevBudgets)
                            : getToolTip(params?.data?.fccRevisedBudgetYear),
                        icon: historicalRevBudgets.length ? (
                            <HistoryIcon />
                        ) : (
                            getIcon(params?.data?.fccRevisedBudgetYear)
                        ),
                    };
                },
            },
            {
                ...DefaultColumn,
                type: 'numericColumn',
                field: 'salesIvLly',
                width: 140,
                headerName: 'Sales iV LLY',
                headerTooltip: 'Sales iV LLY',
            },
            {
                ...DefaultColumn,
                type: 'numericColumn',
                field: 'salesIvLy',
                width: 140,
                headerName: 'Sales iV LY',
                headerTooltip: 'Sales Iv LY',
                valueGetter: (params) => {
                    return (params.node?.rowIndex ?? 0) < 12 ? params.data.salesIvLy : undefined;
                },
            },
            {
                ...DefaultColumn,
                type: 'numericColumn',
                field: 'indexLy',
                width: 110,
                headerName: 'Index LY',
                headerTooltip: 'Index LY',
                valueGetter: (params) => {
                    return (params.node?.rowIndex ?? 0) < 12 ? params.data.indexLy : undefined;
                },
            },
            {
                ...DefaultColumn,
                type: 'numericColumn',
                field: 'plannedSalesIv',
                width: 180,
                headerName: 'Planned Sales iV',
                headerTooltip: 'Planned Sales iV',
                cellRenderer: ComparableCell,
            },
            {
                ...DefaultColumn,
                type: 'numericColumn',
                field: 'plannedIndex',
                width: 155,
                headerName: 'Planned Index',
                headerTooltip: 'Planned Index to LY',
                cellRenderer: ComparableCell,
            },
        ],
        [scope, storeType]
    );

    return { data: tableData, columns, error, loading };
};

const ReusedIcon = styled(Loop)`
  font-size: 14px;
`;

const AlertIcon = styled(PriorityHigh)`
  font-size: 14px;
`;
const HistoryIcon = styled(History)`
  font-size: 14px;
`;

const getIcon = (year: number): JSX.Element => {
    if (!year) return <AlertIcon />;
    return <ReusedIcon />;
};

const getToolTip = (year: number): string => {
    if (!year) return 'No historical value found';
    return `Reused value: ${year}`;
};

const getHistoricalFccBudgetsTooltip = (budgets: FccSalesBudgetModel[]) => {
    return (
        <Box>
            <TooltipRow>
                <TooltipHeader sx={{ flexGrow: 1 }}>Date</TooltipHeader>
                <TooltipHeader>Amount</TooltipHeader>
            </TooltipRow>
            {budgets.map((budget, key: number) => (
                <TooltipRow key={key}>
                    <Box>{format(new Date(budget.budgetStartDate), 'dd-MM-y')}</Box>
                    <Box>{numberWithSpaces(Math.round(budget.amountLcy as number))}</Box>
                </TooltipRow>
            ))}
        </Box>
    );
};

const shouldShowIcon = (year: number, reused: boolean): boolean => {
    return reused || !year;
};

const TooltipRow = styled(Box)`
  display: flex;
  gap: 8px;
  align-items: center;
`;

const TooltipHeader = styled(Box)`
  font-weight: bold;
`;
