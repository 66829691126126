import { useReactiveVar } from '@bestseller-bit/retail-planning.utils.reactive-vars';
import { useMemo } from 'react';
import { GroupLevel } from 'src/domain';
import { countryIdToNameListVar } from 'src/infrastructure/local_state';
import { useQueryParams } from './useQueryParams';
import { useType } from './useType';

export const useFilteredStoresParentName = () => {
    const type = useType();
    const { countryId, cluster, partner } = useQueryParams();
    const [countryIdToNameList] = useReactiveVar(countryIdToNameListVar);

    return useMemo(() => {
        switch (type) {
            case GroupLevel.Country:
                return countryIdToNameList.get(Number(countryId)) ?? '';
            case GroupLevel.Cluster: {
                return cluster ? `Cluster ${cluster}` : '';
            }
            default:
                return partner ?? '';
        }
    }, [type, countryId, countryIdToNameList, partner, cluster]);
};
