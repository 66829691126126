import { ScopeEnum } from 'src/domain';
import { useClusterId } from 'src/hooks/cluster';
import { useScope } from 'src/hooks/useScope';
import { useSelectedPartner } from 'src/hooks/useSelectedPartner';
import { useApiQuery } from 'src/infrastructure/rest-api/useApi';

export const useClusterOverviewTurnover = () => {
    const scope = useScope();
    const [partner] = useSelectedPartner();
    const clusterId = useClusterId(partner);

    const { data, loading, error, refetch } = useApiQuery('/api/turnover/clusters/{clusterId}', 'get', {
        path: {
            clusterId: clusterId,
        },
        enabled: !!clusterId && scope === ScopeEnum.CLUSTER,
    });

    return { data, loading, error, refetch };
};
