import { Stack } from '@mui/material';
import { FC, useMemo, useRef } from 'react';
import Container from 'src/components/atoms/Container';
import { CustomAgGridTooltip } from 'src/components/atoms/CustomAgGridTooltip/CustomAgGridTooltip';
import Header from 'src/components/atoms/Header';
import { DataTable } from 'src/components/organisms/DataTable/DataTable';
import { OverviewColumnOpenToBuyLimitRowGroup } from 'src/components/organisms/DataTable/columns/colums-overview-opentobuylimit-row-group';
import { DefaultColumnsOptionsOverviewPercentage } from 'src/components/organisms/DataTable/columns/default-options';
import { defaultRowGroupOptions } from 'src/components/organisms/DataTable/options/row-group-options';
import { validateSumIs100Percent } from 'src/components/organisms/DataTable/validators/validateSumIs100Percent';
import Topbar from 'src/components/organisms/Topbar';
import { StyledHeaderWrapper } from 'src/components/styled/StyledHeaderWrapper';
import { TableWrapper } from 'src/components/styled/TableWrapper';
import { Headings, Module } from 'src/domain';
import { useUnsavedChangesModule } from 'src/hooks';
import { useDiscardChangesModule } from 'src/hooks/discard-changes/useDiscardChangesModule';
import { useCanSave } from 'src/hooks/open-to-buy-limit/save-changes/useCanSave';
import { useOpenToBuyLimitSaveChanges } from 'src/hooks/open-to-buy-limit/save-changes/useOpenToBuyLimitSaveChanges';
import { useOpenToBuyLimitOverviewSimulationRows } from 'src/hooks/open-to-buy-limit/simulation/useOpenToBuyLimitOverviewSimulationRow';
import { useOpenToBuyLimitHandleCellValueChanged } from '../../../hooks/open-to-buy-limit/useOpenToBuyLimitHandleCellValueChanged';

export const OpenToBuyLimit: FC = () => {
    const {
        data: overviewRows,
        columns: monthColumns,
        loading: rowsLoading,
    } = useOpenToBuyLimitOverviewSimulationRows();

    const tableWrapperRef = useRef<HTMLDivElement | null>(null);

    const defaultColDef = useMemo(() => {
        return {
            ...DefaultColumnsOptionsOverviewPercentage,
            initialWidth: 100,
        };
    }, []);

    const onDiscardChanges = useDiscardChangesModule(Module.OpenToBuyLimit);
    const hasUnsavedChanges = useUnsavedChangesModule(Module.OpenToBuyLimit);
    const canSave = useCanSave();

    const [saveChanges, { loading: isSaving }] = useOpenToBuyLimitSaveChanges();
    const handleCellValueChanged = useOpenToBuyLimitHandleCellValueChanged();

    const rowGroupOptions = useMemo(() => {
        return { ...defaultRowGroupOptions, groupDefaultExpanded: 0, groupRemoveSingleChildren: false };
    }, []);

    const columns = useMemo(() => {
        return [...OverviewColumnOpenToBuyLimitRowGroup, ...(monthColumns ?? [])];
    }, [monthColumns]);

    const loading = rowsLoading || isSaving;

    return (
        <Stack spacing={2}>
            <Topbar
                onDiscard={onDiscardChanges}
                discardDisabled={!hasUnsavedChanges}
                onSave={saveChanges}
                saveDisabled={!canSave}
                loading={loading}
                saveLoading={isSaving}
                hideToggleButtons={true}
            />
            <Container style={{ overflow: 'auto' }}>
                <StyledHeaderWrapper>
                    <Header heading={Headings.h2}>Open To Buy Limit</Header>
                </StyledHeaderWrapper>
                <TableWrapper
                    className="ag-theme-alpine-custom-compact ag-otbl-row-group ag-years"
                    key={'otbl-overview'}
                    ref={tableWrapperRef}
                >
                    <DataTable
                        headerHeight={60}
                        {...rowGroupOptions}
                        suppressColumnVirtualisation={true}
                        autoGroupColumnDef={{
                            headerName: 'Events',
                            field: 'buyingEvent',
                            cellRenderer: 'agGroupCellRenderer',
                            cellRendererParams: {
                                rowGroupField: 'category',
                            },
                            width: 230,
                            suppressSizeToFit: true,
                            tooltipField: 'buyingEvent',
                            tooltipComponent: CustomAgGridTooltip,
                            headerClass: '',
                        }}
                        rows={overviewRows}
                        columns={columns}
                        loading={loading}
                        defaultColDef={defaultColDef}
                        onCellValuesChanged={handleCellValueChanged}
                        cellValidationOptions={{
                            columns: true,
                            validationFunc: validateSumIs100Percent,
                        }}
                        autoSizeToFit
                        columnMinWidth={100}
                    />
                </TableWrapper>
            </Container>
        </Stack>
    );
};
