import { useCallback } from 'react';
import { useLocation } from 'react-router';
import { useQueryParams, useType } from 'src/hooks';
import { StoreModel } from 'src/infrastructure/rest-api/api-types';
import { buildPathFromUrl } from './buildPath';

export function useBuild() {
    const { cluster, partnerId, countryId } = useQueryParams();
    const { storeType } = useQueryParams();
    const url = useLocation();
    const type = useType();

    return useCallback(
        (store: StoreModel) => {
            return {
                pathname: url.pathname,
                search: buildPathFromUrl(
                    store.chain?.id.toString() ?? '',
                    storeType ?? '',
                    type ?? '',
                    store.id.toString(),
                    countryId ?? '',
                    cluster ?? '',
                    partnerId ?? ''
                ),
            };
        },
        [cluster, countryId, partnerId, storeType, type, url.pathname]
    );
}
