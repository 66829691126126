import styled from '@emotion/styled';
import { Button, Grid } from '@mui/material';
import { FC } from 'react';
import Container from 'src/components/atoms/Container';
import Header from 'src/components/atoms/Header';
import Loader from 'src/components/atoms/Loader';
import { Color, Headings, Partner } from 'src/domain';
import { useStoreType } from 'src/hooks/useStoreType';

interface Props {
    partners: Partner[] | undefined;
    onPartnerSelected: (partner: Partner) => void;
    isLoading?: boolean;
}

export const SelectPartner: FC<Props> = ({ partners, onPartnerSelected, isLoading = false }) => {
    const storeType = useStoreType();

    return (
        <Wrapper>
            {isLoading && <Loader />}
            {!isLoading && (
                <>
                    <H2 heading={Headings.h2}>
                        Please select which{' '}
                        {storeType === 'WHOLESALE' ? 'business trading partner' : 'operational responsible'} you would
                        like to view:
                    </H2>
                    <Grid direction={'row'} container gap={1} justifyContent="center">
                        {partners
                            ?.sort((partnerA, partnerB) => partnerA.name?.localeCompare(partnerB.name ?? '') ?? -1)
                            ?.map((partner) => (
                                <PartnerButton
                                    key={partner?.compositeId}
                                    onClick={() => {
                                        onPartnerSelected(partner);
                                    }}
                                >
                                    {partner.name} ({partner.country})
                                </PartnerButton>
                            ))}
                    </Grid>
                </>
            )}
        </Wrapper>
    );
};

const H2 = styled(Header)`
  margin-bottom: 40px;
`;

const Wrapper = styled(Container)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  min-width: 50vw;
`;

const PartnerButton = styled(Button)`
  margin-right: 10px;
  text-transform: none;
  background-color: ${Color.white};
  border: 1px solid ${Color.editBlue};
  color: ${Color.editBlue};
  &:hover {
    background-color: ${Color.editBlue};
    color: ${Color.white};
  }
`;
