import { useAccount, useMsal } from '@azure/msal-react';
import { useMemo } from 'react';
import { useIsStatusPage } from './useIsStatusPage';

export const useHasDevTools = () => {
    const isStatusPage = useIsStatusPage();
    const { accounts } = useMsal();
    const account = useAccount(accounts[0] || {});
    const isAdmin = (account?.idTokenClaims?.roles ?? []).includes('admin');
    const hasDevTools = useMemo(() => isAdmin && !isStatusPage, [isAdmin, isStatusPage]);

    return hasDevTools;
};
