import { useMemo } from 'react';
import { mapTurnoverToTableOverviewRow } from 'src/mapping/turnover-mapping';
import { useTurnoverSimulationRows } from '../simulation/useTurnoverSimulationRows';

export const useTurnoverOverviewSimulationRows = () => {
    const { data: rows, loading, error } = useTurnoverSimulationRows() ?? {};

    const data = useMemo(() => mapTurnoverToTableOverviewRow(rows), [rows]);

    return { data, error, loading };
};
