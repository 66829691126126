export enum Inheritance {
    Partner = 'Partner',
    Cluster = 'Cluster',
    Typed = 'Typed',
    Historical = 'Historical',
    NoosDDR = 'NoosDDR',
    NoosHistoricalOrders = 'NoosHistoricalOrders',
    FccInitial = 'FCC_INITIAL',
    FccRevised = 'FCC_REVISED',
    HistoricalLLY = 'HISTORICAL_LLY',
}
