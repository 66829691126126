import { useMemo } from 'react';
import { EditablePercentageCell } from 'src/components/molecules/EditableCell/EditableCell';
import { createDynamicMonthColumns } from 'src/components/organisms/DataTable/columns/utils';
import { Inheritance, Module } from 'src/domain';
import { useInheritance } from 'src/hooks/inheritance';
import { styleCategoriesToGroupedByData } from 'src/hooks/style-categories/queries/useStyleCategoriesQuery';
import { mapInheritanceToExpectedNew } from 'src/mapping/inheritance.mapping';
import { mapSplitToOverviewTableDataNew } from 'src/mapping/split-modules.mapping';
import { useMarkdownQuery } from '../queries/useMarkdownQuery';

export const useMarkdownOverviewRows = (inheritance?: Inheritance) => {
    const { expected, styleCategories, error, loading } = useMarkdownQuery(inheritance);

    const moduleInheritance = useInheritance(Module.Markdown);
    const inheritanceToUse = inheritance ?? moduleInheritance;
    const isHistorical = inheritanceToUse === Inheritance.Historical;

    const tableData = useMemo(() => {
        if (!inheritanceToUse) {
            return;
        }

        return mapSplitToOverviewTableDataNew(
            mapInheritanceToExpectedNew(expected, inheritanceToUse),
            styleCategoriesToGroupedByData(styleCategories),
            isHistorical
        );
    }, [expected, styleCategories, inheritanceToUse, isHistorical]);

    const columns = useMemo(
        () =>
            expected
                ? createDynamicMonthColumns(expected, {
                      cellRenderer: EditablePercentageCell,
                  })
                : [],
        [expected]
    );

    return { data: tableData, columns, error, loading };
};
