import { FC, useMemo } from 'react';
import { SwitchButton } from 'src/components/molecules/SwitchButton/SwitchButton';
import { StockMgmtTable } from 'src/domain/enums/stock-management-table';
import { useCurrentId } from 'src/hooks';
import { useDefaultTable } from 'src/hooks/stock-management/table/useDefaultTable';
import { useSelectedStockManagementTable } from 'src/hooks/useSelectedStockManagementTable';

export const StockManagementSwitchButton: FC = () => {
    const [selectedTable, setSelectedTable] = useSelectedStockManagementTable();
    const defaultTable = useDefaultTable();
    const id = useCurrentId();

    const handleSelectedTable = (selection: boolean) => {
        if (!id) return;
        const updatedTable = selectedTable.set(id, selection ? StockMgmtTable.Categories : StockMgmtTable.Total);
        setSelectedTable(new Map(updatedTable.entries()));
    };

    const activeTable = useMemo(() => {
        if (!id) return defaultTable;
        return selectedTable.get(id) ?? defaultTable;
    }, [defaultTable, id, selectedTable]);

    return (
        <SwitchButton
            label={'Plan on Categories'}
            active={activeTable === StockMgmtTable.Categories}
            handleSelection={handleSelectedTable}
        />
    );
};
