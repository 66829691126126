import { isNumber } from 'lodash';
import { Module, ScopeEnum } from 'src/domain';
import { OptimalOptionCountRow } from 'src/domain/table/optimal-option-count.row';
import { useUnsavedChangesModule } from 'src/hooks/unsaved-changes';
import { useScope } from 'src/hooks/useScope';
import {
    OptimalOptionCountStylesPer100SQMLabel,
    OptimalOptionCountTotalLabel,
} from 'src/mapping/optimal-option-count.mapping';
import { useOptimalOptionCountPlacementRows } from '../table/useOptimalOptionCountPlacementRows';

export const useOptimalOptionCountPlacementSimulationRows = () => {
    const [unsavedChanges] = useUnsavedChangesModule<OptimalOptionCountRow[]>(Module.OptimalOptionCount);
    const { data, loading, error } = useOptimalOptionCountPlacementRows() ?? {};

    const scope = useScope();

    const hasUnsavedChanges = !!unsavedChanges;
    const overviewRows = hasUnsavedChanges ? unsavedChanges : data;

    if (scope !== ScopeEnum.STORE) return { data: [], loading, error };

    const totalRowIndex = overviewRows?.findIndex((x) => x.category === 'Total');
    const updatedTotalRow = overviewRows.reduce<OptimalOptionCountRow>(
        (acc, curr) => {
            if (!curr.columns) return acc;

            if (curr.category === 'Total') {
                acc.category = curr.category;
                acc.id = curr.id;
                acc.isFooter = curr.isFooter;
                acc.storeId = curr.storeId;
                acc.storeName = curr.storeName;
                acc.subCategory = curr.subCategory;
            }

            Object.entries(curr.columns).forEach(([key, value]) => {
                if (
                    !isNumber(value) ||
                    curr.category === OptimalOptionCountTotalLabel ||
                    curr.category === OptimalOptionCountStylesPer100SQMLabel
                ) {
                    return;
                }

                if (curr.category === 'Total' && key.includes('Id')) {
                    acc.columns[key] = curr.columns[key];
                } else if (curr.category === 'Total') {
                    return;
                } else {
                    acc.columns[key] = (acc.columns[key] ?? 0) + value;
                }
            });
            return acc;
        },
        {
            columns: {},
        } as OptimalOptionCountRow
    );

    if (updatedTotalRow) overviewRows[totalRowIndex] = updatedTotalRow;

    return { data: overviewRows, loading, error };
};
