import { parseNumber } from 'src/utils/formatNumber';

declare global {
    interface String {
        isEmpty(): boolean;
        toUpperCaseLetters(noOfLetters?: number): string;
        capitalize(): string;
        includesInsensitive(s2: string | null | undefined): boolean;
        equalsInsensitive(s2: string | null | undefined): boolean;
        notEqualsInsensitive(s2: string | null | undefined): boolean;
        isNumeric(): boolean;
        toFloat(): number;
        trimChain(): string;
    }
}

if (!String.prototype.isEmpty) {
    String.prototype.isEmpty = function () {
        return this === '';
    };
}

if (!String.prototype.toUpperCaseLetters) {
    String.prototype.toUpperCaseLetters = function (noOfLetters = 1) {
        return this.substring(0, noOfLetters).toUpperCase() + this.substring(noOfLetters).toLowerCase();
    };
}

if (!String.prototype.capitalize) {
    String.prototype.capitalize = function () {
        const words = this.split(' ');

        words.forEach((word, index) => {
            words[index] = word.charAt(0).toUpperCase() + words[index].substring(1);
        });

        return words.join(' ');
    };
}

if (!String.prototype.includesInsensitive) {
    String.prototype.includesInsensitive = function (s2: string | null | undefined) {
        return s2 === null || s2 === undefined ? false : this.toLowerCase().includes(s2.toLowerCase());
    };
}

if (!String.prototype.equalsInsensitive) {
    String.prototype.equalsInsensitive = function (s2: string | null | undefined) {
        return s2 === null || s2 === undefined ? false : this.toLowerCase() === s2.toLowerCase();
    };
}

if (!String.prototype.notEqualsInsensitive) {
    String.prototype.notEqualsInsensitive = function (s2: string | null | undefined) {
        // safe null assertion
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        return !this.equalsInsensitive(s2);
    };
}

if (!String.prototype.includesInsensitive) {
    String.prototype.includesInsensitive = function (s2: string | null | undefined) {
        return s2 === null || s2 === undefined ? false : this.toLowerCase().includes(s2.toLowerCase());
    };
}

if (!String.prototype.isNumeric) {
    String.prototype.isNumeric = function () {
        return isFinite(parseFloat(this.toString()));
    };
}

if (!String.prototype.toFloat) {
    String.prototype.toFloat = function () {
        return parseNumber(this.replace(/\s+/g, ''));
    };
}

if (!String.prototype.trimChain) {
    String.prototype.trimChain = function () {
        return this.split('-').removeFirst().join('-').trim();
    };
}

export {};
