import { useCallback } from 'react';
import { useCompositePartner } from 'src/hooks/partner/useCompositePartner';
import { CreatePartnerStyleTimelineSettingInput } from 'src/infrastructure/rest-api/api-types';
import { useApiMutation } from 'src/infrastructure/rest-api/useApi';

export const useStyleTimelineSettingsSaveChanges = () => {
    const partnerComposite = useCompositePartner();

    const [updateSettings] = useApiMutation('/api/styletimelinesettings', 'post', {
        update(_data, _variables, queryClient) {
            const partnerId = partnerComposite?.id;
            const queryKey = [`/api/styletimelinesettings/partners/${partnerId}`];
            queryClient.invalidateQueries({ queryKey });
        },
    });

    const saveChanges = useCallback(
        (input: CreatePartnerStyleTimelineSettingInput) => {
            return updateSettings({
                body: input,
            });
        },
        [updateSettings]
    );

    return saveChanges;
};
