import { useMemo } from 'react';
import { mapExpectedSalesToVat } from 'src/mapping/turnover-mapping';
import { useTurnoverQuery } from '../queries/useTurnoverQuery';

export function useTurnoverVat() {
    const { expectedSales, loading } = useTurnoverQuery() ?? {};

    const data = useMemo(() => mapExpectedSalesToVat(expectedSales), [expectedSales]);

    return { data, loading };
}
