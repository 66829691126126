import { isNumber } from 'lodash';
import { TableOverviewRow } from 'src/domain';
import { StockManagementRow } from 'src/domain/table/stock-management.row';

export const categoriesSum = (rows: StockManagementRow[] | undefined) => {
    const sumMinId = rows?.length ?? -1;
    const sumMaxId = rows?.length ? rows.length + 1 : -1;
    const sumMin = calculateSum(rows, 'Minimum', sumMinId);
    const sumMax = calculateSum(rows, 'Maximum', sumMaxId);

    return [sumMin, sumMax];
};

const calculateSum = (rows: StockManagementRow[] | undefined, subCategory: string, id: number) => {
    if (!rows) {
        return {
            id,
            category: 'Total',
            subCategory,
            columns: {},
        } as TableOverviewRow;
    }

    return rows.reduce(
        (acc, row) => {
            if (row.category !== 'Total' && row.subCategory === subCategory) {
                Object.entries(row.columns).forEach(([key, value]) => {
                    if (isNumber(acc.columns[key]) && isNumber(value)) {
                        acc.columns[key]! += value;
                    } else if (isNumber(value)) {
                        acc.columns[key] = value;
                    }
                });
            }
            return acc;
        },
        {
            id,
            category: 'Total',
            subCategory,
            columns: {},
        } as StockManagementRow
    );
};
