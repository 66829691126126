import { camelCase } from 'change-case';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useMemo } from 'react';

/**
 * @description This hook can be used to check the state of a LaunchDarkly flag.
 * @param {FEATURE_FLAGS} flagKey - The key of the target flag
 * @example
 * ```ts
 * const hasFlagEnabled = useFlag('some-flag-key');
 * ```
 */
export const useFlag = (flagKey: string): boolean => {
    const flags = useFlags();

    const flagKeyFixedCasing = useMemo(() => camelCase(flagKey), [flagKey]);
    const isActive = useMemo(() => {
        return (flags[flagKeyFixedCasing] as boolean) || false;
    }, [flags, flagKeyFixedCasing]);

    return isActive;
};
