import { useMemo } from 'react';
import { Inheritance, Module, TableOverviewRow } from 'src/domain';
import { useGenderId, useInheritance, useProductLine } from 'src/hooks';
import { useCategorySplitOverviewSimulationRows } from 'src/hooks/category-split/simulation/useCategorySplitOverviewSimulationRows';
import { useUnsavedChangesModule } from 'src/hooks/unsaved-changes';
import { useGenderProductLineKey } from 'src/hooks/useGenderProductLineKey';
import { mapCategorySplitsToRows } from 'src/mapping/planned-splits.mapping';
import { useNoosShareOverviewRows } from '../table/useNoosShareOverviewRows';

export const useNoosShareOverviewSimulationRows = (inheritance?: Inheritance) => {
    const genderId = useGenderId(Module.NoosShare);
    const productLine = useProductLine(Module.NoosShare);

    const [unsavedChanges] = useUnsavedChangesModule<Record<string, TableOverviewRow[]>>(Module.NoosShare);
    const { data: categorySplitSimulationData } = useCategorySplitOverviewSimulationRows(
        undefined,
        genderId,
        productLine?.id
    );

    const { data, columns, loading, error } = useNoosShareOverviewRows(inheritance) ?? { loading: false };
    const genderProductLineKey = useGenderProductLineKey();

    const moduleInheritance = useInheritance(Module.NoosShare);
    const selectedInheritance = inheritance ?? moduleInheritance;

    const isTyped = selectedInheritance === Inheritance.Typed;

    const unsavedChangesGenderProductLine = unsavedChanges?.[genderProductLineKey];
    const hasUnsavedChanges = !!unsavedChangesGenderProductLine;
    const overviewRows = isTyped && hasUnsavedChanges ? unsavedChangesGenderProductLine : data;

    const tableData = useMemo(() => {
        if (!overviewRows || !categorySplitSimulationData) return;
        return mapCategorySplitsToRows(overviewRows, categorySplitSimulationData);
    }, [categorySplitSimulationData, overviewRows]);

    return { data: tableData, columns, loading, error };
};
