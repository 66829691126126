import { Inheritance, IntersectionOptionalFields } from 'src/domain';
import { MarkdownModel, MarkupModel, NoosShareExpectedModel } from 'src/infrastructure/rest-api/api-types';

export type ModuleExpected = {
    id: number;
    monthKey: number;
    splitPercent: number;
    styleCategoryId: number;
    modifiedByUser: boolean;
    splitLY: number;
    splitLLY: number;
    grossMarginLY: number;
    cogsLY?: number;
    cogsLLY?: number;
    grossSalesILY?: number;
    grossSalesILLY?: number;
    genderId?: number;
    productlineGroupId?: number;
};

export type ModuleExpectedNew = {
    id: number;
    monthKey: number;
    splitPct: number;
    styleCategoryId: number;
    modifiedByUser: boolean;
    splitPctLY: number;
    splitPctLLY: number;
    grossMarginPctLY: number;
    cogsLY?: number;
    cogsLLY?: number;
    netSalesLY?: number;
    grossSalesILY?: number;
    grossSalesILLY?: number;
};

type IntersectedModuleExpectedNew = IntersectionOptionalFields<MarkupModel, NoosShareExpectedModel, MarkdownModel>;

export const mapInheritanceToExpectedNew = (
    data: IntersectedModuleExpectedNew[] | undefined | null,
    inheritance: Inheritance //selectedInheritance
): ModuleExpectedNew[] | undefined => {
    return data?.reduce<ModuleExpectedNew[]>((acc, expected, index) => {
        if (!expected) return acc;
        switch (inheritance) {
            case Inheritance.Cluster:
            case Inheritance.Partner:
                acc.push({
                    id: expected?.id ?? index,
                    monthKey: expected.monthKey,
                    splitPct: expected.value ?? expected.splitPct ?? expected.markdownPct ?? 0,
                    styleCategoryId: expected.styleCategoryId,
                    modifiedByUser: expected?.modifiedByUser ?? false,
                    grossSalesILY: expected.grossSalesILY,
                    grossSalesILLY: expected.grossSalesILLY,
                    splitPctLY: expected.valueLY ?? expected.noosShareLY ?? expected.markdownPctLY ?? 0,
                    splitPctLLY: expected.valueLLY ?? expected.noosShareLLY ?? 0,
                    grossMarginPctLY: expected.grossMarginDDRLY ?? expected.grossMarginPctLY ?? 0,
                    cogsLLY: expected.cogsLLY,
                    cogsLY: expected.cogsLY,
                    netSalesLY: expected.netSalesLY,
                });

                break;

            case Inheritance.Typed:
                acc.push({
                    id: expected?.id ?? index,
                    monthKey: expected.monthKey,
                    splitPct: expected.splitPct ?? expected.value ?? expected.markdownPct ?? 0,
                    styleCategoryId: expected.styleCategoryId,
                    modifiedByUser: expected?.modifiedByUser ?? false,
                    grossSalesILY: expected.grossSalesILY,
                    grossSalesILLY: expected.grossSalesILLY,
                    splitPctLY: expected.valueLY ?? expected.noosShareDDRLY ?? expected.markdownPctLY ?? 0,
                    splitPctLLY: expected.valueLLY ?? expected.noosShareDDRLLY ?? 0,
                    grossMarginPctLY: expected.grossMarginDDRLY ?? expected.grossMarginPctLY ?? 0,
                    cogsLLY: expected.cogsLLY,
                    cogsLY: expected.cogsLY,
                    netSalesLY: expected.netSalesLY,
                });

                break;

            case Inheritance.Historical:
                acc.push({
                    id: expected?.id ?? index,
                    monthKey: expected.monthKey,
                    splitPct: expected.valueLY ?? expected.noosShareLY ?? expected.markdownPctLY ?? 0,
                    styleCategoryId: expected.styleCategoryId,
                    modifiedByUser: false,
                    grossSalesILY: expected.grossSalesILY,
                    grossSalesILLY: expected.grossSalesILLY,
                    splitPctLY: expected.valueLY ?? expected.noosShareLY ?? expected.markdownPctLY ?? 0,
                    splitPctLLY: expected.valueLLY ?? expected.noosShareLLY ?? 0,
                    grossMarginPctLY: expected.grossMarginSalesLY ?? expected.grossMarginPctLY ?? 0,
                    cogsLLY: expected.cogsLLY,
                    cogsLY: expected.cogsLY,
                    netSalesLY: expected.netSalesLY,
                });
                break;

            case Inheritance.NoosHistoricalOrders:
                acc.push({
                    id: expected?.id ?? index,
                    monthKey: expected.monthKey,
                    splitPct: expected.noosShareOrdersLY ?? 0,
                    styleCategoryId: expected.styleCategoryId,
                    modifiedByUser: false,
                    grossSalesILY: expected.grossMarginSalesLY ?? 0,
                    grossSalesILLY: undefined,
                    splitPctLY: expected.noosShareOrdersLY ?? 0,
                    splitPctLLY: expected.noosShareOrdersLLY ?? 0,
                    grossMarginPctLY: 0,
                });
                break;

            case Inheritance.NoosDDR:
                acc.push({
                    id: expected?.id ?? index,
                    monthKey: expected.monthKey,
                    splitPct: expected.noosShareDDRLY ?? 0,
                    styleCategoryId: expected.styleCategoryId,
                    modifiedByUser: false,
                    splitPctLY: expected.noosShareDDRLY ?? 0,
                    splitPctLLY: expected.noosShareDDRLLY ?? 0,
                    grossMarginPctLY: expected.grossMarginDDRLY ?? 0,
                });
                break;
        }

        return acc;
    }, []);
};
