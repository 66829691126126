import { ColDef } from 'ag-grid-community';
import { DefaultColumn } from 'src/components/organisms/DataTable/columns/default-columns-details';

export const OverviewColumnOpenToBuyLimitRowGroup: ColDef[] = [
    {
        ...DefaultColumn,
        rowGroup: true,
        hide: true,
        field: 'category',
        headerName: 'Category',
        suppressSizeToFit: true,
    },
    {
        ...DefaultColumn,
        field: 'buyingEvent',
        hide: true,
        headerName: 'Events',
        suppressSizeToFit: true,
    },
];
