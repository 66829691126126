import { useCallback } from 'react';
import { Module, ScopeEnum } from 'src/domain';
import { useDiscardChangesModule } from 'src/hooks/discard-changes/useDiscardChangesModule';
import { useInheritance } from 'src/hooks/inheritance';
import { useChangesSnackbar } from 'src/hooks/snackbar/useChangesSnackbar';
import { useUpdateSplitLevel } from 'src/hooks/split-levels/useUpdateSplitLevel';
import { useScope } from 'src/hooks/useScope';

export const useCategorySplitUpdateInheritance = (standalone = false) => {
    const { updateSplitLevel: updateStoreSplitLevel, loading } = useUpdateSplitLevel();
    const discardChanges = useDiscardChangesModule(Module.CategorySplit);

    const scope = useScope();
    const inheritance = useInheritance();
    const showSnackbar = useChangesSnackbar();

    const updateInheritance = useCallback(() => {
        // biome-ignore lint/suspicious/noConsole: provide better reason
        if (!inheritance) return console.warn(`No inheritance set: ${inheritance}`);

        switch (scope) {
            case ScopeEnum.STORE:
                return updateStoreSplitLevel(inheritance)?.then(() => {
                    if (standalone) {
                        discardChanges();
                        showSnackbar();
                    }
                });
            default:
                return;
        }
    }, [inheritance, scope, updateStoreSplitLevel, standalone, discardChanges, showSnackbar]);

    return { updateInheritance, loading };
};
