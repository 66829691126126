import { useReactiveVar } from '@bestseller-bit/retail-planning.utils.reactive-vars';
import { useMemo } from 'react';
import { useClusterId } from 'src/hooks/cluster';
import { useCompositePartner } from 'src/hooks/partner/useCompositePartner';
import { useStoreId } from 'src/hooks/store';
import { selectedPartnerVar } from 'src/infrastructure/local_state';
import { DeliveryProfileExpectedModel } from 'src/infrastructure/rest-api/api-types';
import { useDeliveryProfileApiQuery } from './useDeliveryProfileApiQUery';

export type DeliveryProfileData = {
    storeMarkdown?: DeliveryProfileExpectedModel[];
    clusterMarkdown?: DeliveryProfileExpectedModel[];
    partnerMarkdown?: DeliveryProfileExpectedModel[];
};

export type DeliveryProfileSplitLevelQuery = {
    data: DeliveryProfileData;
    error: Error | null;
    loading: boolean;
};

export const useDeliveryProfileSplitLevelQuery = (): DeliveryProfileSplitLevelQuery | undefined => {
    const storeId = useStoreId();
    const [selectedPartner] = useReactiveVar(selectedPartnerVar);
    const clusterId = useClusterId(selectedPartner);
    const compositePartner = useCompositePartner();

    const storeResponse = useDeliveryProfileApiQuery(storeId, 'Store');
    const clusterResponse = useDeliveryProfileApiQuery(clusterId, 'Cluster');
    const partnerResponse = useDeliveryProfileApiQuery(compositePartner?.id, 'Partner');

    const response = useMemo<DeliveryProfileSplitLevelQuery | undefined>(() => {
        return {
            data: {
                storeMarkdown: storeResponse.data,
                clusterMarkdown: clusterResponse.data,
                partnerMarkdown: partnerResponse.data,
            },
            error: storeResponse.error ?? clusterResponse.error ?? partnerResponse.error,
            loading: storeResponse.loading || clusterResponse.loading || partnerResponse.loading,
        };
    }, [
        storeResponse.data,
        storeResponse.error,
        storeResponse.loading,
        clusterResponse.data,
        clusterResponse.error,
        clusterResponse.loading,
        partnerResponse.data,
        partnerResponse.error,
        partnerResponse.loading,
    ]);

    return response;
};
