import { useEffect, useState } from 'react';
import { ScopeEnum } from 'src/domain';
import { useFilteredStores } from '../store';
import { useCurrentStore } from '../store/useCurrentStore';
import { useScope } from '../useScope';

export const useIsUsingGender = () => {
    const scope = useScope();
    const currentStore = useCurrentStore();
    const [stores] = useFilteredStores();

    const [doesUseGenderSplit, setDoesUseGenderSplit] = useState<boolean>(false);

    // derived state done like this to keep state inbetween changing stores
    useEffect(() => {
        if (scope === ScopeEnum.STORE) {
            if (!currentStore) return;

            const doesStoreUseGenderSplit = currentStore.useGenderSplit;

            setDoesUseGenderSplit(doesStoreUseGenderSplit);
            return;
        } else {
            const doesAllStoresUseGenderSplit = stores.every((x) => x.useGenderSplit);

            setDoesUseGenderSplit(doesAllStoresUseGenderSplit);
            return;
        }
    }, [currentStore, scope, stores]);

    return doesUseGenderSplit;
};
