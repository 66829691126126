import { Fullscreen, FullscreenExit } from '@mui/icons-material';
import { Box, IconButton, Stack, Tooltip, styled as muiStyled } from '@mui/material';
import {
    CellKeyDownEvent,
    GetContextMenuItemsParams,
    GridApi,
    GridReadyEvent,
    ICellRendererParams,
    IRowNode,
} from 'ag-grid-community';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Container from 'src/components/atoms/Container/Container';
import { GroupedOption } from 'src/components/atoms/Select/Select';
import { CountStatusBar } from 'src/components/molecules/CountStatusBar/CountStatusBar';
import EditAllPartnersButton from 'src/components/molecules/EditAllPartnersButton/EditAllPartnersButton';
import { StorefrontTypeSelect } from 'src/components/molecules/StorefrontTypeSelect/StorefrontTypeSelect';
import { ActionButtons } from 'src/components/organisms/ActionButtons/ActionsButtons';
import SettingsDropdown from 'src/components/organisms/ActionButtons/SettingsDropdown';
import { CachedImageProvider } from 'src/components/organisms/CachedImage/CachedImage';
import { DataTable } from 'src/components/organisms/DataTable/DataTable';
import { defaultRowGroupOptions } from 'src/components/organisms/DataTable/options/row-group-options';
import { Module, ScopeEnum } from 'src/domain';
import {
    StyleTimelineRowUpdateEventPayload,
    StyleTimelineRowUpdatedEvent,
} from 'src/domain/events/styletimeline-row-updated.event';
import { StyleTimelineOverviewRow } from 'src/domain/table/style-timeline-overview-row';
import { useScope, useUnsavedChangesModule } from 'src/hooks';
import { useIsAdmin } from 'src/hooks/auth/useIsAdmin';
import { useIsSuperUser } from 'src/hooks/auth/useIsSuperUser';
import { useDiscardChangesModule } from 'src/hooks/discard-changes/useDiscardChangesModule';
import { useStores } from 'src/hooks/store-selection/queries/useStores';
import { useStyleCategoriesQuery } from 'src/hooks/style-categories/queries/useStyleCategoriesQuery';
import { useStyleTimelineSaveChanges } from 'src/hooks/style-timeline/save-changes/useStyleTimelineSaveChanges';
import { useStyleTimelineSimulationRows } from 'src/hooks/style-timeline/simulation/useStyleTimelineSimulationRows';
import { usePhaseoutProfiles } from 'src/hooks/style-timeline/usePhaseoutProfiles';
import { useStyleTimelineHandleCellValueChanges } from 'src/hooks/style-timeline/useStyleTimelineHandleCellValueChanges';
import { useIsDevEnv } from 'src/hooks/useIsDevEnv';
import { useProductLifecycleTypes } from 'src/hooks/useProductLifecycleTypes';
import { collectionsToMap, mapModelToRow } from 'src/mapping/style-timeline.mapping';
import { generateContextMenuItems } from 'src/utils/ag-grid/getContextMenuItems';
import {
    fillOperationForColumn,
    formatAndSendToClipboard,
    getEmptyValue,
    handleClearCellValue,
    handleCopyValueToVisibleRows,
    handleMarkAsInfinitelyAvailable,
} from 'src/utils/ag-grid/styleTimelineHelper';
import { columns, defaultColDef, globalColumns, lifecycleValueGetter } from './data/columns';

interface StyleTimelineProps {
    disableGlobalPlanning?: boolean;
    disableStorefrontTypeSelect?: boolean;
    disableActionButtons?: boolean;
    storefrontType?: string;
    header?: React.ReactNode;
}

export const StyleTimeline: FC<StyleTimelineProps> = ({
    header,
    disableGlobalPlanning = false,
    disableStorefrontTypeSelect = false,
    disableActionButtons = false,
    storefrontType,
}) => {
    const { data: stores } = useStores();
    const {
        data: overviewRows,
        loading: rowsLoading,
        collections,
        updateCache,
    } = useStyleTimelineSimulationRows(storefrontType);

    const { data: productLifecycleTypes } = useProductLifecycleTypes();

    const { data: styleCategories } = useStyleCategoriesQuery();
    const [fullscreen, setFullscreen] = useState(false);
    const [gridApi, setGridApi] = useState<GridApi | null>(null);

    const isSuperUser = useIsSuperUser();
    const isAdmin = useIsAdmin();
    const scope = useScope();
    const isGlobalPlanning = useMemo(() => scope === ScopeEnum.GLOBAL, [scope]);

    const dataTableWrapperRef = useRef<HTMLDivElement | null>(null);
    const handleCellValueChanges = useStyleTimelineHandleCellValueChanges(overviewRows);

    const { data: phaseoutProfiles, loading: phaseoutProfilesLoading } = usePhaseoutProfiles();

    const isDevEnv = useIsDevEnv();

    useEffect(() => {
        if (isDevEnv) {
            // biome-ignore lint/suspicious/noExplicitAny: we need to use any
            (window as any)['__StyleTimelineApi__'] = {
                setColumnsVisible: (columns: string[], visible: boolean) => {
                    if (gridApi) {
                        gridApi.setColumnsVisible(columns, visible);
                        return true;
                    }
                    return false;
                },
                ensureColumnVisible: (column: string) => {
                    if (gridApi) {
                        gridApi.ensureColumnVisible(column);
                        return true;
                    }
                    return false;
                },
            };
        } else {
            // biome-ignore lint/suspicious/noExplicitAny: we need to use any
            (window as any)['__StyleTimelineApi__'] = undefined;
        }
    }, [isDevEnv, gridApi]);

    const rowGroupOptions = useMemo(() => {
        return {
            ...defaultRowGroupOptions,
            groupDefaultExpanded: isGlobalPlanning ? 1 : 2,
            groupRemoveSingleChildren: false,
            getRowHeight: isGlobalPlanning ? undefined : defaultRowGroupOptions.getRowHeight,
        };
    }, [isGlobalPlanning]);

    useEffect(() => {
        // Note: For performance reasons, we're not using Apollo cache for styletimeline global planning, thus we need to manually update the grid with the data from the server.
        const handleRowUpdate = (event: Event) => {
            const { rows } = (event as CustomEvent<StyleTimelineRowUpdateEventPayload>).detail;
            if (rows) {
                const collectionMap = collectionsToMap(collections);
                const mappedRows = rows.map((row) =>
                    mapModelToRow(row, collectionMap, stores.allStores, styleCategories)
                );
                updateCache(mappedRows);
            }
        };

        window.addEventListener(StyleTimelineRowUpdatedEvent, handleRowUpdate);
        return () => {
            window.removeEventListener(StyleTimelineRowUpdatedEvent, handleRowUpdate);
        };
    }, [collections, styleCategories, updateCache, stores.allStores]);

    const copyValueToVisibleRows = useCallback(
        (params: GetContextMenuItemsParams) => handleCopyValueToVisibleRows(params, handleCellValueChanges),
        [handleCellValueChanges]
    );

    const clearCellValue = useCallback(
        (params: Partial<GetContextMenuItemsParams>) =>
            handleClearCellValue(params, getEmptyValue, handleCellValueChanges),
        [handleCellValueChanges]
    );

    const markAsInfinitelyAvailable = useCallback(
        (params: GetContextMenuItemsParams) => handleMarkAsInfinitelyAvailable(params, handleCellValueChanges),
        [handleCellValueChanges]
    );

    const handleCellKeyDown = useCallback(
        (event: CellKeyDownEvent) => {
            if (!event.event) {
                return;
            }
            const keyboardEvent = event.event as unknown as KeyboardEvent;

            const path = event.eventPath;
            const isInputElement = path?.some((element) => element instanceof HTMLInputElement);

            if (['Backspace', 'Clear', 'Delete'].includes(keyboardEvent.key) && !isInputElement) {
                event.event.preventDefault();
                clearCellValue({ column: event.column, node: event.node })();
            }
        },
        [clearCellValue]
    );

    const handleGridReady = useCallback((event: GridReadyEvent) => {
        setGridApi(event.api);
    }, []);

    const getContextMenuItems = useCallback(
        (params: GetContextMenuItemsParams) =>
            generateContextMenuItems(params, clearCellValue, copyValueToVisibleRows, markAsInfinitelyAvailable),
        [clearCellValue, copyValueToVisibleRows, markAsInfinitelyAvailable]
    );

    const doesExternalFilterPass = useCallback((node: IRowNode<StyleTimelineOverviewRow>): boolean => {
        return !node.data?.storePlacement || (!node.data?.exitWeek && node.data?.exitWeekType !== 'INFINITE');
    }, []);

    const [saveChanges, { isSaving }] = useStyleTimelineSaveChanges(storefrontType);
    const [unsavedChanges] = useUnsavedChangesModule<StyleTimelineOverviewRow[]>(Module.StyleTimeline);
    const hasUnsavedChanges = !!unsavedChanges && unsavedChanges.length > 0;
    const discardChanges = useDiscardChangesModule();

    const tableColumns = useMemo(() => {
        const cols = isGlobalPlanning ? [...globalColumns] : [...columns];
        const phaseoutProfileColumn = cols.find((col) => col.field === 'phaseoutProfileId');
        const productLifecycleColumn = cols.find((col) => col.field === 'productLifeCycleIds');
        if (phaseoutProfileColumn) {
            let selectOptions: GroupedOption[] = (phaseoutProfiles ?? []).map(
                (profile) =>
                    ({
                        display: profile.name,
                        isHeader: false,
                        value: profile.id.toString(),
                    }) as GroupedOption
            );

            if (selectOptions.length === 0) {
                selectOptions = [
                    {
                        display: 'No phaseout profiles available',
                        isHeader: true,
                        value: '-1',
                    },
                ];
            }

            phaseoutProfileColumn.cellEditorParams = {
                selectOptions,
            };
        }
        if (productLifecycleColumn) {
            productLifecycleColumn.valueGetter = (params) => lifecycleValueGetter(params, productLifecycleTypes);
            productLifecycleColumn.filterValueGetter = (params) => lifecycleValueGetter(params, productLifecycleTypes);
        }

        return cols;
    }, [isGlobalPlanning, phaseoutProfiles, productLifecycleTypes]);

    const loading = rowsLoading || isSaving || phaseoutProfilesLoading;

    return (
        <Container
            style={{
                height: '100%',
                zIndex: fullscreen ? 3 : 0,
                minHeight: 750,
                position: fullscreen ? 'fixed' : 'static',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                overflow: 'auto',
            }}
        >
            <Stack height="100%">
                <Stack
                    direction="row"
                    sx={{
                        marginBottom: 4,
                        flexWrap: 'wrap',
                        justifyContent: 'flex-end',
                        gap: 2,
                        '@media (min-width: 1550px)': {
                            justifyContent: 'space-between',
                        },
                    }}
                    mb={2}
                    alignItems="center"
                >
                    {header && (
                        <Stack marginRight="auto" justifyContent={'center'}>
                            {header}
                        </Stack>
                    )}
                    {scope !== ScopeEnum.STORE && !disableStorefrontTypeSelect && <StorefrontTypeSelect />}
                    <Box ml={'auto'}>
                        <ActionButtons
                            showDiscardButton={!disableActionButtons}
                            showSaveButton={!disableActionButtons}
                            discardDisabled={!hasUnsavedChanges}
                            loading={loading}
                            saveLoading={isSaving}
                            saveDisabled={!hasUnsavedChanges}
                            onDiscard={discardChanges}
                            onSave={saveChanges}
                        >
                            <IconButton color="primary" onClick={() => setFullscreen((prev) => !prev)}>
                                {!fullscreen ? (
                                    <Tooltip title="Open fullscreen">
                                        <Fullscreen />
                                    </Tooltip>
                                ) : (
                                    <Tooltip title="Close fullscreen">
                                        <FullscreenExit />
                                    </Tooltip>
                                )}
                            </IconButton>
                            <SettingsDropdown gridApi={gridApi} module={Module.StyleTimeline} />
                            {!disableGlobalPlanning && (isSuperUser || isAdmin) && <EditAllPartnersButton />}
                        </ActionButtons>
                    </Box>
                </Stack>
                <TableWrapper
                    data-testid="style-timeline-table-wrapper"
                    ref={dataTableWrapperRef}
                    className="ag-theme-alpine-custom-compact small-font"
                    flex={1}
                >
                    <CachedImageProvider>
                        <DataTable
                            data-testid="style-timeline-table"
                            {...rowGroupOptions}
                            fillOperation={fillOperationForColumn}
                            sendToClipboard={formatAndSendToClipboard}
                            rows={loading ? undefined : overviewRows}
                            columns={tableColumns}
                            defaultColDef={defaultColDef}
                            domLayout="normal"
                            onGridReady={handleGridReady}
                            groupDisplayType="groupRows"
                            doesExternalFilterPass={doesExternalFilterPass}
                            groupRowRendererParams={{
                                innerRenderer: GroupRowInnerRenderer,
                                suppressDoubleClickExpand: true,
                                suppressEnterExpand: true,
                            }}
                            onCellKeyDown={handleCellKeyDown}
                            isNumberTable={false}
                            animateRows={true}
                            getContextMenuItems={getContextMenuItems}
                            onCellValuesChanged={handleCellValueChanges}
                            loading={loading}
                            gridOptions={{
                                statusBar: {
                                    statusPanels: [
                                        {
                                            statusPanel: CountStatusBar,
                                            align: 'right',
                                        },
                                    ],
                                },
                            }}
                            sideBar={{
                                position: 'left',
                                toolPanels: [
                                    {
                                        id: 'filters',
                                        labelDefault: 'Filters',
                                        labelKey: 'filters',
                                        iconKey: 'filter',
                                        toolPanel: 'agFiltersToolPanel',
                                        toolPanelParams: {
                                            suppressExpandAll: false,
                                        },
                                    },
                                    {
                                        id: 'columns',
                                        labelDefault: 'Columns',
                                        labelKey: 'columns',
                                        iconKey: 'columns',
                                        toolPanel: 'agColumnsToolPanel',
                                        toolPanelParams: {
                                            suppressExpandAll: false,
                                        },
                                    },
                                ],
                            }}
                        />
                    </CachedImageProvider>
                </TableWrapper>
            </Stack>
        </Container>
    );
};

const TableWrapper = muiStyled(Box)`
  .ag-root-wrapper {
    background-color: #fafafa;
  }

  .ag-row {
    background-color: #fff;
  }

  .ag-pivot-mode-panel,
  .ag-last-column-drop {
    display: none;
  }
`;

export const GroupRowInnerRenderer: FC<ICellRendererParams> = ({ node }) => {
    return (
        <Box width={'100%'} fontWeight={'bold'}>
            {node.key}
        </Box>
    );
};
