import { useMemo } from 'react';
import { CategoryOverviewRow, GenderIdToGenderMap, MarkupDetailsRow, Module, TableOverviewRow } from 'src/domain';
import { useCategorySplitOverviewSimulationRows } from 'src/hooks/category-split/simulation/useCategorySplitOverviewSimulationRows';
import { useGender, useGenderId, useIsUsingGender } from 'src/hooks/gender';
import { useGenderSplitOverviewSimulationRows } from 'src/hooks/gender-split/simulation/useGenderSplitOverviewSimulationRows';
import { useIsUsingProductline, useProductLine } from 'src/hooks/productline';
import { useProductLineSplitOverviewSimulationRows } from 'src/hooks/productline-split/simulation/useProductLineSplitOverviewSimulationRows';
import { useTurnoverSimulationRows } from 'src/hooks/turnover/simulation/useTurnoverSimulationRows';
import { useTurnoverVat } from 'src/hooks/turnover/table/useTurnoverVat';
import { useProductLineGroupsApiQuery } from 'src/hooks/useProductLineGroupsApiQuery';
import { getGenderProductLineSplit } from 'src/mapping/getGenderProductLineSplit';
import { calculateGrossMarginWithoutMarkdown } from 'src/utils/gross-margin/calculateGrossMargin';
import { monthKeyToField } from 'src/utils/monthKeys';
import { useMarkupDetailsRows } from '../table/useMarkupDetailsRows';
import { useMarkupOverviewSimulationRows } from './useMarkupOverviewSimulationRows';

export interface DetailsRowMapperProps {
    month: string;
    vat: number;
    turnoverPlannedSalesIv: number;
    genderProductLineSplit: number;
    categorySplitSimulationData: CategoryOverviewRow[] | undefined;
    markupSimulationData: TableOverviewRow[] | undefined;
}

export const detailsRowMapper = ({
    month,
    vat,
    genderProductLineSplit,
    turnoverPlannedSalesIv,
    categorySplitSimulationData,
    markupSimulationData,
}: DetailsRowMapperProps) => {
    return (row: MarkupDetailsRow): MarkupDetailsRow => {
        const markup = markupSimulationData?.find((change) => change.category.equalsInsensitive(row.category))?.columns[
            month
        ];

        const categorySplit =
            categorySplitSimulationData?.find((change) => change.category.equalsInsensitive(row.category))?.columns[
                month
            ] ?? 0;

        const plannedValue = markup ?? 0;
        const plannedSale = turnoverPlannedSalesIv * genderProductLineSplit * (categorySplit / 100);
        const expectedCogs: number = plannedSale / plannedValue;
        const expectedGrossMargin = calculateGrossMarginWithoutMarkdown({
            vat,
            markup: plannedValue,
            turnover: plannedSale,
        });

        const grossMarginIsNumber = !isNaN(expectedGrossMargin) && isFinite(expectedGrossMargin);
        const cogsIsNumber = !isNaN(expectedCogs) && isFinite(expectedCogs);

        return {
            ...row,
            expectedCogs: cogsIsNumber ? Math.round(expectedCogs * 100) / 100 : 0,
            expectedGrossMargin: grossMarginIsNumber ? Math.round(expectedGrossMargin * 100) / 100 : 0,
            plannedValue: Math.round(plannedValue * 100) / 100,
            plannedSplit: categorySplit,
        };
    };
};

export const useMarkupDetailsSimulationRows = (aggregateOnGenderProductlines = false) => {
    const isUsingGender = useIsUsingGender();
    const isUsingProductLine = useIsUsingProductline();
    const gender = useGender(Module.Markup);
    const genderId = useGenderId(Module.Markup);
    const productLine = useProductLine(Module.Markup);

    const { data: productlineGroups } = useProductLineGroupsApiQuery();
    const { data, loading, error } = useMarkupDetailsRows(aggregateOnGenderProductlines) ?? {};

    const { data: turnoverSimulationData } = useTurnoverSimulationRows();
    const { data: vat } = useTurnoverVat();
    const { data: genderSplitSimulationData } = useGenderSplitOverviewSimulationRows();
    const { data: productLineSplitSimulationData } = useProductLineSplitOverviewSimulationRows();
    const { data: categorySplitSimulationData, aggregatedData: categorySplitSimulationAggregatedData } =
        useCategorySplitOverviewSimulationRows(undefined, genderId, productLine?.id, aggregateOnGenderProductlines);
    const { data: markupSimulationData, aggregatedData: markupAggregatedData } = useMarkupOverviewSimulationRows(
        undefined,
        aggregateOnGenderProductlines
    );

    const aggregatedSimulatedData = useMemo(() => {
        if (aggregateOnGenderProductlines) {
            if (!data) return;
            if (!categorySplitSimulationAggregatedData || !markupAggregatedData) {
                return;
            }

            const rowsByGenderProductline: Record<
                string,
                Record<string, Map<string, MarkupDetailsRow[]> | undefined>
            > = {};
            Object.keys(categorySplitSimulationAggregatedData).forEach((productlineId) => {
                const categorySplitByProductline = categorySplitSimulationAggregatedData[productlineId];
                const markupByProductline = markupAggregatedData[productlineId];
                Object.keys(categorySplitSimulationAggregatedData[productlineId] ?? {}).forEach((genderId) => {
                    const map = new Map<string, MarkupDetailsRow[]>();
                    data.forEach((detailsRows, month) => {
                        const categorySplitByGender = categorySplitByProductline[genderId];
                        const markupByGender = markupByProductline[genderId];
                        const productLine = productlineGroups?.find((pl) => pl.id === Number(productlineId));
                        const gender = GenderIdToGenderMap.get(Number(genderId));

                        const turnoverPlannedSalesIv = turnoverSimulationData?.find((change) =>
                            monthKeyToField(change.monthKey).equalsInsensitive(month)
                        )?.plannedSalesIv;

                        const genderSplit = genderSplitSimulationData?.find((change) =>
                            change.category.equalsInsensitive(gender)
                        )?.columns[month];

                        const productLineSplit = productLineSplitSimulationData?.find(
                            (change) =>
                                change.category.equalsInsensitive(productLine?.name) &&
                                change.subCategory?.equalsInsensitive(gender)
                        )?.columns[month];

                        map.set(
                            month,
                            detailsRows.map(
                                detailsRowMapper({
                                    month,
                                    vat: vat[month],
                                    turnoverPlannedSalesIv: turnoverPlannedSalesIv ?? 0,
                                    genderProductLineSplit: getGenderProductLineSplit({
                                        isUsingGender,
                                        isUsingProductLine,
                                        genderSplit,
                                        productLineSplit,
                                    }),
                                    categorySplitSimulationData: categorySplitByGender,
                                    markupSimulationData: markupByGender,
                                })
                            )
                        );
                    });

                    rowsByGenderProductline[productlineId] = rowsByGenderProductline[productlineId] ?? {};
                    rowsByGenderProductline[productlineId][genderId] = map;
                });
            });

            return rowsByGenderProductline;
        }
    }, [
        aggregateOnGenderProductlines,
        categorySplitSimulationAggregatedData,
        data,
        genderSplitSimulationData,
        isUsingGender,
        isUsingProductLine,
        markupAggregatedData,
        productLineSplitSimulationData,
        productlineGroups,
        turnoverSimulationData,
        vat,
    ]);

    const simulatedData = useMemo(() => {
        if (!data) return;
        if (
            !genderSplitSimulationData &&
            !markupSimulationData &&
            !productLineSplitSimulationData &&
            !categorySplitSimulationData &&
            !turnoverSimulationData
        ) {
            return data;
        }

        const map = new Map<string, MarkupDetailsRow[]>();
        data.forEach((detailsRows, month) => {
            const turnoverPlannedSalesIv = turnoverSimulationData?.find((change) =>
                monthKeyToField(change.monthKey).equalsInsensitive(month)
            )?.plannedSalesIv;

            const genderSplit = genderSplitSimulationData?.find((change) => change.category.equalsInsensitive(gender))
                ?.columns[month];

            const productLineSplit = productLineSplitSimulationData?.find(
                (change) =>
                    change.category.equalsInsensitive(productLine?.name) &&
                    change.subCategory?.equalsInsensitive(gender)
            )?.columns[month];

            map.set(
                month,
                detailsRows.map(
                    detailsRowMapper({
                        month,
                        vat: vat[month],
                        turnoverPlannedSalesIv: turnoverPlannedSalesIv ?? 0,
                        genderProductLineSplit: getGenderProductLineSplit({
                            isUsingGender,
                            isUsingProductLine,
                            genderSplit,
                            productLineSplit,
                        }),
                        categorySplitSimulationData,
                        markupSimulationData: markupSimulationData,
                    })
                )
            );
        });

        return map;
    }, [
        data,
        genderSplitSimulationData,
        markupSimulationData,
        productLineSplitSimulationData,
        categorySplitSimulationData,
        turnoverSimulationData,
        vat,
        isUsingGender,
        isUsingProductLine,
        gender,
        productLine?.name,
    ]);

    return { data: simulatedData, aggregatedData: aggregatedSimulatedData, loading, error };
};
