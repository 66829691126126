import { IAggFunc } from 'ag-grid-community';
import { parseNumber } from './formatNumber';

export const comparableCellSum: IAggFunc = ({ values }) => {
    const result = values.reduce(
        (acc, value) => {
            const current = Array.isArray(value) ? parseNumber(value[0]) : parseNumber(value);
            const predicted = Array.isArray(value) ? parseNumber(value[1]) : undefined;

            acc.current += current;

            if (typeof predicted === 'number' && !isNaN(predicted)) {
                acc.predicted += predicted;
            } else {
                acc.predicted += current;
            }

            return acc;
        },
        {
            current: 0,
            predicted: 0,
        }
    );

    return [
        !isNaN(result.current) ? result.current : undefined,
        result.current !== result.predicted ? result.predicted : undefined,
    ];
};

export const comparableCellAvg: IAggFunc = ({ values }) => {
    const result = values.reduce(
        (acc, value) => {
            const current = Array.isArray(value) ? parseNumber(value[0]) : parseNumber(value);
            const predicted = Array.isArray(value) ? parseNumber(value[1]) : undefined;

            acc.current += current ?? 0;
            acc.currentCount++;

            if (typeof predicted === 'number' && !isNaN(predicted)) {
                acc.predicted += predicted;
            } else {
                acc.predicted += current ?? 0;
            }
            acc.predictedCount++;

            return acc;
        },
        {
            current: 0,
            currentCount: 0,
            predicted: 0,
            predictedCount: 0,
        }
    );

    return [
        !isNaN(result.current) ? result.current / result.currentCount : undefined,
        !isNaN(result.predicted) && result.current !== result.predicted
            ? result.predicted / result.predictedCount
            : undefined,
    ];
};
