import { uniq } from 'lodash';

export const compareMonths = (
    a: Record<string, number> | undefined | null,
    b: Record<string, number> | undefined | null
) => {
    const unequalMonths: string[] = [];

    const aMonthKeys = Object.keys(a ?? {});
    const bMonthKeys = Object.keys(b ?? {});

    const keys = uniq([...aMonthKeys, ...bMonthKeys]).filter(
        (key) => !key.endsWith('Id') && !key.endsWith('PlannedSplit')
    );

    keys.forEach((key) => {
        if (a && b ? a[key] !== b[key] : false) unequalMonths.push(key);
    });

    return unequalMonths;
};
