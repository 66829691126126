import { Box, styled } from '@mui/material';
import { format } from 'date-fns';
import { da } from 'date-fns/locale/da';
import { FC } from 'react';
import DatePicker, { ReactDatePickerProps, registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

registerLocale('da', {
    ...da,
    options: {
        ...da.options,
        weekStartsOn: 1,
    },
});

export const MonthPicker: FC<ReactDatePickerProps<boolean | undefined, boolean | undefined>> = ({
    selected,
    ...props
}) => {
    return (
        <DatePickerContainer>
            <StyledDatePicker
                portalId="root"
                popperClassName="ag-custom-component-popup react-datepicker__month-picker"
                dateFormat="MM/yyyy"
                showMonthYearPicker
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                locale={'da'}
                autoFocus
                dayClassName={(date) =>
                    selected && format(date, 'yyyy-MM') === format(selected, 'yyyy-MM')
                        ? 'react-datepicker__day--selected'
                        : ''
                }
                {...props}
            />
        </DatePickerContainer>
    );
};

const DatePickerContainer = styled(Box)`
  width: 100%;
`;

const StyledDatePicker = styled(DatePicker)`
  border: none;
  color: #787c8d;
  font-size: 0.9rem;
  width: 100%;
  box-sizing: border-box;
  height: 31px;
`;
