import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { Checkbox } from '@mui/material';
import { FC, HTMLAttributes } from 'react';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

interface Props {
    label: string;
    selected: boolean;
    native: HTMLAttributes<HTMLLIElement>;
}

export const Option: FC<Props> = ({ label, selected, native }) => {
    return (
        <li {...native}>
            <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
            {label}
        </li>
    );
};
