import { useMemo } from 'react';
import { useClusterId } from 'src/hooks/cluster';
import { useInitializeIsMarkupHistorical } from 'src/hooks/historical';
import { useCompositePartner } from 'src/hooks/partner/useCompositePartner';
import { useStoreId } from 'src/hooks/store';
import { useSelectedPartner } from 'src/hooks/useSelectedPartner';
import { MarkupModel } from 'src/infrastructure/rest-api/api-types';
import { useMarkupApiQuery } from './useMarkupApiQuery';

export type MarkupLevelQuery = {
    data: {
        storeMarkup?: MarkupModel[];
        clusterMarkup?: MarkupModel[];
        partnerMarkup?: MarkupModel[];
    };
    error: Error | null;
    loading: boolean;
};

export const useMarkupLevelQuery = (): MarkupLevelQuery | undefined => {
    const storeId = useStoreId();
    const [selectedPartner] = useSelectedPartner();
    const clusterId = useClusterId(selectedPartner);
    const compositePartner = useCompositePartner();

    const { data: storeMarkup, error: storeError, loading: storeLoading } = useMarkupApiQuery(storeId, 'Store');
    const {
        data: clusterMarkup,
        error: clusterError,
        loading: clusterLoading,
    } = useMarkupApiQuery(clusterId, 'Cluster');
    const {
        data: partnerMarkup,
        error: partnerError,
        loading: partnerLoading,
    } = useMarkupApiQuery(compositePartner?.id, 'Partner');

    const data = useMemo<MarkupLevelQuery['data']>(() => {
        return {
            storeMarkup,
            clusterMarkup,
            partnerMarkup,
        };
    }, [clusterMarkup, partnerMarkup, storeMarkup]);

    useInitializeIsMarkupHistorical(data);

    return {
        data,
        error: storeError ?? clusterError ?? partnerError,
        loading: storeLoading || clusterLoading || partnerLoading,
    };
};
