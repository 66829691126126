import styled from '@emotion/styled';
import { LinearProgress } from '@mui/material';
import { FC, useEffect, useState } from 'react';

interface Props {
    completed?: boolean;
}

const ProgressBar: FC<Props> = ({ completed = false }) => {
    const [currentProgress, setCurrentProgress] = useState(0);

    useEffect(() => {
        const timer = setInterval(() => {
            setCurrentProgress((oldProgress) => {
                if (oldProgress === 100) {
                    return 0;
                }
                const diff = Math.random() * 10;
                return Math.min(oldProgress + diff, 99);
            });
        }, 500);

        return () => {
            clearInterval(timer);
        };
    }, []);
    if (completed) {
        setCurrentProgress(100);
    }
    return (
        <Wrapper>
            <LinearProgress variant="determinate" value={currentProgress} />
        </Wrapper>
    );
};

const Wrapper = styled.div`
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
`;

export default ProgressBar;
