import styled from '@emotion/styled';
import { FC } from 'react';
import { Spacings } from 'src/domain';

interface Props {
    spacing: Spacings;
    vertical?: boolean;
}

const Spacer: FC<Props> = ({ spacing, vertical }) => {
    return <StyledSpacer spacing={spacing} vertical={vertical} />;
};

const StyledSpacer = styled.div<Props>`
  height: ${(props: Props) => (props.vertical ? props.spacing : '1px')};
  min-height: ${(props: Props) => (props.vertical ? props.spacing : '1px')};
  width: ${(props: Props) => (props.vertical ? '1px' : props.spacing)};
  min-width: ${(props: Props) => (props.vertical ? '1px' : props.spacing)};
`;

export default Spacer;
