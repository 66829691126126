import {
    ColDef,
    Column,
    FillOperationParams,
    GetContextMenuItemsParams,
    IRowNode,
    SendToClipboardParams,
} from 'ag-grid-community';
import { isNumber } from 'lodash';
import { Cell } from 'src/components/organisms/DataTable/types';
import { SalesCampaignDiscountType } from 'src/infrastructure/rest-api/api-types';

export const handleCopyValueToVisibleRows = (
    { api, column, value }: GetContextMenuItemsParams,
    handleCellValueChanges: (changes: Cell<unknown>[]) => void
) => {
    return () => {
        const columnDef = column?.getDefinition() as ColDef;
        const field = columnDef.field;

        if (field) {
            const colId = column?.getColId();
            if (!colId) {
                // biome-ignore lint/suspicious/noConsole: provide better reason
                console.warn(`No column found for ${field}!`);
            } else {
                const cells: Cell[] = [];
                api.forEachNodeAfterFilterAndSort((rowNode) => {
                    if (!rowNode.group && rowNode.data?.rowId) {
                        cells.push({
                            column: colId,
                            footer: undefined,
                            rowId: rowNode.data.rowId,
                            value,
                            category: undefined,
                        });
                    }
                });
                handleCellValueChanges(cells);
            }
        }
    };
};

export const handleClearCellValue = (
    { column, node }: Partial<GetContextMenuItemsParams>,
    getEmptyValue: (column: Column) => unknown,
    handleCellValueChanges: (changes: Cell<unknown>[]) => void
) => {
    return () => {
        if (node?.data && column) {
            const changes: Cell<unknown>[] = [
                {
                    column: column.getColId(),
                    footer: undefined,
                    rowId: node.data.rowId,
                    value: getEmptyValue(column),
                    category: undefined,
                },
            ];

            if (column.getColId() === 'discount') {
                changes.push({
                    column: column.getColId(),
                    category: undefined,
                    footer: undefined,
                    rowId: node.data.rowId,
                    value: {
                        x: null,
                        y: null,
                        discountType: 'FIXED_PRICE' as SalesCampaignDiscountType,
                    },
                });
            }

            handleCellValueChanges(changes);
        }
    };
};

export const handleMarkAsInfinitelyAvailable = (
    { node, column }: GetContextMenuItemsParams,
    handleCellValueChanges: (changes: Cell<unknown>[]) => void
) => {
    return () => {
        if (node?.data && column) {
            const changes: Cell<unknown>[] = [
                {
                    column: column.getColId(),
                    footer: undefined,
                    rowId: node.data.rowId,
                    value: {
                        exitWeek: null,
                        exitWeekType: 'INFINITE',
                        lifeSpan: null,
                    },
                    category: undefined,
                },
            ];
            handleCellValueChanges(changes);
        }
    };
};

export const fillOperationForColumn = (params: FillOperationParams) => {
    const { column, rowNode, currentIndex, api, direction } = params;
    const colId = column.getColId();

    if (
        (colId !== 'discount' && colId !== 'exitWeek' && colId !== 'lifeSpan') ||
        !rowNode.id ||
        !isNumber(rowNode.rowIndex) ||
        ['left', 'right'].includes(direction)
    ) {
        return false;
    }

    const originalRowIndex =
        direction === 'down' ? rowNode.rowIndex - currentIndex - 1 : rowNode.rowIndex + currentIndex + 1;

    const originalRow = api.getDisplayedRowAtIndex(originalRowIndex);

    if (!originalRow) {
        return false;
    }

    const cell = getFilledCellByColumn(colId, rowNode.id, originalRow);

    return cell.value;
};

export const getFilledCellByColumn = (
    colId: 'discount' | 'exitWeek' | 'lifeSpan',
    rowId: string,
    row: IRowNode
): Cell => {
    switch (colId) {
        case 'discount':
            return {
                value: {
                    x: row.data['discount']?.valueX,
                    y: row.data['discount']?.valueY,
                    discountType: row.data['discount']?.discountType,
                },
                rowId: rowId,
                column: colId,
                category: undefined,
                footer: undefined,
            };

        case 'exitWeek':
            return {
                value: {
                    exitWeek: row.data.exitWeek,
                    exitWeekType: row.data.exitWeekType,
                },
                rowId: rowId,
                column: 'exitWeek',
                category: undefined,
                footer: undefined,
            };
        case 'lifeSpan':
            return {
                value: {
                    exitWeekType: row.data.exitWeekType,
                    lifeSpan: row.data.lifeSpan,
                },
                rowId: rowId,
                column: 'lifeSpan',
                category: undefined,
                footer: undefined,
            };
    }
};

// Custom handling of copying to clipboard to avoid copying the group header, since they are later pasted as values into other cells.
export const formatAndSendToClipboard = (params: SendToClipboardParams) => {
    // The data string contains all cell values (including group headers) with '\r\n' as the delimiter
    const lines: string[] = params.data.split('\r\n');

    // Filter out lines that are group headers, identified by the value '"\\"\\""'
    const cleanedLines = lines.filter((line) => line !== '"\\"\\""');

    const cleanedData = cleanedLines.join('\r\n');
    navigator.clipboard.writeText(cleanedData);
};

export const getEmptyValue = (column: Column) => {
    const colId = column.getColId();
    if (colId === 'comment') return '';
    if (colId === 'eligibleForSale') return false;
    if (colId === 'exitWeek') {
        return {
            exitWeek: null,
            exitWeekType: 'EMPTY',
        };
    }
    if (colId === 'lifeSpan') {
        return {
            lifeSpan: null,
            exitWeekType: 'EMPTY',
        };
    }
    return null;
};
