import { ScopeEnum } from 'src/domain';
import { useInitializeIsTurnoverHistorical } from 'src/hooks/historical/useIsTurnoverHistorical';
import { useScope } from 'src/hooks/useScope';
import { useApiQuery } from 'src/infrastructure/rest-api/useApi';
import { useStoreId } from '../../store/useStoreId';
import { useTurnoverSplitLevel } from '../split-level/useTurnoverSplitLevel';

export const useStoreTurnover = () => {
    const scope = useScope();
    const turnoverSplitLevel = useTurnoverSplitLevel();
    const storeId = useStoreId();

    const { data, loading, error, refetch } = useApiQuery('/api/turnover/stores/{storeId}', 'get', {
        path: {
            storeId: Number(storeId),
        },
        enabled: !!storeId && !isNaN(Number(storeId)) && scope === ScopeEnum.STORE,
    });

    useInitializeIsTurnoverHistorical(turnoverSplitLevel);
    return { data, loading, error, refetch };
};
