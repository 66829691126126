import { ColDef, ICellRendererParams } from 'ag-grid-community';

export const AutoGroupColumns: ColDef = {
    cellRendererParams: {
        innerRenderer: (props: ICellRendererParams) => {
            // this is not being called
            return <div style={{ fontWeight: 'italic' }}>{props.value}</div>;
        },
    },
};
