import { useMemo } from 'react';
import { combineGenderAndProductline } from 'src/utils/combineGenderAndProductline';
import { useGender } from './gender';
import { useProductLine } from './productline';

export const useGenderProductLineKey = () => {
    const gender = useGender();
    const productLine = useProductLine();

    const useGenderProductLineKey = useMemo(
        () => combineGenderAndProductline(gender, productLine?.name),
        [gender, productLine?.name]
    );

    return useGenderProductLineKey;
};
