import { useMemo } from 'react';
import { useTurnoverVat } from './table/useTurnoverVat';

export const useVat = () => {
    const { data } = useTurnoverVat();

    // We assume all VAT values are the same
    const vat = useMemo(
        () =>
            (data &&
                Object.entries(data).find(([_, vat]) => typeof vat === 'number' && vat !== 0 && !isNaN(vat))?.[1]) ??
            0,
        [data]
    );

    return vat;
};
