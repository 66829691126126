import { QueryObserverResult, RefetchOptions } from '@tanstack/react-query';
import { useMemo } from 'react';
import { ScopeEnum } from 'src/domain';
import { useScope } from 'src/hooks';
import { TurnoverOverviewModel, TurnoverOverviewSummarizedGroupsModel } from 'src/infrastructure/rest-api/api-types';
import { useClusterOverviewTurnover } from './useClusterOverviewTurnover';
import { usePartnerOverviewTurnover } from './usePartnerOverviewTurnover';
import { useStoreTurnover } from './useStoreTurnover';

export type TurnoverLevelQuery = {
    data: TurnoverOverviewModel | TurnoverOverviewSummarizedGroupsModel | undefined;
    error: Error | null;
    loading: boolean;
    partnerRefetch?: (
        options?: RefetchOptions
    ) => Promise<QueryObserverResult<TurnoverOverviewSummarizedGroupsModel, Error>>;
};

export const useTurnoverLevelQuery = (): TurnoverLevelQuery | undefined => {
    const scope = useScope();

    const { data: storeTurnover, error: storeError, loading: storeLoading } = useStoreTurnover();
    const { data: clusterTurnover, error: clusterError, loading: clusterLoading } = useClusterOverviewTurnover();
    const {
        data: partnerTurnover,
        error: partnerError,
        loading: partnerLoading,
        refetch: partnerRefetch,
    } = usePartnerOverviewTurnover();

    const current = useMemo<TurnoverLevelQuery | undefined>(() => {
        switch (scope) {
            case ScopeEnum.STORE:
                return { data: storeTurnover, error: storeError, loading: storeLoading };

            case ScopeEnum.CLUSTER:
                return { data: clusterTurnover, error: clusterError, loading: clusterLoading };

            case ScopeEnum.PARTNER:
                return { data: partnerTurnover, error: partnerError, loading: partnerLoading, partnerRefetch };

            default:
                return;
        }
    }, [
        scope,
        storeTurnover,
        storeError,
        storeLoading,
        clusterTurnover,
        clusterError,
        clusterLoading,
        partnerTurnover,
        partnerError,
        partnerLoading,
        partnerRefetch,
    ]);

    return current;
};
