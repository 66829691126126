import styled from '@emotion/styled';
import { IStatusPanelParams } from 'ag-grid-community';
import { useCallback, useEffect, useState } from 'react';

export function PaginationStatusBar(props: IStatusPanelParams) {
    const [pageSize, setPageSize] = useState(props.api.paginationGetPageSize());
    const [currentPage, setCurrentPage] = useState(props.api.paginationGetCurrentPage());
    const [totalPages, setTotalPages] = useState(props.api.paginationGetTotalPages());
    const [count, setCount] = useState(0);
    const [fromRow, setFromRow] = useState(currentPage * pageSize + 1);
    const [toRow, setToRow] = useState((currentPage + 1) * pageSize);

    const btnDisabled = useCallback(
        (btn: string) => {
            if (btn === 'first') {
                return currentPage === 0;
            }
            if (btn === 'previous') {
                return currentPage === 0;
            }
            if (btn === 'next') {
                return currentPage === totalPages - 1;
            }
            if (btn === 'last') {
                return currentPage === totalPages - 1;
            }
        },
        [currentPage, totalPages]
    );

    const onPageChanged = useCallback(() => {
        let nodeCount = 0;
        props.api.forEachNode((node) => {
            if (!node.group) nodeCount++;
        });

        const updatedCurrentPage = props.api.paginationGetCurrentPage();
        const updatedPageSize = props.api.paginationGetPageSize();

        setCount(nodeCount);
        setFromRow(updatedCurrentPage * updatedPageSize + 1);
        setToRow((updatedCurrentPage + 1) * updatedPageSize);
        setTotalPages(props.api.paginationGetTotalPages());
        setPageSize(updatedPageSize);
        setCurrentPage(updatedCurrentPage);
    }, [props.api]);

    const onBtnLast = useCallback(() => {
        onPageChanged();
        props.api.paginationGoToLastPage();
    }, [onPageChanged, props.api]);

    const onBtFirst = useCallback(() => {
        onPageChanged();
        props.api.paginationGoToFirstPage();
    }, [onPageChanged, props.api]);

    const onBtPrevious = useCallback(() => {
        onPageChanged();
        props.api.paginationGoToPreviousPage();
    }, [onPageChanged, props.api]);

    const onBtNext = useCallback(() => {
        onPageChanged();
        props.api.paginationGoToNextPage();
    }, [onPageChanged, props.api]);

    useEffect(() => {
        props.api.addEventListener('paginationChanged', onPageChanged);
    }, [onPageChanged, props.api]);

    useEffect(() => {
        setCount(props.api.getDisplayedRowCount());
        let nodeCount = 0;
        props.api.forEachNode((node) => {
            if (!node.group) nodeCount++;
        });
        setCount(nodeCount);
    }, [props.api]);

    const notEnoughRowsToPaginate = count <= pageSize;

    return (
        <Wrapper>
            {notEnoughRowsToPaginate ? (
                <div className="ag-paging-row-summary-panel">
                    <span>Showing </span>
                    <span className="ag-paging-row-summary-panel-number">{count}</span>
                    {count > 1 && <span> styles</span>}
                    {count === 1 && <span> style</span>}
                </div>
            ) : (
                <div className="ag-paging-row-summary-panel">
                    <span>Showing style </span>
                    <span className="ag-paging-row-summary-panel-number">{fromRow}</span>
                    <span> to </span>
                    <span className="ag-paging-row-summary-panel-number">{toRow}</span>
                    <span> of </span>
                    <span className="ag-paging-row-summary-panel-number">{count}</span>
                    <span> styles</span>
                </div>
            )}
            <Pagination className="ag-paging-description">
                <div
                    className={
                        btnDisabled('first') ? 'ag-button ag-paging-button ag-disabled' : 'ag-button ag-paging-button'
                    }
                    onClick={onBtFirst}
                    onKeyUp={onBtFirst}
                >
                    <span className="ag-icon ag-icon-first"></span>
                </div>
                <div
                    className={
                        btnDisabled('previous')
                            ? 'ag-button ag-paging-button ag-disabled'
                            : 'ag-button ag-paging-button'
                    }
                    onClick={onBtPrevious}
                    onKeyUp={onBtPrevious}
                >
                    <span className="ag-icon ag-icon-previous"></span>
                </div>
                {totalPages > 0 && (
                    <span>
                        <span>Page </span>
                        <span className="ag-paging-number">{currentPage + 1}</span>
                        <span> of </span>
                        <span className="ag-paging-number">{totalPages}</span>
                    </span>
                )}
                <div
                    className={
                        btnDisabled('next') ? 'ag-button ag-paging-button ag-disabled' : 'ag-button ag-paging-button'
                    }
                    onClick={onBtNext}
                    onKeyUp={onBtNext}
                >
                    <span className="ag-icon ag-icon-next"></span>
                </div>
                <div
                    className={
                        btnDisabled('last') ? 'ag-button ag-paging-button ag-disabled' : 'ag-button ag-paging-button'
                    }
                    onClick={onBtnLast}
                    onKeyUp={onBtnLast}
                >
                    <span className="ag-icon ag-icon-last"></span>
                </div>
            </Pagination>
        </Wrapper>
    );
}

const Pagination = styled.span`
  display: flex;
  align-items: center;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 40px;
`;
