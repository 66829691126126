import { uniq } from 'lodash';
import { TurnoverRow, TurnoverRowTuple } from 'src/domain';
import { TableDetailsMap } from 'src/domain/table/table-details-map';
import { TableOverviewRow, TableOverviewRowTuple } from 'src/domain/table/table-overview-row';
import { mergeMap } from 'src/utils/mergeMap';

export const mergeTableOverviewRowValuesToTuples = <
    TableColumnType = number,
    TableRowType extends TableOverviewRow<TableColumnType> = TableOverviewRow<TableColumnType>,
>(
    a: TableRowType[] | undefined,
    b: TableRowType[] | undefined
) => {
    const result = mergeMap([a ?? [], b ?? []], ([rowA, rowB], i): TableOverviewRowTuple<TableColumnType> => {
        const tempRow: TableOverviewRowTuple<TableColumnType> = {
            id: i,
            category: rowA?.category ?? '', // shouldn't matter, both are the same
            subCategory: rowA?.subCategory ?? '', // shouldn't matter, both are the same
            columns: {},
        };

        const keys = uniq([...Object.keys(rowA?.columns ?? {}), ...Object.keys(rowB?.columns ?? {})]);
        keys.forEach((key) => {
            const current = rowA?.columns[key];
            const predicted = rowB?.columns[key];

            // only use predicted value if it is different from current value
            const predictedIfDifferent = predicted !== current ? predicted : undefined;

            tempRow.columns[key] = [current, predictedIfDifferent] as const;
        });

        return tempRow as TableOverviewRowTuple<TableColumnType>;
    });

    return result;
};

export const mergeTableDetailsRowValuesToTuples = <
    T extends Record<string, unknown>,
    R extends Record<keyof T, readonly [unknown, unknown] | unknown>,
>(
    a: TableDetailsMap<T[]> | undefined,
    b: TableDetailsMap<T[]> | undefined,
    properties: Array<keyof T>
): TableDetailsMap<R[]> => {
    const tempMap: TableDetailsMap<R[]> = new Map();

    const keys = uniq([...Array.from(a?.keys() ?? []), ...Array.from(b?.keys() ?? [])]);

    keys.forEach((fieldName) => {
        const currentRows = a?.get(fieldName);
        const predictedRows = b?.get(fieldName);

        const result = mergeMap([currentRows ?? [], predictedRows ?? []], ([rowA, rowB]) => {
            if (!rowA) {
                return;
            }

            const tempRow: Partial<R> = {};
            const keys: Array<keyof T> = Object.keys(rowA);
            keys.forEach((key) => {
                const current = rowA[key];
                const predicted = rowB?.[key];

                const predictedIfDifferent = predicted !== current ? predicted : undefined;
                if (properties.includes(key)) {
                    tempRow[key] = [current, predictedIfDifferent] as const as never;
                } else {
                    tempRow[key] = (predictedIfDifferent ?? current) as never;
                }
            });

            return tempRow;
        });

        tempMap.set(fieldName, result as never);
    });

    return tempMap;
};

export const mergeTurnoverTableDataToTuples = (a: TurnoverRow[], b: TurnoverRow[]): TurnoverRowTuple[] => {
    return a.map((aVal) => {
        const bVal = b.find((bVal) => bVal.month === aVal.month);

        return {
            ...aVal,
            plannedIndex: [
                aVal.plannedIndex,
                typeof bVal?.plannedIndex !== 'undefined' && aVal.plannedIndex !== bVal.plannedIndex
                    ? bVal.plannedIndex
                    : undefined,
            ],
            plannedSalesIv: [
                aVal.plannedSalesIv,
                typeof bVal?.plannedSalesIv !== 'undefined' && aVal.plannedSalesIv !== bVal.plannedSalesIv
                    ? bVal.plannedSalesIv
                    : undefined,
            ],
        } as TurnoverRowTuple;
    });
};
