import { ColDef, ICellRendererParams } from 'ag-grid-community';
import { DefaultColumn } from './default-columns-details';

export const defaultCategoryColumn: ColDef = {
    ...DefaultColumn,
    field: 'category',
    headerName: 'Category',
    width: 150,
    suppressSizeToFit: true,
    cellRenderer: (params: ICellRendererParams) => {
        return params.value ?? 'Sum';
    },
    pinned: 'left',
    lockPinned: true,
};
