import { useMemo } from 'react';
import { Inheritance, Module } from 'src/domain';
import { useDefaultInheritance } from 'src/hooks/inheritance';
import { useGendersApiQuery } from 'src/hooks/useGendersApiQuery';
import { useGenderSplitSplitLevelQuery } from './useGenderSplitSplitLevelQuery';

export const useGenderSplitInheritanceQuery = (inheritance?: Inheritance) => {
    const { data, error, loading } = useGenderSplitSplitLevelQuery() ?? { loading: false };
    const moduleInheritance = useDefaultInheritance(Module.GenderSplit);
    const selectedInheritance = inheritance ?? moduleInheritance;

    const { data: genders, error: gendersError, loading: gendersLoading } = useGendersApiQuery();

    const sanitizedData = useMemo(() => {
        if (!data) return;

        switch (selectedInheritance) {
            case Inheritance.Typed:
            case Inheritance.Historical:
                return data.storeGenderSplit ?? data.clusterGenderSplit ?? data.partnerGenderSplit;
            case Inheritance.Cluster:
                return data.clusterGenderSplit;

            case Inheritance.Partner:
                return data.partnerGenderSplit;
        }
    }, [data, selectedInheritance]);

    const genderData = genders?.map((gender) => ({
        genderId: gender.id,
        genderName: gender.name,
    }));

    return {
        expected: sanitizedData,
        genders: genderData,
        data,
        error: error ?? gendersError,
        loading: loading || gendersLoading,
    };
};
