import { useMemo } from 'react';
import { mapReleases } from 'src/mapping/notifications.mapping';
import { useNotificationsQuery } from '../queries/useNotificationsQuery';

export const useReleases = () => {
    const { data, loading, error } = useNotificationsQuery();

    const releases = useMemo(() => {
        if (!data) return;
        const filteredData = data.filter((notification) => notification.type === 'Release');
        return mapReleases(filteredData);
    }, [data]);

    return { data: releases, loading, error };
};
