import { useReactiveVar } from '@bestseller-bit/retail-planning.utils.reactive-vars';
import { FC, useCallback, useEffect, useMemo } from 'react';
import Select from 'src/components/atoms/Select';
import { GroupedOption } from 'src/components/atoms/Select/Select';
import { storefrontTypeVar } from 'src/infrastructure/local_state';
import { useStorefrontTypes } from './useStorefrontTypes';

export const StorefrontTypeSelect: FC = () => {
    const [storefrontType, setStorefrontType] = useReactiveVar(storefrontTypeVar);
    const storeFrontTypes = useStorefrontTypes();
    const storefrontTypeOptions: GroupedOption[] = useMemo(() => {
        return storeFrontTypes.map((x) => ({ isHeader: false, display: x, value: x }));
    }, [storeFrontTypes]);

    useEffect(() => {
        if (storefrontTypeOptions.length > 0) {
            const hasChainStorefrontType = storefrontTypeOptions.some((x) => x.value === 'Chain Storefront');
            const hasStorefrontType = !!storefrontType && storefrontTypeOptions.some((x) => x.value === storefrontType);

            // If the current storefront type is not in the list of available storefront types, we need to reset it
            // if Chain storefront is present, we use that, otherwise we use the first in the list.

            if (hasChainStorefrontType && !hasStorefrontType) {
                setStorefrontType('Chain Storefront');
            } else if (!hasStorefrontType) {
                setStorefrontType(storefrontTypeOptions[0].value);
            }
        }
    }, [storefrontTypeOptions, storefrontType, setStorefrontType]);

    const handleStorefrontTypeChange = useCallback(
        (value: string) => {
            setStorefrontType(value);
        },
        [setStorefrontType]
    );

    return (
        <Select
            width={170}
            size="small"
            options={storefrontTypeOptions}
            onSelect={handleStorefrontTypeChange}
            activeItem={storefrontType}
            label="Select storefront type"
        ></Select>
    );
};
