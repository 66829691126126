import { useMemo } from 'react';
import { useCurrentStore } from '../store';
import { useQueryParams } from '../useQueryParams';

export const useCluster = () => {
    const { cluster } = useQueryParams();
    const store = useCurrentStore();

    const clusterName = useMemo(() => {
        return cluster ?? store?.cluster?.name;
    }, [cluster, store?.cluster?.name]);

    return clusterName;
};
