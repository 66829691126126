import { isNumber, sortBy } from 'lodash';
import { Nullable } from 'src/domain';
import { IntersectedSplitExpectedDataNew } from 'src/domain/models/split-modules';
import { NoosShareDetailsRow } from 'src/domain/table/noos-share.row';
import { StyleCategoryModel } from 'src/infrastructure/rest-api/api-types';
import { getNext12MonthKeys, monthKeyToField } from 'src/utils/monthKeys';
import { getGenderProductLineSplit } from './getGenderProductLineSplit';
import { ModuleExpectedNew } from './inheritance.mapping';
import { createFindSalesFunction, createFindSplitFunction } from './split-modules.mapping';
import { ExpectedSalesData } from './turnover-mapping';

function groupByMonth(data: ModuleExpectedNew[]) {
    return data?.reduce<Map<number, ModuleExpectedNew[]>>((acc, item) => {
        if (!item?.monthKey) {
            return acc;
        }

        const items = acc.get(item.monthKey) ?? [];
        items.push(item);
        acc.set(item.monthKey, items);

        return acc;
    }, new Map<number, ModuleExpectedNew[]>());
}

export function groupByStyleCategories(data: ModuleExpectedNew[], styleCategories: StyleCategoryModel[]) {
    const sortedStyleCategories = sortBy(data, (value, _) => {
        const categorySort = styleCategories?.find((x) => x?.id === value?.styleCategoryId)?.order;
        return categorySort;
    });

    return sortedStyleCategories?.reduce((acc, item) => {
        const styleCategoryId = item?.styleCategoryId;

        if (!styleCategoryId) {
            return acc;
        }

        const items = acc.get(styleCategoryId) ?? [];
        items.push(item);

        acc.set(styleCategoryId, items);

        return acc;
    }, new Map<number, ModuleExpectedNew[]>());
}

export function getStyleCategoryName(data: StyleCategoryModel[], id: number) {
    return data?.find((x) => x?.id === id)?.name?.toUpperCaseLetters(1);
}

export const mapNoosToDetailsTableData = (details: {
    noosData: ModuleExpectedNew[] | undefined;
    salesData: ExpectedSalesData[] | undefined | null;
    genderData?: {
        split: Nullable<IntersectedSplitExpectedDataNew[]>;
        isUsingGender: boolean;
        activeGender: number | undefined;
    };
    productLineData?: {
        expectedSplit: Nullable<IntersectedSplitExpectedDataNew[]>;
        isUsingProductLine: boolean;
        activeProductLine: number | undefined;
    };
    categorySplitData: Nullable<IntersectedSplitExpectedDataNew[]>;
    styleCategories: StyleCategoryModel[] | undefined;
}): Map<string, NoosShareDetailsRow[]> | undefined => {
    const noosData = details.noosData;
    const salesData = details.salesData;
    const styleCategories = details.styleCategories;
    const genderData = details.genderData;
    const productLineData = details.productLineData;
    const categorySplitData = details.categorySplitData;

    if (!noosData || !styleCategories || !genderData || !productLineData) return;

    const dataGroupedByMonth = groupByMonth(noosData);

    const rows = Array.from(dataGroupedByMonth.entries()).reduce<Map<string, NoosShareDetailsRow[]>>(
        (acc, monthGroup) => {
            const [monthKey, monthValues] = monthGroup;
            const monthName = monthKeyToField(monthKey);
            const next12MonthKeys = getNext12MonthKeys();

            const dataGroupedByStyleCategories = groupByStyleCategories(monthValues, styleCategories);

            const rows = Array.from(dataGroupedByStyleCategories.entries()).reduce<NoosShareDetailsRow[]>(
                (accRow, styleGroup, innerIndex) => {
                    const [styleCategoryId, _] = styleGroup;
                    const styleCategoryName = getStyleCategoryName(styleCategories, styleCategoryId);

                    if (!styleCategoryName) return accRow;

                    const findSplitFunction = createFindSplitFunction(monthKey, 'styleCategories', {
                        groupId: styleCategoryId,
                    });
                    const findSaleFunction = createFindSalesFunction(monthKey);

                    const findGenderSplitFunction = createFindSplitFunction(monthKey, 'genders', {
                        groupId: genderData?.activeGender,
                    });

                    const findProductLineSplitFunction = createFindSplitFunction(monthKey, 'productLines', {
                        groupId: productLineData.activeProductLine,
                        genderId: genderData?.activeGender,
                    });

                    const plannedPercent = noosData?.find(findSplitFunction)?.splitPct;
                    const sharePercentLY = noosData?.find(findSplitFunction)?.splitPctLY;
                    const sharePercentLLY = noosData?.find(findSplitFunction)?.splitPctLLY;
                    const gmLY = noosData?.find(findSplitFunction)?.grossMarginPctLY;

                    const plannedSales = salesData?.find(findSaleFunction)?.salesExpectediVlcy;
                    const salesLY = salesData?.find(findSaleFunction)?.salesiVlcyLY;
                    const salesLLY = salesData?.find(findSaleFunction)?.salesiVlcyLLY;

                    const genderSplit = genderData.split?.find(findGenderSplitFunction);
                    const plannedGenderSplit = genderSplit?.splitPct;
                    const genderSplitLY = genderSplit?.splitPctLY;
                    const genderSplitLLY = genderSplit?.splitPctLLY;

                    const productLineSplit = productLineData.expectedSplit?.find(findProductLineSplitFunction);
                    const plannedProductLineSplit = productLineSplit?.splitPct;
                    const productLineSplitLY = productLineSplit?.splitPctLY;
                    const productLineSplitLLY = productLineSplit?.splitPctLLY;

                    const plannedGenderProductLineSplit = getGenderProductLineSplit({
                        isUsingGender: genderData?.isUsingGender,
                        isUsingProductLine: productLineData?.isUsingProductLine,
                        genderSplit: plannedGenderSplit,
                        productLineSplit: plannedProductLineSplit,
                    });

                    const genderProductLineSplitLY = getGenderProductLineSplit({
                        isUsingGender: genderData?.isUsingGender,
                        isUsingProductLine: productLineData?.isUsingProductLine,
                        genderSplit: genderSplitLY,
                        productLineSplit: productLineSplitLY,
                    });

                    const genderProductLineSplitLLY = getGenderProductLineSplit({
                        isUsingGender: genderData?.isUsingGender,
                        isUsingProductLine: productLineData?.isUsingProductLine,
                        genderSplit: genderSplitLLY,
                        productLineSplit: productLineSplitLLY,
                    });

                    const categorySplit = categorySplitData?.find(findSplitFunction);
                    const plannedCategorySplit = categorySplit?.splitPct;
                    const categorySplitLY = categorySplit?.splitPctLY;
                    const categorySplitLLY = categorySplit?.splitPctLLY;

                    if (!isNumber(plannedPercent) || !isNumber(sharePercentLY) || !isNumber(sharePercentLLY))
                        return accRow;

                    const sharePercentLyisNA = () => {
                        if (dataGroupedByMonth.size === 18 && !next12MonthKeys.includes(monthKey)) return true;
                        return false;
                    };
                    const plannedValue =
                        isNumber(plannedCategorySplit) && isNumber(plannedSales)
                            ? plannedSales *
                              plannedGenderProductLineSplit *
                              (plannedCategorySplit / 100) *
                              (plannedPercent / 100)
                            : NaN;
                    const shareValueLY =
                        isNumber(categorySplitLY) && isNumber(salesLY)
                            ? salesLY * genderProductLineSplitLY * (categorySplitLY / 100) * (sharePercentLY / 100)
                            : NaN;
                    const shareValueLLY =
                        isNumber(categorySplitLLY) && isNumber(salesLLY)
                            ? salesLLY * genderProductLineSplitLLY * (categorySplitLLY / 100) * (sharePercentLLY / 100)
                            : NaN;

                    accRow.push({
                        id: innerIndex,
                        category: styleCategoryName,
                        plannedPercent,
                        plannedValue: Math.round(plannedValue * 100) / 100,
                        sharePercentLY: sharePercentLyisNA() ? -999 : sharePercentLY,
                        shareValueLY: Math.round(shareValueLY * 100) / 100,
                        sharePercentLLY,
                        shareValueLLY: Math.round(shareValueLLY * 100) / 100,
                        gmLY,
                    });
                    return accRow;
                },
                []
            );

            acc.set(monthName, [...rows]);
            return acc;
        },
        new Map()
    );

    return rows;
};
