import { useMemo } from 'react';
import { Module, ScopeEnum } from 'src/domain';
import { useCategorySplitQuery } from 'src/hooks/category-split';
import { useGenderId, useIsUsingGender } from 'src/hooks/gender';
import { useGenderSplitQuery } from 'src/hooks/gender-split/queries/useGenderSplitQuery';
import { useInheritance } from 'src/hooks/inheritance';
import { useIsUsingProductline, useProductLineId } from 'src/hooks/productline';
import { useProductLineSplitQuery } from 'src/hooks/productline-split/queries/useProductLineSplitQuery';
import { useTurnoverQuery } from 'src/hooks/turnover/queries/useTurnoverQuery';
import { useScope } from 'src/hooks/useScope';
import { mapInheritanceToExpectedNew } from 'src/mapping/inheritance.mapping';
import { mapNoosToDetailsTableData } from 'src/mapping/noos-share.mapping';
import { useNoosShareQuery } from '../queries/useNoosShareQuery';

export const useNoosShareDetailsRows = () => {
    const scope = useScope();
    const gender = useGenderId(Module.NoosShare);
    const productLine = useProductLineId(Module.NoosShare);
    const isUsingGender = useIsUsingGender();
    const isUsingProductLine = useIsUsingProductline();

    const { expected, styleCategories, loading, error } = useNoosShareQuery();
    const { expectedSales } = useTurnoverQuery() ?? {};
    const { expectedSplit: categorySplitExpected } = useCategorySplitQuery(undefined, gender, productLine);
    const { expected: genderExpected } = useGenderSplitQuery();
    const { expectedSplit: productLineExpected } = useProductLineSplitQuery();

    const inheritance = useInheritance(Module.NoosShare);

    const tableData = useMemo(() => {
        if (!inheritance) {
            return;
        }

        switch (scope) {
            case ScopeEnum.STORE:
                return mapNoosToDetailsTableData({
                    noosData: mapInheritanceToExpectedNew(expected, inheritance),
                    salesData: expectedSales,
                    styleCategories,
                    categorySplitData: categorySplitExpected,
                    genderData: {
                        split: genderExpected,
                        isUsingGender,
                        activeGender: gender,
                    },
                    productLineData: {
                        expectedSplit: productLineExpected,
                        isUsingProductLine,
                        activeProductLine: productLine,
                    },
                });
            case ScopeEnum.CLUSTER:
                return mapNoosToDetailsTableData({
                    noosData: mapInheritanceToExpectedNew(expected, inheritance),
                    salesData: expectedSales,
                    styleCategories,
                    categorySplitData: categorySplitExpected,
                    genderData: {
                        split: genderExpected,
                        isUsingGender,
                        activeGender: gender,
                    },
                    productLineData: {
                        expectedSplit: productLineExpected,
                        isUsingProductLine,
                        activeProductLine: productLine,
                    },
                });
            case ScopeEnum.PARTNER:
                return mapNoosToDetailsTableData({
                    noosData: mapInheritanceToExpectedNew(expected, inheritance),
                    salesData: expectedSales,
                    styleCategories,
                    categorySplitData: categorySplitExpected,
                    genderData: {
                        split: genderExpected,
                        isUsingGender,
                        activeGender: gender,
                    },
                    productLineData: {
                        expectedSplit: productLineExpected,
                        isUsingProductLine,
                        activeProductLine: productLine,
                    },
                });

            default:
                return;
        }
    }, [
        categorySplitExpected,
        expectedSales,
        expected,
        gender,
        genderExpected,
        isUsingGender,
        isUsingProductLine,
        productLine,
        productLineExpected,
        scope,
        styleCategories,
        inheritance,
    ]);

    return { data: tableData, loading, error };
};
