import { Inheritance } from 'src/domain';
import { useStyleCategoriesQuery } from 'src/hooks/style-categories/queries/useStyleCategoriesQuery';
import { useDeliveryProfileInheritanceQuery } from './useDeliveryProfileInheritanceQuery';

export const useDeliveryProfileQuery = (inheritance?: Inheritance) => {
    const { data, expected, error, loading } = useDeliveryProfileInheritanceQuery(inheritance);
    const {
        data: styleCategories,
        error: styleCategoriesError,
        loading: styleCategoriesLoading,
    } = useStyleCategoriesQuery();

    return {
        expected,
        data,
        styleCategories: styleCategories,
        error: error ?? styleCategoriesError,
        loading: loading || styleCategoriesLoading,
    };
};
