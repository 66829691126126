import { FC, PropsWithChildren, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Module, ScopeEnum } from 'src/domain';
import { useScope } from 'src/hooks';

export const ProjectedOptionCountGuard: FC<PropsWithChildren<unknown>> = ({ children }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const scope = useScope();

    useEffect(() => {
        if (scope === ScopeEnum.CLUSTER) {
            // Navigate to sales budget
            navigate({
                ...location,
                pathname: `/${Module.Turnover}`,
            });
        }
    }, [location, navigate, scope]);

    return <>{children}</>;
};
