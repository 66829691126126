import { isEmpty } from 'lodash';
import { useCallback, useMemo } from 'react';
import {
    CategorySplitStorageKey,
    MarkdownStorageKey,
    MarkupStorageKey,
    Module,
    ProductLineStorageKey,
    ScopeEnum,
    SessionStorageType,
    TurnoverStorageKey,
} from 'src/domain';
import { ClusterManagementStorageKey } from 'src/domain/session-storage/cluster-management-key';
import { DeliveryProfileStorageKey } from 'src/domain/session-storage/delivery-profile-key';
import { GenderSplitStorageKey } from 'src/domain/session-storage/gender-split-key';
import { InheritanceSettingsStorageKey } from 'src/domain/session-storage/inheritance-settings-key';
import { NoosShareStorageKey } from 'src/domain/session-storage/noos-share-key';
import { OpenToBuyLimitStorageKey } from 'src/domain/session-storage/open-to-buy-limit-key';
import { OptimalOptionCountStorageKey } from 'src/domain/session-storage/optimal-option-count-key';
import { SalesCampaignPlanningStorageKey } from 'src/domain/session-storage/sales-campaign-planning-key';
import { StockManagementStorageKey } from 'src/domain/session-storage/stock-management-key';
import { StyleTimelineStorageKey } from 'src/domain/session-storage/style-timeline-key';
import { WeekCoverStorageKey } from 'src/domain/session-storage/week-cover-key';
import { getSplitLevelKey } from 'src/utils/getSplitLevelKey';
import { useCurrentId } from '../useCurrentId';
import { useCurrentModule } from '../useCurrentModule';
import { useScope } from '../useScope';
import { useUnsavedChanges } from '../useUnsavedChanges';

export const useUnsavedChangesModule = <T>(module?: Module, scope?: ScopeEnum, id?: string | number) => {
    const currentId = useCurrentId();
    const idToUse = id ?? currentId;

    const currentModule = useCurrentModule();
    const moduleToUse = module ?? currentModule;

    const currentScope = useScope();
    const scopeToUse = scope ?? currentScope;

    const splitLevelKey = useMemo(() => getSplitLevelKey(scopeToUse), [scopeToUse]);

    const moduleKey = useMemo(() => {
        switch (moduleToUse) {
            case Module.Turnover:
                return TurnoverStorageKey;
            case Module.CategorySplit:
                return CategorySplitStorageKey;
            case Module.Markup:
                return MarkupStorageKey;
            case Module.Markdown:
                return MarkdownStorageKey;
            case Module.ProductLineSplit:
                return ProductLineStorageKey;
            case Module.WeekCover:
                return WeekCoverStorageKey;
            case Module.StockManagement:
                return StockManagementStorageKey;
            case Module.NoosShare:
                return NoosShareStorageKey;
            case Module.GenderSplit:
                return GenderSplitStorageKey;
            case Module.DeliveryProfile:
                return DeliveryProfileStorageKey;
            case Module.OpenToBuyLimit:
                return OpenToBuyLimitStorageKey;
            case Module.InheritanceSettings:
                return InheritanceSettingsStorageKey;
            case Module.StyleTimeline:
                return StyleTimelineStorageKey;
            case Module.OptimalOptionCount:
                return OptimalOptionCountStorageKey;
            case Module.SalesCampaignsPlanning:
                return SalesCampaignPlanningStorageKey;
            case Module.ClusterManagement:
                return ClusterManagementStorageKey;
        }
    }, [moduleToUse]);

    const [unsavedChangesForLevel, setUnsavedChangesForLevel] = useUnsavedChanges<SessionStorageType<T>>(splitLevelKey);

    const setUnsavedChangesForModule = useCallback(
        (object: T) => {
            if (!idToUse) return;
            if (!moduleKey) return;

            const currentChanges = {
                ...unsavedChangesForLevel,
                ...{
                    [idToUse]: {
                        ...unsavedChangesForLevel?.[idToUse],
                        [moduleKey]: structuredClone(object),
                    },
                },
            };

            if (isEmpty(object)) delete currentChanges[idToUse][moduleKey];

            setUnsavedChangesForLevel(currentChanges);
        },
        [idToUse, moduleKey, setUnsavedChangesForLevel, unsavedChangesForLevel]
    );

    if (!idToUse) return [];
    if (!moduleKey) return [];

    return [unsavedChangesForLevel?.[idToUse]?.[moduleKey], setUnsavedChangesForModule] as const;
};
