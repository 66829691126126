import { useContext, useEffect, useMemo } from 'react';
import { Module, ScopeEnum } from 'src/domain';
import { MarkupLevelQuery } from '../markup/queries/useMarkupLevelQuery';
import { useScope } from '../useScope';
import { IsHistoricalContext } from './IsHistoricalContext';

export const useIsMarkupHistorical = (data: MarkupLevelQuery['data'] | undefined) => {
    const scope = useScope();

    const isTyped = useMemo(() => {
        switch (scope) {
            case ScopeEnum.STORE:
                return data?.storeMarkup?.some((x) => !!x?.modifiedByUser);

            case ScopeEnum.CLUSTER:
                return data?.clusterMarkup?.some((x) => !!x?.modifiedByUser);

            case ScopeEnum.PARTNER:
                return data?.partnerMarkup?.some((x) => !!x?.modifiedByUser);
        }
    }, [data?.clusterMarkup, data?.partnerMarkup, data?.storeMarkup, scope]);

    // invert isTyped but keep undefined
    if (isTyped === true) return false;
    if (isTyped === false) return true;
    return undefined;
};

export const useInitializeIsMarkupHistorical = (data: MarkupLevelQuery['data'] | undefined) => {
    const [isHistoricalMap, setIsHistoricalMap] = useContext(IsHistoricalContext);

    const isMarkupHistorical = useIsMarkupHistorical(data);

    useEffect(() => {
        if (isMarkupHistorical === undefined) return;
        if (isHistoricalMap.get(Module.Markup) === isMarkupHistorical) return;

        isHistoricalMap.set(Module.Markup, isMarkupHistorical);
        setIsHistoricalMap(new Map(isHistoricalMap));
    }, [isMarkupHistorical, isHistoricalMap, setIsHistoricalMap]);
};
