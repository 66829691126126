import { RowHeightParams } from 'ag-grid-community';

export const COMPACT_ROW_HEIGHT = 33;
export const LARGE_ROW_HEIGHT = 40;

export const customRowGroupHeight = (params: RowHeightParams) => {
    const isRowGroupParent = (key: string | null) => {
        return key;
    };

    const node = params?.node;

    if (isRowGroupParent(node?.key) && !node?.firstChild) return LARGE_ROW_HEIGHT;

    return COMPACT_ROW_HEIGHT;
};

export const defaultLargeRowHeight = () => {
    return LARGE_ROW_HEIGHT;
};
