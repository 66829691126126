import { isNumber, round } from 'lodash';

interface NumberWithSpacesFormattingOptions {
    decimalSeparator?: string;
    postfix?: string;
    default?: string;
}

export const numberWithSpaces = (nbr: number | null | undefined, options: NumberWithSpacesFormattingOptions = {}) => {
    if (!isNumber(nbr) || isNaN(nbr) || !isFinite(nbr)) {
        return options.default ?? '';
    }

    const formattedNumber = round(nbr, 0)
        .toString()
        // TODO: Replace this regex since it's vulnerable to denial of service attacks
        .replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

    return `${formattedNumber}${options.postfix ?? ''}`;
};
