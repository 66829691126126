import { isNumber, sortBy } from 'lodash';
import {
    ArrayElement,
    CategoryDetailsRow,
    GenderIdToGenderMap,
    Nullable,
    ProductLineGroup,
    hasProductlineGroupId,
} from 'src/domain';
import {
    IntersectedSplitExpectedArrayData,
    IntersectedSplitExpectedData,
    IntersectedSplitExpectedDataNew,
} from 'src/domain/models/split-modules';
import { TableOverviewRow } from 'src/domain/table/table-overview-row';

import { filterEmpty } from 'src/components/organisms/DataTable/columns/utils';
import { hasGenderId } from 'src/domain/models/gender';
import { isProductLineGroup } from 'src/domain/models/productLineGroup';
import { StyleCategory, hasStyleCategoryId, isStyleCategory } from 'src/domain/models/style-category';
import { getNext12MonthKeys, monthKeyToField } from 'src/utils/monthKeys';
import { calculateIndexLy } from 'src/utils/turnover/calculateIndexLy';
import { calculatePlannedIndex } from 'src/utils/turnover/calculatePlannedIndex';
import { getGenderProductLineSplit } from './getGenderProductLineSplit';
import { ExpectedSalesData } from './turnover-mapping';

function sortMapEntriesByKeys<T>(a: [number, T], b: [number, T]) {
    return a[0] - b[0];
}

export function groupByProductLine<T extends DataToGroup[]>(data: T) {
    return sortBy(data, ['productlineGroupId'])?.reduce((acc, item) => {
        const productLineId = item?.productlineGroupId;

        if (!productLineId) {
            return acc;
        }

        const items = acc.get(productLineId) ?? ([] as unknown as T);
        items.push(item);
        acc.set(productLineId, items);

        return acc;
    }, new Map<number, T>());
}

export function groupByGender<T extends DataToGroup[]>(data: T) {
    return sortBy(data, ['genderId'])?.reduce((acc, item) => {
        const genderId = item?.genderId;

        if (!genderId) {
            return acc;
        }

        const items = acc.get(genderId) ?? ([] as unknown as T);
        items.push(item);

        acc.set(genderId, items);

        return acc;
    }, new Map<number, T>());
}

export function groupByStyleCategory<T extends DataToGroup[]>(data: T, styleCategories: StyleCategory[]) {
    const sortedStyleCategories = sortBy(data, (value, _) => {
        const categorySort = styleCategories?.find((x) => x?.styleCategoryId === value?.styleCategoryId)?.categorySort;
        return categorySort;
    });

    return sortedStyleCategories?.reduce((acc, item) => {
        const styleCategoryId = item?.styleCategoryId;

        if (!styleCategoryId) {
            return acc;
        }

        const items = acc.get(styleCategoryId) ?? ([] as unknown as T);
        items.push(item);

        acc.set(styleCategoryId, items);

        return acc;
    }, new Map<number, T>());
}

export function groupByMonth<
    T extends ArrayElement<IntersectedSplitExpectedArrayData> | IntersectedSplitExpectedDataNew,
>(data: T[]) {
    return data?.reduce((acc, item) => {
        if (!item?.monthKey) {
            return acc;
        }

        const items = acc.get(item.monthKey) ?? [];
        items.push(item);
        acc.set(item.monthKey, items);

        return acc;
    }, new Map<number, T[]>());
}

interface DataToGroup {
    styleCategoryId?: number;
    productlineGroupId?: number;
    genderId?: number;
}

export interface GroupByData {
    styleCategoryId?: number;
    productLineGroupId?: number;
    genderId?: number;
}

export function dataGroupedBy<T extends DataToGroup[]>(data: GroupByData[], expected: T): Map<number, T> | undefined {
    const hasGenders = data?.some((x) => x?.genderId);

    if (hasStyleCategories(data)) return groupByStyleCategory(expected, data);
    if (hasProductLines(data)) return groupByProductLine(expected);
    if (hasGenders) return groupByGender(expected);
}

function hasStyleCategories(data: GroupByData[]): data is StyleCategory[] {
    return data?.some((x) => isStyleCategory(x)) ?? false;
}

function hasProductLines(data: GroupByData[]): data is ProductLineGroup[] {
    return data?.some((x) => isProductLineGroup(x)) ?? false;
}

export function getGroupName(data: GroupByData[], id: number) {
    const hasGenders = data?.some((x) => x?.genderId);

    if (hasStyleCategories(data)) {
        return data.find((x) => x?.styleCategoryId === id)?.styleCategoryName?.toUpperCaseLetters(1);
    }

    if (hasProductLines(data)) {
        return data?.find((x) => x?.productLineGroupId === id)?.productLineGroupName;
    }

    if (hasGenders) {
        return GenderIdToGenderMap.get(id)?.toUpperCaseLetters(1);
    }
}

function getHistoricalValue(value: IntersectedSplitExpectedDataNew) {
    return value.splitPctLY ?? 0;
}

export function valuesToRowNew(
    values: IntersectedSplitExpectedDataNew[],
    isHistorical: boolean
): Record<string, number> {
    return values.reduce(
        (acc, value, index) => {
            if (!value?.monthKey) {
                return acc;
            }

            const monthKeyStr = monthKeyToField(value.monthKey);
            acc[monthKeyStr] = isHistorical ? getHistoricalValue(value) : value.splitPct;
            acc[`${monthKeyStr}Id`] = value.id ?? parseInt(`${index}${value?.monthKey}`);
            return acc;
        },
        {} as Record<string, number>
    );
}

/**
 * @deprecated Use valuesToRowNew instead when switching to REST API
 */
export function valuesToRow(values: ArrayElement<IntersectedSplitExpectedArrayData>[]): Record<string, number> {
    return values.reduce(
        (acc, value, index) => {
            if (!value?.monthKey) {
                return acc;
            }

            const monthKeyStr = monthKeyToField(value.monthKey);
            acc[monthKeyStr] = value.splitPercent;
            acc[`${monthKeyStr}Id`] = value.id ?? parseInt(`${index}${value?.monthKey}`);
            return acc;
        },
        {} as Record<string, number>
    );
}

export function modifiedByUserToRow(
    values: ArrayElement<IntersectedSplitExpectedArrayData>[] | IntersectedSplitExpectedDataNew[]
): Record<string, boolean | undefined> {
    return values.reduce(
        (acc, value) => {
            if (!value?.monthKey) {
                return acc;
            }

            const monthKeyStr = monthKeyToField(value.monthKey);
            acc[monthKeyStr] = value.modifiedByUser;
            return acc;
        },
        {} as Record<string, boolean | undefined>
    );
}

export const mapSplitToOverviewTableData = (
    expected: Nullable<IntersectedSplitExpectedArrayData>,
    groupedByData: GroupByData[] | undefined
): TableOverviewRow[] | undefined => {
    if (!expected || !groupedByData) return;

    const grouped = dataGroupedBy(groupedByData, filterEmpty(expected));

    if (!grouped) return;

    const rows = Array.from(grouped.entries()).reduce<TableOverviewRow[]>((acc, group, outerIndex) => {
        const [groupId, groupValues] = group;
        const productLine = groupedByData?.find((productLine) => productLine?.productLineGroupId === groupId);
        const groupName = getGroupName(groupedByData, groupId);

        if (isProductLineGroup(productLine)) {
            const groupedBygender = groupByGender(groupValues);

            if (!groupedBygender) return acc;

            acc.push(
                ...Array.from(groupedBygender.entries())
                    .toSorted(sortMapEntriesByKeys)
                    .toReversed()
                    .reduce<TableOverviewRow[]>((accGender, genderGroup) => {
                        const [genderId, genderValues] = genderGroup;
                        const genderName = GenderIdToGenderMap.get(genderId);

                        if (!genderName || !productLine?.productLineGroupName) return acc;

                        accGender.push({
                            columns: valuesToRow(genderValues),
                            category: productLine.productLineGroupName,
                            subCategory: genderName.toUpperCaseLetters(1),
                            isModifiedByUser: modifiedByUserToRow(genderValues),
                            id: parseInt(`${genderId}${groupId}`),
                        });

                        return accGender;
                    }, [])
            );
        } else {
            acc.push({
                columns: valuesToRow(groupValues),
                category: groupName ?? '',
                isModifiedByUser: modifiedByUserToRow(groupValues),
                id: outerIndex,
            });
        }

        return acc;
    }, []);

    return rows;
};

export const mapSplitToOverviewTableDataNew = (
    expected: Nullable<IntersectedSplitExpectedDataNew[]>,
    groupedByData: GroupByData[] | undefined,
    isHistorical: boolean
): TableOverviewRow[] | undefined => {
    if (!expected || !groupedByData) return;

    const grouped = dataGroupedBy(groupedByData, expected);

    if (!grouped) return;

    const rows = Array.from(grouped.entries()).reduce<TableOverviewRow[]>((acc, group, outerIndex) => {
        const [groupId, groupValues] = group;
        const productLine = groupedByData?.find((productLine) => productLine?.productLineGroupId === groupId);
        const groupName = getGroupName(groupedByData, groupId);

        if (isProductLineGroup(productLine)) {
            const groupedBygender = groupByGender(groupValues);

            if (!groupedBygender) return acc;

            acc.push(
                ...Array.from(groupedBygender.entries())
                    .toSorted(sortMapEntriesByKeys)
                    .toReversed()
                    .reduce<TableOverviewRow[]>((accGender, genderGroup) => {
                        const [genderId, genderValues] = genderGroup;
                        const genderName = GenderIdToGenderMap.get(genderId);

                        if (!genderName || !productLine?.productLineGroupName) return acc;

                        accGender.push({
                            columns: valuesToRowNew(genderValues, isHistorical),
                            category: productLine.productLineGroupName,
                            subCategory: genderName.toUpperCaseLetters(1),
                            isModifiedByUser: modifiedByUserToRow(genderValues),
                            id: parseInt(`${genderId}${groupId}`),
                        });
                        return accGender;
                    }, [])
            );
        } else {
            acc.push({
                columns: valuesToRowNew(groupValues, isHistorical),
                category: groupName ?? '',
                isModifiedByUser: modifiedByUserToRow(groupValues),
                id: outerIndex,
            });
        }

        return acc;
    }, []);

    return rows;
};

interface CreateFindSplitFunctionOptions {
    groupId?: number;
    genderId?: number;
}

export const createFindSplitFunction = <T extends IntersectedSplitExpectedData | IntersectedSplitExpectedDataNew>(
    monthKey: number,
    groupedByType: GroupedByType,
    { groupId, genderId }: CreateFindSplitFunctionOptions
) => {
    return (split: T | null) => {
        const basicCondition = split?.monthKey === monthKey;

        const productlineGroupId = hasProductlineGroupId(split) ? split?.productlineGroupId : null;

        if (groupedByType === 'styleCategories' && hasStyleCategoryId(split)) {
            return basicCondition && split?.styleCategoryId === groupId;
        }
        if (groupedByType === 'productLines' && hasGenderId(split)) {
            return basicCondition && productlineGroupId === groupId && split?.genderId === genderId;
        }
        if (groupedByType === 'genders' && hasGenderId(split)) {
            return basicCondition && split?.genderId === groupId;
        }

        return false;
    };
};

export const createFindSalesFunction = (monthKey: number) => {
    return (sale: ExpectedSalesData | null) => {
        return sale?.monthKey === monthKey;
    };
};

const combineSaleAndSplitValues = (sale: Nullable<number>, split: Nullable<number>) => {
    if (isNumber(sale) && isNumber(split)) return Math.round(sale * split * 100) / 100;
};

function splitLyisNA(groupedByMonth: Map<number, unknown>, next12MonthKeys: number[], monthKey: number) {
    if (groupedByMonth.size === 18 && !next12MonthKeys.includes(monthKey)) return true;
    return false;
}

type GroupedByType = 'styleCategories' | 'productLines' | 'genders';

function getGroupedByType(groupedByData: GroupByData[]): GroupedByType | undefined {
    const hasGenders = groupedByData.some((x) => x?.genderId);

    if (hasStyleCategories(groupedByData)) return 'styleCategories';
    if (hasProductLines(groupedByData)) return 'productLines';
    if (hasGenders) return 'genders';
}

export const mapSplitToDetailsTableDataNew = <T extends CategoryDetailsRow>(data: {
    expectedSplit: Nullable<IntersectedSplitExpectedDataNew[]>;
    lastYearStoreSplit?: Nullable<IntersectedSplitExpectedDataNew[]>;
    groupedByData: GroupByData[] | undefined;
    expectedSalesIv: ExpectedSalesData[] | undefined | null;
    genderData?: {
        expectedSplit: Nullable<IntersectedSplitExpectedDataNew[]>;
        isUsingGender: boolean;
        activeGender: number | undefined;
    };
    productLineData?: {
        expectedSplit: Nullable<IntersectedSplitExpectedDataNew[]>;
        isUsingProductLine: boolean;
        activeProductLine: number | undefined;
    };
}): Map<string, T[]> | undefined => {
    if (!data) return;
    const groupedByData = data.groupedByData;
    const expectedSplit = data.expectedSplit;
    const expectedSalesIv = data.expectedSalesIv;
    const lastYearStoreSplit = data.lastYearStoreSplit;

    if (!expectedSplit || !groupedByData) return;

    const groupedByType = getGroupedByType(groupedByData);
    if (!groupedByType) return;

    const groupedByMonth = groupByMonth(expectedSplit);

    if (!groupedByMonth) return;

    const rows = Array.from(groupedByMonth?.entries()).reduce<Map<string, T[]>>((acc, monthGroup) => {
        const [monthKey, monthValues] = monthGroup;
        const fieldName = monthKeyToField(monthKey);
        const next12MonthKeys = getNext12MonthKeys();

        if (!monthValues) return acc;

        const grouped = dataGroupedBy(groupedByData, filterEmpty(monthValues));

        if (!grouped) return acc;

        const rows = Array.from(grouped.entries()).reduce<T[]>((accGroup, group, outerIndex) => {
            const [groupId, groupValues] = group;
            const groupName = getGroupName(groupedByData, groupId);
            const productLine = groupedByData?.find((productLine) => productLine?.productLineGroupId === groupId);

            if (isProductLineGroup(productLine)) {
                const groupedBygender = groupByGender(groupValues);

                if (!groupedBygender) return accGroup;

                accGroup.push(
                    ...Array.from(groupedBygender.entries())
                        .toSorted(sortMapEntriesByKeys)
                        .toReversed()
                        .reduce<T[]>((genderAcc, genderGroup) => {
                            const [genderId, genderValues] = genderGroup;
                            const genderName = GenderIdToGenderMap.get(genderId);

                            const findSplitFunction = createFindSplitFunction(monthKey, groupedByType, {
                                groupId,
                                genderId,
                            });
                            const findSalesFunction = createFindSalesFunction(monthKey);

                            const splitData = lastYearStoreSplit ?? expectedSplit;
                            const plannedSplit = genderValues.find(findSplitFunction)?.splitPct;

                            const split = expectedSplit.find(findSplitFunction);
                            const lastYearSplit = splitData.find(findSplitFunction);
                            const splitLy = lastYearSplit?.splitPctLY;
                            const splitLly = lastYearSplit?.splitPctLLY;

                            const cogsLY = genderValues?.find(findSplitFunction)?.cogsLY;

                            const gmLy = split?.grossMarginPctLY;

                            const expectedSales = expectedSalesIv?.find(findSalesFunction);
                            const plannedMonthSales = expectedSales?.salesExpectediVlcy;
                            const monthSalesLy = expectedSales?.salesiVlcyLY;
                            const monthSalesLly = expectedSales?.salesiVlcyLLY;

                            const plannedSalesIv = combineSaleAndSplitValues(
                                plannedMonthSales,
                                plannedSplit ? plannedSplit / 100 : 0
                            );
                            const salesIvLy = combineSaleAndSplitValues(monthSalesLy, splitLy ? splitLy / 100 : 0);
                            const salesIvLly = combineSaleAndSplitValues(monthSalesLly, splitLly ? splitLly / 100 : 0);

                            if (!genderName || !productLine?.productLineGroupName) return accGroup;
                            genderAcc.push({
                                id: parseInt(`${genderId}${groupId}`),
                                category: productLine?.productLineGroupName ?? '',
                                subCategory: genderName?.toUpperCaseLetters(1) ?? '',
                                splitLly,
                                splitLy: splitLyisNA(groupedByMonth, next12MonthKeys, monthKey) ? -999 : splitLy,
                                plannedSplit,
                                plannedIndex: calculatePlannedIndex(1, plannedSalesIv, salesIvLy, salesIvLly),
                                plannedSalesIv,
                                salesIvLy,
                                salesIvLly,
                                indexLy: calculateIndexLy(salesIvLy, salesIvLly),
                                gmLy,
                                cogsLY,
                            } as never);
                            return genderAcc;
                        }, [])
                );
            } else {
                const findSplitFunction = createFindSplitFunction(monthKey, groupedByType, { groupId });

                const findProductLineSplitFunction = createFindSplitFunction(monthKey, 'productLines', {
                    groupId: data?.productLineData?.activeProductLine,
                    genderId: data?.genderData?.activeGender,
                });

                const findGenderSplitFunction = createFindSplitFunction(monthKey, 'genders', {
                    groupId: data?.genderData?.activeGender,
                });

                const findSalesFunction = createFindSalesFunction(monthKey);
                const splitData = lastYearStoreSplit ?? expectedSplit;

                const split = expectedSplit.find(findSplitFunction);
                const plannedSplit = split?.splitPct;

                const lastYearSplit = splitData.find(findSplitFunction);
                const splitLy = lastYearSplit?.splitPctLY;
                const splitLly = lastYearSplit?.splitPctLLY;

                const genderSplit = data?.genderData?.expectedSplit?.find(findGenderSplitFunction);
                const genderPlannedSplit = genderSplit?.splitPct;
                const genderSplitLy = genderSplit?.splitPctLY;
                const genderSplitLly = genderSplit?.splitPctLLY;

                const productLineSplit = data?.productLineData?.expectedSplit?.find(findProductLineSplitFunction);
                const productLinePlannedSplit = productLineSplit?.splitPct;
                const productLineSplitLy = productLineSplit?.splitPctLY;
                const productLineSplitLly = productLineSplit?.splitPctLLY;
                const cogsLY = expectedSplit?.find(findSplitFunction)?.cogsLY;
                const netSalesLY = expectedSplit?.find(findSplitFunction)?.netSalesLY;

                const gmLy = split?.grossMarginPctLY;

                const expectedSales = expectedSalesIv?.find(findSalesFunction);
                const plannedMonthSales = expectedSales?.salesExpectediVlcy;
                const monthSalesLy = expectedSales?.salesiVlcyLY;
                const monthSalesLly = expectedSales?.salesiVlcyLLY;

                const genderProductLineSplit = getGenderProductLineSplit({
                    isUsingGender: data?.genderData?.isUsingGender,
                    isUsingProductLine: data?.productLineData?.isUsingProductLine,
                    genderSplit: genderPlannedSplit,
                    productLineSplit: productLinePlannedSplit,
                });

                const genderProductLineSplitLY = getGenderProductLineSplit({
                    isUsingGender: data?.genderData?.isUsingGender,
                    isUsingProductLine: data?.productLineData?.isUsingProductLine,
                    genderSplit: genderSplitLy,
                    productLineSplit: productLineSplitLy,
                });

                const genderProductLineSplitLLY = getGenderProductLineSplit({
                    isUsingGender: data?.genderData?.isUsingGender,
                    isUsingProductLine: data?.productLineData?.isUsingProductLine,
                    genderSplit: genderSplitLly,
                    productLineSplit: productLineSplitLly,
                });

                const plannedSalesIv = combineSaleAndSplitValues(
                    plannedMonthSales,
                    plannedSplit ? (plannedSplit / 100) * genderProductLineSplit : 0
                );

                const salesIvLy = combineSaleAndSplitValues(
                    monthSalesLy,
                    splitLy ? (splitLy / 100) * genderProductLineSplitLY : 0
                );
                const salesIvLly = combineSaleAndSplitValues(
                    monthSalesLly,
                    splitLly ? (splitLly / 100) * genderProductLineSplitLLY : 0
                );

                accGroup.push({
                    id: outerIndex,
                    category: groupName ?? '',
                    splitLly: splitLly,
                    splitLy: splitLyisNA(groupedByMonth, next12MonthKeys, monthKey) ? -999 : splitLy,
                    plannedSplit,
                    plannedIndex: calculatePlannedIndex(1, plannedSalesIv, salesIvLy, salesIvLly),
                    plannedSalesIv,
                    salesIvLy,
                    salesIvLly,
                    indexLy: calculateIndexLy(salesIvLy, salesIvLly),
                    gmLy,
                    cogsLY,
                    netSalesLY,
                } as never);
            }

            return accGroup;
        }, []);

        acc.set(fieldName, [...rows]);

        return acc;
    }, new Map());

    return rows;
};
