import styled from '@emotion/styled';
import { Box, Stack, StackProps } from '@mui/material';
import { ComponentProps, FC, ReactNode, useMemo } from 'react';
import Container from 'src/components/atoms/Container';
import Header from 'src/components/atoms/Header';
import { Color, Headings } from 'src/domain';

interface Props extends Omit<ComponentProps<'div'>, 'content'> {
    title: string;
    content: ReactNode | string | undefined | null;
    suffix?: string;
    powerOf?: number;
}

const KPIContainer: FC<Props> = ({ title, content, suffix, powerOf, ...props }) => {
    return (
        <StyledContainer {...props}>
            <XSTitle heading={Headings.h3}>{title}</XSTitle>
            <Info>
                {content}
                {suffix}
                {powerOf && <StyledSup>{powerOf}</StyledSup>}
            </Info>
        </StyledContainer>
    );
};

export interface KpiStackProps extends StackProps {
    defaultDirection: 'row' | 'row-reverse' | 'column' | 'column-reverse';
    invertOnLargeScreen?: boolean;
}

/* Smart stack component for kpi cards
 * @description Use it like a regular stack, but in case it is necessary to switch from row to column or vice-versa on the custom breakpoint defined for kpi,
 * the defaultDirection and invertOnLargeScreen properties can be used to automatically switch from row to column and column to row.
 *
 * @param defaultDirection The direction to use for all screen sizes when invertOnLargeScreen is false. The direction to use below the custom breakpoint if invertOnLargeScreen is true.
 * @param invertOnLargeScreen Determines if the direction should change when we pass the custom breakpoint. If false nothing changes.
 */
export const ResponsiveKpiStack: FC<KpiStackProps> = ({
    defaultDirection,
    invertOnLargeScreen = false,
    children,
    ...props
}) => {
    const stackDirectionLarge = useMemo(() => {
        if (!invertOnLargeScreen) {
            return defaultDirection;
        }

        switch (defaultDirection) {
            case 'row':
                return 'column';
            case 'row-reverse':
                return 'column-reverse';
            case 'column':
                return 'row';
            case 'column-reverse':
                return 'row-reverse';
        }
    }, [defaultDirection, invertOnLargeScreen]);

    return (
        <Stack
            sx={{
                flexDirection: stackDirectionLarge,
                '@media screen and (max-width: 1820px)': {
                    flexDirection: defaultDirection,
                },
            }}
            {...props}
        >
            {children}
        </Stack>
    );
};

const StyledContainer = styled(Container)`
  min-height: 85px;
  width: 100%;
  flex: 1;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */
`;

const XSTitle = styled(Header)`
  font-size: 10px;
  text-align: center;
`;

const Info = styled(Box)`
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  color: ${Color.antracit};
  margin: 0;
`;

const StyledSup = styled.sup`
  font-size: 12px;
`;

export default KPIContainer;
