import styled from '@emotion/styled';
import { Box, Modal } from '@mui/material';
import { ComponentProps, FC, Fragment, useMemo, useState } from 'react';
import Header from 'src/components/atoms/Header';
import { Color, Headings } from 'src/domain';
import { useReleases } from 'src/hooks/notifications/releases/useReleases';
import { NotificationSourceTypeEnum } from 'src/infrastructure/rest-api/api-types';

export const VersionLog: FC<ComponentProps<'div'>> = ({ ...props }) => {
    const { data } = useReleases();
    const [modalOpen, setModalOpen] = useState(false);

    const latestRelease = useMemo(() => data?.[0], [data]);

    const handleShowDetails = () => {
        setModalOpen(true);
    };

    if (data && latestRelease)
        return (
            <>
                <Wrapper {...props} onClick={handleShowDetails}>
                    <Version>
                        <Header heading={Headings.h3} bold>
                            Latest release
                        </Header>
                        <div>{latestRelease.dateTime}</div>
                    </Version>
                    <SeeMore>See what&apos;s new in Storeforecast</SeeMore>
                </Wrapper>

                <Modal
                    open={modalOpen}
                    onClose={() => setModalOpen(false)}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <ModalWrapper>
                        {data.slice(0, 5).map((release, index) => {
                            return (
                                <Fragment key={release.dateTime + release.title}>
                                    <ReleaseWrapper isFirst={index === 0}>
                                        <Header heading={Headings.h3} bold>
                                            Release
                                        </Header>
                                        <ReleaseDate>{release.dateTime}</ReleaseDate>
                                    </ReleaseWrapper>
                                    {release.body.map((body) => (
                                        <PRItem key={body.text}>
                                            <VersionTag type={body.type}></VersionTag>
                                            <span>{body.text}</span>
                                        </PRItem>
                                    ))}
                                </Fragment>
                            );
                        })}
                    </ModalWrapper>
                </Modal>
            </>
        );
    return null;
};

interface VersionTagProps {
    type: NotificationSourceTypeEnum;
}

const VersionTag: FC<VersionTagProps> = ({ type }) => {
    const { color, tag } = useMemo(() => {
        switch (type) {
            case 'GITHUB_STOREFORECAST_FRONTEND':
                return { color: '#8884d8', tag: 'FE' };
            case 'GITHUB_STOREFORECAST_API':
                return { color: '#82ca9d', tag: 'API' };
            case 'GITHUB_STOREFORECAST_POWERBI':
                return { color: '#f2c811', tag: 'PBI' };
            default:
                return { color: '#c11513', tag: '??' };
        }
    }, [type]);
    return <Tag color={color}>{tag}</Tag>;
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  &:hover {
    cursor: pointer;
    color: ${Color.editBlue};
  }
`;

const Version = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const ReleaseDate = styled.div`
  font-size: 20px;
`;

const ReleaseWrapper = styled.div<{ isFirst: boolean }>`
  display: flex;
  gap: 10px;
  align-items: center;
  margin-bottom: 7px;
  margin-top: ${({ isFirst }) => (isFirst ? '0px' : '25px')};
`;

const SeeMore = styled.span`
  font-size: 13px;
`;

const PRItem = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
  padding-top: 5px;
  padding-left: 15px;
`;

const Tag = styled.div<{ color: string }>`
  width: 35px;
  height: 20px;
  background-color: ${({ color }) => color};
  position: relative;
  text-align: center;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
  font-weight: 500;
  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: -14.5px;
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 15px solid ${({ color }) => color};
  }
`;

const ModalWrapper = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  max-width: 700px;
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  box-shadow: 24px;
  padding: 30px 20px;
  overflow-y: auto;
  max-height: 500px;
`;
