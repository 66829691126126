import { Box, styled } from '@mui/material';
import Stack from '@mui/material/Stack';
import { CustomTooltipProps } from 'ag-grid-react';

export const StoreCountTooltip = (props: CustomTooltipProps) => {
    const { value, location } = props;
    return (
        <TooltipBackground>
            {Array.isArray(value) && location === 'cell'
                ? value?.map((name: string) => (
                      <Box sx={{ fontSize: 11 }} key={name}>
                          {name}
                      </Box>
                  ))
                : value}
        </TooltipBackground>
    );
};

const TooltipBackground = styled(Stack)(({ theme }) => ({
    backgroundColor: '#f8f8f8',
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    border: '1px solid #aaa',
    padding: '4px 8px',
    borderRadius: '4px',
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
}));
