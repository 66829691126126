// TODO: This could be cleaned up a little
// most of the calculations overlap
// but for now they follow the excel sheet line by line for now

import { isNumber } from 'lodash';
import { SalesCampaignDiscountModelOptionalId } from 'src/domain/table/sales-campaign-overview.row';
import { StyleTimelineOverviewRow } from 'src/domain/table/style-timeline-overview-row';

interface GrossMarginWithoutMarkdownParameters {
    turnover: number;
    vat: number;
    markup: number;
}

export const calculateGrossMarginWithoutMarkdown = ({
    turnover,
    vat,
    markup,
}: GrossMarginWithoutMarkdownParameters) => {
    const plannedSalesIv = turnover;
    const plannedCogs = plannedSalesIv / markup;

    const netSales = plannedSalesIv / vat;
    const grossProfit = netSales - plannedCogs;

    const gm = ((grossProfit / netSales) * 100).toFixed(2).toFloat();

    return gm;
};

interface GrossMarginWithMarkdownParameters {
    turnover: number;
    vat: number;
    markup: number;
    markdown: number;
}

export const calculateGrossMarginWithMarkdown = ({
    turnover,
    vat,
    markdown,
    markup,
}: GrossMarginWithMarkdownParameters) => {
    const plannedSalesIv = turnover;
    const plannedMarkdown = 1 + markdown / 100;
    const plannedCogs = plannedSalesIv / markup;

    const grossSales1Iv = plannedSalesIv * plannedMarkdown;
    const markdownValue = grossSales1Iv - plannedSalesIv;
    const calCogsWithMarkdown = markdownValue / markup + plannedCogs;

    const netSales = plannedSalesIv / vat;
    const grossProfit = netSales - calCogsWithMarkdown;

    const gm = ((grossProfit / netSales) * 100).toFixed(2).toFloat();

    return gm;
};

export type PartialStyleOption = Pick<
    StyleTimelineOverviewRow,
    'recommendedRetailPriceLcy' | 'stockQuantity' | 'salesQuantityPast8Weeks' | 'cogsPast8WeeksLcy' | 'stockValueLcy'
>;

type PartialSalesCampaignDiscountModel = Pick<
    SalesCampaignDiscountModelOptionalId,
    'valueX' | 'valueY' | 'discountType'
>;

export const calculateGrossMarginPercent = (salesPrice: number, cogs: number, vat: number) => {
    // Remove VAT in order to get net sales price
    const netSales = salesPrice / vat;

    // Calculate new gross profit
    const grossProfit = netSales - cogs;

    return ((grossProfit / netSales) * 100).toFixed(2).toFloat();
};

export const calculateDiscountedSalesPrice = (
    styleOption: PartialStyleOption,
    discount: PartialSalesCampaignDiscountModel
) => {
    const valueY = discount.valueY ?? 0;
    const newSalesPrice = ['X_FOR_FIXED_PRICE', 'FIXED_PRICE'].includes(discount.discountType)
        ? valueY / (discount.valueX ?? 1)
        : ((styleOption.recommendedRetailPriceLcy ?? NaN) * valueY) / discount.valueX;
    return isNaN(newSalesPrice) ? undefined : newSalesPrice;
};

export const calculateNewGrossMarginPercent = (
    styleOption: PartialStyleOption,
    discount: PartialSalesCampaignDiscountModel,
    vat: number
) => {
    const newSalesPrice = calculateDiscountedSalesPrice(styleOption, discount);

    if (!newSalesPrice) {
        return;
    }

    let plannedCogs: number | null = null;
    if (styleOption.stockQuantity > 0 && styleOption.stockValueLcy > 0) {
        plannedCogs = styleOption.stockValueLcy / styleOption.stockQuantity;
    } else if (
        typeof styleOption.salesQuantityPast8Weeks === 'number' &&
        typeof styleOption.cogsPast8WeeksLcy === 'number'
    ) {
        // Use sales qty past 8 weeks and cogs past 8 weeks as fallback
        plannedCogs = styleOption.cogsPast8WeeksLcy / styleOption.salesQuantityPast8Weeks;
    }

    if (!isNumber(plannedCogs)) {
        return;
    }

    return calculateGrossMarginPercent(newSalesPrice, plannedCogs, vat);
};
