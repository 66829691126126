export interface StyleCategory {
    categorySort?: number | null;
    styleCategoryId: number;
    styleCategoryName: string;
}

// biome-ignore lint/suspicious/noExplicitAny: we need to use any
export function isStyleCategory(obj: any): obj is StyleCategory {
    return obj && typeof obj.styleCategoryName === 'string';
}

// biome-ignore lint/suspicious/noExplicitAny: we need to use any
export function hasStyleCategoryId(obj: any): obj is { styleCategoryId: number } {
    return obj && typeof obj.styleCategoryId === 'number';
}
