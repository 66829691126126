import { useMemo } from 'react';
import { Inheritance, Module, ScopeEnum } from 'src/domain';
import { useInheritance } from 'src/hooks/inheritance';
import { useScope } from 'src/hooks/useScope';
import { TurnoverOverviewModel, TurnoverOverviewSummarizedGroupsModel } from 'src/infrastructure/rest-api/api-types';
import { mapTurnoverInheritance, mergeTurnoverByMonthKey } from 'src/mapping/turnover-mapping';
import { useTurnoverLevelQuery } from './useTurnoverLevelQuery';

export const useTurnoverInheritanceQuery = (inheritance?: Inheritance) => {
    const { data, error, loading, partnerRefetch } = useTurnoverLevelQuery() ?? {};
    const scope = useScope();
    const moduleInheritance = useInheritance(Module.Turnover);
    const selectedInheritance = inheritance ?? moduleInheritance;

    // biome-ignore lint/correctness/useExhaustiveDependencies: This should only update whenever there is new data. This way we ensure the data will match the splitlevel.
    const sanitizedData = useMemo(() => {
        if (!data) return;

        switch (scope) {
            case ScopeEnum.CLUSTER:
            case ScopeEnum.PARTNER: {
                const turnoverSummarizedGroupsData = data as TurnoverOverviewSummarizedGroupsModel;
                return {
                    data: mergeTurnoverByMonthKey([
                        ...(turnoverSummarizedGroupsData.comparables?.turnover ?? []),
                        ...(turnoverSummarizedGroupsData.nonComparables?.turnover ?? []),
                    ]),
                    expectedSalesComparable: {
                        storeData: null,
                        comparables: turnoverSummarizedGroupsData.comparables,
                        nonComparables: turnoverSummarizedGroupsData.nonComparables,
                    },
                };
            }

            default: {
                const turnoverOverviewData = data as TurnoverOverviewModel;
                if (!selectedInheritance) return;
                return {
                    data: mapTurnoverInheritance(turnoverOverviewData.turnover, selectedInheritance),
                    expectedSalesComparable: {
                        storeData: mapTurnoverInheritance(turnoverOverviewData.turnover, selectedInheritance),
                        comparables: null,
                        nonComparables: null,
                    },
                };
            }
        }
    }, [data, selectedInheritance]);

    const numberOfStores = useMemo(() => {
        if (!data) return;

        let turnoverSummarizedGroupsData: TurnoverOverviewSummarizedGroupsModel | undefined;

        switch (scope) {
            case ScopeEnum.CLUSTER:
            case ScopeEnum.PARTNER:
                turnoverSummarizedGroupsData = data as TurnoverOverviewSummarizedGroupsModel;
                return {
                    data:
                        (turnoverSummarizedGroupsData.comparables?.storeCount ?? 0) +
                        (turnoverSummarizedGroupsData.nonComparables?.storeCount ?? 0),
                    dataComparable: {
                        comparables: turnoverSummarizedGroupsData.comparables?.storeCount ?? 0,
                        nonComparables: turnoverSummarizedGroupsData.nonComparables?.storeCount ?? 0,
                    },
                };

            default:
                return;
        }
    }, [data, scope]);

    return {
        data: sanitizedData?.data,
        expectedSalesComparable: sanitizedData?.expectedSalesComparable,
        numberOfStores: numberOfStores?.data,
        numberOfStoresComparable: numberOfStores?.dataComparable,
        error,
        loading,
        partnerRefetch,
    };
};
