import { ColDef } from 'ag-grid-community';
import { format } from 'date-fns';
import { EditableValueCell } from 'src/components/molecules/EditableCell/EditableCell';
import { SelectCellEditor } from 'src/components/molecules/SelectCellEditor/SelectCellEditor';
import { ClusterManagementRow } from 'src/domain/models/cluster-management/cluster-management-row';
import { numberWithSpaces } from 'src/utils/numberWithSpaces';

export const defaultColDef: ColDef<ClusterManagementRow> = {
    flex: 1,
    sortable: true,
    suppressMovable: true,
    suppressHeaderMenuButton: true,
};

export const defaultColumns: ColDef<ClusterManagementRow>[] = [
    {
        field: 'storeName',
        headerName: 'Store Name',
        flex: 1.5,
        filter: 'agTextColumnFilter',
        cellStyle: {
            fontWeight: 'normal',
            paddingLeft: '2.7rem',
        },
        colSpan(params) {
            return params.data?.rowId.startsWith('empty') ? 6 : 1;
        },
    },
    {
        field: 'openingDate',
        headerName: 'Opening Date',
        filter: 'agDateColumnFilter',
        flex: 0.75,
        valueFormatter: ({ value }) => {
            if (value) {
                return format(new Date(value), 'PP');
            }
            return '';
        },
    },
    {
        field: 'salesIvNext12Months',
        headerName: 'Sales iV (next 12 months)',
        valueFormatter: ({ value }) => {
            return numberWithSpaces(value);
        },
        filter: 'agNumberColumnFilter',
    },
    {
        field: 'salesIvPerSquareMeter',
        headerName: 'Sales iV per m²',
        valueFormatter: ({ value }) => {
            return numberWithSpaces(value);
        },
        filter: 'agNumberColumnFilter',
    },
    {
        field: 'storeSize',
        headerName: 'Store Size',
        flex: 0.75,
        filter: 'agNumberColumnFilter',
    },
    {
        headerName: 'Cluster',
        field: 'cluster',
        rowGroup: true,
        sort: 'asc',
        flex: 1,
        hide: false,
        editable: true,
        cellRenderer: EditableValueCell,
        cellEditor: SelectCellEditor,
        cellEditorParams: {
            selectOptions: [],
        },
        comparator: (valueA, valueB) => {
            if (valueA?.clusterName && valueB?.clusterName) {
                return valueA.clusterName.localeCompare(valueB.clusterName);
            }
            return 0;
        },
        valueSetter: ({ data, newValue }) => {
            const newValueAsNumber = Number(newValue);
            if (newValue && !isNaN(newValueAsNumber)) {
                data.cluster = {
                    ...data.cluster,
                    clusterId: newValueAsNumber,
                };
                return true;
            } else if (newValue && typeof newValue === 'object') {
                data.cluster = newValue;
                return true;
            }
            return false;
        },
        valueFormatter: ({ data }) => {
            return `${data?.cluster.clusterName}`;
        },
    },
];
