import { Module } from 'src/domain';
import { useDefaultProductLine } from './useDefaultProductLine';
import { useSelectedProductLine } from './useSelectedProductLine';

export const useProductLine = (module?: Module) => {
    const [defaultProductLine] = useDefaultProductLine();
    const selectedProductLine = useSelectedProductLine(module);

    return selectedProductLine ?? defaultProductLine;
};
