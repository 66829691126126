import { ColDef } from 'ag-grid-community';
import { FC, useMemo } from 'react';
import Container from 'src/components/atoms/Container';
import { DataTable } from 'src/components/organisms/DataTable/DataTable';
import { TableWrapper } from 'src/components/styled/TableWrapper';
import { useStores } from 'src/hooks/store-selection/queries/useStores';
import { withDefaultHeaderToolTip } from 'src/utils/ag-grid/withDefaultHeaderToolTip';
import { numberWithSpaces } from 'src/utils/numberWithSpaces';
import { SummaryData, calculateSummary } from '../calculateSummary';
import { SummaryReportProps } from '../types';

interface SummaryRow extends SummaryData {
    id: number;
    storeName: string;
}

const storeSalesSummaryColumns: ColDef<SummaryRow>[] = withDefaultHeaderToolTip([
    {
        field: 'storeName',
        headerName: 'Store',
        pinned: 'left',
        lockPinned: true,
        valueFormatter: (params) => {
            return params.value ?? 'Unknown';
        },
    },
    {
        field: 'totalStockValue',
        headerName: 'Total stock value',
    },
    {
        field: 'totalStockValueOnSale',
        headerName: 'Stock value on sale',
    },
    {
        field: 'totalStockValueNotOnSale',
        headerName: 'Stock value not on sale',
    },
    {
        field: 'percentageStockValueOnSale',
        headerName: '% stock value on sale',
        valueFormatter: (params) => {
            return numberWithSpaces(params.value, { postfix: '%' });
        },
    },
    {
        field: 'totalStockQuantity',
        headerName: 'Total stock quantity',
    },
    {
        field: 'totalStockQuantityOnSale',
        headerName: 'Stock quantity on sale',
    },
    {
        field: 'totalStockQuantityNotOnSale',
        headerName: 'Stock quantity not on sale',
    },

    {
        field: 'percentageStockQuantityOnSale',
        headerName: '% stock quantity on sale',
        valueFormatter: (params) => {
            return numberWithSpaces(params.value, { postfix: '%' });
        },
    },
]);

const defaultColDef: ColDef<SummaryData> = {
    editable: false,
    sortable: true,
    valueFormatter: (params) => {
        return numberWithSpaces(params.value);
    },
};

export const StoreSalesSummaryTab: FC<SummaryReportProps> = ({ salesCampaignRowsByStore }) => {
    const { data } = useStores();

    const summaryRows: SummaryRow[] = useMemo(() => {
        return Object.entries(salesCampaignRowsByStore ?? {}).map(([storeId, rows]) => {
            const store = data?.allStores.find((store) => store.id.toString() === storeId);

            const summaryData = calculateSummary(rows);

            return {
                id: store?.id ?? -1,
                storeName: store?.storeName ?? 'Unknown',
                ...summaryData,
            };
        });
    }, [data?.allStores, salesCampaignRowsByStore]);

    return (
        <Container>
            <TableWrapper className="ag-theme-alpine-custom-compact small-font" flex={1}>
                <DataTable
                    autoSizeToFit
                    rows={summaryRows}
                    columns={storeSalesSummaryColumns}
                    defaultColDef={defaultColDef}
                />
            </TableWrapper>
        </Container>
    );
};
