import { Stack } from '@mui/material';
import { ICellRendererParams } from 'ag-grid-community';
import { FC, useMemo } from 'react';
import Container from 'src/components/atoms/Container';
import Header from 'src/components/atoms/Header';
import { DataTable } from 'src/components/organisms/DataTable/DataTable';
import { weightedAverageMarkupDetails } from 'src/components/organisms/DataTable/aggregators/average-markup.agg';
import { calculateAveragesWithPlannedSplits } from 'src/components/organisms/DataTable/aggregators/average-total.agg';
import { ColumnsDetailsMarkup } from 'src/components/organisms/DataTable/columns/columns-details-markup';
import { defaultCategoryColumn } from 'src/components/organisms/DataTable/columns/columns-overview-sum';
import { DefaultColumnOptionsNumbers } from 'src/components/organisms/DataTable/columns/default-options';
import { averageCellRenderer } from 'src/components/organisms/DataTable/helpers/average-cell-renderer';
import { SumBold } from 'src/components/organisms/DataTable/options/sum-bold';
import DetailsTable from 'src/components/organisms/DetailsTable';
import InheritanceDropdown from 'src/components/organisms/InheritanceDropdown';
import { InheritanceTable } from 'src/components/organisms/InheritanceTable/InheritanceTable';
import Topbar from 'src/components/organisms/Topbar';
import { StyledHeaderWrapper } from 'src/components/styled/StyledHeaderWrapper';
import { TableWrapper } from 'src/components/styled/TableWrapper';
import { Headings, MarkupDetailsRow, MarkupDetailsRowTuple, Module } from 'src/domain';
import { useHasUnsavedChangesGenderProductline } from 'src/hooks';
import { useDiscardChangesModule } from 'src/hooks/discard-changes/useDiscardChangesModule';
import { useMarkupReset } from 'src/hooks/markup/reset/useMarkupReset';
import { useMarkupSaveChanges } from 'src/hooks/markup/save-changes/useMarkupSaveChanges';
import { useMarkupDetailsSimulationRows } from 'src/hooks/markup/simulation/useMarkupDetailsSimulationRows';
import { useMarkupOverviewSimulationRows } from 'src/hooks/markup/simulation/useMarkupOverviewSimulationRows';
import { useMarkupUpdateInheritance } from 'src/hooks/markup/split-level/useMarkupUpdateInheritance';
import { useMarkupDetailsRows } from 'src/hooks/markup/table/useMarkupDetailsRows';
import { useMarkupHandleCellChanges } from 'src/hooks/markup/useMarkupHandleCellChanges';
import { mergeTableDetailsRowValuesToTuples } from 'src/mapping/table.mapping';

export const MarkUp: FC = () => {
    const {
        data: overviewData,
        columns: monthColumns,
        loading: overviewLoading,
    } = useMarkupOverviewSimulationRows() ?? {};
    const { data: detailsData, loading: detailsLoading } = useMarkupDetailsRows() ?? {};
    const { data: detailsSimulationData, loading: detailsSimulationLoading } = useMarkupDetailsSimulationRows() ?? {};

    const handleCellValueChanges = useMarkupHandleCellChanges();
    const { updateInheritance, loading: updateInheritanceLoading } = useMarkupUpdateInheritance();
    const { resetMarkup, loading: resetLoading } = useMarkupReset();

    const [saveChanges, { loading: savingChanges }] = useMarkupSaveChanges();
    const onDiscardChanges = useDiscardChangesModule(Module.Markup);

    const columns = useMemo(() => {
        return [
            {
                ...defaultCategoryColumn,
                cellRenderer: (params: ICellRendererParams) => {
                    return params.value ?? 'Average';
                },
            },
            ...monthColumns.map((column) => {
                return {
                    ...column,
                    cellRenderer: (params: ICellRendererParams) => averageCellRenderer(column, params),
                };
            }),
        ];
    }, [monthColumns]);

    const hasUnsavedChanges = useHasUnsavedChangesGenderProductline();
    const loading =
        overviewLoading ||
        updateInheritanceLoading ||
        resetLoading ||
        detailsLoading ||
        detailsSimulationLoading ||
        savingChanges;

    const overviewRows = !loading ? overviewData : undefined;

    const detailsRows = useMemo(() => {
        if (loading) {
            return;
        }

        return mergeTableDetailsRowValuesToTuples<MarkupDetailsRow, MarkupDetailsRowTuple>(
            detailsData,
            detailsSimulationData,
            ['expectedCogs', 'expectedGrossMargin']
        );
    }, [detailsData, detailsSimulationData, loading]);

    return (
        <Stack spacing={2}>
            <Topbar
                onDiscard={onDiscardChanges}
                onSave={saveChanges}
                saveDisabled={!hasUnsavedChanges}
                onReset={resetMarkup}
                onSetInheritance={updateInheritance}
                loading={loading}
                saveLoading={savingChanges}
            />

            <Container>
                <StyledHeaderWrapper>
                    <Header heading={Headings.h2}>Buying Markup</Header>
                    <InheritanceDropdown />
                </StyledHeaderWrapper>
                <TableWrapper className="ag-theme-alpine-custom-compact">
                    <DataTable
                        {...SumBold}
                        autoSizeToFit
                        headerHeight={60}
                        columns={columns}
                        rows={overviewRows}
                        defaultColDef={DefaultColumnOptionsNumbers}
                        onCellValuesChanged={handleCellValueChanges}
                        loading={loading}
                        getGroupRowAgg={calculateAveragesWithPlannedSplits}
                        grandTotalRow="bottom"
                    />
                </TableWrapper>
            </Container>

            <DetailsTable
                key={'details-table'}
                title={'Markup Details'}
                {...SumBold}
                monthColumns={monthColumns}
                loading={loading}
                rows={detailsRows}
                columns={ColumnsDetailsMarkup}
                defaultColDef={DefaultColumnOptionsNumbers}
                onCellValuesChanged={handleCellValueChanges}
                getGroupRowAgg={weightedAverageMarkupDetails}
                grandTotalRow="bottom"
                message='Historical Markup and GM% in this table are based on "Gross Sales I" instead of "Sales iV" to reflect historical values excluding Markdowns & Returns'
            />
            <InheritanceTable moduleString="markup" />
        </Stack>
    );
};
