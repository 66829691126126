import { isNumber } from 'lodash';
import { MarkupDetailsRow, TableOverviewRow } from 'src/domain';
import { OptimalOptionCountRow } from 'src/domain/table/optimal-option-count.row';
import { mergeMap } from './mergeMap';

export function mergeOverviewRows(typedData: TableOverviewRow[], originalData: TableOverviewRow[]) {
    return mergeMap([typedData, originalData], ([expectedRow, currentRow]) => {
        const currentValues = currentRow
            ? Object.keys(currentRow.columns).reduce(
                  (acc, key) => {
                      if (key.endsWith('Id')) {
                          return acc;
                      }
                      acc[key] = currentRow.columns[key];
                      return acc;
                  },
                  {} as Record<string, number>
              )
            : {};

        const result = {
            ...expectedRow,
            ...currentRow,
            columns: {
                ...expectedRow?.columns,
                ...currentValues,
            },
        } as TableOverviewRow;

        return result;
    });
}

export function mergeOptimalOptionCountRows(typedData: OptimalOptionCountRow[], originalData: OptimalOptionCountRow[]) {
    return mergeMap([typedData, originalData], ([expectedRow, currentRow]) => {
        const currentValues = currentRow
            ? Object.keys(currentRow.columns).reduce(
                  (acc, key) => {
                      if (key.endsWith('Id')) {
                          return acc;
                      }
                      acc[key] = currentRow.columns[key];
                      return acc;
                  },
                  {} as Record<string, number | null>
              )
            : {};

        const result = {
            ...expectedRow,
            ...currentRow,
            columns: {
                ...expectedRow?.columns,
                ...currentValues,
            },
        } as OptimalOptionCountRow;

        return result;
    });
}

export function mergeRowsByMonthAndCategory(data: TableOverviewRow[] | undefined): TableOverviewRow[] | undefined {
    const merged = data?.reduce((acc, change) => {
        const row = acc.get(change.category);
        if (row) {
            Object.keys(change.columns).forEach((monthField) => {
                if (monthField.endsWith('Id') || monthField.endsWith('PlannedSplit')) return;
                row.columns[monthField] += change.columns[monthField];
            });
        } else {
            acc.set(change.category, structuredClone(change));
        }
        return acc;
    }, new Map<string, TableOverviewRow>());
    return merged ? Array.from(merged.values()) : undefined;
}

export interface MergeDataByMonthAndCategorySource {
    monthKey: number;
    styleCategoryId?: number;
    value?: number;
    splitPct?: number;
    weekCover?: number;
}

export function mergeDataByMonthAndCategory<T extends MergeDataByMonthAndCategorySource>(
    data: T[] | undefined
): T[] | undefined {
    const merged = data?.reduce((acc, change) => {
        if (!change.styleCategoryId) {
            // biome-ignore lint/suspicious/noConsole: provide better reason
            console.error('Missing required fields for mergeDataByMonthAndCategory', change);
            return acc;
        }

        const id = change.styleCategoryId;

        const rows = acc.get(id) ?? [];
        const row = rows.find((r) => r.monthKey === change.monthKey);

        if (row) {
            if (isNumber(row.value) && isNumber(change.value)) {
                row.value += change.value;
            }
            if (isNumber(row.splitPct) && isNumber(change.splitPct)) {
                row.splitPct += change.splitPct;
            }
            if (isNumber(row.weekCover) && isNumber(change.weekCover)) {
                row.weekCover += change.weekCover;
            }
        } else {
            rows.push({
                ...change,
                genderId: -999,
                productlineGroupId: -999,
            });
        }
        acc.set(id, rows);

        return acc;
    }, new Map<number, T[]>());

    return merged ? Array.from(merged.values()).flat() : undefined;
}

type MergeDetailsRowsDataSource = MarkupDetailsRow;

export function mergeDetailRowsByMonthAndCategory<T extends MergeDetailsRowsDataSource>(
    data: (Map<string, T[]> | undefined)[] | undefined
): Map<string, T[]> | undefined {
    if (!data) {
        return;
    }

    return data.reduce<Map<string, T[]>>((acc, change) => {
        if (!change) {
            return acc;
        }

        Array.from(change.entries()).forEach(([monthName, rows]) => {
            const monthRows = acc.get(monthName) ?? [];
            rows.forEach((row) => {
                const existingRow = monthRows.find((r) => r.category === row.category);
                if (existingRow) {
                    if (isNumber(existingRow.cogsLLY) && isNumber(row.cogsLLY)) {
                        existingRow.cogsLLY += row.cogsLLY;
                    }
                    existingRow.cogsLY += row.cogsLY;
                    existingRow.expectedCogs += row.expectedCogs;

                    existingRow.expectedGrossMargin += row.expectedGrossMargin;

                    existingRow.valueLY += row.valueLY;
                    existingRow.valueLLY += row.valueLLY;
                    existingRow.plannedValue += row.plannedValue;
                    existingRow.plannedSplit += row.plannedSplit;

                    if (isNumber(existingRow.grossMarginLY) && isNumber(row.grossMarginLY)) {
                        existingRow.grossMarginLY += row.grossMarginLY;
                    }
                    if (isNumber(existingRow.grossSalesILY) && isNumber(row.grossSalesILY)) {
                        existingRow.grossSalesILY += row.grossSalesILY;
                    }
                    if (isNumber(existingRow.grossSalesILLY) && isNumber(row.grossSalesILLY)) {
                        existingRow.grossSalesILLY += row.grossSalesILLY;
                    }
                    if (isNumber(existingRow.netSalesLY) && isNumber(row.netSalesLY)) {
                        existingRow.netSalesLY += row.netSalesLY;
                    }
                } else {
                    monthRows.push(row);
                }
            });
            acc.set(monthName, monthRows);
        });

        return acc;
    }, new Map<string, T[]>());
}

interface GroupDataByGenderProductlineDataSource {
    genderId?: number;
    productlineGroupId?: number;
}

export function groupByGenderAndProductLine<T extends GroupDataByGenderProductlineDataSource>(
    data: T[]
): Record<string, Record<string, T[]>> {
    return data.reduce<Record<string, Record<string, T[]>>>((acc, row) => {
        const productlineGroupId = row.productlineGroupId ?? -999;
        const genderId = row.genderId ?? -999;

        acc[productlineGroupId] = acc[productlineGroupId] ?? {};
        acc[productlineGroupId][genderId] = acc[productlineGroupId][genderId] ?? [];
        acc[productlineGroupId][genderId].push(row);

        return acc;
    }, {});
}
