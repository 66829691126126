import { Dispatch, FC, PropsWithChildren, SetStateAction, createContext, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { GroupLevel, Module, ScopeEnum } from 'src/domain';
import { useCurrentModule, useScope } from 'src/hooks';
import { useType } from 'src/hooks/useType';

interface IDrawerContext {
    drawerOpen: boolean;
    setDrawerOpen: Dispatch<SetStateAction<boolean>>;
    settingsDrawerOpen: boolean;
    setSettingsDrawerOpen: Dispatch<SetStateAction<boolean>>;
    bulkEditDrawerOpen: boolean;
    setBulkEditDrawerOpen: Dispatch<SetStateAction<boolean>>;
    hasSidebar: boolean;
    groupLevel: GroupLevel | undefined | null;
    auditLogDrawerOpen: boolean;
    setAuditLogDrawerOpen: Dispatch<SetStateAction<boolean>>;
}

export const DrawerContext = createContext<IDrawerContext>({
    drawerOpen: false,
    setDrawerOpen: () => {
        // init
    },
    settingsDrawerOpen: false,
    setSettingsDrawerOpen: () => {
        // init
    },
    bulkEditDrawerOpen: false,
    setBulkEditDrawerOpen: () => {
        // init
    },
    hasSidebar: false,
    groupLevel: undefined,
    auditLogDrawerOpen: false,
    setAuditLogDrawerOpen: () => {
        // init
    },
});

export const DrawerContextProvider: FC<PropsWithChildren<unknown>> = ({ children }) => {
    const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
    const [settingsDrawerOpen, setSettingsDrawerOpen] = useState<boolean>(false);
    const [bulkEditDrawerOpen, setBulkEditDrawerOpen] = useState<boolean>(false);
    const [auditLogDrawerOpen, setAuditLogDrawerOpen] = useState(false);
    const location = useLocation();

    const type = useType();
    const scope = useScope();
    const module = useCurrentModule();

    const hasSidebar = useMemo(
        () =>
            (type === GroupLevel.Cluster || type === GroupLevel.Country) &&
            module !== Module.Settings &&
            module !== Module.ClusterManagement &&
            module !== Module.BuyingEventManagement &&
            module !== Module.OpenToBuyLimit &&
            module !== Module.InheritanceSettings &&
            module !== Module.StyleTimeline &&
            module !== Module.StyleTimelineSettings &&
            module !== Module.StyleFamilyNameEditor &&
            module !== Module.SalesCampaigns &&
            module !== Module.SalesCampaignsPlanning &&
            module !== Module.SalesCampaignsReport,
        [module, type]
    );

    const bulkEditEnabled = useMemo(() => module === Module.Turnover && scope === ScopeEnum.PARTNER, [module, scope]);

    useEffect(() => {
        if (location.pathname.startsWith('/settings')) {
            setSettingsDrawerOpen(true);
        } else {
            setSettingsDrawerOpen(false);
        }
    }, [location.pathname]);

    useEffect(() => {
        if (!bulkEditEnabled) setBulkEditDrawerOpen(false);
    }, [bulkEditEnabled]);

    const value = useMemo((): IDrawerContext => {
        return {
            drawerOpen,
            setDrawerOpen,
            settingsDrawerOpen,
            setSettingsDrawerOpen,
            bulkEditDrawerOpen,
            setBulkEditDrawerOpen,
            auditLogDrawerOpen,
            setAuditLogDrawerOpen,
            hasSidebar,
            groupLevel: type,
        };
    }, [auditLogDrawerOpen, bulkEditDrawerOpen, drawerOpen, hasSidebar, settingsDrawerOpen, type]);

    return <DrawerContext.Provider value={value}>{children}</DrawerContext.Provider>;
};
