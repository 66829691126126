import { pick } from 'ramda';
import { useCallback } from 'react';
import { ArgumentError } from 'src/domain';
import { useChangesSnackbar, useCurrentId } from 'src/hooks';
import { useCompositePartner } from 'src/hooks/partner/useCompositePartner';
import { useEntityType } from 'src/hooks/useEntityType';
import { useApiMutation } from 'src/infrastructure/rest-api/useApi';
import { useProductLineSplitUpdateInheritance } from '../split-level/useProductLineSplitUpdateInheritance';

export const useProductLineSplitReset = () => {
    const currentId = useCurrentId();
    const partnerComposite = useCompositePartner();
    const entityType = useEntityType();
    const showSnackbar = useChangesSnackbar();

    const [reset, { loading: resetLoading }] = useApiMutation('/api/productlinesplit/reset', 'put', {
        update: (data, variables, queryClient) => {
            queryClient.setQueryData(
                ['/api/productlinesplit', pick(['entityId', 'entityType'], variables.query!)],
                data
            );
        },
    });

    const { updateInheritance, loading: inheritanceLoading } = useProductLineSplitUpdateInheritance();

    const resetProductLineSplit = useCallback(() => {
        if (!currentId) throw new ArgumentError(`ID cannot be null: ${currentId}`);

        const query = {
            entityId: Number(currentId),
            entityType,
        };

        if (entityType === 'Partner') {
            if (!partnerComposite?.id) throw new ArgumentError(`Composite partner id cannot be null`);
            query.entityId = partnerComposite.id;
        }

        return reset({
            query,
        })
            .then(() => updateInheritance())
            .then(() => showSnackbar());
    }, [currentId, entityType, reset, partnerComposite?.id, updateInheritance, showSnackbar]);

    const loading = resetLoading || inheritanceLoading;

    return { resetProductLineSplit, loading };
};
