import { useMemo } from 'react';
import { Inheritance, Module } from 'src/domain';
import { useGenderId } from 'src/hooks/gender';
import { useInheritance } from 'src/hooks/inheritance';
import { useProductLineId } from 'src/hooks/productline';
import { useNoosShareSplitLevelQuery } from './useNoosShareSplitLevelQuery';

export const useNoosShareInheritanceQuery = (inheritance?: Inheritance) => {
    const { data, error, loading } = useNoosShareSplitLevelQuery() ?? {};
    const moduleInheritance = useInheritance(Module.NoosShare);
    const selectedInheritance = inheritance ?? moduleInheritance;

    const selectedGenderId = useGenderId();
    const selectedProductLineId = useProductLineId();
    const sanitizedData = useMemo(() => {
        if (!data) return;

        switch (selectedInheritance) {
            case Inheritance.Typed:
            case Inheritance.Historical:
            case Inheritance.NoosHistoricalOrders:
            case Inheritance.NoosDDR:
                return data.storeNoosShare ?? data.clusterNoosShare ?? data.partnerNoosShare;

            case Inheritance.Cluster:
                return data.clusterNoosShare;

            case Inheritance.Partner:
                return data.partnerNoosShare;
        }
        // This should only update whenever there is new data. This way we ensure the data will match the splitlevel.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, selectedInheritance]);

    const filteredData = useMemo(() => {
        if (!sanitizedData) return;
        return sanitizedData.data?.filter((item) => {
            const isGenderMatch = typeof selectedGenderId === 'number' ? item.genderId === selectedGenderId : true;
            const isProductLineMatch =
                typeof selectedProductLineId === 'number' ? item.productlineGroupId === selectedProductLineId : true;
            return isGenderMatch && isProductLineMatch;
        });
    }, [sanitizedData, selectedGenderId, selectedProductLineId]);

    return {
        expected: filteredData,
        noosShareData: sanitizedData,
        data,
        error,
        loading,
    };
};
