import { Button } from '@mui/material';
import { ColDef, GridApi } from 'ag-grid-community';
import { FC, useCallback, useEffect, useMemo } from 'react';
import { Module } from 'src/domain';
import { SalesCampaignPlanningColumnStateStorageKey } from 'src/domain/session-storage/sales-campaign-planning-key';
import { StyleTimelineColumnStateStorageKey } from 'src/domain/session-storage/style-timeline-key';
import { useCurrentModule } from 'src/hooks';
import { useSnackbar } from 'src/hooks/snackbar/useSnackbar';

interface ColumnSettingsManagerButtonProps {
    gridApi: GridApi | null;
    columns?: ColDef[];
}

export const ColumnSettingsManagerButton: FC<ColumnSettingsManagerButtonProps> = ({ gridApi }) => {
    const showSnackbar = useSnackbar();
    const currentModule = useCurrentModule();

    const storageKey = useMemo(() => {
        switch (currentModule) {
            case Module.StyleTimeline:
                return StyleTimelineColumnStateStorageKey;
            case Module.SalesCampaignsPlanning:
                return SalesCampaignPlanningColumnStateStorageKey;
            default:
                return null;
        }
    }, [currentModule]);

    const saveColumnStateToLocalStorage = useCallback(() => {
        if (gridApi && storageKey) {
            const savedState = gridApi.getColumnState();
            try {
                localStorage.setItem(storageKey, JSON.stringify(savedState));
                showSnackbar('Column settings saved', {
                    key: 'column-settings',
                    variant: 'success',
                    preventDuplicate: true,
                });
            } catch (_err) {
                showSnackbar('Column settings could not be saved', {
                    key: 'column-settings-error',
                    variant: 'error',
                    preventDuplicate: true,
                });
            }
        }
    }, [gridApi, showSnackbar, storageKey]);

    const loadColumnStateFromLocalStorage = useCallback(() => {
        if (gridApi && storageKey) {
            const savedColumnState = localStorage.getItem(storageKey);
            if (savedColumnState) {
                const savedState = JSON.parse(savedColumnState);
                gridApi.applyColumnState({ state: savedState, applyOrder: true });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [gridApi, storageKey]);

    useEffect(() => {
        if (gridApi) {
            loadColumnStateFromLocalStorage();
            gridApi.addEventListener('firstDataRendered', loadColumnStateFromLocalStorage);
            return () => {
                gridApi.removeEventListener('firstDataRendered', loadColumnStateFromLocalStorage);
            };
        }
    }, [gridApi, loadColumnStateFromLocalStorage]);

    return (
        <Button variant="contained" color="secondary" onClick={saveColumnStateToLocalStorage}>
            Save column settings
        </Button>
    );
};
