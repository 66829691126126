import { useMemo } from 'react';
import { Inheritance, ProductLineDetailsRow, ScopeEnum } from 'src/domain';
import { useTurnoverQuery } from 'src/hooks/turnover/queries/useTurnoverQuery';
import { useScope } from 'src/hooks/useScope';
import { mapSplitToDetailsTableDataNew } from 'src/mapping/split-modules.mapping';
import { useProductLineSplitQuery } from '../queries/useProductLineSplitQuery';

export const useProductLineSplitDetailsRows = () => {
    const { expectedSplit, productLines, loading, error } = useProductLineSplitQuery();
    const { expectedSplit: lastYearStoreSplit } = useProductLineSplitQuery(Inheritance.Historical);
    const { expectedSales } = useTurnoverQuery() ?? {};
    const scope = useScope();
    const tableData = useMemo(() => {
        switch (scope) {
            case ScopeEnum.STORE:
                return mapSplitToDetailsTableDataNew<ProductLineDetailsRow>({
                    expectedSplit,
                    lastYearStoreSplit,
                    groupedByData: productLines,
                    expectedSalesIv: expectedSales,
                });
            case ScopeEnum.CLUSTER:
                return mapSplitToDetailsTableDataNew<ProductLineDetailsRow>({
                    expectedSplit,
                    lastYearStoreSplit,
                    groupedByData: productLines,
                    expectedSalesIv: expectedSales,
                });
            case ScopeEnum.PARTNER:
                return mapSplitToDetailsTableDataNew<ProductLineDetailsRow>({
                    expectedSplit,
                    lastYearStoreSplit,
                    groupedByData: productLines,
                    expectedSalesIv: expectedSales,
                });

            default:
                return;
        }
    }, [expectedSales, expectedSplit, lastYearStoreSplit, productLines, scope]);

    return { data: tableData, loading, error };
};
