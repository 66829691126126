import { ColDef, ValueFormatterParams } from 'ag-grid-community';
import { get, set } from 'lodash';
import { sumAggFunc } from 'src/utils/ag-grid/agg-functions';
import { formatNumber, parseNumber } from 'src/utils/formatNumber';
import { numberWithSpaces } from 'src/utils/numberWithSpaces';

export const defaultColumnOptions: Partial<ColDef> = {
    editable: false,
    suppressHeaderMenuButton: true,
    cellDataType: 'text',
};

export const defaultNumericColumnOptions: Partial<ColDef> = {
    ...defaultColumnOptions,
    valueGetter(cell) {
        const value = get(cell.data, cell.colDef.field!);
        if (Array.isArray(value)) {
            return value.map((v) => {
                const valueAsNumber = parseNumber(v);
                if (isNaN(valueAsNumber)) return v;
                return formatNumber(parseNumber(v));
            });
        }
        const valueAsNumber = parseNumber(value as string | number);
        if (isNaN(valueAsNumber)) return value;
        return formatNumber(valueAsNumber);
    },
    valueSetter(cell) {
        const valueAsNumber = parseNumber(cell.newValue);
        set(cell.data as object, cell.colDef.field!, isNaN(valueAsNumber) ? cell.newValue : valueAsNumber);
        return true;
    },
};

export const DefaultColumnsOptionsOverviewPercentage: ColDef = {
    ...defaultNumericColumnOptions,
    editable: false,
    sortable: false,
    filter: false,
    resizable: false,
    suppressAutoSize: true,
    suppressHeaderMenuButton: true,
    wrapHeaderText: true,
    headerClass: '',
    initialWidth: 110,
    valueFormatter: (cell: ValueFormatterParams) => {
        const value = Array.isArray(cell.value) ? NaN : parseNumber(cell.value);
        if (isNaN(value)) return cell.value;
        return `${formatNumber(value)}%`;
    },
};

const isPercentage = (field: string | undefined): boolean => {
    return (
        field === 'plannedSplit' ||
        field === 'splitLly' ||
        field === 'splitLy' ||
        field === 'gmLy' ||
        field === 'sharePercentLLY' ||
        field === 'sharePercentLY' ||
        field === 'plannedPercent' ||
        field === 'gmLY' ||
        field === 'expectedGrossMargin' ||
        field === 'grossMarginLY'
    );
};

export const DefaultColumnOptionsDetails: ColDef = {
    ...defaultNumericColumnOptions,
    editable: false,
    sortable: false,
    filter: false,
    resizable: false,
    suppressAutoSize: true,
    suppressHeaderMenuButton: true,
    valueFormatter: (cell: ValueFormatterParams) => {
        const value = Array.isArray(cell.value) ? NaN : parseNumber(cell.value);
        if (isNaN(value)) return cell.value;

        if (value === -999) return 'N/A';

        if (isPercentage(cell.colDef.field)) {
            return `${formatNumber(value)}%`;
        }
        return numberWithSpaces(Math.round(value));
    },
};

export const DefaultColumnPercentWithSingleDecimal: ColDef = {
    ...defaultNumericColumnOptions,
    valueFormatter: (cell: ValueFormatterParams) => {
        const value = Array.isArray(cell.value) ? NaN : parseNumber(cell.value);
        if (isNaN(value)) return cell.value;

        if (isPercentage(cell.colDef.field)) {
            return `${formatNumber(value, 1)}%`;
        }
        return numberWithSpaces(Math.round(value));
    },
};

export const DefaultColumnOptionsNumbers: ColDef = {
    ...defaultNumericColumnOptions,
    editable: false,
    sortable: false,
    filter: false,
    resizable: false,
    wrapHeaderText: true,
    headerClass: '',
    initialWidth: 100,
    valueFormatter: (cell: ValueFormatterParams) => {
        const value = parseNumber(cell.value);
        if (value === -999) return 'N/A';
        if (isNaN(value)) return cell.value;
        return formatNumber(value);
    },
};

export const defaultColDefNumberWithSpaces: ColDef = {
    ...defaultNumericColumnOptions,
    editable: false,
    sortable: false,
    filter: false,
    resizable: false,
    valueGetter(cell) {
        const value = get(cell.data, cell.colDef.field!);
        if (Array.isArray(value)) {
            return value.map((v) => {
                const valueAsNumber = parseNumber(v);
                if (isNaN(valueAsNumber)) return v;
                return formatNumber(parseNumber(v));
            });
        }
        const valueAsNumber = parseNumber(value as string | number);
        if (isNaN(valueAsNumber)) return value;
        return formatNumber(valueAsNumber, 0);
    },
    valueFormatter: (cell: ValueFormatterParams) => {
        if (Array.isArray(cell.value)) {
            if (cell.value[1]) return numberWithSpaces(Math.round(parseNumber(cell.value[1])));
            if (cell.value[0]) return numberWithSpaces(Math.round(parseNumber(cell.value[0])));
            return '';
        }

        if (cell.value) {
            const valueAsNumber = parseNumber(cell.value);
            if (isNaN(valueAsNumber)) return cell.value;
            return numberWithSpaces(Math.round(valueAsNumber));
        }

        return cell.value;
    },
};

export const DefaultColumnsOptionsOverviewPercentageWithSum: ColDef = {
    ...DefaultColumnsOptionsOverviewPercentage,
    aggFunc: sumAggFunc,
};
