declare global {
    interface Array<T> {
        /**
         * Returns the first element in the array, if found
         */
        first(): T | undefined;

        /**
         * Returns the second element in the array, if found
         */
        second(): T | undefined;

        /**
         * Returns the last element in the array, or undefined if empty
         */
        last(): T | undefined;

        /**
         * Determines whether the array is empty or not
         */
        isEmpty(): boolean;

        /**
         * Determines whether the array is not empty. Returns the opposite of @see {@link isEmpty}
         */
        notEmpty(): boolean;

        /**
         * Removes the last element from the array and returns a copy of the array
         */
        removeLast(): T[];

        /**
         * Removes the first element from the array and return a copy of the array
         */
        removeFirst(): T[];
    }
}
if (!Array.prototype.first) {
    Array.prototype.first = function () {
        return this[0];
    };
}

if (!Array.prototype.second) {
    Array.prototype.second = function () {
        return this[1];
    };
}

if (!Array.prototype.last) {
    Array.prototype.last = function () {
        return this[this.length - 1];
    };
}
if (!Array.prototype.isEmpty) {
    Array.prototype.isEmpty = function () {
        return this.length === 0;
    };
}
if (!Array.prototype.notEmpty) {
    Array.prototype.notEmpty = function () {
        return !this.isEmpty();
    };
}
if (!Array.prototype.removeLast) {
    Array.prototype.removeLast = function () {
        return this.slice(0, -1);
    };
}
if (!Array.prototype.removeFirst) {
    Array.prototype.removeFirst = function () {
        return this.slice(1);
    };
}

export {};
