import { useMemo } from 'react';
import { CategoryDetailsRow, CategoryOverviewRow, Module } from 'src/domain';
import { useGender, useIsUsingGender } from 'src/hooks/gender';
import { useGenderSplitOverviewSimulationRows } from 'src/hooks/gender-split/simulation/useGenderSplitOverviewSimulationRows';
import { useIsUsingProductline, useProductLine } from 'src/hooks/productline';
import { useProductLineSplitOverviewSimulationRows } from 'src/hooks/productline-split/simulation/useProductLineSplitOverviewSimulationRows';
import { useTurnoverSimulationRows } from 'src/hooks/turnover/simulation/useTurnoverSimulationRows';
import { getGenderProductLineSplit } from 'src/mapping/getGenderProductLineSplit';
import { monthKeyToField } from 'src/utils/monthKeys';
import { calculatePlannedIndex } from 'src/utils/turnover/calculatePlannedIndex';
import { useCategorySplitDetailsRows } from '../table/useCategorySplitDetailsRows';
import { useCategorySplitOverviewSimulationRows } from './useCategorySplitOverviewSimulationRows';

export interface DetailsRowMapperProps {
    fieldName: string;
    turnoverPlannedSalesIv: number;
    genderProductLineSplit: number;
    categorySplitSimulationData: CategoryOverviewRow[] | undefined;
}

export const detailsRowMapper = ({
    fieldName,
    genderProductLineSplit,
    turnoverPlannedSalesIv,
    categorySplitSimulationData,
}: DetailsRowMapperProps) => {
    return (row: CategoryDetailsRow): CategoryDetailsRow => {
        const categorySplit = categorySplitSimulationData?.find((change) =>
            change.category.equalsInsensitive(row.category)
        )?.columns[fieldName];

        const plannedSplit = categorySplit ?? 0;
        const plannedSalesIv: number = turnoverPlannedSalesIv * genderProductLineSplit * (plannedSplit / 100);

        return {
            ...row,
            plannedSplit,
            plannedSalesIv: Math.round(plannedSalesIv * 100) / 100,
            plannedIndex: calculatePlannedIndex(1, plannedSalesIv, row.salesIvLy, row.salesIvLly),
        };
    };
};

export const useCategorySplitDetailsSimulationRows = () => {
    const { data, loading, error } = useCategorySplitDetailsRows() ?? {};

    const { data: turnoverSimulationData } = useTurnoverSimulationRows();
    const { data: genderSplitSimulationData } = useGenderSplitOverviewSimulationRows();
    const { data: categorySplitSimulationData } = useCategorySplitOverviewSimulationRows();
    const { data: productLineSplitSimulationData } = useProductLineSplitOverviewSimulationRows();

    const isUsingGender = useIsUsingGender();
    const isUsingProductLine = useIsUsingProductline();
    const gender = useGender(Module.CategorySplit);
    const productLine = useProductLine(Module.CategorySplit);

    const simulatedData = useMemo(() => {
        if (!data) return;
        if (
            !genderSplitSimulationData &&
            !categorySplitSimulationData &&
            !productLineSplitSimulationData &&
            !turnoverSimulationData
        ) {
            return data;
        }

        const map = new Map<string, CategoryDetailsRow[]>();

        data.forEach((detailsRows, fieldName) => {
            const turnoverPlannedSalesIv = turnoverSimulationData?.find((change) =>
                monthKeyToField(change.monthKey).equalsInsensitive(fieldName)
            )?.plannedSalesIv;

            const genderSplit = genderSplitSimulationData?.find((change) => change.category.equalsInsensitive(gender))
                ?.columns[fieldName];

            const productLineSplit = productLineSplitSimulationData?.find(
                (change) =>
                    change.category.equalsInsensitive(productLine?.name) &&
                    change.subCategory?.equalsInsensitive(gender)
            )?.columns[fieldName];

            map.set(
                fieldName,
                detailsRows.map(
                    detailsRowMapper({
                        fieldName,
                        turnoverPlannedSalesIv: turnoverPlannedSalesIv ?? 0,
                        genderProductLineSplit: getGenderProductLineSplit({
                            isUsingGender,
                            isUsingProductLine,
                            genderSplit,
                            productLineSplit,
                        }),
                        categorySplitSimulationData: categorySplitSimulationData,
                    })
                )
            );
        });

        return map;
    }, [
        categorySplitSimulationData,
        data,
        gender,
        genderSplitSimulationData,
        isUsingGender,
        isUsingProductLine,
        productLine?.name,
        productLineSplitSimulationData,
        turnoverSimulationData,
    ]);

    return { data: simulatedData, loading, error };
};
