import { Configuration } from '@azure/msal-browser';
import { EnvironmentVariableError } from 'src/domain';

const clientId = import.meta.env.VITE_APP_AZURE_ACTIVE_DIRECTORY_APP_CLIENT_ID;

if (!clientId) throw new EnvironmentVariableError('Azure client ID not set');

const config: Configuration = {
    auth: {
        clientId,
        authority: 'https://login.microsoftonline.com/98db9fb9-f52b-4e63-83d9-795ccd2dfcca',
        redirectUri: window.location.origin,
    },
    cache: {
        cacheLocation: 'sessionStorage',
        storeAuthStateInCookie: true,
    },
};

const loginRequest = {
    scopes: ['User.Read'],
};

export const msal = {
    config,
    loginRequest,
};
