import { Inheritance, ScopeEnum } from 'src/domain';

import {
    InheritanceLevelEnum,
    ModuleInheritanceLevel,
    ModuleInheritanceLevelAlwaysTyped,
    ModuleInheritanceLevelNoos,
    ModuleInheritanceLevelSalesBudget,
    NoosHistoricalInheritanceLevelEnum,
    SalesBudgetInheritanceLevelEnum,
} from 'src/infrastructure/rest-api/api-types';
import {
    FCCInitial,
    FCCRevised,
    Historical,
    HistoricalLLY,
    NOOS_DDR,
    NOOS_HISTORICAL,
    NOOS_HISTORICAL_ORDERS,
    Typed,
} from './inheritance-options';

export function typedInheritanceToSplitLevel(scope: ScopeEnum): InheritanceLevelEnum {
    switch (scope) {
        case ScopeEnum.CLUSTER:
            return 'Cluster';
        case ScopeEnum.PARTNER:
            return 'Partner';
        case ScopeEnum.STORE:
            return 'Store';
        default:
            return 'Store';
    }
}

export function noosInheritanceToSplitLevel(inheritance: Inheritance): NoosHistoricalInheritanceLevelEnum | null {
    switch (inheritance) {
        case Inheritance.NoosDDR:
            return 'NoosDDR';
        case Inheritance.NoosHistoricalOrders:
            return 'NoosOrderSales';
        case Inheritance.Historical:
            return 'NoosHistorical';
    }
    return null;
}

export function salesBudgetInheritanceToSplitLevel(inheritance: Inheritance): SalesBudgetInheritanceLevelEnum {
    switch (inheritance) {
        case Inheritance.Typed:
            return 'Store';
        case Inheritance.FccInitial:
            return 'FccInitial';
        case Inheritance.FccRevised:
            return 'FccRevised';
        case Inheritance.Historical:
            return 'Historical';
        case Inheritance.HistoricalLLY:
            return 'HistoricalLLY';
    }
    return 'Store';
}

export const inheritanceToSplitLevel = (inheritance: Inheritance, scope: ScopeEnum): InheritanceLevelEnum => {
    switch (inheritance) {
        case Inheritance.Typed:
        case Inheritance.Historical:
            return typedInheritanceToSplitLevel(scope);

        case Inheritance.Cluster:
            return 'Cluster';

        case Inheritance.Partner:
            return 'Partner';
    }
    return typedInheritanceToSplitLevel(scope);
};

export function storeInheritanceFormatter(
    value: Inheritance | null,
    clusterName?: string,
    countryName?: string,
    isNoosShare?: boolean
) {
    switch (value) {
        case Inheritance.Typed:
            return `Store (${Typed})`;

        case Inheritance.Historical:
            if (isNoosShare) return `${NOOS_HISTORICAL}`;
            return `Store (${Historical})`;

        case Inheritance.Cluster:
            return `Cluster ${clusterName}`;

        case Inheritance.Partner:
            return `${countryName}`;

        case Inheritance.FccInitial:
            return FCCInitial;

        case Inheritance.FccRevised:
            return FCCRevised;

        case Inheritance.HistoricalLLY:
            return `Store (${HistoricalLLY})`;

        case Inheritance.NoosDDR:
            return NOOS_DDR;

        case Inheritance.NoosHistoricalOrders:
            return NOOS_HISTORICAL_ORDERS;

        default:
            return '';
    }
}

export function noosSplitLevelToInheritance(splitLevel: NoosHistoricalInheritanceLevelEnum) {
    switch (splitLevel) {
        case 'NoosDDR':
            return Inheritance.NoosDDR;
        case 'NoosOrderSales':
            return Inheritance.NoosHistoricalOrders;
        case 'NoosHistorical':
            return Inheritance.Historical;
    }
}

export function salesBudgetSplitLevelToInheritance(splitLevel: SalesBudgetInheritanceLevelEnum) {
    switch (splitLevel) {
        case 'Store':
            return Inheritance.Typed;
        case 'FccInitial':
            return Inheritance.FccInitial;
        case 'FccRevised':
            return Inheritance.FccRevised;
        case 'Historical':
            return Inheritance.Historical;
        case 'HistoricalLLY':
            return Inheritance.HistoricalLLY;
    }
}

export function splitLevelToInheritance(
    splitLevel: ModuleInheritanceLevel | ModuleInheritanceLevelAlwaysTyped,
    scope: ScopeEnum,
    genderId: number | null,
    productLineGroupId: number | null
) {
    const isTyped = isModifiedByUser(splitLevel, genderId, productLineGroupId);
    switch (splitLevel.splitLevel) {
        case 'Store':
            return isTyped ? Inheritance.Typed : Inheritance.Historical;
        case 'Cluster':
            if (scope === ScopeEnum.CLUSTER) {
                return isTyped ? Inheritance.Typed : Inheritance.Historical;
            }
            return Inheritance.Cluster;
        case 'Partner':
            if (scope === ScopeEnum.PARTNER) {
                return isTyped ? Inheritance.Typed : Inheritance.Historical;
            }
            return Inheritance.Partner;
    }
}

export function isModifiedByUser(
    splitLevel:
        | ModuleInheritanceLevel
        | ModuleInheritanceLevelAlwaysTyped
        | ModuleInheritanceLevelNoos
        | ModuleInheritanceLevelSalesBudget,
    genderId: number | null,
    productLineGroupId: number | null
) {
    if (isModuleInheritanceLevel(splitLevel)) {
        return splitLevel.modifiedByUser?.some(
            (modifiedByUser) =>
                modifiedByUser.isTyped &&
                modifiedByUser.genderId === genderId &&
                modifiedByUser.productLineGroupId === productLineGroupId
        );
    }

    if (isModuleInheritanceLevelSalesBudget(splitLevel)) {
        return splitLevel.salesBudgetSplit === 'Store';
    }

    if (isModuleInheritanceLevelAlwaysTyped(splitLevel)) {
        return true;
    }

    return false;
}

// biome-ignore lint/suspicious/noExplicitAny: we need to use any
export function isModuleInheritanceLevelSalesBudget(splitLevel: any): splitLevel is ModuleInheritanceLevelSalesBudget {
    return Object.hasOwnProperty.call(splitLevel, 'salesBudgetSplit');
}

// biome-ignore lint/suspicious/noExplicitAny: we need to use any
function isModuleInheritanceLevel(splitLevel: any): splitLevel is ModuleInheritanceLevel {
    return Object.hasOwnProperty.call(splitLevel, 'modifiedByUser');
}

// biome-ignore lint/suspicious/noExplicitAny: we need to use any
function isModuleInheritanceLevelAlwaysTyped(splitLevel: any): splitLevel is ModuleInheritanceLevelAlwaysTyped {
    return (
        Object.hasOwnProperty.call(splitLevel, 'splitLevel') &&
        !Object.hasOwnProperty.call(splitLevel, 'modifiedByUser')
    );
}
