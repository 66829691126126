import { useCallback, useState } from 'react';
import { Inheritance, Module, ScopeEnum } from 'src/domain';
import { TableOverviewRow } from 'src/domain/table/table-overview-row';
import { useChangesSnackbar, useCurrentId, useDiscardChangesGenderProductline, useScope } from 'src/hooks';
import { useCompositePartner } from 'src/hooks/partner/useCompositePartner';
import { useUpdateSplitLevel } from 'src/hooks/split-levels/useUpdateSplitLevel';
import { useEntityType } from 'src/hooks/useEntityType';
import { components } from 'src/infrastructure/rest-api/generated';
import { useApiMutation } from 'src/infrastructure/rest-api/useApi';
import { useMarkupOverviewSimulationRows } from '../simulation/useMarkupOverviewSimulationRows';

type UpdateMarkupInput = components['schemas']['UpdateMarkupInput'];

function valueMapper(row: TableOverviewRow): UpdateMarkupInput[] {
    return Object.keys(row.columns).reduce<UpdateMarkupInput[]>((acc, key) => {
        if (key.endsWith('Id') || key.endsWith('PlannedSplit')) return acc;

        const id = row.columns[`${key}Id`];
        const value = row.columns[key];

        acc.push({
            id: id,
            value: value,
        });

        return acc;
    }, []);
}

export const useMarkupSaveChanges = () => {
    const currentId = useCurrentId();
    const compositePartner = useCompositePartner();
    const entityType = useEntityType();
    const scope = useScope();

    const [mutateMarkup] = useApiMutation('/api/markup', 'put', {
        update(data, _variables, queryClient) {
            const query = { entityId: Number(currentId), entityType };
            if (scope === ScopeEnum.PARTNER) {
                query['entityId'] = Number(compositePartner?.id);
            }
            queryClient.setQueryData(['/api/markup', query], data);
            queryClient.invalidateQueries({
                queryKey: ['/api/inheritance', query],
            });
        },
    });

    const { updateSplitLevel: updateStoreSplitLevel } = useUpdateSplitLevel();

    const showSnackbar = useChangesSnackbar();
    const discardChanges = useDiscardChangesGenderProductline(Module.Markup);

    const { data: valuesToUpdate } = useMarkupOverviewSimulationRows();
    const [isUpdating, setIsUpdating] = useState(false);

    const sendUpdateQuery = useCallback(async () => {
        if (!valuesToUpdate) {
            return;
        }

        const payload = valuesToUpdate.flatMap(valueMapper);

        if (scope === ScopeEnum.STORE) {
            await updateStoreSplitLevel(Inheritance.Typed);
        }

        return mutateMarkup({
            body: payload,
        });
    }, [mutateMarkup, scope, updateStoreSplitLevel, valuesToUpdate]);

    const updateMarkup = useCallback(async () => {
        setIsUpdating(true);

        try {
            const response = await sendUpdateQuery();
            discardChanges();
            showSnackbar();
            return response;
        } catch {
            showSnackbar(true);
        } finally {
            setIsUpdating(false);
        }
    }, [discardChanges, sendUpdateQuery, showSnackbar]);

    return [updateMarkup, { loading: isUpdating }] as const;
};
