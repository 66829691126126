import { Checkbox, FormControlLabel, Stack, styled } from '@mui/material';
import { IDoesFilterPassParams } from 'ag-grid-community';
import { CustomFilterProps, useGridFilter } from 'ag-grid-react';
import { isNumber } from 'lodash';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { StyleTimelineOverviewRow } from 'src/domain/table/style-timeline-overview-row';

interface LifeSpanFilterOption {
    isInfinite: boolean;
    value: number | null;
}

// biome-ignore lint/suspicious/noExplicitAny: we need to use any
export const LifeSpanFilter: FC<CustomFilterProps<StyleTimelineOverviewRow, any, Set<LifeSpanFilterOption>>> = ({
    api,
    onModelChange,
}) => {
    const allOptions = useMemo(() => {
        const allOptions = new Set<number>([]);
        let hasBlanks = false;
        let hasInfinite = false;
        api.forEachNodeAfterFilterAndSort((node) => {
            if (isNumber(node.data?.lifeSpan)) {
                allOptions.add(node.data.lifeSpan);
            }

            if (node.data?.exitWeekType === 'INFINITE') {
                hasInfinite = true;
            }

            if (node.data?.exitWeekType === 'EMPTY') {
                hasBlanks = true;
            }
        });

        const sortedOptions: LifeSpanFilterOption[] = [];

        if (hasBlanks) {
            sortedOptions.push({
                isInfinite: false,
                value: null,
            });
        }

        if (hasInfinite) {
            sortedOptions.push({
                isInfinite: true,
                value: null,
            });
        }

        sortedOptions.push(
            ...Array.from(allOptions)
                .sort((a, b) => a - b)
                .map((value) => ({
                    isInfinite: false,
                    value,
                }))
        );

        return sortedOptions;
    }, [api]);

    const [selectedOptions, setSelectedOptions] = useState<Set<LifeSpanFilterOption>>(new Set(allOptions));

    const onFilterToggled = useCallback(
        (filterOption: LifeSpanFilterOption) => {
            const newSelectedOptions = new Set(selectedOptions);
            if (selectedOptions.has(filterOption)) {
                newSelectedOptions.delete(filterOption);
            } else {
                newSelectedOptions.add(filterOption);
            }
            setSelectedOptions(newSelectedOptions);
        },
        [selectedOptions]
    );

    const doesFilterPass = useCallback(
        ({ data, node }: IDoesFilterPassParams<StyleTimelineOverviewRow>) => {
            if (node.group) {
                return true;
            }

            if (data.exitWeekType === 'EMPTY') {
                return selectedOptions.has(allOptions[0]);
            }

            if (data.exitWeekType === 'INFINITE') {
                return selectedOptions.has(allOptions[1]);
            }

            for (const filterOption of selectedOptions) {
                if (filterOption.value === data.lifeSpan) {
                    return true;
                }
            }

            return false;
        },
        [allOptions, selectedOptions]
    );

    const allOptionsSelected = useMemo(() => {
        return allOptions.every((option) => selectedOptions.has(option));
    }, [allOptions, selectedOptions]);

    useEffect(() => {
        if (allOptionsSelected) {
            onModelChange(null);
        } else {
            onModelChange(selectedOptions);
        }
    }, [allOptionsSelected, onModelChange, selectedOptions]);

    useGridFilter({
        doesFilterPass,
    });

    return (
        <Stack p={1}>
            <FilterOptionFormControlLabel
                label="Select all"
                control={
                    <Checkbox
                        checked={allOptionsSelected}
                        onChange={() => {
                            if (allOptionsSelected) {
                                setSelectedOptions(new Set());
                            } else {
                                setSelectedOptions(new Set(allOptions));
                            }
                        }}
                    />
                }
            />
            {allOptions.map((option) => {
                const infiniteOrBlankLabel = option.isInfinite ? '(infinite)' : '(blank)';
                const label = isNumber(option.value) ? option.value : infiniteOrBlankLabel;
                return (
                    <FilterOptionFormControlLabel
                        key={label}
                        label={label}
                        control={
                            <Checkbox
                                checked={selectedOptions.has(option)}
                                onChange={() => {
                                    onFilterToggled(option);
                                }}
                            />
                        }
                    />
                );
            })}
        </Stack>
    );
};

const FilterOptionFormControlLabel = styled(FormControlLabel)`
  margin: 0;
  gap: 0.5rem;
  .MuiCheckbox-root {
    padding: 0;
  }
`;
