import { useMemo } from 'react';
import { useStyleTimelineQuery } from 'src/hooks/style-timeline/queries/useStyleTimelineQuery';
import { mapStyleWarnings } from 'src/mapping/style-warnings.mapping';

export const useStyleWarningsRows = () => {
    const { styleTimelines, styleCategories, loading, error } = useStyleTimelineQuery();
    const rows = useMemo(() => {
        return mapStyleWarnings(styleTimelines, styleCategories);
    }, [styleTimelines, styleCategories]);

    return { data: rows, loading, error };
};
