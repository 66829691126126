import { useMemo } from 'react';

export const useIsDevEnv = () => {
    const isDevEnv = useMemo(() => {
        const hostname = window.location.hostname;
        return ['localhost', 'perci-frontend.dev.k8s.bestcorp.net', 'storeforecastdev.bestseller.com'].includes(
            hostname
        );
    }, []);

    return isDevEnv;
};
