import type { datadogRum as ddRumType } from '@datadog/browser-rum';
import { PropsWithChildren, ReactElement, createContext } from 'react';
import { EnvironmentVariableError } from 'src/domain';
import { useDataDog } from 'src/hooks/useDataDogRum';

import packageJson from '../../package.json';

const DataDogRumContext = createContext<typeof ddRumType | undefined>(undefined);

export const DataDogRumProvider = (props: PropsWithChildren<unknown>): ReactElement => {
    const env = import.meta.env.MODE;
    const applicationId = import.meta.env.VITE_APP_DATADOG_RUM_APPLICATION_ID;
    const clientToken = import.meta.env.VITE_APP_DATADOG_RUM_CLIENT_TOKEN;

    if (!applicationId) throw new EnvironmentVariableError(`DataDog RUM application ID missing`);
    if (!clientToken) throw new EnvironmentVariableError(`DataDog RUM client token missing`);

    const { datadogRum } = useDataDog({
        version: packageJson.version,
        applicationId,
        clientToken,
        service: 'storeforecast-5',
        env,
    });

    return <DataDogRumContext.Provider value={datadogRum}>{props.children}</DataDogRumContext.Provider>;
};
