import { useAccount, useMsal } from '@azure/msal-react';
import { useMemo } from 'react';
import { useDevTools } from '../devtools/useDevTools';

export const useMsalRoles = () => {
    const { isImpersonating, impersonateRoles } = useDevTools();
    const { accounts } = useMsal();
    const account = useAccount(accounts[0] || {});

    const roles = useMemo(() => {
        if (isImpersonating) {
            return impersonateRoles;
        }
        return account?.idTokenClaims?.roles ?? [];
    }, [account?.idTokenClaims?.roles, impersonateRoles, isImpersonating]);

    return roles;
};
