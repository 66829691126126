import { useMemo } from 'react';
import { Module } from 'src/domain';
import { PlanningRangeEnum } from 'src/infrastructure/rest-api/api-types';
import { useChainSettingsApiQuery } from './chain-settings/useChainSettingsApiQuery';
import { useCurrentModule } from './useCurrentModule';

export const useModulePlanningRange = (module?: Module): PlanningRangeEnum => {
    const { data } = useChainSettingsApiQuery();
    const currentModule = useCurrentModule();
    const selectedModule = module ?? currentModule;

    const planningRange = useMemo(() => {
        if (!data) return 'TWELVE_MONTHS';
        switch (selectedModule) {
            case Module.CategorySplit:
                return data?.categorySplitPlanningRange;
            case Module.Markdown:
                return data?.markDownPlanningRange;
            case Module.Markup:
                return data?.markupPlanningRange;
            case Module.NoosShare:
                return data?.noosSharePlanningRange;
            case Module.ProductLineSplit:
                return data?.productLineSplitPlanningRange;
            case Module.WeekCover:
                return data?.weekCoverPlanningRange;
            case Module.DeliveryProfile:
                return data?.deliveryProfilePlanningRange;
            case Module.GenderSplit:
                return data?.genderSplitPlanningRange;
            case Module.ProjectedOptionCount:
                return data?.projectedOptionCountPlanningRange;
            default:
                return 'TWELVE_MONTHS';
        }
    }, [data, selectedModule]);

    return planningRange;
};
