import { Inheritance, InheritanceModule, InheritanceOption, Module, ScopeEnum } from 'src/domain';
import { StoreType } from 'src/infrastructure/rest-api/api-types';

export interface Options {
    scope?: ScopeEnum | undefined;
    countryName?: string | null;
    clusterName?: string | null;
    storeName?: string | null;
    module?: Module;
    storeType?: StoreType;
    isFixed?: boolean;
}

export const Cluster = 'Cluster';
export const Typed = 'Typed';
export const Fixed = 'Fixed';
export const Historical = 'Historical';
export const HistoricalLLY = 'Historical LLY';
export const FCCRevised = 'FCC Revised';
export const FCCInitial = 'FCC Initial';

export const NOOS_HISTORICAL = 'NOOS Share';
export const NOOS_DDR = 'Auto replenishment share';
export const NOOS_HISTORICAL_ORDERS = 'Auto replenishment orders share';

export const getInheritanceOptions = (options: Options, field?: string | null): readonly InheritanceOption[] => {
    const { module } = options;

    switch (module) {
        case Module.NoosShare:
            return getInheritanceOptionsForNoos(options);
        case Module.Markdown:
        case Module.Markup:
            return getDefaultInheritanceOptions({
                ...options,
                isFixed: true,
            });
        case Module.DeliveryProfile:
        case Module.WeekCover:
            return getInheritanceOptionsWithoutHistorical({
                ...options,
                isFixed: true,
            });
        case Module.OptimalOptionCount:
            return getInheritanceOptionsWithoutHistorical(options);
        case Module.Turnover:
            return getInheritanceOptionsForTurnover(options);
        case Module.InheritanceSettings:
            if (field === InheritanceModule.SalesBudget) return getInheritanceOptionsForTurnover(options);
            if (field === InheritanceModule.NoosShare) return getInheritanceOptionsForNoos(options);
            if (field === InheritanceModule.DeliveryProfile || field === InheritanceModule.WeekCover)
                return getInheritanceOptionsWithoutHistorical(options);
            return getDefaultInheritanceOptions(options);
        default:
            return getDefaultInheritanceOptions(options);
    }
};

const getInheritanceOptionsWithoutHistorical = (options: Options): InheritanceOption[] => {
    const { scope, countryName, clusterName, storeName } = options;

    if (!scope) return [];

    switch (scope) {
        case ScopeEnum.CLUSTER:
            if (!clusterName) return [];

            return [
                {
                    value: Inheritance.Typed,
                    display: `${Cluster} ${clusterName} (${options.isFixed ? Fixed : Typed})`,
                },
            ];

        case ScopeEnum.PARTNER:
            if (!countryName) return [];

            return [
                {
                    value: Inheritance.Typed,
                    display: `${countryName} (${options.isFixed ? Fixed : Typed})`,
                },
            ];

        case ScopeEnum.GLOBAL:
            return [];

        case ScopeEnum.STORE:
            if (!countryName || !clusterName || !storeName) return [];

            return [
                {
                    value: Inheritance.Partner,
                    display: `${countryName}`,
                },
                {
                    value: Inheritance.Cluster,
                    display: `${Cluster} ${clusterName}`,
                },
                {
                    value: Inheritance.Typed,
                    display: `${storeName} (${options.isFixed ? Fixed : Typed})`,
                },
            ];
    }
};

const getDefaultInheritanceOptions = (options: Options): InheritanceOption[] => {
    const { scope, countryName, clusterName, storeName } = options;

    if (!scope) return [];

    switch (scope) {
        case ScopeEnum.CLUSTER:
            if (!clusterName) return [];

            return [
                {
                    value: Inheritance.Typed,
                    display: `${Cluster} ${clusterName} (${options.isFixed ? Fixed : Typed})`,
                },
                {
                    value: Inheritance.Historical,
                    display: `${Cluster} ${clusterName} (${Historical})`,
                },
            ];

        case ScopeEnum.PARTNER:
            if (!countryName) return [];

            return [
                {
                    value: Inheritance.Typed,
                    display: `${countryName} (${options.isFixed ? Fixed : Typed})`,
                },
                {
                    value: Inheritance.Historical,
                    display: `${countryName} (${Historical})`,
                },
            ];

        case ScopeEnum.GLOBAL:
            return [];

        case ScopeEnum.STORE:
            if (!countryName || !clusterName || !storeName) return [];

            return [
                {
                    value: Inheritance.Partner,
                    display: `${countryName}`,
                },
                {
                    value: Inheritance.Cluster,
                    display: `${Cluster} ${clusterName}`,
                },
                {
                    value: Inheritance.Typed,
                    display: `${storeName} (${options.isFixed ? Fixed : Typed})`,
                },
                {
                    value: Inheritance.Historical,
                    display: `${storeName} (${Historical})`,
                },
            ];
    }
};
const getInheritanceOptionsForNoos = (options: Options) => {
    const { scope, countryName, clusterName, storeName } = options;

    if (!scope) return [];

    switch (scope) {
        case ScopeEnum.CLUSTER:
            if (!clusterName) return [];

            return [
                {
                    value: Inheritance.Typed,
                    display: `${Cluster} ${clusterName} (${Typed})`,
                },
                {
                    value: Inheritance.Historical,
                    display: `${Cluster} ${clusterName} (${NOOS_HISTORICAL})`,
                },
                {
                    value: Inheritance.NoosDDR,
                    display: `${Cluster} ${clusterName} (${NOOS_DDR})`,
                },
                {
                    value: Inheritance.NoosHistoricalOrders,
                    display: `${Cluster} ${clusterName} (${NOOS_HISTORICAL_ORDERS})`,
                },
            ];
        case ScopeEnum.GLOBAL:
            return [];
        case ScopeEnum.PARTNER:
            if (!countryName) return [];

            return [
                {
                    value: Inheritance.Typed,
                    display: `${countryName} (${Typed})`,
                },
                {
                    value: Inheritance.Historical,
                    display: `${countryName} (${NOOS_HISTORICAL})`,
                },
                {
                    value: Inheritance.NoosDDR,
                    display: `${countryName} (${NOOS_DDR})`,
                },
                {
                    value: Inheritance.NoosHistoricalOrders,
                    display: `${countryName} (${NOOS_HISTORICAL_ORDERS})`,
                },
            ];

        case ScopeEnum.STORE:
            if (!countryName || !clusterName || !storeName) return [];

            return [
                {
                    value: Inheritance.Partner,
                    display: `${countryName}`,
                },
                {
                    value: Inheritance.Cluster,
                    display: `${Cluster} ${clusterName}`,
                },
                {
                    value: Inheritance.Typed,
                    display: `${storeName} (${Typed})`,
                },
                {
                    display: `${Historical}`,
                    value: [
                        {
                            value: Inheritance.Historical,
                            display: `${NOOS_HISTORICAL}`,
                        },
                        {
                            value: Inheritance.NoosDDR,
                            display: `${NOOS_DDR}`,
                        },
                        {
                            value: Inheritance.NoosHistoricalOrders,
                            display: `${NOOS_HISTORICAL_ORDERS}`,
                        },
                    ],
                },
            ];
    }
};

const getInheritanceOptionsForTurnover = (options: Options) => {
    const { scope, countryName, clusterName, storeName, storeType } = options;

    if (!scope) return [];

    switch (scope) {
        case ScopeEnum.CLUSTER:
            return [];

        case ScopeEnum.PARTNER:
            return [];

        case ScopeEnum.GLOBAL:
            return [];

        case ScopeEnum.STORE:
            if (!countryName || !clusterName || !storeName) return [];

            if (storeType === 'OWN_STORE') {
                return [
                    {
                        value: Inheritance.FccInitial,
                        display: `${FCCInitial}`,
                    },
                    {
                        value: Inheritance.FccRevised,
                        display: `${FCCRevised}`,
                    },
                    {
                        value: Inheritance.Typed,
                        display: `${storeName} (${Fixed})`,
                    },
                    {
                        display: `${Historical}`,
                        value: [
                            {
                                value: Inheritance.Historical,
                                display: `${storeName} (${Historical})`,
                            },
                            {
                                value: Inheritance.HistoricalLLY,
                                display: `${storeName} (${HistoricalLLY})`,
                            },
                        ],
                    },
                ];
            }

            return [
                {
                    value: Inheritance.Typed,
                    display: `${storeName} (${Typed})`,
                },
                {
                    display: `${Historical}`,
                    value: [
                        {
                            value: Inheritance.Historical,
                            display: `${storeName} (${Historical})`,
                        },
                        {
                            value: Inheritance.HistoricalLLY,
                            display: `${storeName} (${HistoricalLLY})`,
                        },
                    ],
                },
            ];
    }
};

export function isInheritanceOption(value: unknown): value is InheritanceOption {
    return (
        !!value &&
        Object.prototype.hasOwnProperty.call(value, 'display') &&
        Object.prototype.hasOwnProperty.call(value, 'value')
    );
}

export function getInheritanceValue(inheritanceOption: string | InheritanceOption | null | undefined): string {
    if (typeof inheritanceOption === 'string') {
        return inheritanceOption;
    }

    if (isInheritanceOption(inheritanceOption)) {
        if (!Array.isArray(inheritanceOption.value)) {
            return getInheritanceValue(inheritanceOption.value);
        }
    }

    return '';
}
