import styled from '@emotion/styled';
import { Box, Typography } from '@mui/material';
import { FC } from 'react';

export const BuyingEventListHeader: FC = () => {
    return (
        <StyledBox>
            <InfoRow>
                <div />
                <StyledTypography>Event name</StyledTypography>
                <StyledTypography>Date</StyledTypography>
                <StyledTypography>Delivery range</StyledTypography>
                <StyledTypography>Categories</StyledTypography>
                <StyledTypography>Actions</StyledTypography>
            </InfoRow>
        </StyledBox>
    );
};

const StyledBox = styled(Box)`
  display: flex;
  align-items: center;
  background-color: #f9f9fb;
  border-radius: 5px 5px 0 0;
  border: 1px solid #f5f5f5;
  height: 30px;
  margin-top: 24px;
`;

const StyledTypography = styled(Typography)`
  color: #787c8d;
`;

const InfoRow = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 0.2fr 1.5fr 0.6fr 1.2fr 2fr 0.4fr 0.2fr;
  align-items: center;
`;
