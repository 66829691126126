import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { FC } from 'react';

type ConfirmationDialogProps = {
    open: boolean;
    title: string;
    content: string;
    onOk?: () => void;
    onCancel?: () => void;
};

export const ConfirmationDialog: FC<ConfirmationDialogProps> = ({ onOk, onCancel, open, title, content }) => {
    return (
        <Dialog
            open={open}
            onClose={onCancel}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description" noWrap={false}>
                    {content}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant={'outlined'} onClick={onCancel}>
                    Cancel
                </Button>
                <Button variant="contained" onClick={onOk} autoFocus>
                    Ok
                </Button>
            </DialogActions>
        </Dialog>
    );
};
