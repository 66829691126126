import { useReactiveVar } from '@bestseller-bit/retail-planning.utils.reactive-vars';
import { useCallback } from 'react';
import { Module, ScopeEnum } from 'src/domain';
import { selectedInheritanceMapVar } from 'src/infrastructure/local_state';
import { SerializeMap } from 'src/utils/SerializeMap';
import { useCurrentId } from '../useCurrentId';
import { useCurrentModule } from '../useCurrentModule';
import { useScope } from '../useScope';
import { useUnsavedInheritance } from './useStoredInheritance';

interface Options {
    scope?: ScopeEnum;
    id?: unknown;
    module?: Module;
}

export const useResetInheritance = (opts?: Options) => {
    const currentScope = useScope();
    const scope = opts?.scope ?? currentScope;

    const currentId = useCurrentId();
    const id = opts?.id ?? currentId;

    const currentModule = useCurrentModule();
    const module = opts?.module ?? currentModule;

    const [_, setSelectedInheritanceMap] = useReactiveVar(selectedInheritanceMapVar);
    const { setSessionStorageInheritance } = useUnsavedInheritance();

    const onDiscardChanges = useCallback(() => {
        if (!module || !scope || typeof id !== 'number') return;

        setSessionStorageInheritance({});
        setSelectedInheritanceMap((prev) => {
            // have to make a new map cause of reference
            const newMap = new SerializeMap(prev);
            newMap.delete({ module, scope, id });
            return newMap;
        });
    }, [id, module, scope, setSelectedInheritanceMap, setSessionStorageInheritance]);

    return onDiscardChanges;
};
