import { AccountInfo } from '@azure/msal-browser';
import type { LDContext, LDFlagSet } from 'launchdarkly-js-sdk-common';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useDevTools } from './devtools/useDevTools';

/**
 * @param {(LDContext | undefined)} ldContext - The LaunchDarkly context, containing user data
 * @param {(LDFlagSet | undefined)} ldFlags - All flags and their values depending on the logged in user
 * @param {boolean} isLdUserLoggedIn - Whether or not the context is there and the user is logged in
 */
interface ReturnTypes {
    ldContext: LDContext | undefined;
    ldFlags: LDFlagSet | undefined;
    isLdUserLoggedIn: boolean;
}

/**
 * @description This hook is meant for interactions with the LaunchDarkly SDK
 * @param {string} ldUser.id - The profile id from the Microsoft Graph API
 * @param {string} ldUser.name - The profile name from the Microsoft Graph API
 * @param {string} ldUser.mail - The profile email from the Microsoft Graph API
 * @param {Function} [onError] - A callback that is invoked when LaunchDarkly returns an error
 * @returns {(LDContext | undefined)} ldContext - The LaunchDarkly context, containing user data
 * @returns {(LDFlagSet | undefined)} ldFlags - All flags and their values depending on the logged in user
 * @returns {boolean} isLdUserLoggedIn - Whether or not the context is there and the user is logged in
 */
export const useLaunchDarkly = (ldUser?: AccountInfo, onError?: (err: Error) => void): ReturnTypes => {
    const lastLDKey = useRef<string | undefined>(undefined);
    const [ldClientReady, setLdClientReady] = useState<boolean>(false);
    const ldClient = useLDClient();
    const ldContext = ldClient?.getContext();
    const ldFlags = ldClient?.allFlags();

    const isLdUserLoggedIn = useMemo(() => !!ldContext && !ldContext.anonymous, [ldContext]);

    const { isImpersonating, impersonateEmail } = useDevTools();

    useEffect(() => {
        ldClient
            ?.waitUntilReady()
            .then((): void => {
                setLdClientReady(true);
            })
            .catch((err) => {
                if (import.meta.env.MODE === 'development') {
                    // biome-ignore lint/suspicious/noConsole: provide better reason
                    console.error(`[LAUNCHDARKLY]\nldClient.waitUntilReady failed\n\n`, err);
                }
                onError?.(err);
            });
    }, [ldClient, onError]);

    useEffect(() => {
        const currentLDKey = isImpersonating ? impersonateEmail : ldUser?.username;
        if (ldClient && ldClientReady && currentLDKey && lastLDKey.current !== currentLDKey) {
            // Only call identity if the key changed from last time
            lastLDKey.current = currentLDKey;

            const newLDUser: LDContext = {
                key: currentLDKey,
            };
            ldClient.identify(newLDUser, undefined, (err: Error | null, freshFlags: LDFlagSet | null): void => {
                if (err) {
                    if (import.meta.env.MODE === 'development') {
                        // biome-ignore lint/suspicious/noConsole: provide better reason
                        console.error(`[LAUNCHDARKLY]\nldClient.identify failed\nkey:   ${newLDUser.key}\n\n`, err);
                    }
                    onError?.(err);
                } else if (freshFlags) {
                    if (import.meta.env.MODE === 'development') {
                        // biome-ignore lint/suspicious/noConsole: provide better reason
                        console.debug(
                            `[LAUNCHDARKLY]\nldClient.identify succeeded\nkey:   ${newLDUser.key}\n\n`,
                            freshFlags
                        );
                    }
                }
            });
        }
    }, [impersonateEmail, isImpersonating, ldClient, ldClientReady, ldUser, onError]);

    return { ldContext, ldFlags, isLdUserLoggedIn };
};
