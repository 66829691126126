import { uniq } from 'lodash';
import { TableOverviewRow } from 'src/domain/table/table-overview-row';
import { VatOverviewRow } from 'src/domain/table/vat-overview-row';
import {
    calculateGrossMarginWithMarkdown,
    calculateGrossMarginWithoutMarkdown,
} from 'src/utils/gross-margin/calculateGrossMargin';
import { mergeMap } from 'src/utils/mergeMap';

interface GrossMarginMergeArgs {
    turnover: TableOverviewRow;
    vat: VatOverviewRow;
    categorySplitRows: TableOverviewRow[];
    markupRows: TableOverviewRow[];
    markdownRows: TableOverviewRow[];
    includeMarkdown?: boolean;
}

export const mergeDataGrossMargin = ({
    turnover,
    vat,
    categorySplitRows,
    markupRows,
    markdownRows,
    includeMarkdown,
}: GrossMarginMergeArgs): TableOverviewRow[] => {
    const result = mergeMap(
        [categorySplitRows, markupRows, markdownRows],
        ([cSplit, markup, markdown], i): TableOverviewRow => {
            if (!cSplit || !markup || !markdown) return {} as TableOverviewRow;

            const tempRow: TableOverviewRow = {
                id: i,
                category: cSplit.category, // category should be the same for all three
                columns: {},
            };

            const keys = uniq([
                ...Object.keys(cSplit.columns),
                ...Object.keys(markup.columns),
                ...Object.keys(markdown.columns),
            ]);

            keys.forEach((month) => {
                const monthNameForCategorySplit = month.includes('PlannedSplit')
                    ? month.split('PlannedSplit')[0]
                    : month;
                // ? How do we handle when some of these are 0?
                const turnoverForMonth = turnover.columns[month];
                const vatForMonth = vat[month];
                const cSplitForMonth = cSplit.columns[monthNameForCategorySplit];
                const markupForMonth = markup.columns[month];
                const markdownForMonth = markdown.columns[month];

                if (turnoverForMonth === 0 || vatForMonth === 0 || cSplitForMonth === 0 || markupForMonth === 0) {
                    tempRow.columns[month] = 0;
                    return tempRow;
                }

                let gm = 0;

                if (includeMarkdown)
                    gm = month.includes('PlannedSplit')
                        ? cSplitForMonth
                        : calculateGrossMarginWithMarkdown({
                              vat: vatForMonth,
                              turnover: turnoverForMonth,
                              markup: markupForMonth,
                              markdown: markdownForMonth,
                          });
                else
                    gm = month.includes('PlannedSplit')
                        ? cSplitForMonth
                        : calculateGrossMarginWithoutMarkdown({
                              vat: vatForMonth,
                              turnover: turnoverForMonth,
                              markup: markupForMonth,
                          });

                tempRow.columns[month] = gm;
            });

            return tempRow as TableOverviewRow;
        }
    );

    return result ?? [];
};
