import { isNumber } from 'lodash';

export const calculatePlannedIndex = (
    // ! FIXME: Index is unused - we should remove it from the function signature
    _index: number,
    salesIv: number | undefined | null,
    salesIvLy: number | undefined | null,
    salesIvLly: number | undefined | null
) => {
    // can't calculate index
    if (!isNumber(salesIv)) return;

    // calculate index based on LY or LLY
    if (!isNumber(salesIvLy) && isNumber(salesIvLly) && salesIvLly > 0) {
        return Math.round((salesIv / salesIvLly) * 100);
    } else if (isNumber(salesIvLy) && salesIvLy > 0) {
        return Math.round((salesIv / salesIvLy) * 100);
    }
    return 0;
};
