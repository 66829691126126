import { isNumber } from 'lodash';
import { categoriesSum } from 'src/components/views/StockManagement/helpers/categories-sum';
import {
    CategoryMonthMinMaxList,
    MarkupDetailsRow,
    MonthMinMaxList,
    StockManagement,
    TableOverviewRow,
} from 'src/domain';
import { StockManagementRow } from 'src/domain/table/stock-management.row';
import {
    StockManagementModel,
    StockManagementOverviewModel,
    StyleCategoryModel,
} from 'src/infrastructure/rest-api/api-types';
import { calculateStockOptimalAdjusted, calculateStockOptimalMonthTotal } from 'src/utils/calculateStockOptimal';
import { monthFieldToDate, monthKeyToField } from 'src/utils/monthKeys';

export const sanitizeCategoriesList = (
    data: StockManagementModel[],
    categories: StyleCategoryModel[] | undefined
): CategoryMonthMinMaxList => {
    const unsortedResult: CategoryMonthMinMaxList = {};
    const sortedResult: CategoryMonthMinMaxList = {};

    data?.forEach((element) => {
        const monthField = monthKeyToField(element?.monthKey);
        const category = categories?.find((category) => element?.styleCategoryId === category?.id)?.name;

        const id = element.id;
        const min = element.minimum;
        const max = element.maximum;
        if (!category) throw new Error('Category missing  in Stock Management');

        if (unsortedResult[category] === undefined) unsortedResult[category] = {};

        unsortedResult[category][monthField] = {
            id,
            min,
            max,
        };
    });

    categories?.forEach((category) => {
        const categoryName = category?.name;
        if (categoryName) sortedResult[categoryName] = unsortedResult[categoryName];
    });
    return sortedResult;
};

export const sanitizeTotalsList = (data: StockManagementModel[]): MonthMinMaxList => {
    const result: MonthMinMaxList = {};

    data?.forEach((element) => {
        const month = element?.monthKey ? monthKeyToField(element?.monthKey) : undefined;
        const id = element?.id;
        const min = element?.minimum;
        const max = element?.maximum;

        if (!month) throw new Error('Month missing in Stock Management');

        if (!id) throw new Error('ID missing in Stock Management');
        if (min === undefined) throw new Error('Minimum value missing in Stock Management');
        if (max === undefined) throw new Error('Maximum value missing in Stock Management');

        if (result[month] === undefined) result[month] = { id, min, max };
    });

    return result;
};

export const stockManagementCategoriesToTableData = (data: CategoryMonthMinMaxList | null | undefined) => {
    if (!data) return [];

    let id = 0;
    const rows = Object.entries(data).reduce<StockManagementRow[]>((acc, [category, months]) => {
        const minimum: StockManagementRow = {
            id: id++,
            category,
            subCategory: 'Minimum',
            columns: {},
        };
        const maximum: StockManagementRow = {
            id: id++,
            category,
            subCategory: 'Maximum',
            columns: {},
        };
        Object.keys(months).forEach((month) => {
            minimum.columns[`${month}Id`] = maximum.columns[`${month}Id`] = months[month].id;
            const min = months[month].min;
            const max = months[month].max;

            minimum.columns[month] = min;
            maximum.columns[month] = max;
        });

        acc.push(minimum, maximum);

        return acc;
    }, []);

    if (rows.last()?.category.toLowerCase() !== 'total') {
        const sum = categoriesSum(rows);
        rows.push(...sum);
    }

    return rows;
};

export const stockManagementTotalsToTableData = (
    data: MonthMinMaxList | undefined | null,
    markupData: Record<string, Record<string, Map<string, MarkupDetailsRow[]> | undefined>>,
    weekCoverData: Record<string, Record<string, TableOverviewRow[]>>,
    styleCategories: StyleCategoryModel[] | undefined
) => {
    if (!data || !markupData || !weekCoverData || !styleCategories) return [];

    const expectedCogsPerMonth: Record<string, number> = Object.values(markupData)
        .flatMap(Object.values)
        .reduce<Record<string, number>>((acc, monthData: Map<string, MarkupDetailsRow[]> | undefined) => {
            monthData?.forEach((rows, month) => {
                acc[month] = acc[month] ?? 0;
                acc[month] += rows.reduce((acc, row) => acc + row.expectedCogs, 0);
            });

            return acc;
        }, {});

    const groupedRows = Object.entries(data).reduce<Record<string, StockManagementRow>>((acc, [month, value]) => {
        acc['Minimum'] = acc['Minimum'] ?? {
            id: 0,
            category: 'Minimum',
            subCategory: 'Minimum',
            columns: {},
        };

        acc['Minimum'].columns[month] = value.min;
        acc['Minimum'].columns[`${month}Id`] = value.id;

        acc['Maximum'] = acc['Maximum'] ?? {
            id: 1,
            category: 'Maximum',
            subCategory: 'Maximum',
            columns: {},
        };
        acc['Maximum'].columns[month] = value.max;
        acc['Maximum'].columns[`${month}Id`] = value.id;

        acc['Stock Optimal'] = acc['Stock Optimal'] ?? {
            id: 2,
            category: 'Stock Optimal',
            subCategory: 'Stock Optimal',
            columns: {},
        };

        const monthDate = monthFieldToDate(month);
        const stockOptimal = Object.entries(markupData).reduce((acc, [productlineKey, genderData]) => {
            Object.entries(genderData).forEach(([genderKey, markupData]) => {
                const stockOptimalMonthTotal = calculateStockOptimalMonthTotal(
                    monthDate,
                    styleCategories,
                    markupData,
                    weekCoverData[productlineKey][genderKey]
                );
                acc += stockOptimalMonthTotal;
            });

            return acc;
        }, 0);

        acc['Stock Optimal'].columns[month] = Math.round(stockOptimal);
        acc['Stock Optimal Adj.'] = acc['Stock Optimal Adj.'] ?? {
            id: 3,
            category: 'Stock Optimal Adj.',
            subCategory: 'Stock Optimal Adj.',
            columns: {},
        };

        const stockMin = acc['Minimum'].columns[month];
        const stockMax = acc['Maximum'].columns[month];
        if (
            isNumber(stockOptimal) &&
            (isNumber(stockMin) || stockMin === null) &&
            (isNumber(stockMax) || stockMax === null)
        ) {
            const stockOptimalAdjusted = calculateStockOptimalAdjusted(stockOptimal, stockMin, stockMax);
            acc['Stock Optimal Adj.'].columns[month] = Math.round(stockOptimalAdjusted);
        }

        acc['Expected COGS'] = acc['Expected COGS'] ?? {
            id: 4,
            category: 'Expected COGS',
            subCategory: 'Expected COGS',
            columns: {},
        };
        acc['Expected COGS'].columns[month] = Math.round(expectedCogsPerMonth[month]);

        return acc;
    }, {});

    return Object.values(groupedRows);
};

export const queryToStockManagement = (
    data: StockManagementOverviewModel | undefined,
    styleCategories: StyleCategoryModel[]
): StockManagement | null => {
    if (!data) return null;

    const stockManagementCategories = data.stockManagementCategories ?? [];
    const stockManagementTotals = data.stockManagementTotals ?? [];

    const categoriesList = sanitizeCategoriesList(stockManagementCategories, styleCategories);
    const totalsList = sanitizeTotalsList(stockManagementTotals);

    return {
        categoriesList,
        totalsList,
    };
};
